import { cx } from 'class-variance-authority';
import { FC } from 'react';

import { DropdownItem } from '../../types';

export const DropdownMenuItem: FC<DropdownItem> = ({
  label,
  label2,
  onClick = () => {},
  renderLeftIcon,
  renderRightIcon,
  active,
}) => {
  return (
    <div
      className={cx(
        'text-label-regular flex cursor-pointer items-center gap-1 whitespace-nowrap p-4 transition-colors',
        {
          'bg-white-8': !!active,
          'hover:bg-white-4': !active,
        }
      )}
      onClick={onClick}
    >
      {renderLeftIcon && <div className="mr-2">{renderLeftIcon()}</div>}
      <span
        className={cx('text-white', {
          'font-bold': !!active,
        })}
      >
        {label}
      </span>
      <span className="text-white-40">{label2}</span>
      {renderRightIcon && <div className="ml-auto">{renderRightIcon()}</div>}
    </div>
  );
};
