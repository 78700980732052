import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { toast } from '@highmobility/console-ui-components';

export function downloadTextFile(contents: string, fileName: `${string}.${'txt' | 'json'}`) {
  try {
    saveAs(new Blob([contents], { type: 'text/plain' }), fileName);
    toast(`Downloaded ${fileName}`, 'success');
  } catch (e) {
    toast('Failed to download file.', 'error');
  }
}

export async function downloadZipFile(
  zipFileName: `${string}.zip`,
  files: {
    fileName: string;
    contents: string;
  }[],
  options?: { toastMessage: string }
) {
  try {
    const zip = new JSZip();
    for (const { fileName, contents } of files) {
      zip.file(fileName, contents);
    }

    const zipFile = await zip.generateAsync({ type: 'blob' });
    saveAs(zipFile, zipFileName);
    toast(options?.toastMessage ?? `Downloaded ${zipFileName}`, 'success');
  } catch (e) {
    toast('Failed to download zip file.', 'error');
  }
}

export async function copyTextToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text);
    toast('Text copied to clipboard', 'success');
  } catch (e) {
    toast('Failed to copy text to clipboard', 'error');
  }
}
