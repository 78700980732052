import { Button } from '@highmobility/console-ui-components';
import { LoaderFunction, redirect } from 'react-router-dom';

import { AuthBackgroundIllustrations } from '@/components/atoms/AuthBackgroundIllustrations';
import { AuthHeader } from '@/components/atoms/AuthHeader';
import { GeneralTemplate } from '@/components/templates/general/GeneralTemplate';
import { ROUTE_PATHS } from '@/router/routes';
import { useQueryParams } from '@/hooks/useQueryParams';

export const loader: LoaderFunction = async (args) => {
  const redirectTo = new URL(args.request.url).searchParams.get('redirect_to');

  if (!redirectTo) {
    return redirect(ROUTE_PATHS.ROOT);
  }

  const redirectUrl = decodeURIComponent(redirectTo);
  const redirectIsSameDomain = redirectUrl.startsWith(window.location.origin);
  if (!redirectIsSameDomain) {
    return redirect(ROUTE_PATHS.ROOT);
  }

  return null;
};

export const ActivateAccountView = () => {
  const queryParams = useQueryParams();
  const [baseUrl, queryString] = (queryParams.redirect_to || '').split('?');
  const redirectUrl = `${decodeURIComponent(baseUrl)}?${queryString}`;

  return (
    <GeneralTemplate
      className="bg-beige-100"
      renderHeader={() => <AuthHeader type="signup" />}
      renderIllustrations={() => <AuthBackgroundIllustrations />}
    >
      <main className="pb-header flex h-full w-full items-center justify-around">
        <div className="bg-dark-green-900 w-login-modal-sm flex flex-col gap-6 rounded p-12">
          <h1 className="text-heading-1">Almost done!</h1>
          <p className="text-body-regular font-bold">
            Please verify your email address to complete the registration process.
          </p>
          <a href={redirectUrl} className="mx-auto">
            <Button intent="primary">Verify Email Address</Button>
          </a>
        </div>
      </main>
    </GeneralTemplate>
  );
};
