import {
  AlertDialog,
  AlertDialogProps,
  ControlRow,
  toast,
} from '@highmobility/console-ui-components';
import { capitalize } from 'radash';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { Role } from '@highmobility/console-role-matrix';

import { fakeLoader, updateInvitationRole, updateTeamMemberRole } from '@/services/apiService';
import { updateTeamMemberRoleValidation } from '@/services/zodService';
import { useMobx } from '@/hooks/useMobx';

type EditUserRoleDialogProps = {
  teamId: string;
  currentRole: Role;
  name?: string;
  email: string;
  id: string;
  isPending: boolean;
} & Pick<AlertDialogProps, 'isOpen' | 'setIsOpen' | 'onCancel'>;

export const EditUserRoleDialog = (props: EditUserRoleDialogProps) => {
  const { teams } = useMobx();
  const { register, handleSubmit } = useForm<z.infer<typeof updateTeamMemberRoleValidation>>({
    resolver: zodResolver(updateTeamMemberRoleValidation),
    defaultValues: { role: props.currentRole },
  });

  const updateMemberRoleMutation = useMutation({
    mutationFn: async (data: z.infer<typeof updateTeamMemberRoleValidation>) => {
      const promises = [fakeLoader()];
      if (props.isPending) {
        promises.push(updateInvitationRole(props.teamId, props.id, data));
      } else {
        promises.push(updateTeamMemberRole(props.teamId, props.id, data));
      }

      return Promise.all(promises);
    },
    onError: (e: any) => {
      console.error('Failed to update user role', e);
      toast(e?.response?.data?.message || 'Something went wrong. Please try again.', 'error');
      props.setIsOpen(false);
    },
    onSuccess: async () => {
      await teams.fetchTeam(props.teamId);
      toast('User role updated!', 'success');
      props.setIsOpen(false);
    },
  });

  const onSubmit = handleSubmit((data) => updateMemberRoleMutation.mutate(data));

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title="Edit user role"
      confirmButtonIntent="primary"
      cancelLabel="Close"
      onConfirm={onSubmit}
      onCancel={props.onCancel}
      confirmLabel={updateMemberRoleMutation.isPending ? 'Loading' : 'Save'}
      confirmButtonDisabled={updateMemberRoleMutation.isPending}
    >
      <div className="flex flex-col">
        <div className="border-white-8 mb-6 w-full border-b pb-2">
          <p className="text-body-regular text-white-60">Update permissions for:</p>
        </div>
        <div className="border-white-8 mb-6 w-full border-b pb-6">
          {props.name && <h5 className="text-heading-5">{props.name}</h5>}
          <p className="text-body-regular text-white">{props.email}</p>
        </div>
        <form>
          <ControlRow
            label={<b>{capitalize(Role.ADMIN)}</b>}
            label2="Admins possess full permissions concerning teams, members, roles, projects, billing, simulators, and more."
            type="radio"
            transparent
            {...register('role')}
            defaultValue={Role.ADMIN}
          />
          <ControlRow
            label={<b>{capitalize(Role.MEMBER)}</b>}
            label2="Members have permissions for creating teams, managing projects and simulators, as well as accessing credentials."
            type="radio"
            transparent
            {...register('role')}
            defaultValue={Role.MEMBER}
          />
          <ControlRow
            label={<b>{capitalize(Role.VIEWER)}</b>}
            label2="Viewer can access projects, create a team, and edit the simulator."
            type="radio"
            transparent
            {...register('role')}
            defaultValue={Role.VIEWER}
          />
        </form>
      </div>
    </AlertDialog>
  );
};
