import {
  AlertDialog,
  AlertDialogProps,
  TextInput,
  toast,
} from '@highmobility/console-ui-components';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { fakeLoader, updateSimulatorName } from '@/services/apiService';
import { renameSimulatorValidation } from '@/services/zodService';
import { Simulator } from '@/types';

type RenameSimulatorDialogProps = Pick<AlertDialogProps, 'isOpen' | 'setIsOpen'> & {
  teamId: string;
  simulator: Simulator;
  onRename: () => void;
};

export function RenameSimulatorDialog(props: RenameSimulatorDialogProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof renameSimulatorValidation>>({
    resolver: zodResolver(renameSimulatorValidation),
    defaultValues: {
      name: props.simulator.name,
    },
  });

  const renameSimulatorMutation = useMutation({
    mutationFn: async (data: z.infer<typeof renameSimulatorValidation>) => {
      return Promise.all([
        fakeLoader(),
        updateSimulatorName(props.teamId, props.simulator.vin, data),
      ]);
    },
    onError: (e) => {
      console.error('Failed to rename simulator', e);
      toast('Something went wrong. Please try again.', 'error');
      props.setIsOpen(false);
    },
    onSuccess: async () => {
      toast('Simulator renamed successfully!', 'success');
      props.onRename();
      props.setIsOpen(false);
    },
  });

  const onSubmit = handleSubmit((data) => renameSimulatorMutation.mutate(data));

  return (
    <AlertDialog
      title="Rename simulator"
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      onCancel={() => {
        props.setIsOpen(false);
      }}
      onConfirm={onSubmit}
      cancelLabel="Cancel"
      confirmLabel={renameSimulatorMutation.isPending ? 'Saving' : 'Save'}
      confirmButtonDisabled={renameSimulatorMutation.isPending}
    >
      <form onSubmit={onSubmit} autoComplete="false">
        <div className="mb-8 flex flex-col gap-6">
          <TextInput
            placeholder="My fleet app"
            {...register('name')}
            error={errors.name?.message}
          />
        </div>
      </form>
    </AlertDialog>
  );
}
