import { cva, cx, VariantProps } from 'class-variance-authority';

import { StatusDraft, StatusLive, StatusLiveUpdates, StatusPending } from '../../assets';

const projectStatusIcon = cva('', {
  variants: {
    size: {
      small: 'w-4 h-4 flex-shrink-0',
      large: 'w-6 h-6 flex-shrink-0',
    },
  },
});

export type ProjectStatusIconProps = VariantProps<typeof projectStatusIcon> & {
  status: 'draft' | 'in_review' | 'published' | 'published_with_changes';
  className?: string;
};

export const ProjectStatusIcon = (props: ProjectStatusIconProps) => {
  const { size = 'small' } = props;

  switch (props.status) {
    case 'draft':
      return <StatusDraft className={cx(projectStatusIcon({ size }), props.className)} />;
    case 'in_review':
      return <StatusPending className={cx(projectStatusIcon({ size }), props.className)} />;
    case 'published':
      return <StatusLive className={cx(projectStatusIcon({ size }), props.className)} />;
    case 'published_with_changes':
      return <StatusLiveUpdates className={cx(projectStatusIcon({ size }), props.className)} />;
  }
  /* istanbul ignore next */
  props.status satisfies never;
};
