import { cx } from 'class-variance-authority';
import { FC } from 'react';

import { Chevron } from '../../assets';

export type BrandLinkProps = {
  renderLogo: (className: string) => JSX.Element;
  label: string;
  secondaryLabel?: string;
  active?: boolean;
  renderPrice?: (className: string) => JSX.Element;
};

export const BrandLink: FC<BrandLinkProps> = (props: BrandLinkProps) => {
  return (
    <div
      className={cx(
        'border-black-20 hover:bg-white-4 flex cursor-pointer items-center border-b transition-colors',
        {
          'bg-white-4': props.active,
        }
      )}
    >
      <div className="border-black-20 flex h-16 w-16 flex-shrink-0 items-center justify-center border-r">
        {props.renderLogo('w-10 h-10 text-white flex-shrink-0')}
      </div>
      <div className="flex flex-1 items-center gap-4 px-4">
        <div className="flex-1 whitespace-nowrap">
          <span className="text-body-regular text-white">{props.label}</span>{' '}
          <span className="text-body-regular text-white-60">{props.secondaryLabel}</span>
        </div>
        {props.renderPrice ? (
          props.renderPrice('text-label-regular text-success-500 whitespace-nowrap hover:underline')
        ) : (
          <Chevron
            className={cx('h-4 w-4 flex-shrink-0 -rotate-90', {
              'text-white': props.active,
              'text-white-40': !props.active,
            })}
          />
        )}
      </div>
    </div>
  );
};
