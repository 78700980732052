import { cva, cx, VariantProps } from 'class-variance-authority';
import { forwardRef, ForwardRefRenderFunction } from 'react';

import { Checkbox, CheckboxProps } from '../atoms/Checkbox';
import { Radio, RadioProps } from '../atoms/Radio';

const controlRow = cva('flex items-start gap-3 p-3 min-w-brand-card rounded select-none', {
  variants: {
    disabled: {
      true: ['cursor-not-allowed'],
      false: ['cursor-pointer'],
    },
    layout: {
      row: 'flex-row items-center',
      column: 'flex-col items-start',
    },
    transparent: {
      true: 'bg-transparent',
      false: 'bg-white-4',
    },
  },
});

const controlRowLabels = cva(['flex'], {
  variants: {
    layout: {
      row: ['flex-row', 'items-center', 'gap-2'],
      column: ['flex-col', 'gap-1'],
    },
  },
});

export type ControlRowProps = (CheckboxProps | RadioProps) &
  VariantProps<typeof controlRow> & {
    type: 'checkbox' | 'radio';
    name: string;
    defaultChecked?: boolean;
    layout?: 'row' | 'column';
    disabled?: boolean;
    className?: string;
    label?: string | JSX.Element;
    label2?: string | JSX.Element;
    label3?: string | JSX.Element;
    renderRightIcon?: (className: string) => JSX.Element;
    error?: string;
    transparent?: boolean;
  };

const ControlRowComponent: ForwardRefRenderFunction<HTMLInputElement, ControlRowProps> = (
  {
    type,
    name,
    defaultChecked = false,
    layout = 'column',
    className,
    disabled = false,
    label,
    label2,
    label3,
    renderRightIcon,
    error,
    transparent = false,
    ...props
  },
  ref
) => {
  const InputComponent = type === 'checkbox' ? Checkbox : Radio;

  return (
    <div>
      <label className={cx(controlRow({ disabled, transparent }), className)}>
        <InputComponent
          className="mt-[2px]"
          name={name}
          defaultChecked={defaultChecked}
          disabled={disabled}
          ref={ref}
          {...props}
        />
        <div className={controlRowLabels({ layout })}>
          {label && <div className="text-body-regular text-white">{label}</div>}
          {label2 && <div className="text-white-60 text-body-regular">{label2}</div>}
          {layout === 'column' && label3 && (
            <div className="text-white-60 text-body-regular pt-2">{label3}</div>
          )}
        </div>
        {renderRightIcon && renderRightIcon('w-4 h-4 text-white-20')}
      </label>
      {error && <div className="text-body-regular text-error-600 mt-1 font-bold">{error}</div>}
    </div>
  );
};

export const ControlRow = forwardRef(ControlRowComponent);
