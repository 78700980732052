import { generatePath, Link } from 'react-router-dom';
import { HMLogo } from '@highmobility/console-ui-components';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { Project } from '@/types';
import { ProjectStatusDropdown } from '@/components/organisms/ProjectStatusDropdown';
import { ROUTE_PATHS } from '@/router/routes';

import { RenameProjectDialog } from '../../organisms/RenameProjectDialog';

type ProjectHeaderProps = {
  project: Project;
};

export const ProjectHeader = observer((props: ProjectHeaderProps) => {
  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const isPublished =
    props.project.status === 'published' || props.project.status === 'published_with_changes';

  return (
    <nav className="border-black-20 text-body-small bg-dark-green-900 relative z-20 flex h-10 border-b">
      <div className="border-black-20 flex h-10 w-10 shrink-0 items-center justify-center border-r p-2">
        <div className="h-6 w-6">
          <Link
            to={generatePath(isPublished ? ROUTE_PATHS.DASHBOARD_LIVE : ROUTE_PATHS.DASHBOARD_ALL, {
              teamId: props.project.team_id,
            })}
          >
            <HMLogo className="text-white-60 h-full w-full transition-colors hover:text-white" />
          </Link>
        </div>
      </div>
      <div className="flex flex-1 items-center justify-center gap-6 overflow-hidden pl-2">
        <div className="flex items-center gap-1 overflow-hidden">
          <span className="text-white-20">ID:</span>
          <span className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap font-semibold">
            {props.project.id}
          </span>
        </div>
        <div
          className="flex cursor-pointer items-center gap-1 overflow-hidden"
          onClick={() => setShowRenameDialog(true)}
        >
          <span className="text-white-20">Name:</span>
          <span className="overflow-hidden text-ellipsis whitespace-nowrap font-semibold">
            {props.project.name}
          </span>
        </div>
      </div>
      <div className="border-black-20 flex items-center border-l px-4">
        <ProjectStatusDropdown project={props.project} />
      </div>
      <RenameProjectDialog
        isOpen={showRenameDialog}
        projectId={props.project.id}
        projectName={props.project.name}
        setIsOpen={setShowRenameDialog}
        teamId={props.project.team_id}
      />
    </nav>
  );
});
