import { Button, Plus } from '@highmobility/console-ui-components';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { InviteMembersDialog } from '@/components/views/team-settings/InviteMembersDialog';
import { LeaveTeamDialog } from '@/components/views/team-settings/LeaveTeamDialog';
import { MembersTable } from '@/components/views/team-settings/MembersTable';
import { UserRolesInfoDialog } from '@/components/views/team-settings/UserRolesInfoDialog';

type MembersSectionProps = {
  teamId: string;
  disabled?: boolean;
};

export const MembersSection = observer((props: MembersSectionProps) => {
  const [showLeaveTeamDialog, setShowLeaveTeamDialog] = useState(false);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [showRolesInfoDialog, setShowRolesInfoDialog] = useState(false);

  return (
    <div>
      <div className="flex items-center justify-between gap-2 pb-8">
        <h2 className="text-heading-2">Members</h2>
        <div className="flex items-center gap-2">
          <Button intent="secondary" onClick={() => setShowLeaveTeamDialog(true)}>
            Leave team
          </Button>
          <Button
            intent="primary"
            onClick={() => setShowInviteDialog(true)}
            renderLeftIcon={(c) => <Plus className={c} />}
            disabled={props.disabled}
          >
            Invite members
          </Button>
        </div>
      </div>
      <MembersTable teamId={props.teamId} disabled={props.disabled} />
      <div className="flex items-center justify-end">
        <Button intent="tertiary" onClick={() => setShowRolesInfoDialog(true)}>
          Learn more about roles
        </Button>
      </div>
      <LeaveTeamDialog
        isOpen={showLeaveTeamDialog}
        setIsOpen={setShowLeaveTeamDialog}
        teamId={props.teamId}
      />
      <InviteMembersDialog
        isOpen={showInviteDialog}
        setIsOpen={setShowInviteDialog}
        teamId={props.teamId}
      />
      <UserRolesInfoDialog isOpen={showRolesInfoDialog} setIsOpen={setShowRolesInfoDialog} />
    </div>
  );
});
