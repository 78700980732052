import { FC } from 'react';

export type LinearDeterminateLoaderProps = {
  durationInMs: number;
};

export const LinearDeterminateLoader: FC<LinearDeterminateLoaderProps> = (props) => {
  return (
    <div className="bg-black-20 h-1 w-full">
      <div
        className="bg-white h-full animate-progress-bar rounded-[4px]"
        style={{
          animationDuration: `${props.durationInMs}ms`,
        }}
      />
    </div>
  );
};
