import { AlertDialog, AlertDialogProps, Button } from '@highmobility/console-ui-components';
import { cluster } from 'radash';
import { FC } from 'react';

import { copyTextToClipboard, downloadTextFile } from '@/services/textFileService';

export type MFARecoveryCodesDialogProps = {
  isOpen: AlertDialogProps['isOpen'];
  setIsOpen: AlertDialogProps['setIsOpen'];
  onConfirm: AlertDialogProps['onConfirm'];
  codes: string[];
  replacedExistingCodes?: boolean;
};

export const MFARecoveryCodesDialog: FC<MFARecoveryCodesDialogProps> = (props) => {
  const onClickDownload = () => downloadTextFile(props.codes.join('\n'), 'recovery-codes.txt');
  const onClickCopy = () => copyTextToClipboard(props.codes.join('\n'));

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title="Recovery codes"
      confirmButtonIntent="primary"
      confirmLabel="Finish"
      onConfirm={props.onConfirm}
      onCancel={() => {}}
      cancelButtonVisible={false}
    >
      <div className="flex flex-col gap-6">
        <div>
          <p>
            Please securely save them in a location you can easily access. Do not close this window
            until you have copied and saved your codes.
          </p>
          {props.replacedExistingCodes && (
            <p className="text-error-600 pt-3 font-bold">
              These new codes have replaced your old codes. Save them in a safe spot.
            </p>
          )}
        </div>
        <div className="bg-white-4 flex justify-around gap-12 rounded px-12 py-6 text-center">
          {cluster(props.codes, Math.round(props.codes.length / 2)).map((codes, index) => (
            <div key={index} className="flex flex-1 flex-col gap-2">
              {codes.map((code, index) => (
                <h5 key={index} className="text-heading-5">
                  {code}
                </h5>
              ))}
            </div>
          ))}
        </div>
        <div className="flex items-center justify-around">
          <div className="flex flex-1 items-center justify-center">
            <Button intent="tertiary" onClick={onClickDownload}>
              Download
            </Button>
          </div>
          <div className="flex flex-1 items-center justify-center">
            <Button intent="tertiary" onClick={onClickCopy}>
              Copy
            </Button>
          </div>
        </div>
      </div>
    </AlertDialog>
  );
};
