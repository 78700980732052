import { AlertDialog, Button, toast } from '@highmobility/console-ui-components';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { deleteAccount } from '@/services/apiService';
import { ROUTE_PATHS } from '@/router/routes';

export const DeleteAccountSection = () => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const deleteAccountMutation = useMutation({
    mutationFn: async () => deleteAccount(),
    onError: (e: any) => {
      setDialogOpen(false);
      console.error('Error submitting the form', e);
      toast('Could not delete account. Make sure you are not an admin in any teams.', 'error');
    },
    onSuccess: () => {
      setDialogOpen(false);
      toast('Account deleted successfully.', 'success');
      navigate(ROUTE_PATHS.ROOT);
    },
  });

  return (
    <div>
      <p className="text-white-60 mb-8">
        If you hit delete, this account will no longer exist, and all account data will be
        permanently erased.
      </p>
      <Button intent="primarySignal" onClick={() => setDialogOpen(true)}>
        Delete Account
      </Button>
      <AlertDialog
        isOpen={dialogOpen}
        setIsOpen={setDialogOpen}
        title="Delete Account"
        confirmButtonIntent="primarySignal"
        confirmLabel="Yes, Delete"
        cancelLabel="Cancel"
        onConfirm={() => deleteAccountMutation.mutate()}
        onCancel={() => setDialogOpen(false)}
      >
        Account deletion cannot be undone. If you choose to continue your account and the data in it
        will no longer be available. Do you still want to delete your account?
      </AlertDialog>
    </div>
  );
};
