import { Button, Close, TextInput, toast } from '@highmobility/console-ui-components';
import { generatePath, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { fakeLoader, updateProductInfo } from '@/services/apiService';
import { ROUTE_PATHS } from '@/router/routes';
import { updateLegalInfoValidation } from '@/services/zodService';
import { useMobx } from '@/hooks/useMobx';
import { useTypedParams } from '@/hooks/useTypedParams';

export const ProductInfoLegalView = () => {
  const { teamId, projectId } = useTypedParams<typeof ROUTE_PATHS.PRODUCT_INFO_LEGAL>();
  const { projects } = useMobx();
  const project = projects.byId[projectId];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<z.infer<typeof updateLegalInfoValidation>>({
    resolver: zodResolver(updateLegalInfoValidation),
    defaultValues: {
      privacy_policy: project?.product_info?.privacy_policy,
      terms_of_service: project?.product_info?.terms_of_service,
    },
  });

  const updateLegalInfoMutation = useMutation({
    mutationFn: async (data: z.infer<typeof updateLegalInfoValidation>) => {
      return Promise.all([fakeLoader(), updateProductInfo(teamId, projectId, data)]);
    },
    onError: (e) => {
      console.error('Failed to update legal info', e);
      toast('Someting went wrong. Please try again.', 'error');
      reset();
    },
    onSuccess: async () => {
      await projects.fetchProject(teamId, projectId);
      toast('Legal info updated!', 'success');
    },
  });

  const onSubmit = handleSubmit((data) => updateLegalInfoMutation.mutate(data));

  return (
    <div className="h-full w-full">
      <div className="flex w-full items-center gap-3 bg-[#2B4247] px-4 py-2">
        <h5 className="text-heading-5">Legal Links</h5>
        <Link
          to={generatePath(ROUTE_PATHS.PRODUCT_INFO, {
            teamId,
            projectId,
          })}
          className="ml-auto"
        >
          <Close className="h-4 w-4 cursor-pointer text-white-40 transition-colors hover:text-white" />
        </Link>
      </div>
      <form className="p-8" onSubmit={onSubmit} autoComplete="false">
        <div className="mb-8 flex flex-col gap-6">
          <TextInput
            label="Terms of Service"
            placeholder="e.g. www.mydatabusiness.com/terms"
            {...register('terms_of_service')}
            error={errors.terms_of_service?.message}
          />
          <TextInput
            label="Privacy Policy"
            placeholder="e.g. www.mydatabusiness.com/privacy"
            {...register('privacy_policy')}
            error={errors.privacy_policy?.message}
          />
          <div>
            <Button disabled={updateLegalInfoMutation.isPending} type="submit">
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
