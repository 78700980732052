import { makeAutoObservable, runInAction } from 'mobx';

import { fetchProject, fetchProjects } from '@/services/apiService';
import { Project } from '@/types';

export class Projects {
  byId: Record<string, Project | undefined> = {};
  allIds: Set<string> = new Set();

  constructor() {
    makeAutoObservable(this);
  }

  get list() {
    return Object.values(this.byId)
      .filter(Boolean)
      .sort((a, b) => {
        return new Date(b!.created_at).getTime() - new Date(a!.created_at).getTime();
      }) as Project[];
  }

  teamProjects(teamId: string) {
    return this.list.filter((project) => project.team_id === teamId);
  }

  async fetchProjects(teamId: string) {
    try {
      const { data: projects } = await fetchProjects(teamId);

      runInAction(() => {
        this.byId = projects.reduce((acc, project) => {
          acc[project.id] = project;
          return acc;
        }, {} as Record<string, Project>);
        this.allIds = new Set(projects.map((project) => project.id));
      });

      return projects;
    } catch (e) {
      console.error('Failed to fetch projects', e);
      return null;
    }
  }

  async fetchProject(teamId: string, projectId: string) {
    try {
      const { data: project } = await fetchProject(teamId, projectId);

      runInAction(() => {
        this.byId[project.id] = project;
        this.allIds.add(project.id);
      });

      return project;
    } catch (e) {
      console.error('Failed to fetch project', e);
      return null;
    }
  }

  setProject(project: Project) {
    this.byId[project.id] = project;
    this.allIds.add(project.id);
  }
}
