import { AlertDialog, DialogProps, toast } from '@highmobility/console-ui-components';
import { capitalize } from 'radash';
import { FC } from 'react';
import { useMutation } from '@tanstack/react-query';

import { createSimulator, fakeLoader } from '@/services/apiService';
import { Simulator } from '@/types';

export type CreateSimulatorDialogProps = {
  isOpen: DialogProps['isOpen'];
  setIsOpen: DialogProps['setIsOpen'];
  teamId: string;
  type: Simulator['type'];
  onSuccess: () => void;
};

export const CreateSimulatorDialog: FC<CreateSimulatorDialogProps> = (props) => {
  const createSimulatorMutation = useMutation({
    mutationFn: async () => {
      const [{ data }] = await Promise.all([
        createSimulator(props.teamId, props.type),
        fakeLoader(),
      ]);

      return data;
    },
    onError: (e: any) => {
      console.error('CreateSimulatorDialog::failed to create simulator', e);
      toast(
        e?.message ?? e?.response?.data?.message ?? 'Something went wrong. Please try again.',
        'error'
      );
    },
    onSuccess: () => {
      props.onSuccess();
      props.setIsOpen(false);
    },
  });

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      onCancel={() => props.setIsOpen(false)}
      onConfirm={() => createSimulatorMutation.mutate()}
      title="Create new simulator"
      confirmLabel={createSimulatorMutation.isPending ? 'Creating' : 'Create'}
      confirmButtonDisabled={createSimulatorMutation.isPending}
      cancelLabel="Cancel"
    >
      <div className="flex flex-col gap-2">
        <p className="font-bold">Are you sure you want to create a new {props.type} simulator?</p>
        <p className="text-label-regular text-white-60">
          {capitalize(props.type)} simulator will open a new window
        </p>
      </div>
    </AlertDialog>
  );
};
