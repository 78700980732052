import {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  ForwardRefRenderFunction,
  HTMLInputTypeAttribute,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { cva, cx } from 'class-variance-authority';

import { CloseSmall, Eye, EyeClosed, Info } from '../../assets';
import { useTags } from '../../hooks/useTags';

import { NameTag } from './NameTag';

const textInput = cva(
  [
    'w-full',
    'text-body-regular',
    'group-hover:text-white-60',
    'focus:text-white',
    'focus:group-hover:text-white',
    'placeholder-white-40',
    'placeholder-body-regular',
    'rounded',
    'border',
    'focus:border-white-80',
    'transition-colors',
    'outline-none',
    'p-[10px]',
    'bg-black-40',
    'group-hover:bg-black-60',
    'focus:bg-black-60',
  ],
  {
    variants: {
      hasValue: {
        true: 'text-white',
        false: 'text-white-40',
      },
      error: {
        true: 'border-error-600 focus:border-error-600',
      },
      disabled: {
        true: 'border-black-20 bg-black-20 cursor-not-allowed',
      },
      textarea: {
        true: 'min-h-[72px]',
      },
      hasIcon: {
        true: 'pr-8',
      },
      withTags: {
        true: 'resize-none',
      },
      isFocused: {
        true: 'border-white-80 bg-black-60',
      },
    },
    compoundVariants: [
      {
        error: false,
        disabled: false,
        className: 'border-white-12',
      },
    ],
  }
);

export type TextInputProps = {
  className?: string;
  placeholder?: string;
  defaultValue?: string;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  type?: HTMLInputTypeAttribute;
  error?: string;
  label?: string;
  labelAdditionalInfo?: string;
  disabled?: boolean;
  note?: string;
  textarea?: boolean;
  withTags?: boolean;
  renderRightIcon?: () => JSX.Element;
};

const TextInputComponent: ForwardRefRenderFunction<
  HTMLInputElement & HTMLTextAreaElement,
  TextInputProps
> = (
  {
    className = '',
    onChange = () => {},
    onBlur = () => {},
    onFocus = () => {},
    type,
    placeholder,
    error,
    name,
    label,
    labelAdditionalInfo,
    disabled = false,
    note,
    defaultValue,
    textarea = false,
    withTags = false,
    renderRightIcon,
  },
  ref
) => {
  const localRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  useImperativeHandle(ref, () => localRef.current as HTMLInputElement & HTMLTextAreaElement);

  const [hasValue, setHasValue] = useState(!!defaultValue || !!localRef.current?.value);
  const [isMasked, setIsMasked] = useState(type === 'password');

  const _onChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setHasValue(!!event.target.value);
      onChange(event);
    },
    [onChange]
  );

  const tagsData = useTags({ enabled: withTags, onChange: _onChange, name });
  const Component = textarea ? 'textarea' : 'input';

  useEffect(() => {
    setHasValue(!!localRef.current?.value);
  }, [localRef.current?.value]);

  return (
    <div className={cx('inline-flex flex-col last:mb-0', className)}>
      {label && (
        <label className="flex items-center gap-1 pb-2 text-body-regular" htmlFor={name}>
          <span className="font-bold text-white">{label}</span>
          {labelAdditionalInfo && (
            <span className="font-normal text-white-40"> {labelAdditionalInfo}</span>
          )}
        </label>
      )}
      <div className="group relative flex">
        <Component
          defaultValue={defaultValue}
          className={textInput({
            hasValue,
            error: !!error,
            disabled,
            textarea,
            hasIcon: type === 'password' || !!renderRightIcon,
            withTags,
            isFocused: tagsData.isFocused,
          })}
          ref={withTags ? null : localRef}
          type={type === 'password' && !isMasked ? 'text' : type}
          onChange={_onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={tagsData.isEmpty ? placeholder : ''}
          aria-invalid={!!error ? 'true' : 'false'}
          name={withTags ? '' : name}
          disabled={withTags || disabled}
        />
        {type === 'password' &&
          (isMasked ? (
            <EyeClosed
              className="absolute right-[10px] top-1/2 -translate-y-1/2 cursor-pointer fill-white-40"
              onClick={() => setIsMasked((v) => !v)}
            />
          ) : (
            <Eye
              className="absolute right-[10px] top-1/2 -translate-y-1/2 cursor-pointer stroke-white-40"
              onClick={() => setIsMasked((v) => !v)}
            />
          ))}
        {withTags && (
          <div
            className="absolute inset-0"
            onClick={() => {
              tagsData.inputRef.current?.focus();
            }}
          >
            <div className="relative h-full w-full overflow-hidden p-[10px]">
              <div className="relative inline-flex h-full w-full cursor-text flex-wrap items-start gap-1 overflow-auto">
                {tagsData.tags.map((tag, index) => (
                  <NameTag
                    key={index}
                    active
                    renderRightIcon={(c) => (
                      <CloseSmall
                        className={c}
                        onClick={() => tagsData.setTags(tagsData.tags.filter((t) => t !== tag))}
                      />
                    )}
                  >
                    {tag}
                  </NameTag>
                ))}
                <input
                  {...tagsData.inputProps}
                  className="inline-block h-6 min-w-[20px] max-w-full flex-1 bg-transparent text-white outline-none"
                />
              </div>
            </div>
          </div>
        )}
        {renderRightIcon && (
          <div className="absolute right-[10px] top-1/2 -translate-y-1/2 cursor-pointer fill-white-40">
            {renderRightIcon()}
          </div>
        )}
      </div>
      {error && (
        <div className="flex items-center gap-1 rounded-b py-1 text-label-regular font-bold text-error-600">
          <Info />

          <span>{error}</span>
        </div>
      )}
      {note && !error && (
        <div className="pt-2 text-label-small font-semibold text-white-60">{note}</div>
      )}
    </div>
  );
};

export const TextInput = forwardRef(TextInputComponent);
