import { Button, Plus } from '@highmobility/console-ui-components';
import { useState } from 'react';

import { Simulator } from '@/types';

import { CreateSimulatorDialog } from './CreateSimulatorDialog';

type CreateSimulatorButtonProps = {
  teamId: string;
  type: Simulator['type'];
  refetch: () => void;
};

export default function CreateSimulatorButton(props: CreateSimulatorButtonProps) {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <Button
        intent="tertiary"
        renderLeftIcon={(cn) => <Plus className={cn} />}
        className="pl-0"
        onClick={() => setShowDialog(true)}
      >
        Create new simulator
      </Button>
      <CreateSimulatorDialog
        type={props.type}
        teamId={props.teamId}
        isOpen={showDialog}
        setIsOpen={setShowDialog}
        onSuccess={() => props.refetch()}
      />
    </>
  );
}
