import { Button, Plus } from '@highmobility/console-ui-components';
import { useState } from 'react';

import { CreateTeamDialog } from '@/components/organisms/CreateTeamDialog';
import { useMobx } from '@/hooks/useMobx';

export const TeamsHeader = () => {
  const { teams } = useMobx();
  const [showCreateTeamDialog, setShowCreateTeamDialog] = useState(false);

  return (
    <div className="mb-8 flex items-center justify-between">
      <div>
        <h2 className="text-heading-2 pb-1">Teams</h2>
        <p className="text-white-60">
          You are part of {teams.allIds.size} {teams.allIds.size > 1 ? 'teams' : 'team'}
        </p>
      </div>
      <Button
        intent="primary"
        renderLeftIcon={(c) => <Plus className={c} />}
        onClick={() => setShowCreateTeamDialog(true)}
      >
        New team
      </Button>
      <CreateTeamDialog isOpen={showCreateTeamDialog} setIsOpen={setShowCreateTeamDialog} />
    </div>
  );
};
