import { cva, cx } from 'class-variance-authority';

import { Info } from '@highmobility/console-ui-components';

export type NoneSelectedProps = {
  label?: string;
  error?: string;
};

const noneSelected = cva(
  'text-body-small bg-black-20 text-white-40 flex w-full cursor-default items-center justify-center rounded border px-5 py-[10px] font-semibold',
  {
    variants: {
      error: {
        true: 'border-error-600',
        false: 'border-black-40',
      },
    },
  }
);

export const NoneSelected = (props: NoneSelectedProps) => {
  return (
    <div className="flex flex-col">
      {props.label && <span className="text-body-small text-white-80 pb-2">{props.label}</span>}
      <div className={cx(noneSelected({ error: !!props.error }))}>None</div>
      {props.error && (
        <div className="text-label-regular text-error-600 flex items-center gap-1 rounded-b py-1 font-bold">
          <Info />
          <span>{props.error}</span>
        </div>
      )}
    </div>
  );
};
