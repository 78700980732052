import {
  AlertDialog,
  ControlRow,
  DialogProps,
  TextInput,
  toast,
} from '@highmobility/console-ui-components';
import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { createServiceAccount, fakeLoader } from '@/services/apiService';
import { createServiceAccountValidation } from '@/services/zodService';
import { downloadTextFile } from '@/services/textFileService';
import { generateKeyPair } from '@/services/cryptoService';
import { OAuthClient, ProjectInstance, ServiceAccount } from '@/types';
import { useMobx } from '@/hooks/useMobx';

export type CreateOAuthCredentialsDialogProps = {
  isOpen: DialogProps['isOpen'];
  setIsOpen: DialogProps['setIsOpen'];
  teamId: string;
  projectId: string;
  instance: ProjectInstance;
  onSuccess: (newServiceAccount: ServiceAccount) => void;
  oAuthClientData: OAuthClient;
};

export const CreateOAuthCredentialsDialog: FC<CreateOAuthCredentialsDialogProps> = (props) => {
  const [showDateFields, setShowDateFields] = useState(false);
  const { projects } = useMobx();
  const project = projects.byId[props.projectId];

  const createServiceAccountMutation = useMutation({
    mutationFn: async (data: z.infer<typeof createServiceAccountValidation>) => {
      const { publicKey, privateKey } = await generateKeyPair();

      const [serviceAccountResponse] = await Promise.all([
        createServiceAccount(props.teamId, props.projectId, props.instance, {
          name: data.name,
          expires_at: data.expires_at
            ? new Date(
                `${data.expires_at.year}-${`${data.expires_at.month}`.padStart(2, '0')}-${`${data.expires_at.day}`.padStart(2, '0')}T00:00:00Z`
              ).toISOString()
            : undefined,
          public_key: publicKey,
        }),
        fakeLoader(),
      ]);

      return {
        serviceAccount: serviceAccountResponse.data,
        privateKey,
      };
    },
    onError: (e: any) => {
      console.error('CreateOAuthCredentialsDialog::failed to create service account', e);
      toast(
        e?.message ?? e?.response?.data?.message ?? 'Something went wrong. Please try again.',
        'error'
      );
    },
    onSuccess: ({ serviceAccount, privateKey }) => {
      const instanceURI = new URL(props.oAuthClientData.token_uri).origin;

      downloadTextFile(
        JSON.stringify(
          {
            ...serviceAccount,
            private_key: privateKey,
            instance_uri: instanceURI,
            token_uri: props.oAuthClientData.token_uri,
            client_id: props.oAuthClientData.id,
          },
          null,
          2
        ),
        `${project?.name.split(' ').join('-').toLowerCase()}-${serviceAccount.id}-private-key.json`
      );

      props.onSuccess(serviceAccount);
      props.setIsOpen(false);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<z.infer<typeof createServiceAccountValidation>>({
    resolver: zodResolver(createServiceAccountValidation),
    defaultValues: {
      expires_at: undefined,
    },
  });

  useEffect(() => {
    reset();
    setShowDateFields(false);
  }, [props.isOpen, reset]);

  const onSubmit = handleSubmit((data) => createServiceAccountMutation.mutate(data));

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      onCancel={() => props.setIsOpen(false)}
      onConfirm={onSubmit}
      title="Add credential"
      confirmLabel={createServiceAccountMutation.isPending ? 'Loading' : 'Add'}
      confirmButtonDisabled={createServiceAccountMutation.isPending}
    >
      <div className="flex flex-col gap-4">
        <form className="flex flex-col gap-6" onSubmit={onSubmit}>
          <TextInput
            label="Name"
            placeholder="Name"
            {...register('name')}
            error={errors.name?.message}
          />
          <div>
            <p className="font-bold pb-2 text-white">Expiration date</p>
            <ControlRow
              aria-label="Never"
              name="custom_date"
              className="pl-0"
              type="radio"
              label="Never"
              onChange={() => setShowDateFields(false)}
              transparent
              defaultChecked
            />
            <ControlRow
              aria-label="Set custom date"
              name="custom_date"
              className="pl-0"
              type="radio"
              label="Set custom date"
              onChange={() => setShowDateFields(true)}
              transparent
            />
            {showDateFields && (
              <div>
                <div className="flex gap-2 items-center">
                  <TextInput
                    label="Day"
                    placeholder={new Date().getDate().toString()}
                    {...register('expires_at.day')}
                    error={errors.expires_at ? 'Invalid date' : undefined}
                  />
                  <TextInput
                    label="Month"
                    placeholder={new Date().getMonth().toString()}
                    {...register('expires_at.month')}
                    error={errors.expires_at ? 'Invalid date' : undefined}
                  />
                  <TextInput
                    label="Year"
                    placeholder={new Date().getFullYear().toString()}
                    {...register('expires_at.year')}
                    error={errors.expires_at ? 'Invalid date' : undefined}
                  />
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </AlertDialog>
  );
};
