import { cx } from 'class-variance-authority';
import {
  TextInput,
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@highmobility/console-ui-components';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useMemo, useState } from 'react';

type PasswordInputWithHintsProps = {
  inputProps: UseFormRegisterReturn;
  errorMessage?: string;
  watchedPassword: string;
  isDirty: boolean;
};

export const PasswordInputWithHints = (props: PasswordInputWithHintsProps) => {
  const [passwordFocused, setPasswordFocused] = useState(false);
  const passwordHints = useMemo(() => {
    const tips: {
      message: string;
      state: 'valid' | 'invalid' | 'info';
    }[] = [
      {
        message: 'Have at least one lower case character',
        state: props.watchedPassword?.match(/[a-z]/) ? 'valid' : 'invalid',
      },
      {
        message: 'Have at least one capital letter',
        state: props.watchedPassword?.match(/[A-Z]/) ? 'valid' : 'invalid',
      },
      {
        message: 'Have at least one number',
        state: props.watchedPassword?.match(/[0-9]/) ? 'valid' : 'invalid',
      },
      {
        message: 'Be at least 8 characters',
        state: props.watchedPassword?.length >= 8 ? 'valid' : 'invalid',
      },
      {
        message: 'Have at least one special character',
        state: props.watchedPassword?.match(/[!@#$%^&*(),.?":{}|<>]/) ? 'valid' : 'invalid',
      },
    ];

    if (!props.isDirty) {
      return tips.map((tip) => ({ ...tip, state: 'info' }));
    }

    return tips;
  }, [props.watchedPassword, props.isDirty]);

  return (
    <TooltipProvider open={passwordFocused}>
      <TooltipTrigger>
        <TextInput
          className="w-full"
          label="Password"
          placeholder="8 characters minimum"
          {...props.inputProps}
          error={props.errorMessage}
          type="password"
          onFocus={() => setPasswordFocused(true)}
          onBlur={(e) => {
            setPasswordFocused(false);
            props.inputProps.onBlur(e);
          }}
        />
      </TooltipTrigger>
      <Tooltip>
        <p className="mb-3 font-bold text-white">Password must contain:</p>
        <ul>
          {passwordHints.map((tip, index) => (
            <li key={index} className="mb-3 flex items-center gap-3">
              <div
                className={cx('h-[14px] min-h-[14px] w-[14px] min-w-[14px] rounded-full border', {
                  'border-error-500': tip.state === 'invalid',
                  'bg-success-500 border-success-500': tip.state === 'valid',
                  'border-white': tip.state === 'info',
                })}
              />
              <p
                className={cx('text-body-regular', {
                  'text-error-500': tip.state === 'invalid',
                  'text-success-500': tip.state === 'valid',
                  'text-white': tip.state === 'info',
                })}
              >
                {tip.message}
              </p>
            </li>
          ))}
        </ul>
      </Tooltip>
    </TooltipProvider>
  );
};
