import {
  AlertDialog,
  AlertDialogProps,
  TextInput,
  toast,
} from '@highmobility/console-ui-components';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { createTeam, fakeLoader } from '@/services/apiService';
import { createTeamValidation } from '@/services/zodService';
import { useMobx } from '@/hooks/useMobx';

type CreateTeamDialogProps = Pick<AlertDialogProps, 'isOpen' | 'setIsOpen'>;

export function CreateTeamDialog(props: CreateTeamDialogProps) {
  const { teams } = useMobx();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<z.infer<typeof createTeamValidation>>({
    resolver: zodResolver(createTeamValidation),
  });

  const createTeamMutation = useMutation({
    mutationFn: async (data: z.infer<typeof createTeamValidation>) => {
      return Promise.all([fakeLoader(), createTeam(data)]);
    },
    onError: (e) => {
      console.error('Failed to create team', e);
      toast('Failed to create the team. Please try again.', 'error');
      props.setIsOpen(false);
      reset();
    },
    onSuccess: async (data) => {
      toast('New team created!', 'success');
      await teams.fetchTeams();
      teams.setActiveTeam(data?.[1]?.data?.id);
      reset();
      props.setIsOpen(false);
    },
  });

  const onSubmit = handleSubmit((data) => createTeamMutation.mutate(data));

  return (
    <AlertDialog
      title="Create a new team"
      isOpen={props.isOpen}
      setIsOpen={(value) => {
        reset();
        props.setIsOpen(value);
      }}
      onCancel={() => {
        reset();
        props.setIsOpen(false);
      }}
      onConfirm={onSubmit}
      cancelLabel="Cancel"
      confirmLabel={createTeamMutation.isPending ? 'Loading' : 'Create'}
      confirmButtonDisabled={createTeamMutation.isPending}
    >
      <form onSubmit={onSubmit} autoComplete="false">
        <div className="mb-8 flex flex-col gap-6">
          <TextInput
            label="New team name"
            placeholder="Enter a team name"
            {...register('name')}
            error={errors.name?.message}
          />
          <TextInput
            withTags
            textarea
            label="Add members to your team"
            labelAdditionalInfo="(optional)"
            placeholder="Enter here the email addresses of those you would like on your team"
            {...register('members')}
            error={errors.members?.[0]?.message}
          />
        </div>
      </form>
    </AlertDialog>
  );
}
