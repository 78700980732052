import ReactDOM from 'react-dom/client';
import { StrictMode } from 'react';

import { App } from '@/components/App';

import '@/styles/index.css';

ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement).render(
  <StrictMode>
    <App />
  </StrictMode>
);
