import { LoaderFunction, redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { DashboardHeader } from '@/components/organisms/DashboardHeader';
import { GeneralTemplate } from '@/components/templates/general/GeneralTemplate';
import { mobxStore } from '@/store';
import { ROUTE_PATHS } from '@/router/routes';
import { TeamsHeader } from '@/components/views/teams/TeamsHeader';

import { TeamsTable } from './TeamsTable';

export const loader: LoaderFunction = async () => {
  const { teams: teamsStore } = mobxStore;
  const teams = await teamsStore.fetchTeams();
  if (!teams || teams.length < 1) {
    return redirect(ROUTE_PATHS.DASHBOARD_ALL);
  }

  return null;
};

export const TeamsView = observer(() => {
  return (
    <GeneralTemplate renderHeader={() => <DashboardHeader />}>
      <main className="pt-12">
        <TeamsHeader />
        <TeamsTable />
      </main>
    </GeneralTemplate>
  );
});
