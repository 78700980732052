import { Button, toast } from '@highmobility/console-ui-components';
import { FC, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { DisableMFADialog } from '@/components/views/account-settings/DisableMFADialog';
import { EnableMFADialog } from '@/components/views/account-settings/EnableMFADialog';
import { updateTOTP } from '@/services/apiService';

export type ToggleMFAButtonProps = {
  MFAEnabled: boolean;
};

export const ToggleMFAButton: FC<ToggleMFAButtonProps> = (props) => {
  const [showDisableMFADialog, setShowDisableMFADialog] = useState(false);
  const [showEnableMFADialog, setShowEnableMFADialog] = useState(false);
  const [MFASettings, setMFASettings] = useState<{
    id: string;
    url: string;
    secret: string;
    expires_at: number;
  } | null>(null);

  const getMFASettingsMutation = useMutation({
    mutationFn: () => updateTOTP(),
    onError: (e: any) => {
      toast(e?.response?.data?.message || 'Something went wrong. Please try again.', 'error');
    },
    onSuccess: (response) => {
      setMFASettings({
        id: response.data.id,
        url: response.data.url,
        secret: response.data.secret,
        expires_at: response.data.expires_at,
      });
      setShowEnableMFADialog(true);
    },
  });

  return (
    <>
      {props.MFAEnabled ? (
        <Button intent="secondary" onClick={() => setShowDisableMFADialog(true)}>
          Disable
        </Button>
      ) : (
        <Button
          intent="primary"
          onClick={() => {
            getMFASettingsMutation.mutate();
          }}
          disabled={getMFASettingsMutation.isPending}
        >
          {getMFASettingsMutation.isPending ? 'Loading' : 'Enable'}
        </Button>
      )}

      <DisableMFADialog isOpen={showDisableMFADialog} setIsOpen={setShowDisableMFADialog} />
      <EnableMFADialog
        isOpen={showEnableMFADialog}
        setIsOpen={setShowEnableMFADialog}
        settings={MFASettings}
      />
    </>
  );
};
