import { cx } from 'class-variance-authority';

import { Check } from '../../assets';
import { SegmentedButtonItem } from '../../types';

export type SegmentedButtonsProps<TItems extends ReadonlyArray<SegmentedButtonItem>> = {
  items: TItems;
  activeItem: TItems[number]['value'];
  className?: string;
};

export function SegmentedButtons<TItems extends ReadonlyArray<SegmentedButtonItem>>(
  props: SegmentedButtonsProps<TItems>
) {
  return (
    <div className={cx(props.className, 'flex items-center rounded')}>
      {props.items.map((item, i) => (
        <button
          key={i}
          onClick={() => item.onClick()}
          className={cx(
            'inline-flex items-center justify-center gap-2 px-4 py-3 text-button font-semibold text-white outline-none transition-colors',
            {
              'bg-black-20 hover:bg-black-40 focus:bg-black-40': props.activeItem === item.value,
              'bg-white-4 hover:bg-white-8 focus:bg-white-8': props.activeItem !== item.value,
              'rounded-l': i === 0,
              'rounded-r': i === props.items.length - 1,
            }
          )}
        >
          {props.activeItem === item.value && <Check className="h-4 w-4" />}
          {item.label}
        </button>
      ))}
    </div>
  );
}
