import { cx } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

export type TableProps = PropsWithChildren<{
  className?: string;
  disabled?: boolean;
}>;

export const Table = (props: TableProps) => {
  return (
    <table
      className={cx(props.className, 'table-auto border-separate border-spacing-[1px]', {
        'opacity-50': props.disabled,
      })}
    >
      {props.children}
    </table>
  );
};
