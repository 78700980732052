import { Billing } from '@/store/Billing';
import { MarketData } from '@/store/MarketData';
import { Projects } from '@/store/Projects';
import { Session } from '@/store/Session';
import { Teams } from '@/store/Teams';
import { UI } from '@/store/UI';

const session = new Session();
const teams = new Teams();
const projects = new Projects();
const marketData = new MarketData({ projectsStore: projects });
const billing = new Billing();
const ui = new UI();

export const mobxStore = {
  session,
  teams,
  projects,
  marketData,
  billing,
  ui,
} as const;
