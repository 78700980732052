import { DialogProps } from '@highmobility/console-ui-components';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { fakeLoader, login } from '@/services/apiService';
import { loginWithMFAValidation, MFARecoveryValidation } from '@/services/zodService';
import { MFAMethods } from '@/constants';
import { MFAVerifyDialog } from '@/components/organisms/MFAVerifyDialog';

export type LoginMFAVerifyDialog = {
  isOpen: DialogProps['isOpen'];
  setIsOpen: DialogProps['setIsOpen'];
  onSuccess: () => void;
  email: string;
  password: string;
};

export const LoginMFAVerifyDialog: FC<LoginMFAVerifyDialog> = (props) => {
  const loginWithMFAMutation = useMutation({
    mutationFn: async (data: z.infer<typeof loginWithMFAValidation>) => {
      await fakeLoader();
      await Promise.all([
        login(
          {
            email: props.email,
            password: props.password,
          },
          { 'X-Console-MFA-Token': `${MFAMethods.TOTP}:${data.code}` }
        ),
      ]);
    },
    onError: (e: any) => {
      console.error('MFAVerifyDialog::login failed', e);
      loginForm.setError('code', { message: 'Invalid code' });
    },
    onSuccess: () => {
      props.onSuccess();
    },
  });

  const loginForm = useForm<z.infer<typeof loginWithMFAValidation>>({
    resolver: zodResolver(loginWithMFAValidation),
  });

  const recoveryMutation = useMutation({
    mutationFn: async (data: z.infer<typeof loginWithMFAValidation>) => {
      await fakeLoader();
      await Promise.all([
        login(
          {
            email: props.email,
            password: props.password,
          },
          { 'X-Console-MFA-Token': `${MFAMethods.RECOVERY_CODE}:${data.code}` }
        ),
      ]);
    },
    onError: (e: any) => {
      console.error('MFAVerifyDialog::recovery failed', e);
      recoveryForm.setError('code', { message: 'Invalid code' });
    },
    onSuccess: () => {
      props.onSuccess();
    },
  });

  const recoveryForm = useForm<z.infer<typeof MFARecoveryValidation>>({
    resolver: zodResolver(MFARecoveryValidation),
  });

  return (
    <MFAVerifyDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      loginForm={loginForm}
      loginMutation={loginWithMFAMutation}
      recoveryForm={recoveryForm}
      recoveryMutation={recoveryMutation}
    />
  );
};
