import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { TextInput, TextInputProps } from '@highmobility/console-ui-components';
import { useMemo, useState } from 'react';

type StripeInputProps = {
  label: TextInputProps['label'];
  type: 'cardNumber' | 'cardExpiry' | 'cardCvc';
};

export function StripeInput(props: StripeInputProps) {
  const [error, setError] = useState<string>();
  const Element = useMemo(() => {
    switch (props.type) {
      case 'cardNumber':
        return CardNumberElement;
      case 'cardExpiry':
        return CardExpiryElement;
      case 'cardCvc':
        return CardCvcElement;
    }
  }, [props.type]);

  return (
    <div className="relative w-full">
      <TextInput
        name={props.type}
        label={props.label}
        className="pointer-events-none w-full flex-1"
        error={error}
        disabled
      />
      <div className="absolute bottom-[10px] left-[10px] right-[10px] top-[38px]">
        <Element
          onChange={(e) => setError(e.error?.message)}
          options={{
            style: {
              base: {
                'lineHeight': '22px',
                'color': 'white',
                '::placeholder': {
                  color: 'rgba(255,255,255,0.4)',
                },
              },
            },
            showIcon: props.type === 'cardNumber',
          }}
        />
      </div>
    </div>
  );
}
