import { cx } from 'class-variance-authority';
import { PropsWithChildren, ReactNode } from 'react';

export const GeneralTemplate = ({
  className,
  children,
  renderHeader,
  renderIllustrations,
}: PropsWithChildren<{
  className?: string;
  renderHeader?: () => ReactNode;
  renderIllustrations?: () => ReactNode;
}>) => (
  <>
    {renderIllustrations && renderIllustrations()}
    <div className={cx('relative flex min-h-screen justify-center px-6', className)}>
      <div className="mx-auto flex min-h-screen w-[1296px] min-w-0 max-w-[1296px] flex-col">
        {renderHeader && renderHeader()}
        {children}
      </div>
    </div>
  </>
);
