import { Accordion, BrandLink, Close } from '@highmobility/console-ui-components';
import { generatePath, Link, LoaderFunction, Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useLocalStorage } from 'react-use';

import { getBrandData } from '@/services/packagesService';
import { OEMS } from '@/constants';
import { ROUTE_PATHS } from '@/router/routes';
import { useMobx } from '@/hooks/useMobx';
import { useTypedParams } from '@/hooks/useTypedParams';

import { ProjectSecondaryMenu } from './ProjectSecondaryMenu';

export const loader: LoaderFunction = async () => {
  return {};
};

export const ProjectDataTemplate = observer(() => {
  const { teamId, projectId } = useTypedParams<typeof ROUTE_PATHS.PROJECT_DATA>();
  const { marketData } = useMobx();
  const [closedAccordions, setClosedAccordions] = useLocalStorage<
    Record<string, string[] | undefined>
  >('closedOemAccordions', {});

  return (
    <main className="flex h-full w-full">
      <ProjectSecondaryMenu>
        <div className="h-full overflow-y-auto bg-dark-green-900">
          <div className="flex items-center justify-between border-b border-black-20 bg-[#2B4247] px-4 py-2">
            <h5 className="text-heading-5">Car Data</h5>
            <Link to={generatePath(ROUTE_PATHS.PROJECT, { teamId, projectId })}>
              <Close className="h-4 w-4 cursor-pointer text-white-40 transition-colors hover:text-white" />
            </Link>
          </div>
          <div className="h-full">
            <div className="pb-12">
              {Object.entries(OEMS).map(([oemId, oem], i) => (
                <Accordion
                  key={i}
                  title={oem.name}
                  isOpen={!closedAccordions![projectId]?.includes(oemId)}
                  setIsOpen={(open) => {
                    if (!open) {
                      setClosedAccordions({
                        ...closedAccordions,
                        [projectId]: [...(closedAccordions![projectId] || []), oemId],
                      });
                    } else {
                      setClosedAccordions({
                        ...closedAccordions,
                        [projectId]: [...closedAccordions![projectId]!].filter((n) => n !== oemId),
                      });
                    }
                  }}
                >
                  <div>
                    {oem.brands.map((brand, j) => {
                      const brandData = getBrandData(brand)!;
                      const propertyCount =
                        marketData.byBrand[brand]
                          ?.map(({ properties }) => Object.values(properties))
                          .flat().length || 0;

                      return (
                        <Link
                          key={j}
                          to={generatePath(ROUTE_PATHS.PROJECT_DATA_BRAND, {
                            teamId,
                            projectId,
                            brand,
                          })}
                        >
                          <BrandLink
                            label={brandData.name}
                            secondaryLabel={`${propertyCount} item${propertyCount !== 1 ? 's' : ''}`}
                            renderLogo={(cn) => {
                              const BrandLogo = brandData.logo;
                              return <BrandLogo className={cn} />;
                            }}
                            active={window.location.pathname.includes(brand)}
                          />
                        </Link>
                      );
                    })}
                  </div>
                </Accordion>
              ))}
            </div>
          </div>
        </div>
      </ProjectSecondaryMenu>
      <div className="flex h-full w-full">
        <Outlet />
      </div>
    </main>
  );
});
