import { redirect } from 'react-router-dom';

import * as AccountSettings from '@/components/views/account-settings/AccountSettingsView';
import * as ActivateAccount from '@/components/views/activate-account/ActivateAccountView';
import * as CreateProject from '@/components/views/create-project/CreateProjectView';
import * as Dashboard from '@/components/views/dashboard/DashboardView';
import { EmailConfirmedView } from '@/components/views/email-confirmed/EmailConfirmedView';
import { ForgotPasswordView } from '@/components/views/forgot-password/ForgotPasswordView';
import { LoginView } from '@/components/views/login/LoginView';
import * as ProductInfo from '@/components/views/product-info/ProductInfoView';
import * as ProductInfoLegalView from '@/components/views/product-info-legal/ProductInfoLegalView';
import * as ProductInfoTemplate from '@/components/templates/product-info/ProductInfoTemplate';
import * as ProductInfoWebsiteView from '@/components/views/product-info-website/ProductInfoWebsiteView';
import * as Project from '@/components/views/project/ProjectView';
import * as ProjectAuthOAuth from '@/components/views/project-auth-oauth/ProjectAuthOAuthView';
import * as ProjectAuthStreaming from '@/components/views/project-auth-streaming/ProjectAuthStreamingView';
import * as ProjectAuthTemplate from '@/components/templates/project-auth/ProjectAuthTemplate';
import * as ProjectAuthWebhooks from '@/components/views/project-auth-webhooks/ProjectAuthWebhooksView';
import * as ProjectAuthWebhooksDeliveries from '@/components/views/project-auth-webhooks-deliveries/ProjectAuthWebhooksDeliveriesView';
import * as ProjectAuthWebhooksTemplate from '@/components/templates/project-auth-webhooks/ProjectAuthWebhooksTemplate';
import * as ProjectData from '@/components/views/project-data/ProjectDataView';
import * as ProjectDataBrand from '@/components/views/project-data-brand/ProjectDataBrandView';
import * as ProjectDataTemplate from '@/components/templates/project-data/ProjectDataTemplate';
import * as ProjectSimulationView from '@/components/views/project-auth-simulation/ProjectSimulationView';
import * as ProjectTemplate from '@/components/templates/project/ProjectTemplate';
import { ResetPasswordView } from '@/components/views/reset-password/ResetPasswordView';
import { RouteObject } from '@/types';
import { SignUpView } from '@/components/views/sign-up/SignUpView';
import * as Teams from '@/components/views/teams/TeamsView';
import * as TeamSettings from '@/components/views/team-settings/TeamSettingsView';

export const ROUTE_PATHS = {
  ROOT: '/',
  SIGN_UP: '/sign-up',
  EMAIL_CONFIRMED: '/email-confirmed',
  ACTIVATE_ACCOUNT: '/activate-account',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  DASHBOARD_ALL: '/dashboard/all/:teamId?',
  DASHBOARD_LIVE: '/dashboard/live/:teamId?',
  DASHBOARD: '/dashboard',
  ACCOUNT_SETTINGS: '/account',
  TEAMS: '/teams',
  TEAM_SETTINGS: '/teams/:teamId/settings',
  CREATE_PROJECT: '/teams/:teamId/projects/create',
  PROJECT: '/teams/:teamId/projects/:projectId',
  PROJECT_DATA: '/teams/:teamId/projects/:projectId/data',
  PROJECT_DATA_BRAND: '/teams/:teamId/projects/:projectId/data/:brand',
  PROJECT_AUTH: '/teams/:teamId/projects/:projectId/auth/:instance',
  PROJECT_AUTH_OAUTH: '/teams/:teamId/projects/:projectId/auth/:instance/oauth',
  PROJECT_AUTH_STREAMING: '/teams/:teamId/projects/:projectId/auth/:instance/streaming',
  PROJECT_AUTH_WEBHOOKS: '/teams/:teamId/projects/:projectId/auth/:instance/webhooks',
  PROJECT_AUTH_WEBHOOKS_DELIVERIES:
    '/teams/:teamId/projects/:projectId/auth/:instance/webhooks/deliveries',
  PROJECT_SIMULATION_STUDIO: '/teams/:teamId/projects/:projectId/simulation',
  PRODUCT_INFO: '/teams/:teamId/projects/:projectId/info',
  PRODUCT_INFO_WEBSITE: '/teams/:teamId/projects/:projectId/info/website',
  PRODUCT_INFO_LEGAL: '/teams/:teamId/projects/:projectId/info/legal',
} as const;

const routeMap: RouteObject[] = [
  {
    path: ROUTE_PATHS.ROOT,
    Component: null,
    loader: () => redirect(ROUTE_PATHS.LOGIN),
    type: 'public',
  },
  {
    path: ROUTE_PATHS.SIGN_UP,
    Component: SignUpView,
    type: 'public',
  },
  {
    path: ROUTE_PATHS.EMAIL_CONFIRMED,
    Component: EmailConfirmedView,
    type: 'public',
  },
  {
    path: ROUTE_PATHS.ACTIVATE_ACCOUNT,
    Component: ActivateAccount.ActivateAccountView,
    loader: ActivateAccount.loader,
    type: 'guest',
  },
  {
    path: ROUTE_PATHS.LOGIN,
    Component: LoginView,
    type: 'guest',
  },
  {
    path: ROUTE_PATHS.FORGOT_PASSWORD,
    Component: ForgotPasswordView,
    type: 'public',
  },
  {
    path: ROUTE_PATHS.RESET_PASSWORD,
    Component: ResetPasswordView,
    type: 'public',
  },
  {
    path: ROUTE_PATHS.DASHBOARD,
    Component: null,
    loader: () => redirect(ROUTE_PATHS.DASHBOARD_ALL),
  },
  {
    path: ROUTE_PATHS.DASHBOARD_ALL,
    Component: Dashboard.DashboardView,
    loader: Dashboard.loader,
  },
  {
    path: ROUTE_PATHS.DASHBOARD_LIVE,
    Component: Dashboard.DashboardView,
    loader: Dashboard.loader,
  },
  {
    path: ROUTE_PATHS.CREATE_PROJECT,
    Component: CreateProject.CreateProjectView,
    loader: CreateProject.loader,
  },
  {
    path: ROUTE_PATHS.ACCOUNT_SETTINGS,
    Component: AccountSettings.AccountSettingsView,
    loader: AccountSettings.loader,
  },
  {
    path: ROUTE_PATHS.TEAM_SETTINGS,
    Component: TeamSettings.TeamSettingsView,
    loader: TeamSettings.loader,
  },
  {
    path: ROUTE_PATHS.TEAMS,
    Component: Teams.TeamsView,
    loader: Teams.loader,
    children: [],
  },
  {
    path: ROUTE_PATHS.PROJECT,
    Component: ProjectTemplate.ProjectTemplate,
    loader: ProjectTemplate.loader,
    children: [
      {
        path: ROUTE_PATHS.PROJECT,
        Component: Project.ProjectView,
      },
      {
        path: ROUTE_PATHS.PROJECT_DATA,
        Component: ProjectDataTemplate.ProjectDataTemplate,
        loader: ProjectDataTemplate.loader,
        children: [
          {
            path: ROUTE_PATHS.PROJECT_DATA,
            Component: ProjectData.ProjectDataView,
          },
          {
            path: ROUTE_PATHS.PROJECT_DATA_BRAND,
            Component: ProjectDataBrand.ProjectDataBrandView,
            loader: ProjectDataBrand.loader,
          },
        ],
      },
      {
        path: ROUTE_PATHS.PROJECT_AUTH,
        Component: ProjectAuthTemplate.ProjectAuthTemplate,
        loader: ProjectAuthTemplate.loader,
        children: [
          {
            path: ROUTE_PATHS.PROJECT_AUTH_OAUTH,
            Component: ProjectAuthOAuth.ProjectAuthOAuthView,
            loader: ProjectAuthOAuth.loader,
          },
          {
            path: ROUTE_PATHS.PROJECT_AUTH_STREAMING,
            Component: ProjectAuthStreaming.ProjectAuthStreamingView,
            loader: ProjectAuthStreaming.loader,
          },
          {
            path: ROUTE_PATHS.PROJECT_AUTH_WEBHOOKS,
            Component: ProjectAuthWebhooksTemplate.ProjectAuthWebhooksTemplate,
            children: [
              {
                path: ROUTE_PATHS.PROJECT_AUTH_WEBHOOKS,
                Component: ProjectAuthWebhooks.ProjectAuthWebhooksView,
                loader: ProjectAuthWebhooks.loader,
              },
              {
                path: ROUTE_PATHS.PROJECT_AUTH_WEBHOOKS_DELIVERIES,
                Component: ProjectAuthWebhooksDeliveries.ProjectAuthWebhooksDeliveriesView,
                loader: ProjectAuthWebhooksDeliveries.loader,
              },
            ],
          },
        ],
      },
      {
        path: ROUTE_PATHS.PROJECT_SIMULATION_STUDIO,
        Component: ProjectSimulationView.ProjectSimulationView,
        loader: ProjectSimulationView.loader,
      },
      {
        path: ROUTE_PATHS.PRODUCT_INFO,
        Component: ProductInfoTemplate.ProductInfoTemplate,
        children: [
          {
            path: ROUTE_PATHS.PRODUCT_INFO,
            Component: ProductInfo.ProductInfoView,
          },
          {
            path: ROUTE_PATHS.PRODUCT_INFO_WEBSITE,
            Component: ProductInfoWebsiteView.ProductInfoWebsiteView,
          },
          {
            path: ROUTE_PATHS.PRODUCT_INFO_LEGAL,
            Component: ProductInfoLegalView.ProductInfoLegalView,
          },
        ],
      },
    ],
  },
];

export const routes = Object.values(routeMap).map((route) => {
  const newRoute: RouteObject = {
    ...route,
    type: route.type || 'protected',
  };

  return newRoute;
});
