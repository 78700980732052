import { capitalize } from 'radash';
import {
  Checkbox,
  CheckboxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tag,
  toast,
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { FC, FormEvent, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { getPackagePriceOrDescription } from '@/services/packagesService';
import { MarketDataPackage } from '@/types';
import { useMobx } from '@/hooks/useMobx';

import { CategoryHeaderProps } from './CategoryHeader';

export type CategoryPackageProps = {
  status: CategoryHeaderProps['status'];
  package: MarketDataPackage;
  selectedProperties: string[];
  brand: string;
  teamId: string;
  projectId: string;
};

export const CategoryPackage: FC<CategoryPackageProps> = observer((props) => {
  const { marketData } = useMobx();
  const [showTooltip, setShowTooltip] = useState(false);

  const price = getPackagePriceOrDescription(props.package.price, props.package.price_description);
  const checkboxColor: CheckboxProps['color'] = useMemo(() => {
    switch (props.status) {
      case 'published':
        return 'green';
      case 'in_review':
        return 'orange';
      case 'deprecated':
        return 'red';
      case 'draft':
        return 'white';
    }

    /* istanbul ignore next */
    props.status satisfies never;
  }, [props.status]);

  const marketDataByBrand = JSON.stringify(marketData.byBrand);
  const marketDataSelectedDraftProperties = JSON.stringify(marketData.selectedDraftProperties);
  const exclusivePackageAlreadySelected = useMemo(
    () => {
      if (props.status !== 'draft') {
        return false;
      }

      const exclusiveId = marketData.byBrand[props.brand]?.find(
        (p) => p.id === props.package.id
      )?.exclusive_id;
      if (!exclusiveId) {
        return false;
      }

      const selectedPackages = Object.keys(marketData.selectedDraftProperties[props.brand] || {});

      return selectedPackages.some((pckg) => {
        const data = marketData.byBrand[props.brand]?.find((p) => p.id === pckg);
        return data?.id !== props.package.id && data?.exclusive_id === exclusiveId;
      });
    },
    // eslint-disable-next-line
    [
      props.brand,
      props.package.id,
      marketDataByBrand,
      marketDataSelectedDraftProperties,
      props.status,
    ]
  );

  const checkboxesDisabled = props.status !== 'draft' || exclusivePackageAlreadySelected;
  const toggleProperties = async (e: FormEvent<HTMLInputElement>, properties: string[]) => {
    try {
      if (e.currentTarget.checked) {
        await marketData.selectProperties(props.teamId, props.projectId, properties);
      } else {
        await marketData.removeProperties(props.teamId, props.projectId, properties);
      }
    } catch (e) {
      toast('Something went wrong. Please try again.', 'error');
    }
  };

  return (
    <TooltipProvider
      open={exclusivePackageAlreadySelected && showTooltip}
      setOpen={setShowTooltip}
      useHover
      followMouse
    >
      <TooltipTrigger>
        <div
          className={cx('flex flex-col gap-4 px-8 py-4 transition-opacity', {
            'cursor-not-allowed opacity-50': exclusivePackageAlreadySelected,
          })}
        >
          <div className="flex items-center gap-3">
            <span>Package</span>
            <Tag type="dark" round>
              {props.package.name}
              {price && (
                <>
                  {' '}
                  <span className="text-success-500">{price}</span>
                </>
              )}
            </Tag>
          </div>
          <Table>
            <TableHeader>
              <TableRow
                className={cx({
                  'opacity-50':
                    props.package.deprecated ||
                    props.package.properties.every(
                      (p) => p.deprecated && !props.selectedProperties.includes(p.id)
                    ),
                })}
              >
                <TableHead className="w-[280px]">
                  <div className="flex items-center gap-3">
                    <Checkbox
                      color={checkboxColor}
                      disabled={checkboxesDisabled}
                      name="allProperties"
                      checked={props.package.properties.every((p) =>
                        props.selectedProperties.includes(p.id)
                      )}
                      onChange={async (e) =>
                        toggleProperties(
                          e,
                          props.package.properties.map((p) => p.id)
                        )
                      }
                    />
                    <span className="text-body-small">Property</span>
                  </div>
                </TableHead>
                <TableHead className="w-[144px] text-body-small">Communication</TableHead>
                <TableHead className="w-[280px] text-body-small">Frequency</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {props.package.properties.map((property, i) => (
                <TableRow
                  key={i}
                  className={cx({
                    'opacity-50':
                      (props.package.deprecated || property.deprecated) &&
                      !props.selectedProperties.includes(property.id),
                  })}
                >
                  <TableCell>
                    <div className="flex items-center gap-3 text-body-small">
                      <Checkbox
                        color={checkboxColor}
                        disabled={checkboxesDisabled}
                        name={property.name}
                        checked={props.selectedProperties.includes(property.id)}
                        onChange={async (e) => toggleProperties(e, [property.id])}
                      />
                      <span>{property.name}</span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center gap-1">
                      {property.delivery_method.map((method, j) => (
                        <Tag key={j} type="dark" round>
                          {capitalize(method)}
                        </Tag>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell>
                    {property.frequency && (
                      <Tag type="dark" round>
                        {property.frequency}
                      </Tag>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </TooltipTrigger>
      <Tooltip>
        <div className="max-w-[210px]">
          This package can&apos;t be selected because another frequency-based package is selected.
        </div>
      </Tooltip>
    </TooltipProvider>
  );
});
