import { RouteObject } from '@/types';

export const AUTH_REDIRECT_PARAM = 'redirect';

export function applyMiddleware(
  routes: RouteObject[],
  middlewares: ((routes: RouteObject[]) => RouteObject[])[]
) {
  return middlewares.reduce((acc, middleware) => {
    return middleware(acc);
  }, routes);
}
