import { cva, cx, VariantProps } from 'class-variance-authority';
import { forwardRef, ForwardRefRenderFunction, HTMLAttributes } from 'react';

const checkbox = cva(
  [
    'inline-flex',
    'items-center',
    'justify-center',
    'rounded',
    'transition-colors',
    'outline-none',
    'w-4',
    'h-4',
    'min-w-4',
    'min-h-4',
    'rounded',
    'bg-black-12',
    'appearance-none',
    'border',
    'shadow-[0_4px_4px_0px_rgba(16,16,16,0.10)_inset]',
    'flex-shrink-0',
  ],
  {
    variants: {
      color: {
        green: 'checked:bg-success-500',
        orange: 'checked:bg-alert-500',
        white: 'checked:bg-white',
        red: 'checked:bg-error-600',
      },
      disabled: {
        true: 'shadow-none border-white-20 cursor-not-allowed',
        false: 'border-white-80 cursor-pointer',
      },
      checked: {
        true: '',
      },
    },
    compoundVariants: [
      {
        disabled: true,
        checked: false,
        className: 'bg-white-4',
      },
    ],
  }
);

export type CheckboxProps = HTMLAttributes<HTMLInputElement> &
  VariantProps<typeof checkbox> & {
    name: string;
    defaultChecked?: boolean;
    disabled?: boolean;
    type?: 'checkbox' | 'radio';
    checked?: boolean;
    color?: 'green' | 'orange' | 'white' | 'red';
  };

const CheckboxComponent: ForwardRefRenderFunction<HTMLInputElement, CheckboxProps> = (
  { className, name, defaultChecked = false, disabled, color = 'green', ...props },
  ref
) => (
  <input
    ref={ref}
    className={cx(
      checkbox({
        color,
        disabled: !!disabled,
        checked: !!props.checked,
      }),
      className
    )}
    type="checkbox"
    name={name}
    defaultChecked={props.checked === undefined ? defaultChecked : undefined}
    disabled={!!disabled}
    {...props}
  />
);

export const Checkbox = forwardRef(CheckboxComponent);
