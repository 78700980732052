import { observer } from 'mobx-react-lite';

import BackgroundIllustrationWithCarsLarge from '@/assets/illustrations/BackgroundIllustrationWithCarsLarge.svg';
import { Project } from '@/types';

import { ProjectCard } from './ProjectCard';

type ProjectListProps = {
  projects: Project[];
};

export const ProjectList = observer((props: ProjectListProps) => {
  return (
    <>
      <div className="w-full pb-24">
        {props.projects.length > 0 ? (
          <div
            className="grid gap-x-2 gap-y-8"
            style={{
              gridTemplateColumns: 'repeat(auto-fill, minmax(min(260px, 100%), 1fr))',
            }}
          >
            {props.projects.map((project, key) => (
              <div key={key}>
                <ProjectCard project={project} />
              </div>
            ))}
          </div>
        ) : (
          <div className="border-white-4 flex w-full flex-col gap-1 rounded-[4px] border p-10">
            <p className="text-white">New data projects are waiting</p>
            <p className="text-white-60">
              Create a new data project and start getting live data delivered to your services
            </p>
          </div>
        )}
      </div>
      {props.projects.length < 1 && <BackgroundIllustrationWithCarsLarge className="w-full" />}
    </>
  );
});
