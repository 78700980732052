import { cva } from 'class-variance-authority';
import { FC, PropsWithChildren } from 'react';
import { Radio, Tag } from '@highmobility/console-ui-components';

export type StreamingCardProps = PropsWithChildren<{
  active: boolean;
  checked: boolean;
  onClick: () => void;
  title: string;
}>;

const streamingCard = cva(
  'flex cursor-pointer gap-3 rounded border bg-white-4 hover:bg-white-12 p-8 transition-colors flex-1',
  {
    variants: {
      checked: {
        true: 'border-white-40',
        false: 'border-white-12',
      },
    },
  }
);

export const StreamingCard: FC<StreamingCardProps> = (props) => {
  return (
    <div
      className={streamingCard({ checked: props.checked })}
      onClick={props.onClick}
      key={props.checked ? 'checked' : 'unchecked'}
      aria-label={`Switch to ${props.title}`}
    >
      <div className="">
        <Radio
          aria-label="check"
          name="streaming_option"
          color="green"
          defaultChecked={props.checked}
        />
      </div>
      <div className="flex flex-col gap-4 px-4">
        <div className="flex items-center justify-between gap-1">
          <h5 className="text-heading-5">{props.title}</h5>
          {props.active && <Tag type="success">Active</Tag>}
        </div>
        <p className="">{props.children}</p>
      </div>
    </div>
  );
};
