import { cx } from 'class-variance-authority';
import { FC } from 'react';

export type DropdownMenuSeparatorProps = {
  thick?: boolean;
};

export const DropdownMenuSeparator: FC<DropdownMenuSeparatorProps> = (props) => (
  <div
    className={cx('h-[1px]', {
      'bg-white-4': !props.thick,
      'bg-white-20': !!props.thick,
    })}
  />
);
