import { ControlRow, toast } from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { emailPreferencesValidation } from '@/services/zodService';
import { updateSubscriptions } from '@/services/apiService';
import { User } from '@/types';

type EmailPreferencesFormProps = {
  className?: string;
  defaultValues?: Partial<z.infer<typeof emailPreferencesValidation>>;
};

export const EmailPreferencesForm: FC<EmailPreferencesFormProps> = (props) => {
  const emailPreferencesMutation = useMutation({
    mutationFn: async (data: z.infer<typeof emailPreferencesValidation>) => {
      const subscriptions: User['subscriptions'] = [];
      data.newsletter && subscriptions.push('newsletter');

      return updateSubscriptions({
        subscriptions,
      });
    },
    onError: (e) => {
      console.error('Error submitting the form', e);
      toast('Oops! Changes were not saved. Please try again!', 'error');
    },
    onSuccess: () => {
      toast('Subscription preferences updated', 'success');
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof emailPreferencesValidation>>({
    resolver: zodResolver(emailPreferencesValidation),
    defaultValues: props.defaultValues,
  });
  const onSubmit = handleSubmit((data) => emailPreferencesMutation.mutate(data));

  return (
    <form className={cx(props.className, 'max-w-[800px]')} onSubmit={onSubmit}>
      <div className="mb-8 flex flex-col gap-6">
        <ControlRow
          type="checkbox"
          label={
            <span className="text-body-regular">
              Receive our quarterly digest{' '}
              <b className="text-label-regular font-bold">Life after Data</b>
            </span>
          }
          {...register('newsletter')}
          onChange={(e) => {
            register('newsletter').onChange(e);
            onSubmit();
          }}
          error={errors.newsletter?.message}
        />
      </div>
    </form>
  );
};
