import { AlertDialog, AlertDialogProps, toast } from '@highmobility/console-ui-components';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FC } from 'react';
import { useMutation } from '@tanstack/react-query';

import { setupCard, setupCardInit } from '@/services/apiService';
import { StripeValidationError } from '@/errors/StripeValidationError';
import { useMobx } from '@/hooks/useMobx';

import { StripeElementsProvider } from './StripeElementsProvider';
import { StripeInput } from './StripeInput';

export type EditCardDialogProps = { teamId: string } & Pick<
  AlertDialogProps,
  'isOpen' | 'setIsOpen'
>;

const EditCardDialogContent: FC<EditCardDialogProps> = (props: EditCardDialogProps) => {
  const { billing } = useMobx();
  const stripe = useStripe();
  const elements = useElements();

  const saveCardMutation = useMutation({
    mutationFn: async () => {
      if (!stripe || !elements) {
        throw new Error('Stripe or elements not initialized');
      }

      const cardNumber = elements.getElement(CardNumberElement);
      if (!cardNumber) {
        throw new Error('Card number element not found');
      }

      const clientSecret = await setupCardInit(props.teamId).then((res) => res.data.secret);
      const stripeCardToken = await stripe.createToken(cardNumber).then((res) => {
        if (res.error && res.error.message && res.error.type === 'validation_error') {
          throw new StripeValidationError(res.error.message);
        } else if (res.error) {
          throw new Error('Failed to create card token');
        }

        return res.token?.id;
      });

      const paymentMethodId = await stripe
        .confirmCardSetup(clientSecret, {
          payment_method: {
            card: {
              token: stripeCardToken!,
            },
          },
        })
        .then((res) => {
          if (res.error) {
            throw new Error(res.error.message);
          }

          if (!res.setupIntent.payment_method) {
            throw new Error('Payment method not found');
          }

          return res.setupIntent?.payment_method.toString();
        });

      await setupCard(props.teamId, { id: paymentMethodId });
    },
    onError: (e: any) => {
      console.error('Save card mutation failed', e.constructor.name);
      let toastMessage = 'Something went wrong. Please try again.';
      if (e instanceof StripeValidationError) {
        toastMessage = e.message;
      } else if (e?.response?.data?.message) {
        toastMessage = e.response.data.message;
      }

      toast(toastMessage, 'error');
    },
    onSuccess: () => {
      props.setIsOpen(false);
      toast('Card updated successfully.', 'success');
      billing.fetchCompanyProfile(props.teamId);
    },
  });

  const onSubmit = () => saveCardMutation.mutate();

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title="Edit card details"
      confirmLabel={saveCardMutation.isPending ? 'Saving' : 'Save'}
      confirmButtonDisabled={saveCardMutation.isPending}
      cancelLabel="Cancel"
      onConfirm={onSubmit}
      onCancel={() => props.setIsOpen(false)}
    >
      <form className="flex flex-col gap-4" onSubmit={onSubmit}>
        <StripeInput type="cardNumber" label="Card number" />
        <div className="flex w-full gap-2">
          <StripeInput type="cardExpiry" label="Exp date" />
          <StripeInput type="cardCvc" label="CVC" />
        </div>
      </form>
    </AlertDialog>
  );
};

export const EditCardDialog: FC<EditCardDialogProps> = (props) => {
  return (
    <StripeElementsProvider>
      <EditCardDialogContent {...props} />
    </StripeElementsProvider>
  );
};
