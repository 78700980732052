import { DropdownField } from '@highmobility/console-ui-components';
import { FC, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { CreateTeamDialog } from '@/components/organisms/CreateTeamDialog';
import { ROUTE_PATHS } from '@/router/routes';
import { useMobx } from '@/hooks/useMobx';

export type TeamsSelectProps = {
  onSelect: (teamId: string) => void;
};

export const TeamsSelect: FC<TeamsSelectProps> = observer((props) => {
  const { teams } = useMobx();
  const [showCreateTeamDialog, setShowCreateTeamDialog] = useState(false);
  const navigate = useNavigate();

  const teamsSelectItems = useMemo(
    () => [
      ...teams.list.map((team, i) => ({
        label: team.name,
        value: team.id,
        onClick: () => props.onSelect(team.id),
        thickSeparator: teams.list.length - 1 === i,
      })),
      {
        label: 'Create a new team',
        onClick: () => setShowCreateTeamDialog(true),
      },
      {
        label: 'Manage teams',
        onClick: () => {
          navigate(ROUTE_PATHS.TEAMS);
        },
      },
    ],
    [props, navigate, teams.list]
  );

  return (
    <>
      <DropdownField
        className="min-w-[320px]"
        name="team"
        placeholder="Select team"
        items={teamsSelectItems}
        value={teams.activeTeam?.id}
      />
      <CreateTeamDialog isOpen={showCreateTeamDialog} setIsOpen={setShowCreateTeamDialog} />
    </>
  );
});
