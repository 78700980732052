import { AlertDialog, AlertDialogProps, toast } from '@highmobility/console-ui-components';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { leaveTeam } from '@/services/apiService';
import { ROUTE_PATHS } from '@/router/routes';
import { useMobx } from '@/hooks/useMobx';

type LeaveTeamDialogProps = { teamId: string } & Pick<AlertDialogProps, 'isOpen' | 'setIsOpen'>;

export const LeaveTeamDialog = (props: LeaveTeamDialogProps) => {
  const { teams } = useMobx();
  const navigate = useNavigate();
  const leaveTeamMutation = useMutation({
    mutationFn: async () => leaveTeam(props.teamId),
    onError: (e: any) => {
      props.setIsOpen(false);
      toast(e?.response?.data?.message || 'Something went wrong. Please try again.', 'error');
    },
    onSuccess: () => {
      teams.fetchTeams();
      props.setIsOpen(false);
      toast('You have left the team', 'success');
      navigate(ROUTE_PATHS.DASHBOARD_ALL);
    },
  });

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title={`Do you want to leave ${teams.byId[props.teamId]?.name} team?`}
      confirmButtonIntent="primarySignal"
      confirmLabel="Yes, Leave"
      cancelLabel="Cancel"
      onConfirm={() => leaveTeamMutation.mutate()}
      onCancel={() => props.setIsOpen(false)}
    >
      You can be invited again by an admin member.
    </AlertDialog>
  );
};
