import { Button, TextInput, toast } from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { changeEmail, fakeLoader, updateUserDetails } from '@/services/apiService';
import {
  userDetailsValidation,
  userDetailsValidationForChangingEmail,
} from '@/services/zodService';

type UserDetailsFormProps = {
  className?: string;
  defaultValues?: Partial<z.infer<typeof userDetailsValidation>>;
};

export const UserDetailsForm: FC<UserDetailsFormProps> = (props) => {
  const userDetailsMutation = useMutation({
    mutationFn: async (data: z.infer<typeof userDetailsValidation>) => {
      return Promise.all([
        fakeLoader(),
        updateUserDetails({
          name: data.name,
        }),
        data.email !== props.defaultValues?.email &&
          changeEmail({ email: data.email, password: data.currentPassword }),
      ]);
    },
    onError: (e) => {
      console.error('Error submitting the form', e);
      toast('Oops! Changes were not saved. Please try again!', 'error');
    },
    onSuccess: () => {
      const emailChanged = getValues('email') !== props.defaultValues?.email;
      toast(emailChanged ? 'Confirmation email sent' : 'User details updated', 'success');
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<z.infer<typeof userDetailsValidation>>({
    resolver: (...args) => {
      if (props.defaultValues?.email !== getValues('email')) {
        return zodResolver(userDetailsValidationForChangingEmail)(...args);
      }

      return zodResolver(userDetailsValidation)(...args);
    },
    defaultValues: props.defaultValues,
  });
  const onSubmit = handleSubmit((data) => userDetailsMutation.mutate(data));

  return (
    <form className={cx(props.className, 'max-w-[800px]')} onSubmit={onSubmit}>
      <div className="mb-8 flex flex-col gap-6">
        <TextInput
          label="Name"
          placeholder={props.defaultValues?.name}
          {...register('name')}
          error={errors.name?.message}
        />
        <TextInput
          label="Email"
          placeholder={props.defaultValues?.email}
          {...register('email')}
          error={errors.email?.message}
        />
        <TextInput
          label="Current password"
          labelAdditionalInfo="(needed when changing your email)"
          placeholder="**********"
          {...register('currentPassword')}
          error={errors.currentPassword?.message}
          type="password"
        />
      </div>
      <div className="flex justify-end">
        <Button disabled={userDetailsMutation.isPending} type="submit">
          {userDetailsMutation.isPending ? 'Loading' : 'Save'}
        </Button>
      </div>
    </form>
  );
};
