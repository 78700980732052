import { Chevron } from '@highmobility/console-ui-components';
import { cva } from 'class-variance-authority';
import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

export type ContentLinkProps = PropsWithChildren<{
  title: string;
  link: string;
  active: boolean;
}>;

const contentLink = cva(
  'py-8 px-4 flex gap-3 cursor-pointer transition-colors hover:bg-white-4 border-b border-black-20 min-h-[120px] items-center',
  {
    variants: {
      active: {
        true: 'bg-white-4',
      },
    },
  }
);

export const ContentLink: FC<ContentLinkProps> = (props) => {
  return (
    <Link to={props.link}>
      <div className={contentLink({ active: props.active })}>
        <div className="flex flex-col gap-4 text-white">
          <h5 className="text-heading-5">{props.title}</h5>
          {props.children && <p className="text-white-60">{props.children}</p>}
        </div>
        <Chevron className="ml-auto h-4 w-4 flex-shrink-0 -rotate-90 text-white-60" />
      </div>
    </Link>
  );
};
