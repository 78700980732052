import { ActionButton, Button, Plus, toast } from '@highmobility/console-ui-components';
import { FC, Fragment, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { sort } from 'radash';
import { useMutation } from '@tanstack/react-query';

import { deleteServiceAccount, fakeLoader } from '@/services/apiService';
import { OAuthClient, ProjectInstance, ServiceAccount } from '@/types';

import { CreateOAuthCredentialsDialog } from './CreateOAuthCredentialsDialog';

export type OAuthClientCredentialsSectionProps = {
  teamId: string;
  projectId: string;
  instance: ProjectInstance;
  serviceAccounts: ServiceAccount[] | null;
  hasViewPermission: boolean;
  hasManagePermission: boolean;
  oAuthClientData: OAuthClient | null;
};

export const OAuthClientCredentialsSection: FC<OAuthClientCredentialsSectionProps> = (props) => {
  const sortServiceAccounts = (serviceAccounts: ServiceAccount[]) =>
    sort(serviceAccounts, (item) => new Date(item.inserted_at).getTime(), true);
  const [showCreateCredentialsDialog, setShowCreateCredentialsDialog] = useState(false);
  const [serviceAccounts, setServiceAccounts] = useState<ServiceAccount[]>(
    sortServiceAccounts(props.serviceAccounts ?? [])
  );

  useEffect(() => {
    setServiceAccounts(props.serviceAccounts ?? []);
  }, [props.serviceAccounts]);

  const deleteServiceAccountMutation = useMutation({
    mutationFn: async (input: { id: string }) => {
      const [{ data }] = await Promise.all([
        deleteServiceAccount(props.teamId, props.projectId, props.instance, input.id),
        fakeLoader(),
      ]);

      return data;
    },
    onError: (e: any) => {
      console.error('deleteServiceAccountMutation::failed to delete service account', e);
      toast(e?.response?.data?.message ?? 'Something went wrong. Please try again.', 'error');
    },
    onSuccess: (_, variables) => {
      setServiceAccounts((prev) => {
        return sortServiceAccounts(
          prev.filter((serviceAccount) => serviceAccount.id !== variables.id)
        );
      });
      toast(`Credentials deleted`, 'success');
    },
  });

  return (
    <div className="flex flex-col gap-4 p-8">
      <h5 className="text-heading-5">OAuth Client Credentials</h5>
      <p className="text-white-60">
        Create a new key or use client_credential grant with private key.{' '}
        <a
          className="text-white-60 underline transition-colors hover:text-white"
          href="https://docs.develop.high-mobility.net/guides/platform/oauth-client-credentials"
          rel="noreferrer"
          target="_blank"
        >
          Learn more
        </a>
      </p>
      <ActionButton
        aria-label="Create a new key"
        className="w-full"
        disabled={!props.hasManagePermission}
        onClick={() => setShowCreateCredentialsDialog(true)}
      >
        {(cn) => <Plus className={cn} />}
      </ActionButton>
      {props.hasViewPermission && (
        <div
          className="grid gap-4 whitespace-nowrap"
          style={{
            gridTemplateColumns: 'repeat(5, auto)',
          }}
        >
          {serviceAccounts.map((serviceAccount, i) => (
            <Fragment key={i}>
              <div className="">
                <div className="">ID</div>
                <div className="text-body-small">{serviceAccount.id}</div>
              </div>
              <div className="">
                <div className="">Name</div>
                <div className="text-body-small">{serviceAccount.name}</div>
              </div>
              <div>
                <div className="">Created on</div>
                <div className="text-body-small">
                  {format(new Date(serviceAccount.inserted_at), 'yyyy-MM-dd hh:mm:ss')}
                </div>
              </div>
              <div>
                <div className="">Expiration date</div>
                <div className="text-body-small">
                  {serviceAccount.expires_at
                    ? format(new Date(serviceAccount.expires_at), 'yyyy-MM-dd hh:mm:ss')
                    : 'Never'}
                </div>
              </div>
              <Button
                intent="secondary"
                onClick={() => deleteServiceAccountMutation.mutate({ id: serviceAccount.id })}
                disabled={
                  deleteServiceAccountMutation.isPending &&
                  deleteServiceAccountMutation.variables.id === serviceAccount.id
                }
              >
                Delete
              </Button>
            </Fragment>
          ))}
        </div>
      )}

      {props.oAuthClientData && (
        <CreateOAuthCredentialsDialog
          isOpen={showCreateCredentialsDialog}
          setIsOpen={setShowCreateCredentialsDialog}
          instance={props.instance}
          projectId={props.projectId}
          teamId={props.teamId}
          oAuthClientData={props.oAuthClientData}
          onSuccess={(newServiceAccount) =>
            setServiceAccounts((prev) => sortServiceAccounts([...prev, newServiceAccount]))
          }
        />
      )}
    </div>
  );
};
