import { DialogProps } from '@highmobility/console-ui-components';
import { FC, useState } from 'react';

import { MFAConnectDialog } from '@/components/views/account-settings/MFAConnectDialog';
import { MFAConnectSuccessDialog } from '@/components/views/account-settings/MFAConnectSuccessDialog';
import { MFARecoveryCodesDialog } from '@/components/views/account-settings/MFARecoveryCodesDialog';

export type EnableMFADialogProps = {
  isOpen: DialogProps['isOpen'];
  setIsOpen: DialogProps['setIsOpen'];
  settings: {
    id: string;
    url: string;
    secret: string;
    expires_at: number;
  } | null;
};

export const EnableMFADialog: FC<EnableMFADialogProps> = (props) => {
  const [showMFAConnectSuccessDialog, setShowMFAConnectSuccessDialog] = useState(false);
  const [showMFARecoveryCodesDialog, setShowMFARecoveryCodesDialog] = useState(false);
  const [MFACodes, setMFACodes] = useState<string[] | null>(null);
  const [code, setCode] = useState('');

  return (
    <>
      {props.settings && (
        <MFAConnectDialog
          isOpen={props.isOpen}
          setIsOpen={props.setIsOpen}
          onConfirm={(code) => {
            props.setIsOpen(false);
            setCode(code);
            setShowMFAConnectSuccessDialog(true);
          }}
          MFASettings={props.settings}
        />
      )}
      <MFAConnectSuccessDialog
        isOpen={showMFAConnectSuccessDialog}
        setIsOpen={setShowMFAConnectSuccessDialog}
        code={code}
        onGenerateRecoveryCodes={(codes) => {
          setMFACodes(codes);
          setShowMFARecoveryCodesDialog(true);
          setShowMFAConnectSuccessDialog(false);
        }}
      />
      {MFACodes && (
        <MFARecoveryCodesDialog
          isOpen={showMFARecoveryCodesDialog}
          setIsOpen={setShowMFARecoveryCodesDialog}
          onConfirm={() => setShowMFARecoveryCodesDialog(false)}
          codes={MFACodes}
        />
      )}
    </>
  );
};
