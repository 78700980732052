import { ActionButton, Gear } from '@highmobility/console-ui-components';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { DashboardSecondaryHeader } from '@/components/organisms/DashboardSecondaryHeader';
import { ROUTE_PATHS } from '@/router/routes';
import { TeamsSelect } from '@/components/molecules/TeamsSelect';
import { useMobx } from '@/hooks/useMobx';

export const TeamOptionsHeader = observer(() => {
  const { teams } = useMobx();
  const navigate = useNavigate();

  return (
    <DashboardSecondaryHeader>
      <div className="flex items-center gap-3">
        <TeamsSelect
          onSelect={(teamId) => {
            navigate(generatePath(ROUTE_PATHS.DASHBOARD_ALL, { teamId }));
          }}
        />
        {teams.activeTeam && (
          <Link
            to={generatePath(ROUTE_PATHS.TEAM_SETTINGS, {
              teamId: `${teams.activeTeam?.id}`,
            })}
          >
            <ActionButton>{(cn) => <Gear className={cn} />}</ActionButton>
          </Link>
        )}
      </div>
    </DashboardSecondaryHeader>
  );
});
