import { AlertDialog, DialogProps, toast } from '@highmobility/console-ui-components';
import { browserSupportsWebAuthn, startRegistration } from '@simplewebauthn/browser';
import { FC } from 'react';
import { useMutation } from '@tanstack/react-query';

import { fetchPasskeyOptions, registerPasskey } from '@/services/apiService';
import { useMobx } from '@/hooks/useMobx';

export type CreatePasskeyDialogProps = {
  isOpen: DialogProps['isOpen'];
  setIsOpen: DialogProps['setIsOpen'];
};

export const CreatePasskeyDialog: FC<CreatePasskeyDialogProps> = (props) => {
  const { session } = useMobx();

  const createPasskeyMutation = useMutation({
    mutationFn: async () => {
      if (!browserSupportsWebAuthn()) {
        throw new Error('Your browser does not support Passkey login.');
      }

      const { data } = await fetchPasskeyOptions();
      const registrationResponse = await startRegistration(data);
      const { data: authenticator } = await registerPasskey(registrationResponse);
      session.setPasskeys([...session.passkeys, authenticator]);
    },
    onError: (e: any) => {
      console.error('Failed to create passkey', e);
      toast(
        e?.response?.data?.message || e?.message || 'Something went wrong. Please try again.',
        'error'
      );
    },
    onSuccess: async () => {
      toast('Passkey created', 'success');
      props.setIsOpen(false);
    },
  });

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title="Create a Passkey"
      confirmButtonIntent="primary"
      confirmLabel={createPasskeyMutation.isPending ? 'Creating' : 'Create'}
      confirmButtonDisabled={createPasskeyMutation.isPending}
      cancelLabel="Cancel"
      onConfirm={() => createPasskeyMutation.mutate()}
      onCancel={() => props.setIsOpen(false)}
    >
      <div className="w-[380px]">
        Create a passkey to start signing in with just your fingerprint, face or screen lock
      </div>
    </AlertDialog>
  );
};
