import { Close, Tag } from '@highmobility/console-ui-components';
import { FC } from 'react';
import { generatePath, Link } from 'react-router-dom';

import { ProjectInstance } from '@/types';
import { ROUTE_PATHS } from '@/router/routes';

export type ProjectAuthHeaderProps = {
  title: string;
  instance: ProjectInstance;
  teamId: string;
  projectId: string;
};

export const ProjectAuthHeader: FC<ProjectAuthHeaderProps> = (props) => {
  return (
    <div className="flex items-center gap-3 bg-[#2B4247] px-4 py-2">
      <h5 className="text-heading-5">{props.title}</h5>
      <Tag type={props.instance === 'sandbox' ? 'alert' : 'success'}>
        {props.instance === 'sandbox' ? 'Sandbox' : 'Live API'}
      </Tag>
      <Link
        to={generatePath(ROUTE_PATHS.PROJECT_AUTH, {
          teamId: props.teamId,
          projectId: props.projectId,
          instance: props.instance,
        })}
        className="ml-auto"
      >
        <Close className="h-4 w-4 cursor-pointer text-white-40 transition-colors hover:text-white" />
      </Link>
    </div>
  );
};
