import { observer } from 'mobx-react-lite';

import { DashboardHeader } from '@/components/organisms/DashboardHeader';
import { dashboardViewLoader } from '@/loaders/dashboardViewLoader';
import { GeneralTemplate } from '@/components/templates/general/GeneralTemplate';
import { ProjectList } from '@/components/views/dashboard/ProjectList';
import { ROUTE_PATHS } from '@/router/routes';
import { useCurrentPath } from '@/hooks/useCurrentPath';
import { useMobx } from '@/hooks/useMobx';

import { ProjectListHeader } from './ProjectListHeader';
import { TeamOptionsHeader } from './TeamOptionsHeader';

export const loader = dashboardViewLoader;

export const DashboardView = observer(() => {
  const { teams, projects } = useMobx();
  const currentPath = useCurrentPath();
  const activeTab = currentPath === ROUTE_PATHS.DASHBOARD_ALL ? 'all' : 'live';

  const allProjects = teams.activeTeam?.id ? projects.teamProjects(teams.activeTeam.id) : [];
  const liveProjects = allProjects.filter(
    (project) => project.status === 'published' || project.status === 'published_with_changes'
  );
  const shownProjects = activeTab === 'all' ? allProjects : liveProjects;

  return (
    <GeneralTemplate renderHeader={() => <DashboardHeader />}>
      <TeamOptionsHeader />
      <main className="pb-header flex h-full w-full">
        <div className="flex w-full flex-col items-start gap-2 pb-8">
          {teams.activeTeam && <h2 className="text-heading-2 pb-8">{teams.activeTeam?.name}</h2>}
          <ProjectListHeader
            activeTab={activeTab}
            allProjectsCount={allProjects.length}
            liveProjectsCount={liveProjects.length}
            teamId={teams.activeTeam?.id || ''}
          />
          <ProjectList projects={shownProjects} />
        </div>
      </main>
    </GeneralTemplate>
  );
});
