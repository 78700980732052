import { AlertDialog, DialogProps, toast } from '@highmobility/console-ui-components';
import { FC } from 'react';
import { useMutation } from '@tanstack/react-query';

import { fakeLoader, resetOAuthClientSecret } from '@/services/apiService';
import { OAuthClient, ProjectInstance } from '@/types';

export type RegenerateClientSecretDialogProps = {
  isOpen: DialogProps['isOpen'];
  setIsOpen: DialogProps['setIsOpen'];
  teamId: string;
  projectId: string;
  instance: ProjectInstance;
  clientId: string;
  onSuccess: (newClient: OAuthClient) => void;
};

export const RegenerateClientSecretDialog: FC<RegenerateClientSecretDialogProps> = (props) => {
  const regenerateClientSecretMutation = useMutation({
    mutationFn: async () => {
      await fakeLoader();

      return await resetOAuthClientSecret(
        props.teamId,
        props.projectId,
        props.instance,
        props.clientId
      );
    },
    onError: (e: any) => {
      console.error('RegenerateClientSecretDialog::failed to reset', e);
      toast(e?.response?.data?.message || 'Something went wrong. Please try again.', 'error');
    },
    onSuccess: ({ data }) => {
      props.setIsOpen(false);
      props.onSuccess(data);
      toast('Client secret has been regenerated', 'success');
    },
  });

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      onCancel={() => props.setIsOpen(false)}
      onConfirm={() => regenerateClientSecretMutation.mutate()}
      title="Regenerate code"
      confirmLabel={regenerateClientSecretMutation.isPending ? 'Loading' : 'Regenerate'}
      confirmButtonDisabled={regenerateClientSecretMutation.isPending}
    >
      <div className="flex flex-col gap-4">
        <p>
          This action is irreversible and will result in a permanent change of the Client Secret for
          that application
        </p>
        <p className="font-bold text-error-500">
          This new Client secret code will replace your old code.
        </p>
      </div>
    </AlertDialog>
  );
};
