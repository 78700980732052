import { cx } from 'class-variance-authority';
import { format } from 'date-fns';
import { ProjectStatusIcon } from '@highmobility/console-ui-components';
import { useMemo } from 'react';

import { ProjectStatus } from '@/types';

type ProjectStatusBoxProps = {
  status: ProjectStatus;
  publishedAt?: string | null;
  active?: boolean;
};

export const ProjectStatusBox = (props: ProjectStatusBoxProps) => {
  const description = useMemo(() => {
    switch (props.status) {
      case 'draft':
        return 'Application has been created and data packages can be selected.';
      case 'in_review':
        return 'Our experts will review application submission and validate data selection.';
      case 'published':
      case 'published_with_changes':
        return 'Ready for data updates from connected cars.';
    }

    /* istanbul ignore next */
    props.status satisfies never;
  }, [props.status]);

  const title = useMemo(() => {
    switch (props.status) {
      case 'draft':
        return 'Draft';
      case 'in_review':
        return 'Review';
      case 'published':
        return 'Live';
      case 'published_with_changes':
        return 'Live*';
    }

    /* istanbul ignore next */
    props.status satisfies never;
  }, [props.status]);

  return (
    <div
      className={cx('flex items-center gap-6 px-6 py-3', {
        'bg-black-40': props.active,
        'bg-black-4': !props.active,
      })}
    >
      <ProjectStatusIcon size="large" status={props.status} />
      <div className="flex flex-col gap-1">
        <h5 className="text-body-regular text-heading-5">{title}</h5>
        <div className="text-white-60 text-body-regular">{description}</div>
        {props.publishedAt && (
          <div className="text-body-regular text-white">
            Live since {format(new Date(props.publishedAt), 'dd MMM yyyy')}
          </div>
        )}
      </div>
    </div>
  );
};
