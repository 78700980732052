import { Accordion, AccordionContent, Toggle } from '@highmobility/console-ui-components';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { LockedTextField } from '@/components/atoms/LockedTextField';
import { NoneSelected } from '@/components/atoms/NoneSelected';
import { Project, ProjectType, Region } from '@/types';
import { PROJECT_REGION, PROJECT_TYPE } from '@/constants';
import { useMobx } from '@/hooks/useMobx';

import { BillingAccordion } from './BillingAccordion';
import { SelectedDataAccordion } from './SelectedDataAccordion';

type ProjectDetails = {
  project: Project;
};

export const ProjectDetails = observer((props: ProjectDetails) => {
  const { billing, ui } = useMobx();
  const [accordionStates, setAccordionStates] = useState<Record<'product', boolean>>({
    product: true,
  });
  const typeOptions: { label: string; value: ProjectType }[] = [
    { label: PROJECT_TYPE.fleet, value: 'fleet' },
    { label: PROJECT_TYPE.non_fleet, value: 'non_fleet' },
  ];

  const regionOptions: { label: string; value: Region }[] = [
    { label: PROJECT_REGION.europe, value: 'europe' },
    { label: PROJECT_REGION.north_america, value: 'north_america' },
  ];

  return (
    <div className="z-40 w-[320px] flex-shrink-0 overflow-y-auto border-l border-black-20 bg-dark-green-900 pb-12">
      <div className="border-b border-black-20 bg-white-12 px-4 py-2">
        <h5 className="text-heading-5">Project Details</h5>
      </div>

      <Accordion locked title="Consent" isOpen>
        <AccordionContent>
          <div className="flex items-center justify-between">
            <div>Consent type</div>
            <Toggle disabled options={typeOptions} value={props.project.type} />
          </div>
        </AccordionContent>
      </Accordion>
      <Accordion locked title="Market" isOpen>
        <AccordionContent>
          <div className="flex items-center justify-between">
            <div>Availability in</div>
            <Toggle disabled options={regionOptions} value={props.project.region} />
          </div>
        </AccordionContent>
      </Accordion>
      <SelectedDataAccordion
        teamId={props.project.team_id}
        projectId={props.project.id}
        orders={props.project.orders || []}
      />
      <BillingAccordion
        billingInfo={billing.companyProfiles[props.project.team_id]}
        billingMethod={props.project.billing_method}
        showError={ui.projectTemplate.projectSubmitted}
      />
      <Accordion
        title="Product info"
        isOpen={!!accordionStates.product}
        setIsOpen={(open) => setAccordionStates({ ...accordionStates, product: open })}
      >
        <AccordionContent>
          <LockedTextField label="Project name" value={props.project.name} />
          {props.project.product_info.website ? (
            <LockedTextField label="Product website" value={props.project.product_info.website} />
          ) : (
            <NoneSelected label="Product website" />
          )}
          {props.project.product_info.terms_of_service ? (
            <LockedTextField
              label="Terms of Service"
              value={props.project.product_info.terms_of_service}
            />
          ) : (
            <NoneSelected label="Terms of Service" />
          )}
          {props.project.product_info.privacy_policy ? (
            <LockedTextField
              label="Privacy Policy"
              value={props.project.product_info.privacy_policy}
            />
          ) : (
            <NoneSelected label="Privacy Policy" />
          )}
        </AccordionContent>
      </Accordion>
    </div>
  );
});
