import { capitalize } from 'radash';
import { cx } from 'class-variance-authority';
import {
  EmptyProject,
  NameTag,
  ProjectStatusIcon,
  Tag,
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@highmobility/console-ui-components';
import { generatePath, Link } from 'react-router-dom';
import { useMemo, useState } from 'react';

import { getBrandData } from '@/services/packagesService';
import { getImageUrl } from '@/services/projectService';
import { Project } from '@/types';
import { PROJECT_REGION, PROJECT_STATUS, PROJECT_TYPE } from '@/constants';
import { ProjectCardOptionsDropdown } from '@/components/views/dashboard/ProjectCardOptionsDropdown';
import { ROUTE_PATHS } from '@/router/routes';

type ProjectCardProps = {
  project: Project;
};

export const ProjectCard = (props: ProjectCardProps) => {
  const tooltipTitle = PROJECT_STATUS[props.project.status];
  const tooltipText = useMemo(() => {
    switch (props.project.status) {
      case 'draft':
        return 'Application has been created and data packages can be selected.';
      case 'in_review':
        return 'Our experts will review application submission and validate data selection.';
      case 'published':
      case 'published_with_changes':
        return 'Ready for data updates from connected cars.';
    }

    /* istanbul ignore next */
    props.project.status satisfies never;
  }, [props.project.status]);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const showDefaultImage = (props.project.brands?.length || 0) <= 0 || !props.project.image;

  return (
    <div className="flex flex-col gap-4">
      <div className="overflow-hidden rounded-[4px] border border-white-12">
        <Link
          to={generatePath(ROUTE_PATHS.PROJECT, {
            teamId: props.project.team_id,
            projectId: props.project.id,
          })}
        >
          <div
            className={cx('relative', {
              'border-b border-white-12': showDefaultImage,
            })}
          >
            <div className="relative flex max-h-[230px] min-h-[230px] items-center justify-center overflow-hidden transition-opacity hover:opacity-80">
              {showDefaultImage ? (
                <EmptyProject />
              ) : (
                <img
                  className="max-w-[inherit] bg-gray-400 object-cover"
                  src={getImageUrl(props.project.image)}
                />
              )}
            </div>
            <div className="absolute left-3 right-0 top-3 flex flex-wrap items-center gap-1">
              {props.project.brands?.map((brand, i) => (
                <NameTag key={i} className="pointer-events-none" active>
                  {getBrandData(brand)?.name || capitalize(brand)}
                </NameTag>
              ))}
            </div>
          </div>
        </Link>
        <div className="flex h-14">
          <TooltipProvider open={tooltipOpen} setOpen={setTooltipOpen} useHover offset={0}>
            <TooltipTrigger>
              <div className="flex w-14 items-center justify-center p-4">
                <ProjectStatusIcon status={props.project.status} size="large" />
              </div>
            </TooltipTrigger>
            <Tooltip>
              <div className="flex w-60 flex-col gap-1 text-label-regular">
                <b>{tooltipTitle}</b>
                <p>{tooltipText}</p>
              </div>
            </Tooltip>
          </TooltipProvider>
          <div className="flex-1 border-x border-white-12" />
          <div className="flex items-center justify-center">
            <ProjectCardOptionsDropdown
              projectId={props.project.id}
              projectName={props.project.name}
              teamId={props.project.team_id}
            />
          </div>
        </div>
      </div>
      <h5 className="text-heading-5 text-beige-100">{props.project.name}</h5>
      <div className="flex items-center gap-1">
        <Tag type="light" round>
          {PROJECT_TYPE[props.project.type]}
        </Tag>
        <Tag type="light" round>
          {PROJECT_REGION[props.project.region]}
        </Tag>
      </div>
    </div>
  );
};
