import { ActionButton, Arrow } from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { generatePath, Link, LoaderFunction, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { CreateProjectCard } from '@/components/views/create-project/CreateProjectCard';
import { DashboardHeader } from '@/components/organisms/DashboardHeader';
import { DashboardSecondaryHeader } from '@/components/organisms/DashboardSecondaryHeader';
import { GeneralTemplate } from '@/components/templates/general/GeneralTemplate';
import { mobxStore } from '@/store';
import { NoPermissionNotification } from '@/components/molecules/NoPermissionNotification';
import { PathParams } from '@/hooks/useTypedParams';
import { PROJECT_REGION, PROJECT_TYPE } from '@/constants';
import { ProjectType, Region } from '@/types';
import { ROUTE_PATHS } from '@/router/routes';
import { TeamsSelect } from '@/components/molecules/TeamsSelect';
import { useMobx } from '@/hooks/useMobx';
import { usePermissions } from '@/hooks/usePermissions';

export const loader: LoaderFunction = async (args) => {
  const { teamId } = args.params as PathParams<typeof ROUTE_PATHS.CREATE_PROJECT>;

  const { teams } = mobxStore;
  await teams.fetchTeams();
  teams.setActiveTeam(teamId);

  return null;
};

export const CreateProjectView = observer(() => {
  const { teams } = useMobx();
  const { userHasPermission } = usePermissions(teams.activeTeam?.id);
  const navigate = useNavigate();

  return (
    <GeneralTemplate renderHeader={() => <DashboardHeader />}>
      <DashboardSecondaryHeader>
        <div className="flex w-full items-center justify-between gap-6">
          <Link
            to={generatePath(ROUTE_PATHS.DASHBOARD_ALL, { teamId: teams.activeTeam?.id || '' })}
          >
            <ActionButton>{(cn) => <Arrow className={cx('rotate-180', cn)} />}</ActionButton>
          </Link>
          <div className="flex items-center gap-1">
            <TeamsSelect
              onSelect={(teamId) => navigate(generatePath(ROUTE_PATHS.CREATE_PROJECT, { teamId }))}
            />
          </div>
        </div>
      </DashboardSecondaryHeader>
      <main className="pb-header h-full w-full">
        {!userHasPermission('projects.create') && <NoPermissionNotification className="mb-12" />}
        <div
          className="grid gap-2"
          style={{
            gridTemplateColumns: 'repeat(auto-fill, minmax(min(260px, 100%), 1fr))',
          }}
        >
          {Object.keys(PROJECT_REGION).map((region, index) =>
            Object.keys(PROJECT_TYPE).map((type, typeIndex) => (
              <CreateProjectCard
                teamId={teams.activeTeam?.id || ''}
                key={`${index}${typeIndex}`}
                region={region as Region}
                type={type as ProjectType}
                disabled={
                  !userHasPermission('projects.create') || region !== 'europe' || type !== 'fleet'
                }
              />
            ))
          )}
        </div>
      </main>
    </GeneralTemplate>
  );
});
