import { Button, Tag, TextInput, toast } from '@highmobility/console-ui-components';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import {
  fakeLoader,
  fetchWebhooksConfiguration,
  setupWebhooks,
  triggerWebhook,
  updateWebhooks,
} from '@/services/apiService';
import { NoPermissionNotification } from '@/components/molecules/NoPermissionNotification';
import { PathParams, useTypedParams } from '@/hooks/useTypedParams';
import { ProjectInstance, WebhooksConfiguration } from '@/types';
import { ROUTE_PATHS } from '@/router/routes';
import { setupWebhooksValidation } from '@/services/zodService';
import { usePermissions } from '@/hooks/usePermissions';
import { WEBHOOK_EVENTS } from '@/constants';

import { EventsTable } from './EventsTable';

export const loader = async (args: LoaderFunctionArgs) => {
  const params = args.params as PathParams<typeof ROUTE_PATHS.PROJECT_AUTH_WEBHOOKS>;
  const instance = params.instance as ProjectInstance;
  const webhooksConfig = await fetchWebhooksConfiguration(
    params.teamId,
    params.projectId,
    instance
  ).catch(() => null);

  return {
    webhooksConfig: webhooksConfig?.data ?? null,
  };
};

export const ProjectAuthWebhooksView = observer(() => {
  const loaderData = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const { teamId, projectId, ...params } =
    useTypedParams<typeof ROUTE_PATHS.PROJECT_AUTH_WEBHOOKS>();
  const instance = params.instance as ProjectInstance;
  const [webhooksConfig, setWebhooksConfig] = useState<WebhooksConfiguration | null>(
    loaderData.webhooksConfig
  );
  const { userHasPermission } = usePermissions(teamId);
  const hasViewPermission = userHasPermission(
    instance === 'sandbox'
      ? 'projects.view.credentials'
      : 'projects.view.production.credentials.secrets'
  );
  const hasManagePermission = userHasPermission(`projects.manage.${instance}.credentials`);

  const setupWebhooksMutation = useMutation({
    mutationFn: async (data: z.infer<typeof setupWebhooksValidation>) => {
      const [webhooksConfigResponse] = await Promise.all([
        webhooksConfig
          ? updateWebhooks(teamId, projectId, instance, webhooksConfig.id, data)
          : setupWebhooks(teamId, projectId, instance, data),
        fakeLoader(),
      ]);
      await triggerWebhook(teamId, projectId, instance);

      return webhooksConfigResponse.data;
    },
    onError: (e: any) => {
      console.error('Failed to update webhook settings', e);
      toast(e?.response?.data?.message || 'Something went wrong. Please try again.', 'error');
    },
    onSuccess: async (data) => {
      setWebhooksConfig(data);
      toast('Webhook settings updated.', 'success');
    },
  });

  const defaultValues = useMemo(
    () => ({
      callback_url: loaderData.webhooksConfig?.callback_url ?? '',
      secret: loaderData.webhooksConfig?.secret ?? '',
      events: (loaderData.webhooksConfig?.events ?? []).filter((event) => {
        return Object.values(WEBHOOK_EVENTS).includes(event); // remove "ping"
      }),
    }),
    [loaderData.webhooksConfig]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm<z.infer<typeof setupWebhooksValidation>>({
    resolver: zodResolver(setupWebhooksValidation),
    defaultValues,
  });

  useEffect(() => {
    setWebhooksConfig(loaderData.webhooksConfig);
    reset(defaultValues);
  }, [loaderData.webhooksConfig, reset, defaultValues]);

  const onSubmit = handleSubmit((data) => setupWebhooksMutation.mutate(data));
  const selectedEvents = watch('events');

  return (
    <div className="flex flex-col gap-6 xl:max-w-[800px]">
      {(!hasViewPermission || !hasManagePermission) && <NoPermissionNotification />}
      {hasViewPermission && (
        <form onSubmit={onSubmit} autoComplete="false">
          <div className="mb-8 flex flex-col gap-6">
            <TextInput
              label="Payload URL"
              placeholder="Payload URL"
              {...register('callback_url')}
              error={errors.callback_url?.message}
              disabled={!hasManagePermission}
            />
            <TextInput
              type="password"
              label="Secret key"
              placeholder="Secret key"
              {...register('secret')}
              error={errors.secret?.message}
              disabled={!hasManagePermission}
            />
            <div className="flex flex-col gap-6">
              <div className="flex items-center gap-3">
                <span className="text-body-small text-white-80">Events</span>
                <Tag type="dark" round>
                  <span className="text-success-500">Free</span>
                </Tag>
              </div>
              <EventsTable
                selectedEvents={selectedEvents}
                onChange={(events) => setValue('events', events)}
                disabled={!hasManagePermission}
              />
              <div className="flex justify-end">
                <Button disabled={setupWebhooksMutation.isPending || !hasManagePermission}>
                  Update
                </Button>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
});
