import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { AuthBackgroundIllustrations } from '@/components/atoms/AuthBackgroundIllustrations';
import { AuthHeader } from '@/components/atoms/AuthHeader';
import { AuthModal } from '@/components/atoms/AuthModal';
import { GeneralTemplate } from '@/components/templates/general/GeneralTemplate';
import { resendActivationToken } from '@/services/apiService';
import { useQueryParams } from '@/hooks/useQueryParams';

import { InitialStep } from './InitialStep';

export const SignUpView = () => {
  const [step, setStep] = useState<'initial' | 'success' | 'emailResent' | 'error'>('initial');
  const [email, setEmail] = useState('');
  const queryParams = useQueryParams();

  const resendEmailMutation = useMutation({
    mutationFn: (email: string) => {
      return resendActivationToken({
        email: email,
      });
    },
    onError: (e) => {
      console.error('Failed to resend email', e);
      setStep('error');
    },
    onSuccess: () => {
      setStep('emailResent');
    },
  });

  const onResendEmail = () => resendEmailMutation.mutate(email);

  return (
    <GeneralTemplate
      className="bg-beige-100"
      renderHeader={() => <AuthHeader type="signup" />}
      renderIllustrations={() => <AuthBackgroundIllustrations />}
    >
      <main className="pb-header flex h-full w-full items-center justify-around gap-6">
        {step === 'initial' && (
          <InitialStep
            onSuccess={() => setStep('success')}
            onError={() => setStep('error')}
            setEmail={(email) => setEmail(email)}
            defaultValues={{
              email: queryParams.email,
            }}
            invitationToken={queryParams.invitation_token}
          />
        )}
        {step === 'success' && (
          <AuthModal title="Yay, sign up complete!">
            <>
              <p className="text-body-regular font-bold">
                We&apos;ve sent an email to {email} with a link to activate your account.
              </p>
              <p className="text-body-regular font-bold">
                Didn&apos;t receive an email?{' '}
                <button className="underline" onClick={onResendEmail}>
                  Resend the email.
                </button>
              </p>
            </>
          </AuthModal>
        )}
        {step === 'emailResent' && (
          <AuthModal title="Check your inbox!">
            <p className="text-body-regular font-bold">
              We have sent you the confirmation link again, check your inbox. If you still
              haven&apos;t received any email please contact support@high-mobility.com
            </p>
          </AuthModal>
        )}
        {step === 'error' && (
          <AuthModal title="Something went wrong!">
            <p className="text-body-regular font-bold">
              Please try again or contact support@high-mobility.com.
            </p>
          </AuthModal>
        )}
      </main>
    </GeneralTemplate>
  );
};
