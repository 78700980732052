import {
  Button,
  SelectInput,
  SelectInputProps,
  TextInput,
  toast,
} from '@highmobility/console-ui-components';
import { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { CompanyProfile } from '@/types';
import { fakeLoader, updateCompanyProfile } from '@/services/apiService';
import { ISO_ALPHA2_EUROPE_COUNTRIES } from '@/constants';
import { updateCompanyProfileValidation } from '@/services/zodService';
import { useMobx } from '@/hooks/useMobx';

type CompanyProfileSectionProps = {
  teamId: string;
  disabled?: boolean;
  companyProfile: CompanyProfile | undefined;
};

export const CompanyProfileSection: FC<CompanyProfileSectionProps> = (
  props: CompanyProfileSectionProps
) => {
  const { billing } = useMobx();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<z.infer<typeof updateCompanyProfileValidation>>({
    resolver: (...args) => {
      const newValues = {
        ...args[0],
        contact: {
          ...args[0].contact,
          billing: [
            args[0].contact.billing[0],
            ...args[0].contact.billing.slice(1).filter((contact) => contact.name || contact.email),
          ],
        },
      };
      args[0] = newValues;

      return zodResolver(updateCompanyProfileValidation)(...args);
    },
    defaultValues: {
      entity_name: props.companyProfile?.entity_name || '',
      address: {
        street: props.companyProfile?.address.street || '',
        city: props.companyProfile?.address.city || '',
        zip_code: props.companyProfile?.address.zip_code || '',
        country: props.companyProfile?.address.country || '',
      },
      contact: props.companyProfile?.contact || {},
      vat_number: props.companyProfile?.vat_number || '',
    },
  });

  const updateCompanyProfileMutation = useMutation({
    mutationFn: async (data: z.infer<typeof updateCompanyProfileValidation>) => {
      return Promise.all([fakeLoader(), updateCompanyProfile(props.teamId, data)]);
    },
    onError: (e: any) => {
      console.error('Failed to update company profile', e);
      toast(e?.response?.data?.message || 'Something went wrong. Please try again.', 'error');
    },
    onSuccess: async (data) => {
      billing.setCompanyProfile(props.teamId, data?.[1]?.data);
      toast('Company profile saved!', 'success');
    },
  });

  const onSubmit = handleSubmit((data) => updateCompanyProfileMutation.mutate(data));
  const countries: SelectInputProps<string>['items'] = useMemo(() => {
    return Object.entries(ISO_ALPHA2_EUROPE_COUNTRIES).map(([key, value]) => ({
      label: value,
      value: key,
    }));
  }, []);

  return (
    <div>
      <h2 className="text-heading-2 mb-6">Company profile</h2>

      <form onSubmit={onSubmit} className="flex flex-col gap-6">
        <TextInput
          label="Company legal name*"
          labelAdditionalInfo="(Required for billing purposes. This will not be shared with users of your car data service.)"
          placeholder="Company legal name"
          {...register('entity_name')}
          error={errors.entity_name?.message}
          disabled={props.disabled}
        />

        <div className="bg-white-4 h-[1px] w-full" />

        <TextInput
          label="Address*"
          placeholder="Street"
          {...register('address.street')}
          error={errors.address?.street?.message}
          disabled={props.disabled}
        />
        <div className="grid gap-4 lg:grid-cols-2">
          <TextInput
            className="w-full"
            label="City*"
            placeholder="City"
            {...register('address.city')}
            error={errors.address?.city?.message}
            disabled={props.disabled}
          />
          <TextInput
            className="w-full"
            label="Postal code*"
            placeholder="Postal code"
            {...register('address.zip_code')}
            error={errors.address?.zip_code?.message}
            disabled={props.disabled}
          />
        </div>

        <div className="grid gap-4 lg:grid-cols-2">
          <SelectInput
            name="address.country"
            label="Country*"
            placeholder="Country"
            error={errors.address?.country?.message}
            items={countries}
            value={watch('address.country')}
            onChange={(value) => {
              setValue('address.country', value);
            }}
            disabled={props.disabled}
          />
          <TextInput
            label="VAT number*"
            placeholder="VAT number"
            {...register('vat_number')}
            error={errors.vat_number?.message}
            disabled={props.disabled}
          />
        </div>

        <div className="font-bold">Technical contact*</div>
        <div className="grid gap-4 md:grid-cols-2">
          <TextInput
            placeholder="e.g Name Surname"
            {...register('contact.technical.name')}
            error={errors.contact?.technical?.name?.message}
            disabled={props.disabled}
          />
          <TextInput
            placeholder="e.g name@company.com"
            type="email"
            {...register('contact.technical.email')}
            error={errors.contact?.technical?.email?.message}
            disabled={props.disabled}
          />
        </div>

        <div>
          <span className="font-bold">Billing contact for invoice notifications*</span>{' '}
          <span className="text-white-40">(One contact required)</span>
        </div>
        <div className="flex flex-col gap-2">
          {Array.from({ length: 5 }).map((_, i) => (
            <div key={i} className="grid gap-4 md:grid-cols-2">
              <TextInput
                placeholder="e.g Name Surname"
                {...register(`contact.billing.${i}.name`)}
                error={errors.contact?.billing?.[i]?.name?.message}
                disabled={props.disabled}
              />
              <TextInput
                placeholder="e.g name@company.com"
                type="email"
                {...register(`contact.billing.${i}.email`)}
                error={errors.contact?.billing?.[i]?.email?.message}
                disabled={props.disabled}
              />
            </div>
          ))}
        </div>

        <div className="flex items-center justify-end">
          <Button
            intent="primary"
            onClick={onSubmit}
            disabled={props.disabled || updateCompanyProfileMutation.isPending}
          >
            {updateCompanyProfileMutation.isPending ? 'Loading' : 'Save'}
          </Button>
        </div>
      </form>
    </div>
  );
};
