import { Permission, Roles } from '@highmobility/console-role-matrix';

import { useMobx } from '@/hooks/useMobx';

export const usePermissions = (teamId: string | undefined) => {
  const { teams, session } = useMobx();

  const userHasPermission = (permission: Permission) => {
    const team = teams.byId[teamId ?? ''];
    const userRole = team?.members?.find((member) => member.id === session.user?.id)?.role;
    if (!userRole) return false;
    return Roles.has(userRole, permission);
  };

  return { userHasPermission };
};
