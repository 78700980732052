import { cva, cx } from 'class-variance-authority';
import { useClickAway } from 'react-use';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Check, Chevron } from '../../assets';
import { DropdownMenuItem } from '../atoms/DropdownMenuItem';
import { DropdownMenuSeparator } from '../atoms/DropdownMenuSeparator';
import { useNativeSelectSearch } from '../../hooks/useNativeSelectSearch';

import { Dropdown } from './Dropdown';
import { DropdownMenu } from './DropdownMenu';

const selectInput = cva(
  [
    'inline-flex',
    'items-center',
    'w-full',
    'text-body-regular',
    'group-hover:text-white-60',
    'placeholder-white-40',
    'placeholder-body-regular',
    'rounded',
    'border',
    'blur:border-none',
    'transition-colors',
    'outline-none',
    'p-[10px]',
    'bg-black-40',
    'group-hover:bg-black-60',
    'border',
  ],
  {
    variants: {
      hasValue: {
        true: 'text-white',
        false: 'text-white-40',
      },
      error: {
        true: 'border-error-600',
      },
      disabled: {
        true: 'border-black-20 bg-black-20 cursor-not-allowed',
        false: 'cursor-pointer',
      },
      focused: {
        true: 'border-white-80 bg-black-60 text-white',
        false: 'border-none',
      },
    },
  }
);

export type SelectInputProps<TValue = unknown> = {
  disabled?: boolean;
  items: {
    label: string;
    value: TValue;
    onClick?: () => void;
    thickSeparator?: boolean;
  }[];
  name: string;
  value?: TValue;
  placeholder?: string;
  className?: string;
  onChange?: (value: TValue) => void;
  label?: string;
  labelAdditionalInfo?: string;
  error?: string;
};

export function SelectInput<TValue extends string | number | null>(
  props: SelectInputProps<TValue>
) {
  const ref = useRef(null);
  const optionsRef = useRef<HTMLDivElement[]>([]);
  const activeItem = useMemo(() => {
    if (!props.value) {
      return null;
    }

    return props.items.find((i) => i.value === props.value);
  }, [props.value, props.items]);
  const [open, setOpen] = useState(false);
  const [focused, setFocused] = useState(false);

  useClickAway(
    ref,
    (e) => {
      if (focused && e.currentTarget !== ref.current) {
        setFocused(false);
      }
    },
    ['click']
  );

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && focused) {
        setOpen((prev) => !prev);
      }
    };

    if (open && activeItem) {
      const activeItemIndex = props.items.findIndex((i) => i.value === activeItem.value);
      setTimeout(() => {
        optionsRef.current?.[activeItemIndex]?.scrollIntoView?.({
          block: 'nearest',
          inline: 'center',
        });
      }, 10); // Wait for floating-ui positioning
    }

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [focused, open, props.items, props.value, activeItem]);

  useNativeSelectSearch(props.items, optionsRef, setOpen, focused, props.onChange, props.value);

  return (
    <Dropdown
      options={{
        open,
        onOpenChange: setOpen,
      }}
      offset={{
        crossAxis: 0,
        mainAxis: 8,
      }}
      disabled={props.disabled}
      renderButton={(open) => (
        <div className="group">
          {props.label && (
            <label className="text-body-regular flex items-center gap-1 pb-2" htmlFor={props.name}>
              <span className="font-bold text-white">{props.label}</span>
              {props.labelAdditionalInfo && (
                <span className="text-white-40 font-normal"> {props.labelAdditionalInfo}</span>
              )}
            </label>
          )}
          <div
            ref={ref}
            tabIndex={0}
            onFocus={() => setFocused(true)}
            onBlur={() => !open && setFocused(false)}
            className={cx(
              selectInput({
                disabled: !!props.disabled,
                error: !!props.error,
                hasValue: !!props.value,
                focused,
              }),
              props.className
            )}
          >
            <input
              type="text"
              name={props.name}
              defaultValue={props.value ?? ''}
              className="hidden"
            />
            <div>{activeItem?.label || props.placeholder || ''}</div>
            <Chevron
              className={cx('ml-auto text-white transition-transform', {
                'rotate-180': open,
              })}
            />
          </div>
        </div>
      )}
    >
      <DropdownMenu>
        {props.items.map((item, index) => (
          <div
            key={index}
            ref={(node) => {
              if (!node) {
                return;
              }

              optionsRef.current[index] = node;
            }}
          >
            <DropdownMenuItem
              active={item.value && activeItem?.value === item.value}
              label={item.label}
              onClick={() => {
                item.onClick && item.onClick();
                props.onChange && props.onChange(item.value);
                setOpen(false);
              }}
              renderRightIcon={() =>
                item.value && activeItem?.value === item.value ? <Check /> : null
              }
            />
            {index < props.items.length - 1 && (
              <DropdownMenuSeparator thick={item.thickSeparator} />
            )}
          </div>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
