import { useParams } from 'react-router-dom';

import { ROUTE_PATHS } from '@/router/routes';

type ExtractRouteParams<T> = string extends T
  ? Record<string, string>
  : T extends `${infer _}:${infer Param}/${infer Rest}` // eslint-disable-line
  ? { [k in Param | keyof ExtractRouteParams<Rest>]: string }
  : T extends `${infer _}:${infer Param}?` // eslint-disable-line
  ? { [k in Param]?: string }
  : T extends `${infer _}:${infer Param}` // eslint-disable-line
  ? { [k in Param]: string }
  : {}; // eslint-disable-line

type Path = (typeof ROUTE_PATHS)[keyof typeof ROUTE_PATHS];

export type PathParams<P extends Path> = ExtractRouteParams<P>;

export const useTypedParams = <P extends Path>() => {
  const params = useParams();

  return params as unknown as PathParams<P>;
};
