import {
  AlertDialog,
  AlertDialogProps,
  TextInput,
  toast,
} from '@highmobility/console-ui-components';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { fakeLoader, updateProject } from '@/services/apiService';
import { renameProjectValidation } from '@/services/zodService';
import { useMobx } from '@/hooks/useMobx';

type RenameProjectDialogProps = Pick<AlertDialogProps, 'isOpen' | 'setIsOpen'> & {
  teamId: string;
  projectId: string;
  projectName: string;
};

export function RenameProjectDialog(props: RenameProjectDialogProps) {
  const { projects } = useMobx();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof renameProjectValidation>>({
    resolver: zodResolver(renameProjectValidation),
    defaultValues: {
      name: props.projectName,
    },
  });

  const renameProjectMutation = useMutation({
    mutationFn: async (data: z.infer<typeof renameProjectValidation>) => {
      return Promise.all([fakeLoader(), updateProject(props.teamId, props.projectId, data)]);
    },
    onError: (e) => {
      console.error('Failed to rename project', e);
      toast('Failed to rename project. Please try again.', 'error');
      props.setIsOpen(false);
    },
    onSuccess: async (responses) => {
      projects.setProject({
        ...projects.byId[props.projectId]!,
        name: responses[1].data.name,
      });
      toast('Project renamed successfully!', 'success');
      props.setIsOpen(false);
    },
  });

  const onSubmit = handleSubmit((data) => renameProjectMutation.mutate(data));

  return (
    <AlertDialog
      title="Name Your Project"
      isOpen={props.isOpen}
      setIsOpen={(value) => {
        props.setIsOpen(value);
      }}
      onCancel={() => {
        props.setIsOpen(false);
      }}
      onConfirm={onSubmit}
      cancelLabel="Cancel"
      confirmLabel={renameProjectMutation.isPending ? 'Loading' : 'Save'}
      confirmButtonDisabled={renameProjectMutation.isPending}
    >
      <form onSubmit={onSubmit} autoComplete="false">
        <div className="mb-8 flex flex-col gap-6">
          <TextInput
            placeholder="Name your project"
            {...register('name')}
            error={errors.name?.message}
          />
        </div>
      </form>
    </AlertDialog>
  );
}
