import { cx } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

export type TableRowProps = PropsWithChildren<{
  className?: string;
}>;

export const TableRow = (props: TableRowProps) => {
  return (
    <tr className={cx(props.className, 'bg-white-4 hover:bg-white-8 transition-colors')}>
      {props.children}
    </tr>
  );
};
