import { useMemo } from 'react';

import { MarketDataPackage, Order } from '@/types';

import { useMobx } from './useMobx';

export function useSplitPropertiesByStatus(orders: Order[], brand: string) {
  const { marketData } = useMobx();

  const {
    publishedPackages,
    inReviewPackages,
    draftPackages,
    deprecatedPackages,
    publishedProperties,
    inReviewProperties,
    draftProperties,
  } = useMemo(() => {
    const publishedPackages: MarketDataPackage[] = [];
    const inReviewPackages: MarketDataPackage[] = [];
    const draftPackages: MarketDataPackage[] = [];
    const deprecatedPackages: MarketDataPackage[] = [];

    const publishedProperties = new Set<string>();
    const inReviewProperties = new Set<string>();
    const draftProperties = new Set<string>(
      Object.values(marketData.selectedDraftProperties).flatMap((i) => {
        return Object.values(i!).flatMap((j) => j!);
      })
    );

    const publishedOrders: Order[] = [];
    orders.forEach((order) => {
      if (order.status === 'published') {
        publishedOrders.push(order);
      }

      Object.values(order.packages).forEach((pckg) => {
        if (order.status === 'published') {
          pckg!.properties.forEach((property) => publishedProperties.add(property));
        } else if (order.status === 'in_review') {
          pckg!.properties.forEach((property) => inReviewProperties.add(property));
        }
      });
    });

    marketData.byBrand[brand]?.forEach((pckg) => {
      if (pckg.deprecated) {
        deprecatedPackages.push(pckg);
        return;
      }

      const publishedOrderWithThisPackage = publishedOrders.find((o) => o.packages[pckg.id]);
      const publishedPrice = publishedOrderWithThisPackage?.packages[pckg.id]?.price;
      const price = publishedPrice
        ? {
            cents: publishedPrice.amount,
            currency: 'euro' as const,
          }
        : pckg.price;

      const publishedPackageProperties: MarketDataPackage['properties'] = [];
      const inReviewPackageProperties: MarketDataPackage['properties'] = [];
      const deprecatedPackageProperties: MarketDataPackage['properties'] = [];

      pckg.properties.forEach((property) => {
        if (property.deprecated) {
          deprecatedPackageProperties.push(property);
          return;
        }

        if (publishedProperties.has(property.id)) {
          publishedPackageProperties.push(property);
        }

        if (inReviewProperties.has(property.id)) {
          inReviewPackageProperties.push(property);
        }
      });

      if (publishedPackageProperties.length > 0) {
        publishedPackages.push({
          ...pckg,
          properties: publishedPackageProperties,
          price,
        });
      }

      if (inReviewPackageProperties.length > 0) {
        inReviewPackages.push({
          ...pckg,
          properties: inReviewPackageProperties,
          price,
        });
      }

      if (deprecatedPackageProperties.length > 0) {
        deprecatedPackages.push({
          ...pckg,
          properties: deprecatedPackageProperties,
          price,
        });
      }

      draftPackages.push({
        ...pckg,
        properties: pckg.properties,
        price,
      });
    });

    return {
      publishedPackages,
      inReviewPackages,
      draftPackages,
      deprecatedPackages,
      publishedProperties,
      inReviewProperties,
      draftProperties,
    };
  }, [brand, marketData.byBrand, orders, marketData.selectedDraftProperties]);

  return {
    publishedPackages,
    inReviewPackages,
    draftPackages,
    deprecatedPackages,
    publishedProperties,
    inReviewProperties,
    draftProperties,
  };
}
