import { createBrowserRouter } from 'react-router-dom';

import { authMiddleware } from '@/router/middleware/authMiddleware';
import { ErrorView } from '@/components/views/error/ErrorView';
import { PUBLIC_PATH } from '@/constants';

import { applyMiddleware } from './middleware';
import { routes } from './routes';

export const router = createBrowserRouter(
  [
    {
      errorElement: <ErrorView />,
      children: applyMiddleware(routes, [authMiddleware]),
    },
  ],
  {
    basename: PUBLIC_PATH.replace(/\/$/, ''), // remove trailing slash
  }
);
