import { AlertDialog, DialogProps, TextInput, toast } from '@highmobility/console-ui-components';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { fakeLoader, updatePasskey } from '@/services/apiService';
import { updatePasskeyValidation } from '@/services/zodService';
import { useMobx } from '@/hooks/useMobx';

export type UpdatePasskeyDialogProps = {
  isOpen: DialogProps['isOpen'];
  setIsOpen: DialogProps['setIsOpen'];
  id: string;
  name: string;
};

export const UpdatePasskeyDialog: FC<UpdatePasskeyDialogProps> = (props) => {
  const { session } = useMobx();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof updatePasskeyValidation>>({
    resolver: zodResolver(updatePasskeyValidation),
    defaultValues: { name: props.name },
  });

  const updatePasskeyMutation = useMutation({
    mutationFn: async (data: z.infer<typeof updatePasskeyValidation>) => {
      const [{ data: newPasskey }] = await Promise.all([
        updatePasskey(props.id, data.name),
        fakeLoader(),
      ]);
      session.setPasskeys(
        session.passkeys.map((passkey) => (passkey.id === props.id ? newPasskey : passkey))
      );
    },
    onError: (e: any) => {
      toast(e?.response?.data?.message || 'Something went wrong. Please try again.', 'error');
    },
    onSuccess: async () => {
      toast('Passkey renamed', 'success');
      props.setIsOpen(false);
    },
  });

  const onSubmit = handleSubmit((data) => updatePasskeyMutation.mutate(data));

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title="Edit your passkey name"
      confirmButtonIntent="primary"
      confirmLabel={updatePasskeyMutation.isPending ? 'Saving' : 'Save'}
      confirmButtonDisabled={updatePasskeyMutation.isPending}
      cancelLabel="Cancel"
      onConfirm={onSubmit}
      onCancel={() => props.setIsOpen(false)}
    >
      <form onSubmit={onSubmit}>
        <TextInput
          className="w-full"
          {...register('name')}
          placeholder="Passkey name"
          error={errors.name?.message}
        />
      </form>
    </AlertDialog>
  );
};
