import { Close, Toggle } from '@highmobility/console-ui-components';
import { generatePath, Link, LoaderFunction, Outlet, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { ContentLink } from '@/components/templates/project-auth/ContentLink';
import { PathParams, useTypedParams } from '@/hooks/useTypedParams';
import { ProjectInstance } from '@/types';
import { ProjectSecondaryMenu } from '@/components/templates/project-data/ProjectSecondaryMenu';
import { ROUTE_PATHS } from '@/router/routes';
import { useCurrentPath } from '@/hooks/useCurrentPath';

export const loader: LoaderFunction = async (args) => {
  const params = args.params as PathParams<typeof ROUTE_PATHS.PROJECT_AUTH>;
  const instance = params.instance as ProjectInstance;

  if (instance !== 'sandbox' && instance !== 'production') {
    throw new Response('Invalid environment', { status: 404 });
  }

  return {};
};

export const ProjectAuthTemplate = observer(() => {
  const { teamId, projectId, ...typedParams } = useTypedParams<typeof ROUTE_PATHS.PROJECT_AUTH>();
  const instance = typedParams.instance as ProjectInstance;
  const currentPath = useCurrentPath();
  const navigate = useNavigate();

  const toggleOptions: { label: string; value: ProjectInstance }[] = useMemo(
    () => [
      { label: 'Sandbox', value: 'sandbox' },
      { label: 'Live API', value: 'production' },
    ],
    []
  );

  const onChangeInstance = (value: ProjectInstance) => {
    navigate(generatePath(currentPath, { teamId, projectId, instance: value }));
  };

  return (
    <main className="flex h-full w-full">
      <ProjectSecondaryMenu>
        <div className="h-full overflow-y-auto bg-dark-green-900">
          <div className="border border-black-20 bg-white-4">
            <div className="flex items-center justify-between px-4 py-2">
              <h5 className="text-heading-5">API Certificates</h5>
              <Link to={generatePath(ROUTE_PATHS.PROJECT, { teamId, projectId })}>
                <Close className="h-4 w-4 cursor-pointer text-white-40 transition-colors hover:text-white" />
              </Link>
            </div>
            <div className="p-4">
              <Toggle
                fullWidth
                options={toggleOptions}
                value={instance as ProjectInstance}
                onChange={onChangeInstance}
              />
            </div>
          </div>
          <ContentLink
            title="OAuth Client"
            link={generatePath(ROUTE_PATHS.PROJECT_AUTH_OAUTH, {
              teamId,
              projectId,
              instance,
            })}
            active={currentPath.includes(ROUTE_PATHS.PROJECT_AUTH_OAUTH)}
          >
            Use the OAuth2 framework to request vehicle access to your app. Follow the tutorial.
          </ContentLink>
          <ContentLink
            title="Streaming"
            link={generatePath(ROUTE_PATHS.PROJECT_AUTH_STREAMING, {
              teamId,
              projectId,
              instance,
            })}
            active={currentPath.includes(ROUTE_PATHS.PROJECT_AUTH_STREAMING)}
          >
            Select a data delivery method by choosing MQTT, AWS S3 Bucket or Kinesis streaming.
          </ContentLink>
          <ContentLink
            title="Webhooks"
            link={generatePath(ROUTE_PATHS.PROJECT_AUTH_WEBHOOKS, {
              teamId,
              projectId,
              instance,
            })}
            active={currentPath.includes(ROUTE_PATHS.PROJECT_AUTH_WEBHOOKS)}
          >
            Webhooks allow your server to subscribe to vehicle events. When the selected events
            happen, we will deliver a POST request to the URL that you provide.
          </ContentLink>
        </div>
      </ProjectSecondaryMenu>
      <div className="flex h-full w-full">
        <Outlet />
      </div>
    </main>
  );
});
