import {
  DotMenu,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
} from '@highmobility/console-ui-components';
import { useState } from 'react';

import { Simulator } from '@/types';
import { usePermissions } from '@/hooks/usePermissions';

import { DeleteSimulatorDialog } from './DeleteSimulatorDialog';
import { RenameSimulatorDialog } from './RenameSimulatorDialog';

type SimulatorCardDropdownProps = {
  teamId: string;
  simulator: Simulator;
  simulatorLink: string;
  refetch: () => void;
};

export function SimulatorCardDropdown(props: SimulatorCardDropdownProps) {
  const [showRenameDialog, setShowRenameDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const { userHasPermission } = usePermissions(props.teamId);

  return (
    <>
      <Dropdown
        renderButton={() => (
          <div className="group flex h-14 w-14 cursor-pointer select-none items-center justify-center text-white-20">
            <DotMenu className="h-4 min-h-4 w-4 min-w-4 text-white transition-colors hover:text-white-80 group-hover:text-white-60" />
          </div>
        )}
      >
        <DropdownMenu>
          <a target="_blank" rel="noreferrer" href={props.simulatorLink}>
            <DropdownMenuItem label="Open" />
          </a>
          {userHasPermission('simulators.edit') && (
            <DropdownMenuItem label="Rename" onClick={() => setShowRenameDialog(true)} />
          )}
          {userHasPermission('simulators.manage') && (
            <DropdownMenuItem label="Delete" onClick={() => setShowDeleteDialog(true)} />
          )}
        </DropdownMenu>
      </Dropdown>
      {showRenameDialog && (
        <RenameSimulatorDialog
          isOpen={showRenameDialog}
          setIsOpen={setShowRenameDialog}
          teamId={props.teamId}
          simulator={props.simulator}
          onRename={() => props.refetch()}
        />
      )}
      {showDeleteDialog && (
        <DeleteSimulatorDialog
          isOpen={showDeleteDialog}
          setIsOpen={setShowDeleteDialog}
          simulatorVin={props.simulator.vin}
          teamId={props.teamId}
          onDelete={() => props.refetch()}
        />
      )}
    </>
  );
}
