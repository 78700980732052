import { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react';
import { cva, cx, VariantProps } from 'class-variance-authority';

const button = cva(
  'inline-flex items-center justify-center rounded gap-2 text-button font-semibold transition-colors outline-none',
  {
    variants: {
      intent: {
        primary:
          'dark:bg-beige-100 dark:hover:bg-beige-50 dark:focus:bg-beige-50 dark:text-dark-green-900',
        primarySignal:
          'dark:bg-error-600 dark:hover:bg-error-500 dark:focus:bg-error-500 dark:text-white',
        secondary:
          'dark:bg-white dark:bg-opacity-[0.04] dark:focus:bg-opacity-[0.08] dark:hover:bg-opacity-[0.08] dark:text-white',
        tertiary: 'dark:text-white dark:focus:text-beige-100-shade dark:hover:text-beige-100-shade',
      },
      paddingSize: {
        default: 'px-6 py-3',
        small: 'px-2 py-1',
      },
      disabled: {
        true: 'bg-opacity-40 dark:bg-opacity-40 dark:hover:bg-opacity-40 cursor-not-allowed dark:focus:bg-opacity-40',
      },
    },
    defaultVariants: {
      intent: 'primary',
      disabled: false,
    },
    compoundVariants: [
      {
        intent: 'primarySignal',
        disabled: true,
        className: 'dark:text-opacity-40',
      },
      {
        intent: 'secondary',
        disabled: true,
        className: 'dark:bg-opacity-[0.04] dark:text-opacity-40',
      },
    ],
  }
);

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof button> & {
    renderLeftIcon?: (className: string) => JSX.Element;
    renderRightIcon?: (className: string) => JSX.Element;
  };

export const Button: FC<PropsWithChildren<ButtonProps>> = ({
  className,
  children,
  intent,
  disabled = false,
  renderLeftIcon,
  renderRightIcon,
  ...props
}) => (
  <button
    className={cx(
      button({ intent, disabled, paddingSize: intent === 'tertiary' ? 'small' : 'default' }),
      className
    )}
    disabled={disabled}
    {...props}
  >
    {renderLeftIcon && renderLeftIcon('w-4 h-4')}
    {children && <span>{children}</span>}
    {renderRightIcon && renderRightIcon('w-4 h-4')}
  </button>
);
