import { cva, cx } from 'class-variance-authority';
import { FC, PropsWithChildren } from 'react';

export type NavLinkProps = PropsWithChildren<{
  className?: string;
  renderLeftIcon?: (className: string) => JSX.Element;
  renderRightIcon?: (className: string) => JSX.Element;
  active?: boolean;
}>;

const navLinkClasses = cva(
  'text-body-regular flex cursor-pointer select-none items-center gap-2 px-[10px] py-4 transition-colors hover:text-white',
  {
    variants: {
      active: {
        true: 'text-white font-bold',
        false: 'text-white-80',
      },
    },
  }
);

export const NavLink: FC<PropsWithChildren<NavLinkProps>> = ({
  className,
  children,
  renderLeftIcon,
  renderRightIcon,
  active = false,
}) => {
  return (
    <div className={cx(className, navLinkClasses({ active: !!active }))}>
      {renderLeftIcon && renderLeftIcon('w-4 h-4')}
      <div className="text-label-regular">{children}</div>
      {renderRightIcon && renderRightIcon('w-4 h-4')}
    </div>
  );
};
