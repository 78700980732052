import { makeAutoObservable, runInAction } from 'mobx';

import { Authenticator, User } from '@/types';
import { fetchCurrentUser, fetchPasskeys, login, logout } from '@/services/apiService';

export class Session {
  user: User | null = null;
  passkeys: Authenticator[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async fetchCurrentUser() {
    try {
      const { data } = await fetchCurrentUser();
      const user = data || null;

      runInAction(() => {
        this.user = user;
      });
      return user;
    } catch {
      runInAction(() => {
        this.user = null;
      });
      return null;
    }
  }

  async login(data: { email: string; password: string }) {
    try {
      await login(data);
      await this.fetchCurrentUser();
      return true;
    } catch {
      return false;
    }
  }

  async logout() {
    try {
      this.user = null;
      await logout();
      return true;
    } catch {
      return false;
    }
  }

  async fetchPasskeys() {
    const { data } = await fetchPasskeys();
    this.passkeys = data;
  }

  setPasskeys(passkeys: Authenticator[]) {
    this.passkeys = passkeys;
  }
}
