import { AlertDialog, AlertDialogProps, toast } from '@highmobility/console-ui-components';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { deleteTeam, fakeLoader } from '@/services/apiService';
import { ROUTE_PATHS } from '@/router/routes';

type DeleteTeamDialogProps = { teamId: string; teamName: string } & Pick<
  AlertDialogProps,
  'isOpen' | 'setIsOpen'
>;

export const DeleteTeamDialog = (props: DeleteTeamDialogProps) => {
  const navigate = useNavigate();
  const deleteTeamMutation = useMutation({
    mutationFn: async () => Promise.all([fakeLoader(), deleteTeam(props.teamId)]),
    onError: () => {
      props.setIsOpen(false);
      toast('Failed to delete team. Please try again.', 'error');
    },
    onSuccess: () => {
      props.setIsOpen(false);
      toast('Team deleted successfully', 'success');
      navigate(ROUTE_PATHS.DASHBOARD_ALL);
    },
  });

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title="Delete Team"
      confirmButtonIntent="primarySignal"
      confirmLabel={deleteTeamMutation.isPending ? 'Deleting...' : 'Yes, Delete'}
      confirmButtonDisabled={deleteTeamMutation.isPending}
      cancelLabel="Cancel"
      onConfirm={() => deleteTeamMutation.mutate()}
      onCancel={() => props.setIsOpen(false)}
    >
      <div className="flex flex-col">
        <h5 className="text-heading-5 pb-3">Please note: team deletion cannot be undone.</h5>
        <p className="pb-6">
          Deleting {props.teamName} means all members lose access to team-specific resources and
          associated data. Are you sure you want to proceed with the team deletion?
        </p>
      </div>
    </AlertDialog>
  );
};
