import { Button } from '@highmobility/console-ui-components';
import { isRouteErrorResponse, Link, useRouteError } from 'react-router-dom';
import { useMemo } from 'react';

import { GeneralTemplate } from '@/components/templates/general/GeneralTemplate';

export const ErrorView = () => {
  const error = useRouteError();

  const [code, message] = useMemo(() => {
    if (isRouteErrorResponse(error) && error.status === 404) {
      return [404, "This page doesn't exist!"];
    }

    return [500, 'Something went wrong'];
  }, [error]);

  return (
    <GeneralTemplate>
      <main className="pb-header flex h-full w-full flex-col items-center justify-center">
        <h1 className="text-heading-1 pb-2 text-7xl">{code}</h1>
        <p className="pb-6">{message}</p>
        <Link to="/">
          <Button>Go back</Button>
        </Link>
      </main>
    </GeneralTemplate>
  );
};
