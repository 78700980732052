import { capitalize } from 'radash';
import {
  Check,
  Close,
  Dialog,
  DialogProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@highmobility/console-ui-components';
import { Role, Roles } from '@highmobility/console-role-matrix';

type UserRolesInfoDialogProps = Pick<DialogProps, 'isOpen' | 'setIsOpen'>;

export const UserRolesInfoDialog = (props: UserRolesInfoDialogProps) => {
  return (
    <Dialog isOpen={props.isOpen} setIsOpen={props.setIsOpen} title="User roles" flexWidth>
      <p className="text-body-regular font-bold">
        Each role defines a different set of permissions
      </p>
      <Table className="w-[800px] max-w-full">
        <TableHeader>
          <TableRow>
            <TableHead>Permissions</TableHead>
            {Object.values(Role).map((role) => (
              <TableHead key={role}>{capitalize(role)}</TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.values(Roles.permissions).map((permission, index) => (
            <TableRow key={index}>
              <TableCell>{permission.description}</TableCell>
              {Object.values(Role).map((role) => (
                <TableCell key={role}>
                  {permission.roles.includes(role) ? (
                    <Check className="min-w-4 min-h-4 text-success-500 h-4 w-4" />
                  ) : (
                    <Close className="min-w-4 min-h-4 text-error-600 h-4 w-4" />
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Dialog>
  );
};
