import { makeAutoObservable, runInAction } from 'mobx';

import { fetchMarketData, saveProjectDraft } from '@/services/apiService';
import { MarketDataPackage, Order, ProjectType, Region, SelectedProperties } from '@/types';
import { Projects } from '@/store/Projects';

export class MarketData {
  data: MarketDataPackage[] = [];
  selectedDraftProperties: SelectedProperties = {};
  projectsStore: Projects;

  constructor({ projectsStore }: { projectsStore: Projects }) {
    this.projectsStore = projectsStore;
    makeAutoObservable(this);
  }

  get byBrand() {
    const result: Record<string, MarketDataPackage[] | undefined> = {};

    this.data.forEach((item) => {
      item.brands.forEach((brand) => {
        if (!result[brand]) {
          result[brand] = [];
        }

        result[brand]!.push(item);
      });
    });

    return result;
  }

  setSelectedDraftProperties(properties: SelectedProperties) {
    this.selectedDraftProperties = properties;
  }

  setSelectedPropertiesFromOrders(orders: Order[]) {
    let newData: SelectedProperties = {};

    orders.forEach((order) => {
      if (order.status !== 'draft') {
        return;
      }

      const { brand, packages } = order;

      Object.entries(packages).forEach(([packageId, pckg]) => {
        const uniqueProperties = new Set(newData[brand]?.[packageId] || []);
        pckg!.properties.forEach((property) => uniqueProperties.add(property));
        newData = {
          ...newData,
          [brand]: {
            ...newData[brand],
            [packageId]: Array.from(uniqueProperties),
          },
        };
      });
    });

    this.setSelectedDraftProperties(newData);
  }

  async fetchMarketData(region: Region, type: ProjectType) {
    try {
      const { data } = await fetchMarketData(region, type);
      runInAction(() => {
        this.data = data;
      });

      return data;
    } catch (e) {
      console.error('Failed to fetch market data', e);
      return null;
    }
  }

  async selectProperties(teamId: string, projectId: string, propertyIds: string[]) {
    try {
      const newData = this.data.reduce((acc, dataPackage) => {
        if (dataPackage.properties.some((property) => propertyIds.includes(property.id))) {
          let result = acc;

          dataPackage.brands.forEach((brand) => {
            const packagePropertyIds = new Set(
              this.selectedDraftProperties[brand]?.[dataPackage.id] || []
            );

            dataPackage.properties.forEach((property) => {
              if (propertyIds.includes(property.id)) {
                packagePropertyIds.add(property.id);
              }
            });

            result = {
              ...result,
              [brand]: {
                ...result[brand],
                [dataPackage.id]: Array.from(packagePropertyIds),
              },
            };
          });

          return result;
        }

        return acc;
      }, this.selectedDraftProperties);

      const { data: project } = await saveProjectDraft(teamId, projectId, newData);

      this.setSelectedDraftProperties(newData);
      this.projectsStore.setProject(project);
    } catch (e) {
      console.error('Failed to select properties', {
        teamId,
        projectId,
        propertyIds,
        e,
      });

      throw e;
    }
  }

  async removeProperties(teamId: string, projectId: string, propertyIds: string[]) {
    try {
      const newData = this.selectedDraftProperties;

      Object.entries(this.selectedDraftProperties).forEach(([brand, packages]) => {
        const propertiesInBrand = Object.values(packages!)
          .map((properties) =>
            properties?.filter((propertyId) => !propertyIds.includes(propertyId))
          )
          .flat()
          .filter(Boolean);

        if (propertiesInBrand.length === 0) {
          delete newData[brand];
        }

        Object.entries(packages!).forEach(([packageId, properties]) => {
          const newProperties = properties!.filter(
            (propertyId) => !propertyIds.includes(propertyId)
          );

          if (newProperties.length) {
            newData[brand] = {
              ...newData[brand],
              [packageId]: newProperties,
            };
          } else {
            delete newData[brand]?.[packageId];
          }
        });
      });

      const { data: project } = await saveProjectDraft(teamId, projectId, newData);

      this.setSelectedDraftProperties(newData);
      this.projectsStore.setProject(project);
    } catch (e) {
      console.error('Failed to remove properties', {
        teamId,
        projectId,
        propertyIds,
        e,
      });

      throw e;
    }
  }
}
