import { cva, cx, VariantProps } from 'class-variance-authority';
import { useMemo, useState } from 'react';

import { Check, Chevron } from '../../assets';
import { DropdownMenuItem } from '../atoms/DropdownMenuItem';
import { DropdownMenuSeparator } from '../atoms/DropdownMenuSeparator';

import { Dropdown } from './Dropdown';
import { DropdownMenu } from './DropdownMenu';

const dropdownField = cva(
  'text-body-regular font-bold py-[14px] px-4 flex items-center gap-2 select-none rounded-[4px]',
  {
    variants: {
      disabled: {
        true: ['cursor-not-allowed text-beige-100-shade bg-white-4 border-white-4'],
        false: ['cursor-pointer text-beige-100 bg-white-4 border-white-12 hover:bg-white-8'],
      },
    },
  }
);

export type DropdownFieldProps<TValue> = {
  disabled?: boolean;
  items: {
    label: string;
    value?: TValue;
    onClick?: () => void;
    thickSeparator?: boolean;
  }[];
  name: string;
  value?: TValue;
  placeholder?: string;
  className?: string;
  onChange?: (value?: TValue) => void;
} & VariantProps<typeof dropdownField>;

export function DropdownField<TValue extends string | number | null>(
  props: DropdownFieldProps<TValue>
) {
  const activeItem = useMemo(() => {
    if (!props.value) {
      return null;
    }

    return props.items.find((i) => i.value === props.value);
  }, [props.value, props.items]);
  const [open, setOpen] = useState(false);

  return (
    <Dropdown
      options={{
        open,
        onOpenChange: setOpen,
      }}
      offset={{
        crossAxis: 0,
        mainAxis: 8,
      }}
      renderButton={(open) => (
        <div className={cx(dropdownField({ disabled: !!props.disabled }), props.className)}>
          <input
            type="text"
            name={props.name}
            defaultValue={props.value ?? ''}
            className="hidden"
          />
          <div>{activeItem?.label || props.placeholder || ''}</div>
          <Chevron
            className={cx('ml-auto transition-transform', {
              'rotate-180': open,
            })}
          />
        </div>
      )}
    >
      <DropdownMenu>
        {props.items.map((item, index) => (
          <div key={index}>
            <DropdownMenuItem
              active={item.value && activeItem?.value === item.value}
              label={item.label}
              onClick={() => {
                item.onClick && item.onClick();
                props.onChange && props.onChange(item.value);
                setOpen(false);
              }}
              renderRightIcon={() =>
                item.value && activeItem?.value === item.value ? <Check /> : null
              }
            />
            {index < props.items.length - 1 && (
              <DropdownMenuSeparator thick={item.thickSeparator} />
            )}
          </div>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
}
