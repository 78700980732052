import { cx } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

export type TableHeadProps = PropsWithChildren<{
  colSpan?: number;
  className?: string;
}>;

export const TableHead = (props: TableHeadProps) => {
  return (
    <th
      colSpan={props.colSpan}
      className={cx(
        props.className,
        'text-label-regular text-white-60 px-3 py-6 text-left font-normal first:rounded-l-[4px] last:rounded-r-[4px]'
      )}
    >
      {props.children}
    </th>
  );
};
