import { AlertDialog, AlertDialogProps, toast } from '@highmobility/console-ui-components';
import { FC } from 'react';
import { useMutation } from '@tanstack/react-query';

import { fakeLoader, generateMFARecoveryCodes } from '@/services/apiService';

export type MFAConnectSuccessDialogProps = {
  isOpen: AlertDialogProps['isOpen'];
  setIsOpen: AlertDialogProps['setIsOpen'];
  onGenerateRecoveryCodes: (codes: string[]) => void;
  code: string;
};

export const MFAConnectSuccessDialog: FC<MFAConnectSuccessDialogProps> = (props) => {
  const generateRecoveryCodesMutation = useMutation({
    mutationFn: async () => {
      await fakeLoader();
      return await generateMFARecoveryCodes({
        'X-Console-MFA-Token': `totp:${props.code}`,
      });
    },
    onError: (e: any) => {
      console.error('Error generating recovery codes', e);
      toast(e?.response?.data?.message || 'Something went wrong. Please try again.', 'error');
    },
    onSuccess: (response) => {
      props.onGenerateRecoveryCodes(response.data);
    },
  });

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title="Connect your app"
      confirmButtonIntent="primary"
      confirmLabel={
        generateRecoveryCodesMutation.isPending ? 'Generating' : 'Generate recovery codes'
      }
      confirmButtonDisabled={generateRecoveryCodesMutation.isPending}
      cancelLabel="Skip"
      onConfirm={() => generateRecoveryCodesMutation.mutate()}
      onCancel={() => props.setIsOpen(false)}
    >
      <div className="flex flex-col gap-6">
        <div>
          <h5 className="text-heading-5 pb-3">App authentication added successfully</h5>
          <p>
            Use your authentication app as a secure authentication method. You can disable it at any
            time, but we recommend you to keep it enabled to keep your account as secure as
            possible.
          </p>
        </div>
        <div>
          <h5 className="text-heading-5 pb-3">We also recommend to generate recovery codes</h5>
          <p>
            Generate a set of unique recovery codes to be able to authenticate with as well. Keep
            them as safe as any password.
          </p>
        </div>
        <p className="text-error-600 font-bold">
          Once generated, your recovery codes can only be viewed once. Please securely save them in
          a location you can easily access
        </p>
      </div>
    </AlertDialog>
  );
};
