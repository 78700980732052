import { AlertDialog, AlertDialogProps, toast } from '@highmobility/console-ui-components';
import { FC } from 'react';
import { useMutation } from '@tanstack/react-query';

import { deletePasskey, fakeLoader } from '@/services/apiService';
import { useMobx } from '@/hooks/useMobx';

type DeletePasskeyDialogProps = { id: string } & Pick<AlertDialogProps, 'isOpen' | 'setIsOpen'>;

export const DeletePasskeyDialog: FC<DeletePasskeyDialogProps> = (props) => {
  const { session } = useMobx();
  const deletePasskeyMutation = useMutation({
    mutationFn: async () => {
      await Promise.all([deletePasskey(props.id), fakeLoader()]);
      session.setPasskeys(session.passkeys.filter((passkey) => passkey.id !== props.id));
    },
    onError: (e: any) => {
      toast(e?.response?.data?.message || 'Something went wrong. Please try again.', 'error');
    },
    onSuccess: async () => {
      toast('Passkey removed', 'success');
      props.setIsOpen(false);
    },
  });

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title="Remove your Passkey"
      confirmLabel={deletePasskeyMutation.isPending ? 'Removing' : 'Remove'}
      confirmButtonDisabled={deletePasskeyMutation.isPending}
      cancelLabel="Cancel"
      onConfirm={() => deletePasskeyMutation.mutate()}
      onCancel={() => props.setIsOpen(false)}
    >
      You will no longer be able to sign in using a passkey and will have to use your previous
      sign-in method. You can create a new passkey at any time.
    </AlertDialog>
  );
};
