import { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { MarketDataPackage } from '@/types';
import { useMobx } from '@/hooks/useMobx';

import { CategoryHeader, CategoryHeaderProps } from './CategoryHeader';
import { CategoryPackage } from './CategoryPackage';

export type CategoryProps = {
  teamId: string;
  projectId: string;
  brand: string;
  header: string;
  status: CategoryHeaderProps['status'];
  packages: MarketDataPackage[];
  selectedProperties: string[];
};

export const Category: FC<CategoryProps> = observer((props: CategoryProps) => {
  const { marketData } = useMobx();

  const propertyCount = useMemo(() => {
    return props.packages.reduce((acc, pckg) => {
      if (props.status === 'draft') {
        return acc + (marketData.selectedDraftProperties[props.brand]?.[pckg.id]?.length || 0);
      }

      return acc + pckg.properties.length;
    }, 0);
  }, [props.packages, props.status, props.brand, marketData.selectedDraftProperties]);

  return (
    <div className="gap-4 py-4">
      <CategoryHeader status={props.status} propertyCount={propertyCount}>
        {props.header}
      </CategoryHeader>
      <div>
        {props.packages.map((dataPackage, i) => {
          return (
            <CategoryPackage
              key={i}
              package={dataPackage}
              selectedProperties={props.selectedProperties}
              teamId={props.teamId}
              projectId={props.projectId}
              brand={props.brand}
              status={props.status}
            />
          );
        })}
      </div>
    </div>
  );
});
