import { AlertDialogProps, toast } from '@highmobility/console-ui-components';
import { FC, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { fakeLoader, generateMFARecoveryCodes } from '@/services/apiService';
import { MFARecoveryCodesDialog } from '@/components/views/account-settings/MFARecoveryCodesDialog';
import { UpdateMFAVerifyDialog } from '@/components/views/account-settings/UpdateMFAVerifyDialog';

export type GenerateNewMFACodesDialogProps = {
  isOpen: AlertDialogProps['isOpen'];
  setIsOpen: AlertDialogProps['setIsOpen'];
};

export const GenerateNewMFACodesDialog: FC<GenerateNewMFACodesDialogProps> = (props) => {
  const [MFACodes, setMFACodes] = useState<string[] | null>(null);
  const [showMFARecoveryCodesDialog, setShowMFARecoveryCodesDialog] = useState(false);
  const generateRecoveryCodesMutation = useMutation({
    mutationFn: async (code: string) => {
      await fakeLoader();
      return await generateMFARecoveryCodes({
        'X-Console-MFA-Token': `totp:${code}`,
      });
    },
    onError: (e: any) => {
      console.error('Error generating recovery codes', e);
      toast(e?.response?.data?.message || 'Something went wrong. Please try again.', 'error');
    },
    onSuccess: (response) => {
      setMFACodes(response.data);
      setShowMFARecoveryCodesDialog(true);
      props.setIsOpen(false);
    },
  });

  return (
    <>
      <UpdateMFAVerifyDialog
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        onSuccess={(_, codeUsed) => {
          generateRecoveryCodesMutation.mutate(codeUsed);
        }}
      />
      {MFACodes && (
        <MFARecoveryCodesDialog
          isOpen={showMFARecoveryCodesDialog}
          setIsOpen={setShowMFARecoveryCodesDialog}
          onConfirm={() => setShowMFARecoveryCodesDialog(false)}
          codes={MFACodes}
          replacedExistingCodes
        />
      )}
    </>
  );
};
