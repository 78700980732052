import { capitalize } from 'radash';

import { Brand, BrandData, MarketDataPackage } from '@/types';
import { BRANDS } from '@/constants';

import { formatPrice } from './currencyService';

export function getPackagePriceOrDescription(
  price: MarketDataPackage['price'],
  priceDescription?: MarketDataPackage['price_description']
) {
  if (!price) {
    if (priceDescription) {
      return capitalize(priceDescription);
    }

    return null;
  }

  return formatPrice(price.cents);
}

export function isValidBrand(brand: string): brand is Brand {
  return brand in BRANDS;
}

export function getBrandData(brand: string): BrandData | null {
  if (!isValidBrand(brand)) {
    console.error('Unknown brand:', brand);
    return null;
  }

  return BRANDS[brand];
}
