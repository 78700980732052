import { AlertDialog, DialogProps, toast } from '@highmobility/console-ui-components';
import { FC } from 'react';
import { useMutation } from '@tanstack/react-query';

import { deleteSimulator, fakeLoader } from '@/services/apiService';

export type DeleteSimulatorDialogProps = {
  isOpen: DialogProps['isOpen'];
  setIsOpen: DialogProps['setIsOpen'];
  simulatorVin: string;
  teamId: string;
  onDelete: () => void;
};

export const DeleteSimulatorDialog: FC<DeleteSimulatorDialogProps> = (props) => {
  const deleteSimulatorMutation = useMutation({
    mutationFn: async () => {
      const [{ data }] = await Promise.all([
        deleteSimulator(props.teamId, props.simulatorVin),
        fakeLoader(),
      ]);

      return data;
    },
    onError: (e: any) => {
      console.error('DeleteSimulatorDialog::failed to delete simulator', e);
      toast(
        e?.message ?? e?.response?.data?.message ?? 'Something went wrong. Please try again.',
        'error'
      );
    },
    onSuccess: () => {
      toast('Simulator deleted successfully', 'success');
      props.onDelete();
      props.setIsOpen(false);
    },
  });

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      onCancel={() => props.setIsOpen(false)}
      onConfirm={() => deleteSimulatorMutation.mutate()}
      title="Delete simulator"
      confirmLabel={deleteSimulatorMutation.isPending ? 'Deleting' : 'Delete'}
      confirmButtonDisabled={deleteSimulatorMutation.isPending}
      confirmButtonIntent="primarySignal"
      cancelLabel="Cancel"
    >
      <div className="flex flex-col gap-2">
        <p className="font-bold">Are you sure you want to delete the simulator?</p>
        <p className="text-label-regular text-white-60">
          This action cannot be undone. You will not be able to recover this simulator.
        </p>
      </div>
    </AlertDialog>
  );
};
