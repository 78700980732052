import { ChangeEvent, ClipboardEvent, FC, KeyboardEvent, useEffect, useRef } from 'react';
import { cx } from 'class-variance-authority';

import { MFADigitInput } from '@/components/views/account-settings/MFADigitInput';

export type MFACodeProps = {
  onChange: (value: string) => void;
  error?: string;
  isRecoveryCode?: boolean;
};

export const MFACode: FC<MFACodeProps> = (props) => {
  const code = useRef('');
  const ref = useRef<HTMLDivElement>(null);
  const inputsRef = useRef([
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ]);

  const codeLength = props.isRecoveryCode ? 11 : 6;

  const onInputChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.value === '') {
      code.current = code.current.slice(0, -1);
      props.onChange(code.current);
      if (index > 0) {
        inputsRef.current[index - 1].current?.focus();
      }

      return;
    }

    if (!props.isRecoveryCode && !/[0-9]/.test(e.target.value.slice(-1))) {
      e.target.value = e.target.value.slice(0, -1);
      return;
    }

    e.target.value = e.target.value.slice(-1);
    const newCode =
      code.current.slice(0, index) + e.target.value.slice(-1) + code.current.slice(index + 1);
    code.current = newCode;
    props.onChange(newCode);
    if (index < inputsRef.current.length - 1) {
      inputsRef.current[index + 1].current?.focus();
    }
  };

  const onPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    if (pastedData.length !== codeLength) {
      return;
    }

    if (!props.isRecoveryCode && !/^\d+$/.test(pastedData)) {
      return;
    }

    for (let i = 0; i < inputsRef.current.length; i++) {
      const input = inputsRef.current[i].current;
      if (input) {
        input.value = pastedData[i];
      }
      code.current = pastedData;
      props.onChange(pastedData);
    }

    inputsRef.current[codeLength - 1].current?.focus();
  };

  useEffect(() => {
    inputsRef.current[0].current?.focus();
  }, [inputsRef]);

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      const currentValue = inputsRef.current[index].current!.value;
      if (currentValue === '') {
        inputsRef.current[Math.max(0, code.current.length - 1)].current?.focus();
        e.preventDefault();
      }
    }
  };

  return (
    <div ref={ref} className="flex flex-col gap-2">
      {!props.isRecoveryCode && <p className="text-white-60">Enter a {codeLength} digit code</p>}
      <div
        className={cx('flex w-full rounded border', {
          'border-error-500': props.error,
          'border-white-12': !props.error,
        })}
      >
        {Array.from({ length: codeLength }).map((_, index) => (
          <MFADigitInput
            key={index}
            inputRef={inputsRef.current[index]}
            onChange={(e) => onInputChange(e, index)}
            onPaste={onPaste}
            onKeyDown={(e) => onKeyDown(e, index)}
          />
        ))}
      </div>
      {props.error && <p className="text-error-500 font-bold">{props.error}</p>}
    </div>
  );
};
