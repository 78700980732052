import { ChangeEvent, ClipboardEvent, FC, KeyboardEvent, MutableRefObject } from 'react';

export type MFADigitInputProps = {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onPaste?: (e: ClipboardEvent<HTMLInputElement>) => void;
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
  inputRef: MutableRefObject<HTMLInputElement | null>;
};

export const MFADigitInput: FC<MFADigitInputProps> = (props) => {
  return (
    <div className="bg-black-40 border-white-12 flex h-14 flex-1 justify-center border border-x">
      <input
        ref={props.inputRef}
        className="text-heading-3 text-white-60 h-14 w-9 bg-transparent text-center"
        type="text"
        onChange={props.onChange}
        onPaste={props.onPaste}
        onKeyDown={props.onKeyDown}
      />
    </div>
  );
};
