import { cva, cx, VariantProps } from 'class-variance-authority';
import { FC } from 'react';

const nameTagStyles = cva(
  'inline-flex p-1 justify-center items-center rounded-[4px] gap-1 text-label-small font-normal text-dark-green-900 max-h-6 whitespace-nowrap',
  {
    variants: {
      disabled: {
        true: '',
        false: '',
      },
      active: {
        true: '',
      },
    },
    compoundVariants: [
      {
        disabled: true,
        className: 'bg-white-40 text-dark-green-900',
      },
      {
        disabled: false,
        active: false,
        className: 'bg-white-80',
      },
      {
        disabled: false,
        active: true,
        className: 'bg-dark-green-900 text-white',
      },
    ],
  }
);

export type NameTagProps = VariantProps<typeof nameTagStyles> & {
  className?: string;
  children: string;
  renderLeftIcon?: (className: string) => JSX.Element;
  renderRightIcon?: (className: string) => JSX.Element;
};

export const NameTag: FC<NameTagProps> = ({
  disabled = false,
  active = false,
  className,
  children,
  renderLeftIcon,
  renderRightIcon,
}) => {
  return (
    <div className={cx(nameTagStyles({ disabled, active }), className)}>
      {renderLeftIcon &&
        renderLeftIcon(
          'w-4 h-4 max-w-4 max-h-4 text-white-40 cursor-pointer transition-colors hover:text-white'
        )}
      {children}
      {renderRightIcon &&
        renderRightIcon(
          'w-4 h-4 max-w-4 max-h-4 text-white-40 cursor-pointer transition-colors hover:text-white'
        )}
    </div>
  );
};
