import { Button } from '@highmobility/console-ui-components';
import { useState } from 'react';

import { DeleteTeamDialog } from '@/components/views/team-settings/DeleteTeamDialog';

type DeleteTeamSectionProps = {
  disabled?: boolean;
  teamId: string;
  teamName?: string;
};

export const DeleteTeamSection = (props: DeleteTeamSectionProps) => {
  const [showDeleteTeamDialog, setShowDeleteTeamDialog] = useState(false);

  return (
    <div>
      <h2 className="text-heading-2 pb-8">Delete Team</h2>
      <p className="text-white-60 pb-8">
        Once you hit delete this team will no longer exist and all team data will be permanently
        erased. Other team members will also no longer have access to the team. If you only want to
        leave the team, but not delete it, you can simply assign a new administrator then leave it.
      </p>
      <Button
        intent="primarySignal"
        disabled={props.disabled}
        onClick={() => setShowDeleteTeamDialog(true)}
      >
        Delete Team
      </Button>
      <DeleteTeamDialog
        isOpen={showDeleteTeamDialog}
        setIsOpen={setShowDeleteTeamDialog}
        teamId={props.teamId}
        teamName={props.teamName || ''}
      />
    </div>
  );
};
