import { cva } from 'class-variance-authority';
import { FC, useState } from 'react';

import { Close } from '../../assets';

export type BarProps = {
  type: 'success' | 'error' | 'info';
  children: string;
  onClose?: () => void;
};

const barClasses = cva(
  'flex w-full items-center justify-between gap-2 rounded-b-[4px] border-b-4 py-[10px] px-4',
  {
    variants: {
      type: {
        success: ['border-b-success-500 bg-success-500-shade text-success-500'],
        error: ['border-b-error-600 bg-error-600-shade text-error-600'],
        info: ['border-b-notification-500 bg-notification-500-shade text-notification-500'],
      },
    },
    defaultVariants: {
      type: 'success',
    },
  }
);

export const Bar: FC<BarProps> = (props) => {
  const [isVisible, setIsVisible] = useState(true);
  const onClose = () => {
    setIsVisible(false);
    props.onClose && props.onClose();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={barClasses(props)}>
      <span className="text-body-regular text-white">{props.children}</span>
      <Close
        className="text-white-40 h-4 w-4 cursor-pointer transition-colors hover:text-white"
        onClick={onClose}
      />
    </div>
  );
};
