import { makeAutoObservable, runInAction } from 'mobx';

import { fetchTeam, fetchTeams, setDefaultTeam } from '@/services/apiService';
import { Team } from '@/types';

export class Teams {
  activeTeamId: string | null = null;
  byId: Record<string, Team | undefined> = {};
  allIds: Set<string> = new Set();
  lastFetch: string | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  get list() {
    return Object.values(this.byId).filter(Boolean) as Team[];
  }

  // Active team, selected by the user for current session. Defaults to defaultTeam or first available team
  get activeTeam() {
    return (
      this.byId[this.activeTeamId ?? ''] || this.defaultTeam || this.byId[Object.keys(this.byId)[0]]
    );
  }

  setActiveTeam(teamId: string) {
    if (this.allIds.has(teamId)) {
      this.activeTeamId = teamId;
    }
  }

  // Default team, stored in the database
  get defaultTeam() {
    return Object.values(this.byId).find((team) => team?.is_default);
  }

  async setDefaultTeam(teamId: string) {
    try {
      await setDefaultTeam(teamId);
      await this.fetchTeams();

      return true;
    } catch (e) {
      console.error('Failed to set default team', e);
      return false;
    }
  }

  async fetchTeams() {
    try {
      const { data: teams } = await fetchTeams();

      runInAction(() => {
        this.byId = teams.reduce(
          (acc, team) => {
            acc[team.id] = team;
            return acc;
          },
          {} as Record<string, Team>
        );
        this.allIds = new Set(teams.map((team) => team.id));
        this.lastFetch = new Date().toISOString();
      });

      return teams;
    } catch (e) {
      console.error('Failed to fetch teams', e);
      return null;
    }
  }

  async fetchTeam(teamId: string) {
    try {
      const { data: team } = await fetchTeam(teamId);

      runInAction(() => {
        this.byId[team.id] = team;
        this.allIds.add(team.id);
        this.lastFetch = new Date().toISOString();
      });

      return team;
    } catch (e) {
      console.error('Failed to fetch team', e);
      return null;
    }
  }
}
