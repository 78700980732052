import { Bar } from '@highmobility/console-ui-components';
import { capitalize } from 'radash';
import { generatePath, LoaderFunction, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo } from 'react';

import { BRANDS } from '@/constants';
import { getBrandData } from '@/services/packagesService';
import { ROUTE_PATHS } from '@/router/routes';
import { useMobx } from '@/hooks/useMobx';
import { useSplitPropertiesByStatus } from '@/hooks/useSplitPropertiesByStatus';
import { useTypedParams } from '@/hooks/useTypedParams';

import { Category } from './Category';
import { DeprecatedNotification } from './DeprecatedNotification';
import { NotSupportedNotification } from './NotSupportedNotification';
import { SharedDataOfferingNotification } from './SharedDataOfferingNotification';

export const loader: LoaderFunction = async () => {
  return {};
};

export const ProjectDataBrandView = observer(() => {
  const { teamId, projectId, brand } = useTypedParams<typeof ROUTE_PATHS.PROJECT_DATA_BRAND>();
  const { projects, marketData } = useMobx();
  const project = projects.byId[projectId];
  const navigate = useNavigate();

  useEffect(() => {
    if (!(brand in BRANDS)) {
      console.error('Invalid brand', brand);
      navigate(generatePath(ROUTE_PATHS.PROJECT_DATA, { teamId, projectId }));
    }
  }, [projectId, marketData, project?.orders, brand, teamId, navigate]);

  const {
    publishedPackages,
    inReviewPackages,
    draftPackages,
    deprecatedPackages,
    publishedProperties,
    inReviewProperties,
    draftProperties,
  } = useSplitPropertiesByStatus(project?.orders ?? [], brand);
  const packagesForThisBrand = useMemo(() => {
    return marketData.byBrand[brand]?.length || 0;
  }, [brand, JSON.stringify(marketData.byBrand)]); // eslint-disable-line react-hooks/exhaustive-deps

  const showSharedDataOfferingNotification = useMemo(() => {
    if (packagesForThisBrand === 0) {
      return false;
    }

    const selectedPackagesInThisBrand: string[] = Object.keys(
      marketData.selectedDraftProperties[brand] || {}
    )?.flat();
    const selectedPackagesInOtherBrands: string[] = [];

    project?.orders?.forEach((order) => {
      if (order.brand === brand) {
        return;
      }

      Object.keys(order.packages).forEach((packageId) => {
        selectedPackagesInOtherBrands.push(packageId);
      });
    });

    return selectedPackagesInThisBrand.some((property) =>
      selectedPackagesInOtherBrands.includes(property)
    );
  }, [brand, JSON.stringify(project?.orders)]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderDeprecatedCategory = () => (
    <Category
      packages={deprecatedPackages}
      selectedProperties={[...publishedProperties, ...inReviewProperties]}
      teamId={teamId}
      projectId={projectId}
      brand={brand}
      status="deprecated"
      header="Deprecated"
    />
  );

  const showDeprecatedFirst = project?.status !== 'draft';

  return (
    <main className="flex h-full w-full flex-col overflow-y-auto">
      <div className="flex items-center gap-2 bg-[#2B4247] px-4 py-2">
        <h5 className="text-heading-5">{getBrandData(brand)?.name || capitalize(brand)}</h5>
        <span className="text-white-60">
          Pricing is applied per bundle and active vehicle per month.
        </span>
      </div>
      {project?.status !== 'published' && (
        <Bar type="info">Estimated Price: The final amount will be calculated during checkout.</Bar>
      )}

      {(showSharedDataOfferingNotification || packagesForThisBrand === 0) && (
        <div className="flex flex-col items-center justify-center gap-4 px-8 py-4">
          {showSharedDataOfferingNotification && <SharedDataOfferingNotification />}
          {deprecatedPackages.length > 0 && <DeprecatedNotification brand={brand} />}
          {packagesForThisBrand === 0 && <NotSupportedNotification />}
        </div>
      )}

      {packagesForThisBrand > 0 && (
        <div>
          {publishedPackages.length > 0 && (
            <Category
              packages={publishedPackages}
              selectedProperties={[...publishedProperties]}
              teamId={teamId}
              projectId={projectId}
              brand={brand}
              status="published"
              header="Live data"
            />
          )}
          {inReviewPackages.length > 0 && (
            <Category
              packages={inReviewPackages}
              selectedProperties={[...inReviewProperties]}
              teamId={teamId}
              projectId={projectId}
              brand={brand}
              status="in_review"
              header="Review"
            />
          )}

          {deprecatedPackages.length > 0 && showDeprecatedFirst && renderDeprecatedCategory()}
          <Category
            packages={draftPackages}
            selectedProperties={[...draftProperties]}
            teamId={teamId}
            projectId={projectId}
            brand={brand}
            status="draft"
            header="Draft"
          />
          {deprecatedPackages.length > 0 && !showDeprecatedFirst && renderDeprecatedCategory()}
        </div>
      )}
    </main>
  );
});
