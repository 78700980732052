import { Button, CheckCircle, Chevron, Error } from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { format } from 'date-fns';
import { useState } from 'react';

import type { ProjectInstance, WebhookDelivery } from '@/types';

import { WebhookDeliveryDetailsDialog } from './WebhookDeliveryDetailsDialog';

type WebhookDeliveryProps = {
  delivery: WebhookDelivery;
  withDetails?: boolean;
  teamId: string;
  projectId: string;
  instance: ProjectInstance;
  webhookId: string;
};

export function WebhookDelivery({ withDetails = true, ...props }: WebhookDeliveryProps) {
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);

  return (
    <div className="flex h-12 items-center gap-6 whitespace-nowrap">
      <div className="h-6 w-6">
        {props.delivery.status === 200 ? (
          <CheckCircle className="text-success-500" />
        ) : (
          <Error className="text-error-500" />
        )}
      </div>
      <div className="flex-1">{props.delivery.id}</div>
      <div>{format(new Date(props.delivery.inserted_at), 'dd-MM-yyyy HH:mm:ss')}</div>
      {withDetails && (
        <>
          <Button
            aria-label="Open details dialog"
            intent="secondary"
            renderRightIcon={(cn) => <Chevron className={cx(cn, '-rotate-90')} />}
            onClick={() => setShowDetailsDialog(true)}
          />
          <WebhookDeliveryDetailsDialog
            isOpen={showDetailsDialog}
            setIsOpen={setShowDetailsDialog}
            webhookDelivery={props.delivery}
            teamId={props.teamId}
            projectId={props.projectId}
            instance={props.instance}
            webhookId={props.webhookId}
          />
        </>
      )}
    </div>
  );
}
