import { cx } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

import { Lock, Triangle } from '../../assets';

export type AccordionProps = {
  title: string;
  renderSubtitle?: (className: string) => JSX.Element | null;
  className?: string;
  locked?: boolean;
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
};

export const Accordion = (props: PropsWithChildren<AccordionProps>) => {
  return (
    <div className={props.className}>
      <div
        className={cx('flex w-full select-none items-center gap-3 bg-black-20 px-4 py-2', {
          'cursor-pointer': !props.locked,
          'cursor-default': props.locked,
        })}
        onClick={() => !props.locked && props.setIsOpen?.(!props.isOpen)}
      >
        <Triangle
          className={cx('-rotate-90 text-white transition-transform', {
            'rotate-0 transform': props.isOpen,
          })}
        />
        <span className="text-label-regular text-white">{props.title}</span>
        {(props.renderSubtitle || props.locked) && (
          <div className="ml-auto flex items-center gap-2">
            {props.renderSubtitle && props.renderSubtitle('text-label-regular text-white-60')}
            {props.locked && <Lock className="h-4 w-4 text-white-60" />}
          </div>
        )}
      </div>
      {props.isOpen && <div>{props.children}</div>}
    </div>
  );
};
