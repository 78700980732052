import { toast as reactHotToast, Toaster as ReactHotToaster, ToastOptions } from 'react-hot-toast';

import { Toast, ToastProps } from '../components/atoms/Toast';

export const toast = (message: string, type: ToastProps['type'], options: ToastOptions = {}) => {
  reactHotToast.custom(
    (t) => (
      <div className={t.visible ? 'animate-toast-enter' : 'animate-toast-leave'}>
        <Toast type={type}>{message}</Toast>
      </div>
    ),
    options
  );
};

export const Toaster = (options: ToastOptions) => (
  <ReactHotToaster
    toastOptions={{
      duration: 4000,
      position: 'bottom-right',
      ...options,
    }}
  />
);
