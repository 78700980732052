import { AlertDialog, AlertDialogProps, toast } from '@highmobility/console-ui-components';
import { useMutation } from '@tanstack/react-query';

import { deleteInvitation, fakeLoader, removeTeamMember } from '@/services/apiService';
import { useMobx } from '@/hooks/useMobx';

type RemoveMemberDialogProps = {
  id: string;
  email: string;
  teamId: string;
  isPending: boolean;
} & Pick<AlertDialogProps, 'isOpen' | 'setIsOpen'>;

export const RemoveMemberDialog = (props: RemoveMemberDialogProps) => {
  const { teams } = useMobx();
  const removeMemberMutation = useMutation({
    mutationFn: async () => {
      const promises = [fakeLoader()];
      if (props.isPending) {
        promises.push(deleteInvitation(props.teamId, props.id));
      } else {
        promises.push(removeTeamMember(props.teamId, props.id));
      }

      return Promise.all(promises);
    },
    onError: () => {
      props.setIsOpen(false);
      toast('Failed to remove user. Please try again.', 'error');
    },
    onSuccess: async () => {
      await teams.fetchTeam(props.teamId);
      props.setIsOpen(false);
      toast('User removed', 'success');
    },
  });

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title="Remove user"
      confirmButtonIntent="primarySignal"
      confirmLabel={removeMemberMutation.isPending ? 'Removing...' : 'Remove user'}
      confirmButtonDisabled={removeMemberMutation.isPending}
      cancelLabel="Cancel"
      onConfirm={() => removeMemberMutation.mutate()}
      onCancel={() => props.setIsOpen(false)}
    >
      You are about to remove {props.email} from the team. They will lose access immediately.
    </AlertDialog>
  );
};
