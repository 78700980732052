import { makeAutoObservable } from 'mobx';

import { CompanyProfile, WithPaymentCard } from '@/types';
import { fetchCompanyProfile } from '@/services/apiService';

export class Billing {
  companyProfiles: Record<string, WithPaymentCard<CompanyProfile> | undefined> = {};

  constructor() {
    makeAutoObservable(this);
  }

  setCompanyProfile(teamId: string, companyProfile: WithPaymentCard<CompanyProfile> | null) {
    if (!companyProfile) return;
    this.companyProfiles[teamId] = companyProfile;
  }

  async fetchCompanyProfile(teamId: string) {
    try {
      const { data: companyProfile } = await fetchCompanyProfile(teamId);

      this.setCompanyProfile(teamId, companyProfile);
      return companyProfile;
    } catch (e) {
      console.error('Failed to fetch company profile', e);
      return null;
    }
  }
}
