import { Button, toast } from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { Link, LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { ApplicationSinkSettings, ProjectInstance, StreamingType } from '@/types';
import {
  fakeLoader,
  fetchMQTTCertificates,
  fetchStreamingSettings,
  updateStreamingSettings,
} from '@/services/apiService';
import { NoPermissionNotification } from '@/components/molecules/NoPermissionNotification';
import { PathParams, useTypedParams } from '@/hooks/useTypedParams';
import { ProjectAuthHeader } from '@/components/molecules/ProjectAuthHeader';
import { ROUTE_PATHS } from '@/router/routes';
import { usePermissions } from '@/hooks/usePermissions';

import { MQTTCerificatesSection } from './MQTTCertificatesSection';
import { StreamingCard } from './StreamingCard';
import { StreamingTestDialog, StreamingTestDialogProps } from './StreamingTestDialog';
import { StreamingTestFooter } from './StreamingTestFooter';

export const loader = async (args: LoaderFunctionArgs) => {
  const params = args.params as PathParams<typeof ROUTE_PATHS.PROJECT_AUTH_OAUTH>;
  const instance = params.instance as ProjectInstance;

  const [streamingSettingsResponse, mqttListResponse] = await Promise.all([
    fetchStreamingSettings(params.teamId, params.projectId, instance).catch(() => null),
    fetchMQTTCertificates(params.teamId, params.projectId, instance).catch(() => null),
  ]);

  return {
    streamingSettings: streamingSettingsResponse?.data ?? null,
    mqttCertificates: mqttListResponse?.data ?? null,
  };
};

export const ProjectAuthStreamingView = observer(() => {
  const loaderData = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const { teamId, projectId, ...params } =
    useTypedParams<typeof ROUTE_PATHS.PROJECT_AUTH_STREAMING>();
  const instance = params.instance as ProjectInstance;
  const { userHasPermission } = usePermissions(teamId);
  const hasManagePermission = userHasPermission(`projects.manage.${instance}.credentials`);
  const hasViewPermission = userHasPermission('projects.view.credentials');
  const [selectedStreamingType, setSelectedStreamingType] = useState<StreamingType>(
    loaderData.streamingSettings?.sink?.type ?? 'none'
  );
  const [activeStreamingSettings, setActiveStreamingSettings] =
    useState<ApplicationSinkSettings | null>(loaderData.streamingSettings ?? null);
  const [testingDialogData, setTestingDialogData] = useState<{
    type: StreamingTestDialogProps['type'];
    initialValue?: string;
  } | null>(null);

  useEffect(() => {
    setActiveStreamingSettings(loaderData.streamingSettings);
    setSelectedStreamingType(loaderData.streamingSettings?.sink?.type ?? 'none');
  }, [loaderData.streamingSettings]);

  const updateStreamingSettingsMutation = useMutation({
    mutationFn: async (settings: ApplicationSinkSettings['sink']) => {
      const [{ data }] = await Promise.all([
        updateStreamingSettings(teamId, projectId, instance, settings),
        fakeLoader(),
      ]);

      return data;
    },
    onError: (e: any) => {
      console.error('ProjectAuthStreamingView::update of streaming settings failed', e);
      toast(e?.data?.message ?? 'Something went wrong. Please try again.', 'error');
    },
    onSuccess: async (newSettings) => {
      /* istanbul ignore next */
      if (!activeStreamingSettings) {
        throw new Error('Streaming settings are missing.');
      }

      setActiveStreamingSettings({
        sink: newSettings,
        app_id: activeStreamingSettings.app_id,
      });
      toast('Streaming settings updated', 'success');
    },
  });

  const onClickSave = () => {
    switch (selectedStreamingType) {
      case 's3': {
        setTestingDialogData({ type: 's3' });
        return;
      }
      case 'kinesis': {
        setTestingDialogData({ type: 'kinesis' });
        return;
      }
      case 'none': {
        updateStreamingSettingsMutation.mutate({ type: 'none' });
        return;
      }
      case 'mqtt': {
        updateStreamingSettingsMutation.mutate({ type: 'mqtt' });
        return;
      }
    }

    /* istanbul ignore next */
    selectedStreamingType satisfies never;
  };

  return (
    <main className="flex h-full w-full flex-col overflow-y-auto">
      <ProjectAuthHeader
        title="Data Streaming"
        instance={instance}
        teamId={teamId}
        projectId={projectId}
      />

      <div className="flex flex-col gap-6 p-8">
        <p className="text-white-60">
          Select a data streaming method using{' '}
          <Link
            to="https://docs.high-mobility.com/guides/getting-started/mqtt/"
            target="_blank"
            className="underline hover:no-underline"
          >
            MQTT protocol
          </Link>
          {', '}
          <Link
            to="https://docs.high-mobility.com/guides/getting-started/aws-s3/"
            target="_blank"
            className="underline hover:no-underline"
          >
            AWS S3 bucket
          </Link>
          {', or '}
          <Link
            to="https://docs.high-mobility.com/guides/getting-started/aws-kinesis/"
            target="_blank"
            className="underline hover:no-underline"
          >
            Kinesis
          </Link>
          .
        </p>
        {(!hasManagePermission || !hasViewPermission) && <NoPermissionNotification />}
        <div
          className={cx('grid grid-cols-1 gap-2 xl:grid-cols-2', {
            'pointer-events-none opacity-50': !hasManagePermission,
          })}
        >
          <StreamingCard
            title="Streaming disabled"
            active={activeStreamingSettings?.sink.type === 'none'}
            checked={selectedStreamingType === 'none'}
            onClick={() => setSelectedStreamingType('none')}
          >
            No streaming method is currently selected. Choose another option to enable real-time
            vehicle data streaming.
          </StreamingCard>
          <StreamingCard
            title="MQTT"
            active={activeStreamingSettings?.sink.type === 'mqtt'}
            checked={selectedStreamingType === 'mqtt'}
            onClick={() => setSelectedStreamingType('mqtt')}
          >
            The MQTT Auto API broker enables you to subscribe to vehicle data updates and get them
            pushed to your application using MQTT protocol.{' '}
            <Link
              aria-label="Learn more about MQTT"
              to="https://docs.high-mobility.com/guides/getting-started/mqtt/"
              target="_blank"
              className="underline hover:no-underline"
              onClick={(e) => e.stopPropagation()}
            >
              Learn more
            </Link>
          </StreamingCard>
          <StreamingCard
            title="AWS S3 BUCKET"
            active={activeStreamingSettings?.sink.type === 's3'}
            checked={selectedStreamingType === 's3'}
            onClick={() => setSelectedStreamingType('s3')}
          >
            Amazon S3 is an object storage service that stores data as objects in buckets. Objects
            are files with metadata, and buckets are containers for those objects.{' '}
            <Link
              aria-label="Learn more about AWS-S3"
              to="https://docs.high-mobility.com/guides/getting-started/aws-s3/"
              target="_blank"
              className="underline hover:no-underline"
              onClick={(e) => e.stopPropagation()}
            >
              Learn more
            </Link>
          </StreamingCard>
          <StreamingCard
            title="Kinesis"
            active={activeStreamingSettings?.sink.type === 'kinesis'}
            checked={selectedStreamingType === 'kinesis'}
            onClick={() => setSelectedStreamingType('kinesis')}
          >
            Amazon Kinesis enables real-time data streaming, letting you collect, process, and
            analyze data instantly as it arrives.{' '}
            <Link
              aria-label="Learn more about Kinesis"
              to="https://docs.high-mobility.com/guides/getting-started/aws-kinesis/"
              target="_blank"
              className="underline hover:no-underline"
              onClick={(e) => e.stopPropagation()}
            >
              Learn more
            </Link>
          </StreamingCard>
        </div>
        <div className="flex w-full justify-end">
          <Button
            disabled={
              !hasManagePermission ||
              selectedStreamingType === activeStreamingSettings?.sink.type ||
              updateStreamingSettingsMutation.isPending
            }
            intent="primary"
            onClick={onClickSave}
          >
            Save and update
          </Button>
        </div>

        {selectedStreamingType === 'mqtt' &&
          loaderData.mqttCertificates &&
          activeStreamingSettings && (
            <MQTTCerificatesSection
              teamId={teamId}
              projectId={projectId}
              instance={instance}
              certificates={loaderData.mqttCertificates}
              appId={activeStreamingSettings.app_id}
            />
          )}
        {activeStreamingSettings?.sink.type === 'kinesis' &&
          selectedStreamingType === 'kinesis' && (
            <StreamingTestFooter
              title="Stream ARN"
              placeholder="Enter ARN"
              initialValue={activeStreamingSettings.sink.options.stream_arn}
              onClickTest={(newARN) =>
                setTestingDialogData({ type: 'kinesis', initialValue: newARN })
              }
            />
          )}
        {activeStreamingSettings?.sink.type === 's3' && selectedStreamingType === 's3' && (
          <StreamingTestFooter
            title="Bucket name"
            placeholder="Enter bucket name"
            initialValue={activeStreamingSettings.sink.options.bucket_name}
            onClickTest={(newName) => setTestingDialogData({ type: 's3', initialValue: newName })}
          />
        )}

        {activeStreamingSettings && (
          <StreamingTestDialog
            type={testingDialogData?.type ?? 's3'}
            initialValue={testingDialogData?.initialValue}
            isOpen={!!testingDialogData}
            setIsOpen={() => setTestingDialogData(null)}
            onSave={async (newSettings) => {
              await updateStreamingSettingsMutation.mutateAsync(newSettings['sink']);
            }}
            teamId={teamId}
            projectId={projectId}
            instance={instance}
            appId={activeStreamingSettings.app_id}
          />
        )}
      </div>
    </main>
  );
});
