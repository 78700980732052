import { Button, TextInput } from '@highmobility/console-ui-components';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';

import { AuthBackgroundIllustrations } from '@/components/atoms/AuthBackgroundIllustrations';
import { AuthHeader } from '@/components/atoms/AuthHeader';
import { GeneralTemplate } from '@/components/templates/general/GeneralTemplate';
import { initResetPassword } from '@/services/apiService';

export const ForgotPasswordView = () => {
  const [step, setStep] = useState<'initial' | 'success' | 'error'>('initial');
  const validation = z.object({
    email: z.string().email({ message: 'Invalid email' }),
  });

  const initResetPasswordMutation = useMutation({
    mutationFn: (data: z.infer<typeof validation>) => {
      return initResetPassword(data);
    },
    onError: (e) => {
      console.error('Error submitting the form', e);
      setStep('error');
    },
    onSuccess: () => {
      setStep('success');
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof validation>>({
    resolver: zodResolver(validation),
  });

  const onSubmit = handleSubmit((data) => initResetPasswordMutation.mutate(data));

  return (
    <GeneralTemplate
      className="bg-beige-100"
      renderHeader={() => <AuthHeader type="login" />}
      renderIllustrations={() => <AuthBackgroundIllustrations />}
    >
      <main className="pb-header flex h-full w-full items-center justify-around">
        {step === 'initial' && (
          <form
            className="w-login-modal-md bg-dark-green-900 flex flex-col gap-6 rounded p-12"
            onSubmit={onSubmit}
          >
            <div>
              <h1 className="text-heading-1 mb-3">Reset password</h1>
              <p className="font-bold">
                Enter your email address and we&apos;ll send you a link to reset your password
              </p>
            </div>
            <div className="flex flex-col gap-4">
              <TextInput
                label="Email"
                placeholder="joe@highmobility.com"
                {...register('email')}
                error={errors.email?.message}
                className="flex-1"
                type="email"
              />
            </div>
            <div className="flex justify-center">
              <Button type="submit">
                {initResetPasswordMutation.isPending ? 'Loading' : 'Send reset password email'}
              </Button>
            </div>
          </form>
        )}
        {step === 'success' && (
          <div className="w-login-modal-sm bg-dark-green-900 flex flex-col gap-6 rounded p-12">
            <h1 className="text-heading-1">Done!</h1>
            <p className="font-bold">
              We have sent you an email with instructions on how to reset your password. If you
              haven&apos;t received any email, please go back and try again to make sure you
              didn&apos;t enter an invalid email address.
            </p>
            <div className="flex justify-center">
              <Button onClick={() => setStep('initial')}>Go back</Button>
            </div>
          </div>
        )}
        {step === 'error' && (
          <div className="w-login-modal-sm bg-dark-green-900 flex flex-col gap-6 rounded p-12">
            <h1 className="text-heading-1">Oops!</h1>
            <p className="font-bold">
              Something went wrong. Please try again or contact support@high-mobility.com.
            </p>
            <div className="flex justify-center">
              <Button onClick={() => setStep('initial')}>Go back</Button>
            </div>
          </div>
        )}
      </main>
    </GeneralTemplate>
  );
};
