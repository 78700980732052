import {
  coloredCar01,
  coloredCar02,
  coloredCar03,
  coloredCar04,
  coloredCar05,
  coloredCar06,
  coloredCar07,
  coloredCar08,
  coloredCar09,
  coloredCar10,
  coloredCar11,
  coloredCar12,
  coloredCar13,
  coloredCar14,
  coloredCar15,
  coloredCar16,
  coloredCar17,
  coloredCar18,
  coloredCar19,
  coloredCar20,
} from '@highmobility/console-ui-components';
import shuffle from 'knuth-shuffle-seeded';

export function chooseImage(userId: string, existingProjectsCount: number) {
  const IMAGES_COUNT = 20;

  const userIdInt = parseInt(userId.replace(/\D/g, ''), 10);
  const shuffledArray = shuffle(
    Array.from({ length: IMAGES_COUNT }, (_, index) => index + 1),
    userIdInt
  );

  const imageIndex =
    existingProjectsCount <= IMAGES_COUNT - 1
      ? existingProjectsCount
      : existingProjectsCount % IMAGES_COUNT;

  return `${shuffledArray[imageIndex]}`;
}

export function getImageUrl(image: string): string {
  switch (image) {
    case '1':
      return coloredCar01;
    case '2':
      return coloredCar02;
    case '3':
      return coloredCar03;
    case '4':
      return coloredCar04;
    case '5':
      return coloredCar05;
    case '6':
      return coloredCar06;
    case '7':
      return coloredCar07;
    case '8':
      return coloredCar08;
    case '9':
      return coloredCar09;
    case '10':
      return coloredCar10;
    case '11':
      return coloredCar11;
    case '12':
      return coloredCar12;
    case '13':
      return coloredCar13;
    case '14':
      return coloredCar14;
    case '15':
      return coloredCar15;
    case '16':
      return coloredCar16;
    case '17':
      return coloredCar17;
    case '18':
      return coloredCar18;
    case '19':
      return coloredCar19;
    case '20':
      return coloredCar20;
    default:
      return '';
  }
}
