import { Button, Tag } from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { useState } from 'react';

import { PROJECT_REGION, PROJECT_TYPE } from '@/constants';
import { ProjectType, Region } from '@/types';

import { CreateProjectDialog } from './CreateProjectDialog';

type CreateProjectCardProps = {
  teamId: string;
  type: ProjectType;
  region: Region;
  disabled?: boolean;
};

export const CreateProjectCard = (props: CreateProjectCardProps) => {
  const [showCreateProjectDialog, setShowCreateProjectDialog] = useState(false);

  return (
    <>
      <div
        className={cx(
          'border-white-12 bg-dark-green-900-shade-2 relative inline-flex h-[360px] rounded border',
          {
            'cursor-not-allowed': props.disabled,
            'group cursor-pointer': !props.disabled,
          }
        )}
        onClick={() => !props.disabled && setShowCreateProjectDialog(true)}
      >
        <div className="flex flex-col">
          <div className="flex-1 px-4 pt-8">
            <div className="mb-4 flex items-center gap-2">
              <Tag type="light" round disabled={props.disabled}>
                {PROJECT_TYPE[props.type]}
              </Tag>
              {props.disabled && <span className="text-white-40">Not yet available</span>}
            </div>
            <p
              className={cx({
                'text-white-40': props.disabled,
              })}
            >
              Select this consent type if the owner of the vehicle and the driver are separate
              entities (a typical B2B use case). In terms of data privacy, the whole fleet of
              vehicles can be cleared for data access by the fleet owner.
            </p>
          </div>
          <div className="border-white-12 border-t p-4">
            <Tag type="light" disabled={props.disabled}>
              {PROJECT_REGION[props.region]}
            </Tag>
          </div>
        </div>
        <div className="absolute inset-0 opacity-0 backdrop-blur-sm transition-opacity duration-300 group-hover:opacity-100">
          <div className="flex h-full w-full translate-y-1 items-center justify-center transition-transform duration-500 group-hover:translate-y-0">
            <Button disabled={props.disabled} intent="primary">
              Select
            </Button>
          </div>
        </div>
      </div>
      <CreateProjectDialog
        region={props.region}
        type={props.type}
        teamId={props.teamId}
        isOpen={showCreateProjectDialog}
        setIsOpen={setShowCreateProjectDialog}
      />
    </>
  );
};
