import { Delete, Edit } from '@highmobility/console-ui-components';
import { FC, useMemo, useState } from 'react';
import { format, formatDistance } from 'date-fns';
import { observer } from 'mobx-react-lite';

import { Authenticator } from '@/types';
import { DeletePasskeyDialog } from '@/components/views/account-settings/DeletePasskeyDialog';
import { UpdatePasskeyDialog } from '@/components/views/account-settings/UpdatePasskeyDialog';

export type PasskeyDataProps = {
  data: Authenticator;
};

export const PasskeyData: FC<PasskeyDataProps> = observer(
  ({ data: { id, name, created_at, last_used, metadata } }) => {
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeletePasskeyDialog, setShowDeletePasskeyDialog] = useState(false);
    const lastUsed = useMemo(() => {
      if (!last_used) {
        return '';
      }

      const diff = new Date().getTime() - new Date(last_used).getTime();
      if (diff < 1000 * 60 * 60) {
        return 'less than an hour ago';
      }

      return `${formatDistance(new Date(last_used), new Date())} ago`;
    }, [last_used]);

    const displayName = useMemo(
      () => `${name}${metadata && metadata.name ? ` (${metadata.name})` : ''}`,
      [name, metadata]
    );

    return (
      <div className="py-4 pl-6 border border-white-12 w-full bg-white-4 rounded flex items-center gap-8 pr-3">
        {metadata && metadata.icon_light ? (
          <img src={metadata.icon_light} alt={metadata.name} className="w-6 h-6 flex-shrink-0" />
        ) : null}
        <div className="flex flex-col gap-2">
          <h5 className="text-heading-5">{displayName}</h5>
          <p>
            Added on {format(new Date(created_at), 'MMM dd, yyyy')}
            {lastUsed ? ` | Last used ${lastUsed}` : ''}
          </p>
        </div>
        <div className="flex items-center ml-auto">
          <div
            className="hover:text-white-60 cursor-pointer transition-colors p-3"
            onClick={() => setShowEditDialog(true)}
            aria-label="Edit passkey"
          >
            <Edit />
          </div>

          <div
            className="hover:text-white-60 cursor-pointer transition-colors p-3"
            onClick={() => setShowDeletePasskeyDialog(true)}
            aria-label="Delete passkey"
          >
            <Delete />
          </div>
        </div>
        <UpdatePasskeyDialog
          isOpen={showEditDialog}
          setIsOpen={setShowEditDialog}
          id={id}
          name={name}
        />
        <DeletePasskeyDialog
          isOpen={!!showDeletePasskeyDialog}
          setIsOpen={setShowDeletePasskeyDialog}
          id={id}
        />
      </div>
    );
  }
);
