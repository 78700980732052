import { Button, CredentialField } from '@highmobility/console-ui-components';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { copyTextToClipboard } from '@/services/textFileService';
import { fetchOAuthClient, fetchServiceAccounts } from '@/services/apiService';
import { NoPermissionNotification } from '@/components/molecules/NoPermissionNotification';
import { OAuthClient, ProjectInstance } from '@/types';
import { PathParams, useTypedParams } from '@/hooks/useTypedParams';
import { ProjectAuthHeader } from '@/components/molecules/ProjectAuthHeader';
import { ROUTE_PATHS } from '@/router/routes';
import { usePermissions } from '@/hooks/usePermissions';

import { OAuthClientCredentialsSection } from './OAuthClientCredentialsSection';
import { RegenerateClientSecretDialog } from './RegenerateClientSecretDialog';

export const loader = async (args: LoaderFunctionArgs) => {
  const params = args.params as PathParams<typeof ROUTE_PATHS.PROJECT_AUTH_OAUTH>;
  const instance = params.instance as ProjectInstance;

  const [OAuthClientResponse, serviceAccountsResponse] = await Promise.all([
    fetchOAuthClient(params.teamId, params.projectId, instance).catch(() => null),
    fetchServiceAccounts(params.teamId, params.projectId, instance).catch(() => null),
  ]);

  return {
    OAuthClientData: OAuthClientResponse?.data ?? null,
    serviceAccounts: serviceAccountsResponse?.data ?? null,
  };
};

export const ProjectAuthOAuthView = observer(() => {
  const loaderData = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const { teamId, projectId, ...params } = useTypedParams<typeof ROUTE_PATHS.PROJECT_AUTH_OAUTH>();
  const instance = params.instance as ProjectInstance;
  const [OAuthClientData, setOAuthClientData] = useState<OAuthClient | null>(
    loaderData.OAuthClientData
  );
  const [showRegenerateClientSecretDialog, setShowRegenerateClientSecretDialog] = useState(false);
  const { userHasPermission } = usePermissions(teamId);
  const hasViewPermission = userHasPermission(
    instance === 'sandbox'
      ? 'projects.view.credentials'
      : 'projects.view.production.credentials.secrets'
  );
  const hasManagePermission = userHasPermission(`projects.manage.${instance}.credentials`);

  useEffect(() => {
    setOAuthClientData(loaderData.OAuthClientData);
    setShowRegenerateClientSecretDialog(false);
  }, [loaderData]);

  return (
    <main className="flex h-full w-full flex-col overflow-y-auto">
      <ProjectAuthHeader
        title="OAuth Client"
        instance={instance}
        teamId={teamId}
        projectId={projectId}
      />

      <div className="flex flex-col gap-6 p-8">
        {(!hasViewPermission || !hasManagePermission) && <NoPermissionNotification />}
        {hasViewPermission && OAuthClientData && (
          <>
            <CredentialField
              label="CLIENT ID"
              value={OAuthClientData.id}
              onCopy={(value) => copyTextToClipboard(value)}
            />
            <div className="flex flex-col gap-3">
              <CredentialField
                label="CLIENT SECRET"
                value={OAuthClientData.secret}
                secret
                onCopy={(value) => copyTextToClipboard(value)}
              />
              <div className="ml-auto">
                <Button
                  intent="secondary"
                  onClick={() => setShowRegenerateClientSecretDialog(true)}
                >
                  Regenerate
                </Button>
              </div>
            </div>
            <CredentialField
              label="TOKEN URI"
              value={OAuthClientData.token_uri}
              onCopy={(value) => copyTextToClipboard(value)}
            />
          </>
        )}
      </div>
      <OAuthClientCredentialsSection
        teamId={teamId}
        projectId={projectId}
        instance={instance}
        serviceAccounts={loaderData.serviceAccounts}
        hasViewPermission={hasViewPermission}
        hasManagePermission={hasManagePermission}
        oAuthClientData={OAuthClientData}
      />
      {OAuthClientData && (
        <RegenerateClientSecretDialog
          isOpen={showRegenerateClientSecretDialog}
          setIsOpen={setShowRegenerateClientSecretDialog}
          onSuccess={(newClient) => setOAuthClientData(newClient)}
          clientId={OAuthClientData.id}
          instance={instance}
          projectId={projectId}
          teamId={teamId}
        />
      )}
    </main>
  );
});
