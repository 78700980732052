import { capitalize } from 'radash';
import { Info, Notification } from '@highmobility/console-ui-components';
import { Link } from 'react-router-dom';

type DeprecatedNotificationProps = {
  brand: string;
};

export const DeprecatedNotification = (props: DeprecatedNotificationProps) => {
  return (
    <Notification
      className="w-full"
      renderLeftIcon={(c) => <Info className={c} />}
      renderAdditionalContent={() => (
        <p className="text-white-60">
          Selection will no longer be available for {capitalize(props.brand)}.{' '}
          <Link
            to="https://www.high-mobility.com/car-data"
            target="_blank"
            className="font-bold hover:underline"
          >
            Learn more about deprecated data.
          </Link>
        </p>
      )}
    >
      Certains properties have been deprecated
    </Notification>
  );
};
