import { capitalize } from 'radash';
import {
  Check,
  Close,
  DotMenu,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { EditUserRoleDialog } from '@/components/views/team-settings/EditUserRoleDialog';
import { MemberOrInvitation } from '@/types';
import { RemoveMemberDialog } from '@/components/views/team-settings/RemoveMemberDialog';
import { useMobx } from '@/hooks/useMobx';

const MFAStatusCell: FC<{ enabled: boolean }> = ({ enabled }) => {
  const Icon = enabled ? Check : Close;
  const classNames = cx(['min-w-4 min-h-4 h-4 w-4'], {
    'text-success-500': enabled,
    'text-white-60': !enabled,
  });

  return (
    <TableCell>
      <div className="flex items-center justify-center">
        <Icon className={classNames} />
      </div>
    </TableCell>
  );
};

type MembersTableProps = {
  teamId: string;
  disabled?: boolean;
};

export const MembersTable = observer((props: MembersTableProps) => {
  const { teams, session } = useMobx();
  const team = teams.byId[props.teamId];
  const [activeUser, setActiveUser] = useState<MemberOrInvitation | null>(null);
  const [showEditUserRoleDialog, setShowEditUserRoleDialog] = useState(false);
  const [showRemoveMemberDialog, setShowRemoveMemberDialog] = useState(false);

  const editRole = (member: MemberOrInvitation) => {
    setActiveUser(member);
    setShowEditUserRoleDialog(true);
  };

  const removeTeamMember = (member: MemberOrInvitation) => {
    setActiveUser(member);
    setShowRemoveMemberDialog(true);
  };

  const membersAndInvitations = team
    ? [
        ...team.members.map((member) => ({ ...member, isMember: true } as const)),
        ...team.invitations.map((invitation) => ({ ...invitation, isMember: false } as const)),
      ]
    : [];

  return (
    <div>
      <Table className="mb-6 w-full" disabled={props.disabled}>
        <TableHeader>
          <TableRow>
            <TableHead>User</TableHead>
            <TableHead className="w-10">2FA</TableHead>
            <TableHead colSpan={2}>Role</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {membersAndInvitations.map((memberOrInvitation, index) => (
            <TableRow key={index}>
              <TableCell>
                <div className="flex items-center">
                  {memberOrInvitation.isMember && (
                    <>
                      <b>{memberOrInvitation.name}</b>
                      <div className="bg-white-60 mx-2 h-[12px] w-[1px]" />
                    </>
                  )}
                  <span className="text-white-60">{memberOrInvitation.email}</span>
                </div>
              </TableCell>
              <MFAStatusCell enabled={memberOrInvitation.isMember && memberOrInvitation.mfa} />
              <TableCell className="flex items-center">
                {memberOrInvitation.isMember ? (
                  capitalize(memberOrInvitation.role)
                ) : (
                  <>
                    <span>Pending</span>
                    <div className="bg-white-60 mx-2 h-[12px] w-[1px]" />
                    <span className="text-white-60">{capitalize(memberOrInvitation.role)}</span>
                  </>
                )}
              </TableCell>
              <TableCell className="w-10" noPadding>
                <Dropdown
                  disabled={props.disabled}
                  renderButton={() => (
                    <div
                      className={cx(
                        'group flex h-10 w-full cursor-pointer items-center justify-center',
                        {
                          'hover:cursor-not-allowed': props.disabled,
                        }
                      )}
                    >
                      <DotMenu className="min-w-4 min-h-4 hover:text-white-80 group-hover:text-white-60 h-4 w-4 text-white transition-colors" />
                    </div>
                  )}
                >
                  <DropdownMenu>
                    <DropdownMenuItem
                      label="Edit role"
                      onClick={() => editRole(memberOrInvitation)}
                    />
                    {memberOrInvitation.id !== session.user?.id && (
                      <>
                        <DropdownMenuSeparator />
                        <DropdownMenuItem
                          label="Remove team member"
                          onClick={() => removeTeamMember(memberOrInvitation)}
                        />
                      </>
                    )}
                  </DropdownMenu>
                </Dropdown>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {activeUser && (
        <>
          <EditUserRoleDialog
            isOpen={showEditUserRoleDialog}
            setIsOpen={setShowEditUserRoleDialog}
            onCancel={() => setActiveUser(null)}
            currentRole={activeUser.role}
            email={activeUser.email}
            id={activeUser.id}
            name={activeUser.isMember ? activeUser.name : undefined}
            teamId={props.teamId}
            isPending={!activeUser.isMember}
          />
          <RemoveMemberDialog
            isOpen={showRemoveMemberDialog}
            setIsOpen={setShowRemoveMemberDialog}
            id={activeUser.id}
            email={activeUser.email}
            teamId={props.teamId}
            isPending={!activeUser.isMember}
          />
        </>
      )}
    </div>
  );
});
