import { cva } from 'class-variance-authority';
import { FC, MouseEvent, useState } from 'react';

import { Copy, Eye, EyeClosed } from '../../assets';

export type CredentialFieldProps = {
  value?: string;
  label: string;
  onCopy?: (value: string) => void;
  secret?: boolean;
};

const credentialField = cva(
  'w-full border flex justify-between items-center group border-white-12 rounded py-4 px-3 relative gap-2 select-none',
  {
    variants: {
      canBeCopied: {
        true: 'cursor-pointer',
        false: 'cursor-default',
      },
    },
  }
);

const credentialFieldValue = cva(
  'text-white-80 transition-colors group-hover:text-white overflow-hidden text-ellipsis whitespace-nowrap',
  {
    variants: {
      hasValue: {
        false: 'text-uppercase-small uppercase',
      },
    },
  }
);

export const CredentialField: FC<CredentialFieldProps> = (props) => {
  const [isHidden, setIsHidden] = useState(!!props.secret);

  const onClickEye = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsHidden((prev) => !prev);
  };

  const shownValue = isHidden ? '••••••••••••••••••' : props.value;

  return (
    <div
      className={credentialField({ canBeCopied: !!props.onCopy && !isHidden })}
      onClick={() => props.value && !isHidden && props.onCopy?.(props.value)}
    >
      {props.value && (
        <div className="text-uppercase-small px-1 uppercase transition-colors bg-dark-green-900 absolute bottom-full left-2 translate-y-1/2 group-hover:text-white text-white-80">
          {props.label}
        </div>
      )}
      <div className={credentialFieldValue({ hasValue: !!props.value })}>
        {props.value ? shownValue : props.label}
      </div>
      <div className="ml-auto flex items-center gap-2">
        {!isHidden && !!props.onCopy ? (
          <Copy className="text-white-60 hover:text-white-80 transition-colors flex-shrink-0 w-4 h-4" />
        ) : null}
        {props.secret ? (
          <div aria-label="Toggle visibility" onClick={onClickEye}>
            {isHidden ? (
              <EyeClosed className="text-white-60 hover:text-white-80 transition-colors flex-shrink-0 w-4 h-4 cursor-pointer" />
            ) : (
              <Eye className="text-white-60 hover:text-white-80 transition-colors flex-shrink-0 w-4 h-4 cursor-pointer" />
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
