import { observer } from 'mobx-react-lite';

import BackgroundIllustrationWithCars from '@/assets/illustrations/BackgroundIllustrationWithCars.svg';

export const ProjectView = observer(() => {
  return (
    <main className="h-full w-full">
      <div className="flex h-full w-full items-center justify-center">
        <BackgroundIllustrationWithCars />
      </div>
    </main>
  );
});
