import { Close } from '@highmobility/console-ui-components';
import { generatePath, Link, Outlet } from 'react-router-dom';

import { ContentLink } from '../project-auth/ContentLink';
import { ProjectSecondaryMenu } from '../project-data/ProjectSecondaryMenu';
import { ROUTE_PATHS } from '@/router/routes';
import { useCurrentPath } from '@/hooks/useCurrentPath';
import { useTypedParams } from '@/hooks/useTypedParams';

export function ProductInfoTemplate() {
  const { teamId, projectId } = useTypedParams<typeof ROUTE_PATHS.PRODUCT_INFO>();
  const currentPath = useCurrentPath();

  return (
    <main className="flex h-full w-full">
      <ProjectSecondaryMenu>
        <div className="bg-dark-green-900">
          <div className="flex items-center justify-between border-b border-black-20 bg-[#2B4247] px-4 py-2">
            <h5 className="text-heading-5">Product info</h5>
            <Link to={generatePath(ROUTE_PATHS.PROJECT, { teamId, projectId })}>
              <Close className="h-4 w-4 cursor-pointer text-white-40 transition-colors hover:text-white" />
            </Link>
          </div>
          <div className="h-full">
            <div className="pb-12">
              <ContentLink
                title="Website"
                link={generatePath(ROUTE_PATHS.PRODUCT_INFO_WEBSITE, {
                  teamId,
                  projectId,
                })}
                active={currentPath.includes(ROUTE_PATHS.PRODUCT_INFO_WEBSITE)}
              />
              <ContentLink
                title="Legal"
                link={generatePath(ROUTE_PATHS.PRODUCT_INFO_LEGAL, {
                  teamId,
                  projectId,
                })}
                active={currentPath.includes(ROUTE_PATHS.PRODUCT_INFO_LEGAL)}
              />
            </div>
          </div>
        </div>
      </ProjectSecondaryMenu>
      <div className="w-full">
        <Outlet />
      </div>
    </main>
  );
}
