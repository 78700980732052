import { Role } from '@highmobility/console-role-matrix';
import { z, ZodSchema } from 'zod';

export const passwordSchema: ZodSchema<string> = z.string().refine((value) => {
  // One digit, one lowercase, one uppercase, one special character, at least 8 characters
  const regex =
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/g;

  return regex.test(value);
}, 'Invalid password format');

export const loginValidation = z.object({
  email: z.string().email({ message: 'Invalid email' }),
  password: z.string().min(1, { message: 'Invalid password' }),
});

export const loginWithMFAValidation = z.object({
  code: z.string().length(6, { message: 'Invalid code' }),
});

export const MFARecoveryValidation = z.object({
  code: z.string().length(11, { message: 'Invalid code' }),
});

export const signUpValidation = z.object({
  firstName: z.string().min(1, { message: 'First name is required' }),
  lastName: z.string().min(1, { message: 'Last name is required' }),
  company: z.string().min(1, { message: 'Organisation name is required' }),
  email: z.string().email({ message: 'Invalid email' }),
  password: passwordSchema,
  terms: z.boolean().refine((v) => v, { message: 'Terms and privacy policy must be accepted' }),
});

export const userDetailsValidation = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  email: z.string().email({ message: 'Invalid email' }),
  currentPassword: z.string(),
});

export const userDetailsValidationForChangingEmail = userDetailsValidation.extend({
  currentPassword: z.string().min(1, { message: 'Current password is required' }),
});

export const changePasswordValidation = z
  .object({
    currentPassword: z.string().min(1, { message: 'Current password is required' }),
    newPassword: passwordSchema,
    newPasswordConfirmation: z.string(),
  })
  .refine((data) => data.newPassword === data.newPasswordConfirmation, {
    message: "Passwords don't match",
    path: ['newPasswordConfirmation'],
  });

export const emailPreferencesValidation = z.object({
  newsletter: z.boolean(),
});

export const createTeamValidation = z.object({
  name: z.string().min(1, { message: 'Team name is required' }),
  members: z.array(z.string().email({ message: 'Includes invalid emails' })),
});

export const changeTeamNameValidation = z.object({
  name: z.string().min(1, { message: 'Team name is required' }),
});

export const inviteMembersValidation = z.object({
  members: z.array(z.string().email({ message: 'Includes invalid emails' })).min(1, {
    message: 'At least one email is required',
  }),
  role: z.enum([Role.ADMIN, Role.MEMBER, Role.VIEWER]),
});

export const updateTeamMemberRoleValidation = z.object({
  role: z.enum([Role.ADMIN, Role.MEMBER, Role.VIEWER]),
});

export const createProjectValidation = z.object({
  name: z.string().min(1, { message: 'Project name is required' }),
});

export const renameProjectValidation = z.object({
  name: z.string().min(1, { message: 'Project name is required' }),
});

export const updateCompanyProfileValidation = z.object({
  entity_name: z.string().min(1, { message: 'Entity name is required' }),
  address: z.object({
    street: z.string().min(1, { message: 'Street is required' }),
    city: z.string().min(1, { message: 'City is required' }),
    zip_code: z.string().min(1, { message: 'Zip code is required' }),
    country: z.string().min(1, { message: 'Country is required' }),
  }),
  contact: z.object({
    technical: z
      .object({
        name: z.string().min(1, { message: 'Name is required' }),
        email: z.string().email({ message: 'Invalid email' }),
      })
      .optional(),
    billing: z
      .array(
        z.object({
          name: z.string().min(1, { message: 'Name is required' }),
          email: z.string().email({ message: 'Invalid email' }),
        })
      )
      .min(1, { message: 'At least one billing contact is required' }),
  }),
  vat_number: z.string().min(1, { message: 'VAT number is required' }),
});

export const verifyTOTPValidation = z.object({
  code: z.string().length(6, { message: 'Invalid code' }),
});

export const updatePasskeyValidation = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
});

export const createServiceAccountValidation = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  expires_at: z
    .object({
      day: z.coerce
        .number()
        .min(1, { message: 'Day is required' })
        .max(31, { message: 'Invalid day' }),
      month: z.coerce
        .number()
        .int()
        .min(1, { message: 'Month is required' })
        .max(12, { message: 'Invalid month' }),
      year: z.coerce.number().int().min(new Date().getFullYear(), { message: 'Year is required' }),
    })
    .optional(),
});

export const setupWebhooksValidation = z.object({
  callback_url: z.string().url({ message: 'Invalid URL' }),
  events: z.array(z.enum(['fleet_clearance_changed', 'fleet_geofence_notification'])),
  secret: z.string().optional(),
});

export const renameSimulatorValidation = z.object({
  name: z.string().min(1, { message: 'Simulator name is required' }),
});

export const updateProductWebsiteValidation = z.object({
  website: z.union([z.literal(''), z.string().url({ message: 'Invalid URL' })]),
});

export const updateLegalInfoValidation = z.object({
  terms_of_service: z.union([z.literal(''), z.string().url({ message: 'Invalid URL' })]),
  privacy_policy: z.union([z.literal(''), z.string().url({ message: 'Invalid URL' })]),
});
