import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@highmobility/console-ui-components';

import { WEBHOOK_EVENTS } from '@/constants';
import { WebhookEvent } from '@/types';

type EventsTableProps = {
  onChange: (events: WebhookEvent[]) => void;
  selectedEvents: WebhookEvent[];
  disabled?: boolean;
};

export function EventsTable(props: EventsTableProps) {
  const possibleEvents = Object.values(WEBHOOK_EVENTS);

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell>
            <div className="flex items-center gap-3 text-body-small">
              <Checkbox
                aria-label="Toggle all events"
                color="green"
                name="allEvents"
                checked={possibleEvents.every((event) => props.selectedEvents.includes(event))}
                onChange={(e) => {
                  if (e.currentTarget.checked) {
                    props.onChange(possibleEvents);
                  } else {
                    props.onChange([]);
                  }
                }}
                disabled={props.disabled}
              />
              <span className="text-white-60">Event</span>
            </div>
          </TableCell>
        </TableRow>
        {possibleEvents.map((event, i) => (
          <TableRow key={i}>
            <TableCell>
              <div className="flex items-center gap-3 text-body-small">
                <Checkbox
                  aria-label={`${event} event`}
                  color="green"
                  name={`events.${event}`}
                  checked={props.selectedEvents.some((e) => e === event)}
                  onChange={(e) => {
                    if (e.currentTarget.checked) {
                      props.onChange([...props.selectedEvents, event]);
                    } else {
                      props.onChange(props.selectedEvents.filter((e) => e !== event));
                    }
                  }}
                  disabled={props.disabled}
                />
                <span className="text-white-80">{event}</span>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
