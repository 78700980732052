import { generatePath, LoaderFunction, redirect } from 'react-router-dom';

import { mobxStore } from '@/store';
import { PathParams } from '@/hooks/useTypedParams';
import { ROUTE_PATHS } from '@/router/routes';

export const dashboardViewLoader: LoaderFunction = async (args) => {
  const { teamId } = args.params as PathParams<typeof ROUTE_PATHS.DASHBOARD_ALL>;
  const { teams, projects } = mobxStore;
  await teams.fetchTeams();
  if (teamId) {
    if (teams.allIds.has(teamId)) {
      teams.setActiveTeam(teamId);
      await projects.fetchProjects(teamId);
      return null;
    }

    return redirect(generatePath(ROUTE_PATHS.DASHBOARD_ALL));
  }

  if (teams.list.length > 0) {
    const teamToUse = teams.list.find((team) => team.is_default) || teams.list[0];

    return redirect(generatePath(ROUTE_PATHS.DASHBOARD_ALL, { teamId: teamToUse!.id }));
  }

  return null;
};
