import { Accordion, Close } from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { generatePath, Link, LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { fetchScenes, fetchSimulators } from '@/services/apiService';
import { PathParams, useTypedParams } from '@/hooks/useTypedParams';
import { ROUTE_PATHS } from '@/router/routes';
import { useMobx } from '@/hooks/useMobx';

import CreateSimulatorButton from './CreateSimulatorButton';
import { SimulatorsGrid } from './SimulatorsGrid';

export const loader = async (args: LoaderFunctionArgs) => {
  const params = args.params as PathParams<typeof ROUTE_PATHS.PROJECT_AUTH_OAUTH>;
  const [{ data: simulators }, { data: scenes }] = await Promise.all([
    fetchSimulators(params.teamId),
    fetchScenes(),
  ]);

  return { simulators, scenes };
};

export const ProjectSimulationView = observer(() => {
  const loaderData = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const { teamId, projectId } = useTypedParams<typeof ROUTE_PATHS.PROJECT_AUTH_STREAMING>();
  const { projects } = useMobx();
  const project = projects.byId[projectId];
  const [showFleetSimulators, setShowFleetSimulators] = useState(project?.type === 'fleet');
  const [showNonFleetSimulators, setShowNonFleetSimulators] = useState(
    project?.type === 'non_fleet'
  );
  const simulatorsQuery = useQuery({
    queryKey: ['simulators', teamId, projectId],
    queryFn: () => fetchSimulators(teamId).then(({ data }) => data),
    initialData: loaderData.simulators,
    staleTime: 1000,
  });

  return (
    <main className="flex h-full w-full">
      <div className="flex h-full">
        <div className="w-2 flex-shrink-0 bg-white-4" />
      </div>
      <div className="h-full w-full overflow-y-auto">
        <div className="flex items-center justify-between gap-2 bg-[#2B4247] px-4 py-2">
          <h5 className="text-heading-5">Simulation Studio</h5>
          <Link to={generatePath(ROUTE_PATHS.PROJECT, { teamId, projectId })}>
            <Close className="h-4 w-4 cursor-pointer text-white-40 transition-colors hover:text-white" />
          </Link>
        </div>
        <div className="w-full bg-black-40 px-4 py-3">
          <CreateSimulatorButton type="fleet" teamId={teamId} refetch={simulatorsQuery.refetch} />
        </div>
        <div className="flex flex-col">
          <Accordion
            className={cx({ 'opacity-50': project?.type !== 'fleet' })}
            title="Fleet consent"
            isOpen={showFleetSimulators}
            setIsOpen={setShowFleetSimulators}
            locked={project?.type !== 'fleet'}
          >
            <div className="px-2 py-4">
              <SimulatorsGrid
                teamId={teamId}
                simulators={simulatorsQuery.data.filter((simulator) => simulator.type === 'fleet')}
                scenes={loaderData.scenes}
                refetch={simulatorsQuery.refetch}
              />
            </div>
          </Accordion>
          <Accordion
            className={cx({ 'opacity-50': project?.type !== 'non_fleet' })}
            title="Non Fleet consent"
            isOpen={showNonFleetSimulators}
            setIsOpen={setShowNonFleetSimulators}
            locked={project?.type !== 'non_fleet'}
          >
            <div className="px-2 py-4">
              <SimulatorsGrid
                teamId={teamId}
                simulators={simulatorsQuery.data.filter((simulator) => simulator.type === 'driver')}
                scenes={loaderData.scenes}
                refetch={simulatorsQuery.refetch}
              />
            </div>
          </Accordion>
        </div>
      </div>
    </main>
  );
});
