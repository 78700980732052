import { cva, cx, VariantProps } from 'class-variance-authority';
import { HTMLAttributes, MouseEvent } from 'react';

const actionButton = cva(
  [
    'inline-flex',
    'items-center',
    'justify-center',
    'rounded',
    'transition-colors',
    'border',
    'p-3',
    'dark:bg-white-4',
    'dark:border-white-12',
    'dark:text-beige-100',
    'dark:hover:bg-white-8',
  ],
  {
    variants: {
      disabled: {
        true: ['cursor-not-allowed', 'dark:text-beige-100-shade', 'dark:border-white-4'],
      },
    },
    defaultVariants: {
      disabled: false,
    },
  }
);

export type ActionButtonProps = Omit<
  HTMLAttributes<HTMLButtonElement> & VariantProps<typeof actionButton>,
  'children'
> & {
  children?: (className: string) => JSX.Element;
  size?: 'small' | 'large';
};

export const ActionButton: React.FC<ActionButtonProps> = ({
  className = '',
  children,
  size,
  disabled = false,
  onClick,
  ...props
}) => {
  const _onClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (disabled) {
      return;
    }

    onClick?.(e);
  };
  return (
    <button className={cx(actionButton({ disabled }), className)} onClick={_onClick} {...props}>
      {children && children(size === 'small' ? 'w-4 h-4' : 'w-6 h-6')}
    </button>
  );
};
