import { Simulator, SimulatorScene } from '@/types';

import { SimulatorCard } from './SimulatorCard';

type SimulatorsGridProps = {
  teamId: string;
  simulators: Simulator[];
  scenes: SimulatorScene[];
  refetch: () => void;
};

export function SimulatorsGrid(props: SimulatorsGridProps) {
  if (props.simulators.length <= 0) {
    return (
      <div className="flex w-full flex-col gap-2 p-4">
        <div>Car simulators are waiting.</div>
        <div className="text-white-60">Create a new car simulator and start testing.</div>
      </div>
    );
  }

  return (
    <div
      className="grid w-full gap-x-2 gap-y-8"
      style={{
        gridTemplateColumns: 'repeat(auto-fill, minmax(min(260px, 100%), 1fr))',
      }}
    >
      {props.simulators.map((simulator, key) => (
        <SimulatorCard
          key={key}
          teamId={props.teamId}
          simulator={simulator}
          scene={props.scenes.find((s) => s.id === simulator.scene)}
          refetch={props.refetch}
        />
      ))}
    </div>
  );
}
