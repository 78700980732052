import { Button, CheckCircle, TextInput } from '@highmobility/console-ui-components';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';

import { AuthBackgroundIllustrations } from '@/components/atoms/AuthBackgroundIllustrations';
import { AuthHeader } from '@/components/atoms/AuthHeader';
import { GeneralTemplate } from '@/components/templates/general/GeneralTemplate';
import { PasswordInputWithHints } from '@/components/molecules/PasswordInputWithHints';
import { passwordSchema } from '@/services/zodService';
import { resetPassword } from '@/services/apiService';
import { ROUTE_PATHS } from '@/router/routes';

export const ResetPasswordView = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [step, setStep] = useState<'initial' | 'success' | 'error'>('initial');
  const validation = z
    .object({
      password: passwordSchema,
      confirmPassword: z.string(),
      token: z.string().min(1, { message: 'Token is required' }),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: "Passwords don't match",
      path: ['confirmPassword'],
    });

  const resetPasswordMutation = useMutation({
    mutationFn: (data: z.infer<typeof validation>) => {
      return resetPassword({
        password: data.password,
        password_confirmation: data.confirmPassword,
        token: data.token,
      });
    },
    onError: (e: any) => {
      console.error('Error submitting the form', e);
      const reusedPwError = e?.response?.data?.details?.password?.keyword === 'unique';

      if (reusedPwError) {
        return setError('password', {
          message: 'Password may not be reused',
        });
      }

      setStep('error');
    },
    onSuccess: () => {
      setStep('success');
      setTimeout(() => {
        navigate(ROUTE_PATHS.LOGIN);
      }, 3000);
    },
  });

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setError,
  } = useForm<z.infer<typeof validation>>({
    resolver: zodResolver(validation),
    defaultValues: {
      token,
    },
  });

  const onSubmit = handleSubmit((data) => resetPasswordMutation.mutate(data));

  return (
    <GeneralTemplate
      className="bg-beige-100"
      renderHeader={() => <AuthHeader type="login" />}
      renderIllustrations={() => <AuthBackgroundIllustrations />}
    >
      <main className="pb-header flex h-full w-full items-center justify-around">
        {step === 'initial' && (
          <form
            className="w-login-modal-md bg-dark-green-900 flex flex-col gap-6 rounded p-12"
            onSubmit={onSubmit}
          >
            <div>
              <h1 className="text-heading-1 mb-3">Reset password</h1>
              <p className="font-bold">Create a new password for your High mobility account</p>
            </div>
            <div className="flex flex-col gap-4">
              <PasswordInputWithHints
                inputProps={register('password')}
                watchedPassword={watch('password')}
                errorMessage={errors.password?.message}
                isDirty={!!dirtyFields.password}
              />
              <TextInput
                placeholder="Confirm your new password"
                {...register('confirmPassword')}
                error={errors.confirmPassword?.message}
                className="flex-1"
                type="password"
              />
            </div>
            <div className="flex justify-center">
              <Button type="submit">
                {resetPasswordMutation.isPending ? 'Loading' : 'Update password'}
              </Button>
            </div>
          </form>
        )}
        {step === 'success' && (
          <div className="w-login-modal-sm bg-dark-green-900 flex flex-col gap-6 rounded p-12">
            <h1 className="text-heading-1 flex items-center gap-3">
              <CheckCircle />
              <span>Success!</span>
            </h1>
            <p className="font-bold">
              Your password has been reset, redirecting in a few seconds...
            </p>
          </div>
        )}
        {step === 'error' && (
          <div className="w-login-modal-sm bg-dark-green-900 flex flex-col gap-6 rounded p-12">
            <h1 className="text-heading-1">Oops!</h1>
            <p className="font-bold">
              This reset password link has expired. Please go through the process again.
            </p>
            <div className="flex justify-center">
              <Link to={ROUTE_PATHS.FORGOT_PASSWORD}>
                <Button onClick={() => setStep('initial')}>Go back</Button>
              </Link>
            </div>
          </div>
        )}
      </main>
    </GeneralTemplate>
  );
};
