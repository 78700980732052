import { cva } from 'class-variance-authority';
import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { PropsWithChildren, useEffect } from 'react';

import Close from '../../assets/icons/Close.svg';
import { LinearDeterminateLoader } from '../atoms/LinearDeterminateLoader';

export type DialogProps = PropsWithChildren<{
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  title: string;
  flexWidth?: boolean;
  timeoutConfig?: {
    onTimeout: () => void;
    timeoutInMs: number;
  };
}>;

const containerClassName = cva(
  'bg-dark-green-900 w-11/12 max-w-full overflow-hidden rounded-[4px] m-auto',
  {
    variants: {
      flexWidth: {
        true: 'sm:w-auto sm:min-w-[600px] sm:max-w-[90%]',
        false: 'sm:w-[600px]',
      },
    },
  }
);

export const Dialog = (props: DialogProps) => {
  const { context, refs } = useFloating({
    open: props.isOpen,
    onOpenChange: props.setIsOpen,
  });

  const dismiss = useDismiss(context, {
    escapeKey: true,
    outsidePress: false,
  });

  const { getFloatingProps } = useInteractions([dismiss]);

  useEffect(() => {
    if (props.timeoutConfig) {
      const timeout = setTimeout(() => {
        props.timeoutConfig?.onTimeout();
      }, props.timeoutConfig.timeoutInMs);

      return () => clearTimeout(timeout);
    }
  }, [props.isOpen, JSON.stringify(props.timeoutConfig)]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!props.isOpen) {
    return null;
  }

  return (
    props.isOpen && (
      <FloatingPortal>
        <FloatingOverlay
          lockScroll
          className="z-50 flex items-center justify-center bg-black-40 backdrop-blur-sm"
        >
          <FloatingFocusManager context={context}>
            <div
              ref={refs.setFloating}
              {...getFloatingProps()}
              className={containerClassName({ flexWidth: !!props.flexWidth })}
            >
              <div>
                {props.timeoutConfig && (
                  <LinearDeterminateLoader durationInMs={props.timeoutConfig.timeoutInMs} />
                )}
                <div className="flex items-center justify-between gap-20 border-b-black-20 bg-white-4 px-6 py-2">
                  <h5 className="text-heading-5 text-white">{props.title}</h5>
                  <Close
                    className="h-5 max-h-5 w-5 max-w-5 cursor-pointer text-white text-white-40 opacity-40 transition-colors hover:text-white"
                    onClick={() => props.setIsOpen(false)}
                    aria-label="Close"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-6 p-6 text-body-regular text-white">
                {props.children}
              </div>
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      </FloatingPortal>
    )
  );
};
