import {
  AlertDialog,
  AlertDialogProps,
  TextInput,
  toast,
} from '@highmobility/console-ui-components';
import { generatePath, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { chooseImage } from '@/services/projectService';
import { createProject, fakeLoader } from '@/services/apiService';
import { createProjectValidation } from '@/services/zodService';
import { ProjectType, Region } from '@/types';
import { ROUTE_PATHS } from '@/router/routes';
import { useMobx } from '@/hooks/useMobx';

type CreateProjectDialogProps = {
  teamId: string;
  region: Region;
  type: ProjectType;
} & Pick<AlertDialogProps, 'isOpen' | 'setIsOpen'>;

export const CreateProjectDialog = (props: CreateProjectDialogProps) => {
  const navigate = useNavigate();
  const { teams, session } = useMobx();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<z.infer<typeof createProjectValidation>>({
    resolver: zodResolver(createProjectValidation),
  });
  const team = teams.byId[props.teamId];

  const createProjectMutation = useMutation({
    mutationFn: async (data: z.infer<typeof createProjectValidation>) => {
      return Promise.all([
        fakeLoader(),
        createProject(props.teamId, {
          name: data.name,
          region: props.region,
          type: props.type,
          image: chooseImage(session.user!.id, team!.projects_count || 0),
        }),
      ]);
    },
    onError: (e) => {
      console.error('Failed to create project', e);
      toast('Failed to create project. Please try again.', 'error');
      close();
    },
    onSuccess: async () => {
      await teams.fetchTeam(props.teamId);
      toast('Project is being created.', 'success');
      navigate(generatePath(ROUTE_PATHS.DASHBOARD_ALL, { teamId: props.teamId }));
      close();
    },
  });

  const close = () => {
    reset();
    props.setIsOpen(false);
  };

  const onSubmit = handleSubmit((data) => createProjectMutation.mutate(data));

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={close}
      title="Name Your Project"
      confirmButtonIntent="primary"
      confirmLabel={createProjectMutation.isPending ? 'Loading' : 'Create project'}
      cancelLabel="Cancel"
      confirmButtonDisabled={createProjectMutation.isPending}
      onConfirm={onSubmit}
      onCancel={close}
    >
      <form className="flex flex-col gap-6" onSubmit={onSubmit} autoComplete="false">
        <TextInput placeholder="My fleet app" {...register('name')} error={errors.name?.message} />
      </form>
    </AlertDialog>
  );
};
