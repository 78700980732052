import {
  Button,
  Dialog,
  DialogProps,
  toast,
  Toggle,
  Update,
} from '@highmobility/console-ui-components';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { fakeLoader, redeliverWebhook } from '@/services/apiService';
import type { ProjectInstance, WebhookDelivery as WebhookDeliveryType } from '@/types';

import { WebhookDelivery } from './WebhookDelivery';
import { WebhookDeliveryRequestDetails } from './WebhookDeliveryRequestDetails';
import { WebhookDeliveryResponseDetails } from './WebhookDeliveryResponseDetails';

type WebhookDeliveryDetailsDialogProps = Pick<DialogProps, 'isOpen' | 'setIsOpen'> & {
  webhookDelivery: WebhookDeliveryType;
  teamId: string;
  projectId: string;
  instance: ProjectInstance;
  webhookId: string;
};

export function WebhookDeliveryDetailsDialog(props: WebhookDeliveryDetailsDialogProps) {
  const tabs = [
    { label: 'Request', value: 'request' },
    { label: `Response (${props.webhookDelivery.status})`, value: 'response' },
  ] as const;
  const [tab, setTab] = useState<(typeof tabs)[number]['value']>('request');

  const redeliverWebhookMutation = useMutation({
    mutationFn: async () => {
      return Promise.all([
        fakeLoader(),
        redeliverWebhook(
          props.teamId,
          props.projectId,
          props.instance,
          props.webhookId,
          props.webhookDelivery.id
        ),
      ]);
    },
    onError: (e) => {
      console.error('Failed to redeliver webhook', e);
      toast('Failed to redeliver the webhook. Please try again.', 'error');
    },
    onSuccess: async () => {
      toast('Webhook redelivered!', 'success');
    },
  });

  return (
    <Dialog isOpen={props.isOpen} setIsOpen={props.setIsOpen} title="Webhook Delivery Details">
      <div>
        <p className="mb-3 text-white-60">Recent deliveries for:</p>
        <div className="h-[1px] w-full bg-white-4" />
        <WebhookDelivery
          withDetails={false}
          delivery={props.webhookDelivery}
          teamId={props.teamId}
          projectId={props.projectId}
          instance={props.instance}
          webhookId={props.webhookId}
        />
        <div className="h-[1px] w-full bg-white-4" />
        <div className="flex items-center gap-3 py-3">
          <Toggle className="flex-1" options={tabs} value={tab} onChange={setTab} />
          <Button
            aria-label="Redeliver webhook"
            intent="secondary"
            renderLeftIcon={(cn) => <Update className={cn} />}
            onClick={() => redeliverWebhookMutation.mutate()}
            disabled={redeliverWebhookMutation.isPending}
          />
        </div>
        {tab === 'request' ? (
          <WebhookDeliveryRequestDetails request={props.webhookDelivery.request} />
        ) : (
          <WebhookDeliveryResponseDetails response={props.webhookDelivery.response} />
        )}
      </div>
    </Dialog>
  );
}
