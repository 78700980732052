import { Button, Copy, TextInput } from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { useState } from 'react';

import { copyTextToClipboard } from '@/services/textFileService';

type StreamingTestFooterProps = {
  title: string;
  initialValue: string;
  placeholder: string;
  onClickTest: (arn: string) => void;
};

export function StreamingTestFooter(props: StreamingTestFooterProps) {
  const [resourceIdentifier, setResourceIdentifier] = useState(props.initialValue);
  const onClickCopy = () => copyTextToClipboard(resourceIdentifier);

  return (
    <div className="flex w-full flex-col gap-3">
      <h5 className="text-heading-5">{props.title}</h5>
      <div className="flex items-center gap-3">
        <TextInput
          className="flex-1"
          defaultValue={resourceIdentifier}
          onChange={(e) => setResourceIdentifier(e.currentTarget.value)}
          name="resourceIdentifier"
          placeholder={props.placeholder}
          renderRightIcon={() => (
            <Copy
              aria-label="Copy"
              onClick={onClickCopy}
              className="h-4 w-4 cursor-pointer text-white-60 transition-colors hover:text-white-80"
            />
          )}
        />
        <Button intent="secondary" onClick={() => props.onClickTest(resourceIdentifier)}>
          Test
        </Button>
        <Button
          intent="secondary"
          disabled
          className={cx({
            'opacity-0': props.initialValue !== resourceIdentifier,
          })}
        >
          Saved
        </Button>
      </div>
    </div>
  );
}
