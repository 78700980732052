import { ActionButton, Button, Plus, Snippet, toast } from '@highmobility/console-ui-components';
import { FC, Fragment, useState } from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { sort } from 'radash';
import { useMutation, useQuery } from '@tanstack/react-query';

import {
  createMQTTCertificate,
  fakeLoader,
  fetchMQTTCertificates,
  revokeMQTTCertificate,
} from '@/services/apiService';
import { downloadZipFile } from '@/services/textFileService';
import { MQTTCertificate, ProjectInstance } from '@/types';
import { usePermissions } from '@/hooks/usePermissions';

import { MQTTCertificateSnippetDialog } from './MQTTCertificateSnippetDialog';

export type MQTTCerificatesSectionProps = {
  teamId: string;
  projectId: string;
  instance: ProjectInstance;
  certificates: MQTTCertificate[];
  appId: string;
};

export const MQTTCerificatesSection: FC<MQTTCerificatesSectionProps> = observer((props) => {
  const { userHasPermission } = usePermissions(props.teamId);
  const hasManagePermission = userHasPermission(`projects.manage.${props.instance}.credentials`);
  const [showSnippetDialog, setShowSnippetDialog] = useState(false);
  const [selectedCertificateId, setSelectedCertificateId] = useState<string>();

  const certificatesQuery = useQuery({
    queryKey: ['mqttCertificates', props.teamId, props.projectId, props.instance],
    queryFn: async () => {
      try {
        const { data } = await fetchMQTTCertificates(props.teamId, props.projectId, props.instance);
        return sort(data, (item) => new Date(item.inserted_at).getTime(), true);
      } catch (e) {
        console.error('MQTTCertificatesSection::certificatesQuery failed', e);
        toast('Could not fetch certificates.', 'error');
        throw e;
      }
    },
    initialData: props.certificates,
    refetchInterval: (query) => {
      /* istanbul ignore next */
      const existingData = query.state.data ?? [];
      if (existingData.some((c) => ['creating', 'revoking'].includes(c.status))) {
        return 1500;
      }

      return false;
    },
    staleTime: 2000,
  });

  const createMQTTCertificateMutation = useMutation({
    mutationFn: async () => {
      const [{ data }] = await Promise.all([
        createMQTTCertificate(props.teamId, props.projectId, props.instance),
        fakeLoader(),
      ]);

      return data;
    },
    onError: (e: any) => {
      console.error('createMQTTCertificate::failed to create certificate', e);
      toast(e?.response?.data?.message ?? 'Something went wrong. Please try again.', 'error');
    },
    onSuccess: (data) => {
      downloadZipFile(
        `mqtt-client-certificates-${data.id}.zip`,
        [
          {
            fileName: 'private.pem.key',
            contents: data.client_private_key,
          },
          {
            fileName: 'certificate.pem.crt',
            contents: data.client_certificate,
          },
          {
            fileName: 'ca_certificates.pem',
            contents: data.ca_certificates,
          },
        ],
        {
          toastMessage: 'Certificate saved to your computer. Store it securely.',
        }
      );
      certificatesQuery.refetch();
    },
  });

  const revokeMQTTClientCertificateMutation = useMutation({
    mutationFn: async (input: { id: string }) => {
      const [{ data }] = await Promise.all([
        revokeMQTTCertificate(props.teamId, props.projectId, props.instance, input.id),
        fakeLoader(),
      ]);

      return data;
    },
    onError: (e: any) => {
      console.error('revokeMQTTClientCertificate::failed to revoke certificate', e);
      toast(e?.data?.message ?? 'Something went wrong. Please try again.', 'error');
    },
    onSuccess: () => {
      certificatesQuery.refetch();
      toast(`Revoking certificate`, 'success');
    },
  });

  return (
    <div className="flex flex-col gap-4">
      <h5 className="text-heading-5">Add MQTT Client Certificate</h5>
      <ActionButton
        aria-label="Add MQTT Client certificate"
        className="w-full"
        disabled={!hasManagePermission || createMQTTCertificateMutation.isPending}
        onClick={() => createMQTTCertificateMutation.mutate()}
      >
        {(cn) => <Plus className={cn} />}
      </ActionButton>
      <div
        className="grid gap-4 whitespace-nowrap"
        style={{
          gridTemplateColumns:
            '250px 1fr minmax(125px, 1fr) minmax(125px, 1fr) max-content max-content',
        }}
      >
        {certificatesQuery.data.map((certificate, i) => (
          <Fragment key={i}>
            <div className="">
              <div className="">Certificate ID</div>
              <div className="text-body-small">{certificate.id}</div>
            </div>
            <div className="">
              <div className="">Status</div>
              <div className="text-body-small">{certificate.status}</div>
            </div>
            <div>
              <div className="">Created on</div>
              <div className="text-body-small">
                {format(new Date(certificate.inserted_at), 'yyyy-MM-dd hh:mm:ss')}
              </div>
            </div>
            <div>
              <div className="">Expiration date</div>
              <div className="text-body-small">
                {format(new Date(certificate.validity_end_datetime), 'yyyy-MM-dd hh:mm:ss')}
              </div>
            </div>
            <ActionButton
              className="border-0"
              size="small"
              onClick={() => {
                setSelectedCertificateId(certificate.id);
                setShowSnippetDialog(true);
              }}
              aria-label="View certificate snippet"
            >
              {(cn) => <Snippet className={cn} />}
            </ActionButton>
            <Button
              intent="secondary"
              disabled={
                certificate.status === 'revoked' ||
                certificate.status === 'revoking' ||
                revokeMQTTClientCertificateMutation.isPending
              }
              onClick={() => revokeMQTTClientCertificateMutation.mutate({ id: certificate.id })}
            >
              Revoke
            </Button>
          </Fragment>
        ))}
      </div>
      {selectedCertificateId && (
        <MQTTCertificateSnippetDialog
          instance={props.instance}
          isOpen={showSnippetDialog}
          setIsOpen={setShowSnippetDialog}
          certificateId={selectedCertificateId}
          appId={props.appId}
        />
      )}
    </div>
  );
});
