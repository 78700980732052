import { Fragment } from 'react';

import { WebhookDelivery } from '@/types';

type WebhookDeliveryRequestDetailsProps = {
  request: WebhookDelivery['request'];
};

export function WebhookDeliveryRequestDetails(props: WebhookDeliveryRequestDetailsProps) {
  const getParsedBody = () => {
    try {
      return JSON.stringify(JSON.parse(props.request.body), null, 2);
    } catch (e) {
      return props.request.body;
    }
  };

  return (
    <div>
      <p className="pb-2 pt-3 font-bold">Headers</p>
      <div
        className="grid gap-x-5 break-all rounded border border-white-20 bg-white-4 px-4 py-3"
        style={{
          gridTemplateColumns: 'auto minmax(0,1fr)',
        }}
      >
        {Object.entries(props.request.headers).map(([key, value]) => (
          <Fragment key={key}>
            <div className="font-bold">{key}:</div>
            <div>{value}</div>
          </Fragment>
        ))}
      </div>
      <p className="pb-2 pt-3 font-bold">Payload</p>
      <div className="break-all rounded border border-white-20 bg-white-4 px-4 py-3">
        <pre className="text-wrap">{getParsedBody()}</pre>
      </div>
    </div>
  );
}
