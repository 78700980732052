import { Button, Plus, Tag } from '@highmobility/console-ui-components';
import { capitalize } from 'radash';
import { cx } from 'class-variance-authority';
import { FC, useState } from 'react';

import cardPlaceholder from '@/assets/images/stripe/placeholder.png';
import { PaymentCard } from '@/types';
import { STRIPE_CARD_BRANDS } from '@/constants';

import { EditCardDialog } from './EditCardDialog';

type CreditCardBoxProps = {
  teamId: string;
  disabled?: boolean;
  paymentCard?: PaymentCard;
};

export const CreditCardBox: FC<CreditCardBoxProps> = (props) => {
  const [showEditCardDialog, setShowEditCardDialog] = useState(false);
  const cardBrandData = props.paymentCard && STRIPE_CARD_BRANDS[props.paymentCard?.brand];

  return (
    <div
      className={cx({
        'cursor-not-allowed opacity-50': props.disabled,
      })}
    >
      {props.paymentCard ? (
        <div className="bg-white-4 flex items-center justify-between gap-4 rounded px-6 py-4">
          <img
            src={cardBrandData?.logo || cardPlaceholder}
            alt={cardBrandData?.name}
            className="h-8"
          />
          <div className="flex flex-1 items-center justify-start gap-4 px-4">
            <div className="text-heading-5">
              {cardBrandData?.name || capitalize(props.paymentCard.brand)} ending in{' '}
              {props.paymentCard.last4}
            </div>
            <div>
              Exp. date {props.paymentCard.exp_month}/
              {props.paymentCard.exp_year.toString().slice(-2)}
            </div>
            {props.paymentCard.exp_year < new Date().getFullYear() ||
            (props.paymentCard.exp_year === new Date().getFullYear() &&
              props.paymentCard.exp_month < new Date().getMonth() + 1) ? (
              <Tag className="ml-auto" type="error" withBorder round>
                Expired
              </Tag>
            ) : null}
          </div>
          <Button
            disabled={props.disabled}
            intent="tertiary"
            onClick={() => !props.disabled && setShowEditCardDialog(true)}
          >
            Edit
          </Button>
        </div>
      ) : (
        <div className="bg-white-4 border-white-12 flex items-center justify-center gap-4 rounded border">
          <Button
            className="w-full px-11 py-7"
            disabled={props.disabled}
            intent="tertiary"
            renderRightIcon={(cn) => <Plus className={cn} />}
            onClick={() => setShowEditCardDialog(true)}
          >
            Add credit card
          </Button>
        </div>
      )}
      <EditCardDialog
        isOpen={showEditCardDialog}
        setIsOpen={setShowEditCardDialog}
        teamId={props.teamId}
      />
    </div>
  );
};
