import { Lock, Notification } from '@highmobility/console-ui-components';

type NoPermissionNotificationProps = {
  className?: string;
};

export const NoPermissionNotification = (props: NoPermissionNotificationProps) => {
  return (
    <Notification
      className={props.className}
      renderLeftIcon={(c) => <Lock className={c} />}
      renderAdditionalContent={() => (
        <p className="text-white-60">
          To get access, please contact your organisation administrator.
        </p>
      )}
    >
      Your role doesn&apos;t have permissions to configure this. Some functions are disabled.
    </Notification>
  );
};
