import { Button, TextInput, toast } from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { changePassword, fakeLoader } from '@/services/apiService';
import { changePasswordValidation } from '@/services/zodService';
import { ROUTE_PATHS } from '@/router/routes';

type ChangePasswordFormProps = {
  className?: string;
};

export const ChangePasswordForm: FC<ChangePasswordFormProps> = (props) => {
  const changePasswordMutation = useMutation({
    mutationFn: async (data: z.infer<typeof changePasswordValidation>) => {
      return Promise.all([
        fakeLoader(),
        changePassword({
          currentPassword: data.currentPassword,
          password: data.newPassword,
          passwordConfirmation: data.newPasswordConfirmation,
        }),
      ]);
    },
    onError: (e) => {
      console.error('Error submitting the form', e);
      toast('Oops! Changes were not saved. Please try again!', 'error');
    },
    onSuccess: () => {
      toast('Password changed successfully', 'success');
      reset();
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<z.infer<typeof changePasswordValidation>>({
    resolver: zodResolver(changePasswordValidation),
  });
  const onSubmit = handleSubmit((data) => changePasswordMutation.mutate(data));

  return (
    <form className={cx(props.className, 'max-w-[800px]')} onSubmit={onSubmit}>
      <div className="mb-8 flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <TextInput
            type="password"
            label="Current password"
            placeholder="**********"
            {...register('currentPassword')}
            error={errors.currentPassword?.message}
          />
          <Link
            to={ROUTE_PATHS.FORGOT_PASSWORD}
            className="text-label-small text-white-60 block font-semibold hover:underline"
          >
            Forgot password?
          </Link>
        </div>
        <TextInput
          type="password"
          label="New password"
          placeholder="**********"
          {...register('newPassword')}
          error={errors.newPassword?.message}
        />
        <TextInput
          type="password"
          label="Repeat new password"
          placeholder="**********"
          {...register('newPasswordConfirmation')}
          error={errors.newPasswordConfirmation?.message}
        />
      </div>
      <div className="flex justify-end">
        <Button disabled={changePasswordMutation.isPending} type="submit">
          {changePasswordMutation.isPending ? 'Loading' : 'Save'}
        </Button>
      </div>
    </form>
  );
};
