import { cva } from 'class-variance-authority';
import { FC } from 'react';

import Alert from '../../assets/icons/Alert.svg';
import CheckCircle from '../../assets/icons/CheckCircle.svg';
import Info from '../../assets/icons/Info.svg';

export type ToastProps = {
  type: 'success' | 'error' | 'info';
  children: string;
};

const toastClasses = cva('flex max-w-[800px] items-center gap-2 rounded-[4px] border-b-4 p-6', {
  variants: {
    type: {
      success: ['border-b-success-500 bg-success-500-message text-success-500'],
      error: ['border-b-error-600 bg-error-600-message text-error-600'],
      info: ['border-b-notification-500 bg-notification-500-message text-notification-500'],
    },
  },
  defaultVariants: {
    type: 'success',
  },
});

export const Toast: FC<ToastProps> = (props) => {
  return (
    <div className={toastClasses(props)}>
      {props.type === 'success' && <CheckCircle className="min-w-6 min-h-6 h-6 w-6" />}
      {props.type === 'info' && <Info className="min-w-6 min-h-6 h-6 w-6" />}
      {props.type === 'error' && <Alert className="min-w-6 min-h-6 h-6 w-6" />}
      <span className="text-body-regular text-white">{props.children}</span>
    </div>
  );
};
