import { capitalize } from 'radash';
import { Dialog, DialogProps } from '@highmobility/console-ui-components';
import { FC } from 'react';

import { getBrandData, getPackagePriceOrDescription } from '@/services/packagesService';
import { Order } from '@/types';
import { useMobx } from '@/hooks/useMobx';

export type PriceBreakdownDialogProps = Pick<DialogProps, 'isOpen' | 'setIsOpen'> & {
  orders: Order[];
};

export const PriceBreakdownDialog: FC<PriceBreakdownDialogProps> = (props) => {
  const { marketData } = useMobx();

  return (
    <Dialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title={`Selected Brands & Data (${Object.keys(marketData.selectedDraftProperties).length})`}
    >
      <div className="flex flex-col gap-6">
        <div className="mb-6 flex flex-col gap-6 border-b border-white-8">
          {props.orders.map((order, i) => {
            const brandData = getBrandData(order.brand);

            return (
              <div key={i}>
                <h5 className="pb-2 text-heading-5 text-white">
                  {brandData?.name || capitalize(order.brand)} ({Object.keys(order.packages).length}
                  )
                </h5>
                <div className="flex items-center justify-between gap-3 border-y border-white-8 py-3 text-white-40">
                  <div>Package</div>
                  <div>Price</div>
                </div>
                {Object.entries(order.packages).map(([pckgId, pckg], j) => {
                  const packageData = marketData.byBrand?.[order.brand]?.find(
                    (p) => p.id === pckgId
                  );

                  return (
                    <div key={j} className="flex items-center justify-between py-[10px]">
                      <div>{packageData?.name}</div>
                      <div className="text-success-500">
                        {packageData
                          ? getPackagePriceOrDescription(
                              {
                                cents: pckg!.price?.amount || packageData.price?.cents || 0,
                                currency: 'euro',
                              },
                              packageData.price_description
                            )
                          : '-'}
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </Dialog>
  );
};
