import { Button, Close, TextInput, toast } from '@highmobility/console-ui-components';
import { generatePath, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { fakeLoader, updateProductInfo } from '@/services/apiService';
import { ROUTE_PATHS } from '@/router/routes';
import { updateProductWebsiteValidation } from '@/services/zodService';
import { useMobx } from '@/hooks/useMobx';
import { useTypedParams } from '@/hooks/useTypedParams';

export const ProductInfoWebsiteView = () => {
  const { teamId, projectId } = useTypedParams<typeof ROUTE_PATHS.PRODUCT_INFO_WEBSITE>();
  const { projects } = useMobx();
  const project = projects.byId[projectId];

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<z.infer<typeof updateProductWebsiteValidation>>({
    resolver: zodResolver(updateProductWebsiteValidation),
    defaultValues: {
      website: project?.product_info?.website,
    },
  });

  const updateProductInfoMutation = useMutation({
    mutationFn: async (data: z.infer<typeof updateProductWebsiteValidation>) => {
      return Promise.all([fakeLoader(), updateProductInfo(teamId, projectId, data)]);
    },
    onError: (e) => {
      console.error('Failed to update product info', e);
      toast('Someting went wrong. Please try again.', 'error');
      reset();
    },
    onSuccess: async () => {
      await projects.fetchProject(teamId, projectId);
      toast('Website updated!', 'success');
    },
  });

  const onSubmit = handleSubmit((data) => updateProductInfoMutation.mutate(data));

  return (
    <div className="h-full w-full">
      <div className="flex w-full items-center gap-3 bg-[#2B4247] px-4 py-2">
        <h5 className="text-heading-5">Website</h5>
        <Link
          to={generatePath(ROUTE_PATHS.PRODUCT_INFO, {
            teamId,
            projectId,
          })}
          className="ml-auto"
        >
          <Close className="h-4 w-4 cursor-pointer text-white-40 transition-colors hover:text-white" />
        </Link>
      </div>
      <form className="p-8" onSubmit={onSubmit} autoComplete="false">
        <div className="mb-8 flex flex-col gap-6">
          <TextInput
            label="Website URL"
            placeholder="e.g. www.mydatabusiness.com"
            {...register('website')}
            error={errors.website?.message}
          />
          <div>
            <Button disabled={updateProductInfoMutation.isPending} type="submit">
              Save
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
