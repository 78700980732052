import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from 'react-router-dom';
import { Toaster } from '@highmobility/console-ui-components';

import { AppConfig } from '@/config';
import { AppConfigContext } from '@/hooks/useAppConfig';
import { MobxContext } from '@/hooks/useMobx';
import { mobxStore } from '@/store';
import { router } from '@/router';

const queryClient = new QueryClient();

export const App = () => (
  <div className="bg-dark-green-900 text-body-regular min-h-screen text-white">
    <AppConfigContext.Provider value={AppConfig}>
      <MobxContext.Provider value={mobxStore}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
          <Toaster />
        </QueryClientProvider>
      </MobxContext.Provider>
    </AppConfigContext.Provider>
  </div>
);
