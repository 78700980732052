import { cx } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

export type TableBodyProps = PropsWithChildren<{
  className?: string;
}>;

export const TableBody = (props: TableBodyProps) => {
  return <tbody className={cx(props.className)}>{props.children}</tbody>;
};
