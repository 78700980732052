import { cx } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

export type TableCellProps = PropsWithChildren<{
  colSpan?: number;
  noPadding?: boolean;
  className?: string;
}>;

export const TableCell = (props: TableCellProps) => {
  return (
    <td
      colSpan={props.colSpan}
      className={cx(
        props.className,
        'text-body-regular text-left text-white first:rounded-l-[4px] last:rounded-r-[4px]',
        {
          'px-3 py-[10px]': !props.noPadding,
          'p-0': props.noPadding,
        }
      )}
    >
      {props.children}
    </td>
  );
};
