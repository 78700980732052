import {
  Arrow,
  Button,
  Info,
  Notification,
  Passkey,
  TextInput,
} from '@highmobility/console-ui-components';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { AUTH_REDIRECT_PARAM } from '@/router/middleware';
import { AuthBackgroundIllustrations } from '@/components/atoms/AuthBackgroundIllustrations';
import { AuthHeader } from '@/components/atoms/AuthHeader';
import { GeneralTemplate } from '@/components/templates/general/GeneralTemplate';
import { login } from '@/services/apiService';
import { LoginMFAVerifyDialog } from '@/components/views/login/LoginMFAVerifyDialog';
import { loginValidation } from '@/services/zodService';
import { LoginWithPasskey } from '@/components/views/login/LoginWithPasskey';
import { ROUTE_PATHS } from '@/router/routes';
import { useQueryParams } from '@/hooks/useQueryParams';

export const LoginView = () => {
  const redirectTo = new URLSearchParams(window.location.search).get(AUTH_REDIRECT_PARAM);
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const [showMFAVerifyDialog, setShowMFAVerifyDialog] = useState(false);
  const [showLoginWithPasskey, setShowLoginWithPasskey] = useState(false);
  const [passkeyError, setPasskeyError] = useState<string | null>(null);

  const onLoginSuccess = () => navigate(redirectTo || ROUTE_PATHS.DASHBOARD_ALL);
  const loginMutation = useMutation({
    mutationFn: (data: z.infer<typeof loginValidation>) => {
      return login(data, undefined, {
        invitation_token: queryParams?.invitation_token,
      });
    },
    onError: (error: any) => {
      if (error?.response?.status === 428) {
        setShowMFAVerifyDialog(true);
        return;
      }

      setError('email', { message: 'Invalid email or password' });
    },
    onSuccess: onLoginSuccess,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<z.infer<typeof loginValidation>>({
    resolver: zodResolver(loginValidation),
  });

  const onSubmit = handleSubmit(
    (data) => {
      loginMutation.mutate(data);
    },
    () => setPasskeyError(null)
  );

  const onClickLoginWithPasskey = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    reset();
    setPasskeyError(null);
    setShowLoginWithPasskey(true);
  };

  return (
    <GeneralTemplate
      className="bg-beige-100"
      renderHeader={() => <AuthHeader type="login" />}
      renderIllustrations={() => <AuthBackgroundIllustrations />}
    >
      <main className="pb-header flex h-full w-full items-center justify-around">
        {showLoginWithPasskey ? (
          <LoginWithPasskey
            setIsOpen={setShowLoginWithPasskey}
            onError={(error) => setPasskeyError(error)}
            onSuccess={onLoginSuccess}
            invitationToken={queryParams?.invitation_token}
          />
        ) : (
          <form
            className="w-login-modal-md bg-dark-green-900 flex flex-col gap-6 rounded px-6 py-8 sm:gap-8 sm:p-12"
            onSubmit={onSubmit}
          >
            <div>
              <h1 className="text-heading-1 mb-2 sm:mb-4">Welcome back</h1>
              <p className="font-bold">Sign in to access your High Mobility account</p>
            </div>
            {passkeyError && (
              <div className="">
                <Notification type="error" renderLeftIcon={() => <Info />}>
                  {passkeyError}
                </Notification>
              </div>
            )}
            <div className="flex flex-col gap-4">
              <TextInput
                label="Email"
                placeholder="joe@highmobility.com"
                {...register('email')}
                error={errors.email?.message}
                className="flex-1"
                type="email"
              />
              <TextInput
                label="Password"
                placeholder="8 characters minimum"
                {...register('password')}
                error={errors.password?.message}
                className="flex-1"
                type="password"
              />
              <Link
                to={ROUTE_PATHS.FORGOT_PASSWORD}
                className="text-label-small text-white-60 block font-semibold hover:underline"
              >
                Forgot password?
              </Link>
            </div>
            <div className="flex justify-between items-center">
              <Button
                intent="tertiary"
                renderLeftIcon={() => <Passkey />}
                onClick={onClickLoginWithPasskey}
                type="button"
              >
                Log in with passkey
              </Button>
              <Button type="submit" renderRightIcon={() => <Arrow />}>
                {loginMutation.isPending ? 'Loading' : 'Sign in'}
              </Button>
            </div>
          </form>
        )}
        {loginMutation.variables && (
          <LoginMFAVerifyDialog
            isOpen={showMFAVerifyDialog}
            setIsOpen={setShowMFAVerifyDialog}
            onSuccess={onLoginSuccess}
            email={loginMutation.variables.email}
            password={loginMutation.variables.password}
          />
        )}
      </main>
    </GeneralTemplate>
  );
};
