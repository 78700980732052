import { Copy, Dialog, DialogProps } from '@highmobility/console-ui-components';

import { copyTextToClipboard } from '@/services/textFileService';
import { ProjectInstance } from '@/types';

export type MQTTCertificateSnippetDialogProps = {
  isOpen: DialogProps['isOpen'];
  setIsOpen: DialogProps['setIsOpen'];
  instance: ProjectInstance;
  certificateId: string;
  appId: string;
};

export function MQTTCertificateSnippetDialog(props: MQTTCertificateSnippetDialogProps) {
  const hostName =
    props.instance === 'production' ? 'mqtt.high-mobility.com' : 'sandbox.mqtt.high-mobility.com';

  const command = 'mosquitto_sub';
  const args = `--disable-clean-session -h ${hostName} -p 8883 -q 1 \\
--cafile ca_certificates.pem \\
--cert certificate.pem.crt \\
--key private.pem.key  \\
-t '${props.instance === 'production' ? `live/level13/${props.appId}/#` : `sandbox/level13/${props.appId}/#`}' \\
-i '${props.certificateId}'`;

  const onClickCopy = () => copyTextToClipboard(`${command} ${args}`);

  return (
    <Dialog isOpen={props.isOpen} setIsOpen={props.setIsOpen} title="Snippet" flexWidth>
      <div className="flex gap-3" aria-label="Snippet">
        <div>{command}</div>
        <div
          className="whitespace-pre"
          dangerouslySetInnerHTML={{
            __html: args,
          }}
        />
        <div aria-label="Copy snippet" onClick={onClickCopy}>
          <Copy className="ml-auto h-4 w-4 flex-shrink-0 cursor-pointer text-white-60 transition-colors hover:text-white-80" />
        </div>
      </div>
    </Dialog>
  );
}
