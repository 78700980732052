import { AlertDialogProps } from '@highmobility/console-ui-components';
import { FC, useState } from 'react';

import { EnableMFADialog } from '@/components/views/account-settings/EnableMFADialog';
import { UpdateMFAVerifyDialog } from '@/components/views/account-settings/UpdateMFAVerifyDialog';

export type UpdateMFADialogProps = {
  isOpen: AlertDialogProps['isOpen'];
  setIsOpen: AlertDialogProps['setIsOpen'];
};

export const UpdateMFADialog: FC<UpdateMFADialogProps> = (props) => {
  const [showEnableMFADialog, setShowEnableMFADialog] = useState(false);
  const [MFASettings, setMFASettings] = useState<{
    id: string;
    url: string;
    secret: string;
    expires_at: number;
  } | null>(null);

  return (
    <>
      <UpdateMFAVerifyDialog
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        onSuccess={(settings) => {
          setMFASettings(settings);
          setShowEnableMFADialog(true);
          props.setIsOpen(false);
        }}
      />
      <EnableMFADialog
        isOpen={showEnableMFADialog}
        setIsOpen={setShowEnableMFADialog}
        settings={MFASettings}
      />
    </>
  );
};
