import {
  Button,
  Chevron,
  NavLink,
  ProjectStatusIcon,
  toast,
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { useMemo, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import { fakeLoader, submitProjectForReview } from '@/services/apiService';
import { Project } from '@/types';
import { PROJECT_STATUS } from '@/constants';
import { ProjectStatusBox } from '@/components/atoms/ProjectStatusBox';
import { useMobx } from '@/hooks/useMobx';
import { usePermissions } from '@/hooks/usePermissions';

type ProjectStatusDropdownProps = {
  project: Project;
};

export const ProjectStatusDropdown = (props: ProjectStatusDropdownProps) => {
  const { projects, ui } = useMobx();
  const [showStatusTooltip, setShowStatusTooltip] = useState(false);
  const { userHasPermission } = usePermissions(props.project.team_id);

  const submitForReviewMutation = useMutation({
    mutationFn: async () => {
      return Promise.all([
        fakeLoader(),
        submitProjectForReview(props.project.team_id, props.project.id),
      ]);
    },
    onError: (e: any) => {
      console.error('Failed to submit project for review', e);
      toast(e?.response?.data?.message || 'Something went wrong. Please try again.', 'error');
      setShowStatusTooltip(false);
    },
    onSuccess: async () => {
      projects.fetchProject(props.project.team_id, props.project.id);
      toast('Project has been submitted and is under review.', 'info');
      setShowStatusTooltip(false);
    },
    onSettled: () => {
      ui.setProjectTemplate({ projectSubmitted: true });
    },
  });

  const buttonText = useMemo(() => {
    if (submitForReviewMutation.isPending) {
      return 'Loading';
    }

    if (props.project.status === 'draft') {
      return 'Submit for review';
    }

    return 'Submit changes';
  }, [props.project.status, submitForReviewMutation.isPending]);

  return (
    <TooltipProvider open={showStatusTooltip} setOpen={setShowStatusTooltip}>
      <TooltipTrigger>
        <div onClick={() => setShowStatusTooltip((v) => !v)}>
          <NavLink
            renderLeftIcon={(cn) => (
              <ProjectStatusIcon className={cn} status={props.project.status} size="small" />
            )}
            renderRightIcon={(cn) => (
              <Chevron
                className={cx(cn, 'transition-transform', {
                  'rotate-180': showStatusTooltip,
                })}
              />
            )}
          >
            {PROJECT_STATUS[props.project.status]}
          </NavLink>
        </div>
      </TooltipTrigger>
      <Tooltip arrowCentered>
        <div className="border-dark-green-950 w-[480px] rounded p-3">
          <div className="text-uppercase-regular text-white-80 mb-1">GO LIVE!</div>
          <div className="text-body-regular text-white-60 mb-6">
            Submit application and get live data.
          </div>
          <div className="mb-6 flex flex-col gap-1">
            <ProjectStatusBox status="draft" active={props.project.status === 'draft'} />
            <ProjectStatusBox status="in_review" active={props.project.status === 'in_review'} />
            {props.project.status === 'published_with_changes' ? (
              <ProjectStatusBox
                status="published_with_changes"
                publishedAt={props.project.published_at}
                active={props.project.status === 'published_with_changes'}
              />
            ) : (
              <ProjectStatusBox
                status="published"
                publishedAt={props.project.published_at}
                active={props.project.status === 'published'}
              />
            )}
          </div>
          {userHasPermission('projects.edit') && (
            <div className="flex items-center justify-end">
              <Button
                intent="primary"
                onClick={() => submitForReviewMutation.mutate()}
                disabled={submitForReviewMutation.isPending}
              >
                {buttonText}
              </Button>
            </div>
          )}
        </div>
      </Tooltip>
    </TooltipProvider>
  );
};
