import { observer } from 'mobx-react-lite';
import { ScreenSeparator } from '@highmobility/console-ui-components';

import { FC, PropsWithChildren } from 'react';

export type DashboardSecondaryHeaderProps = PropsWithChildren;

export const DashboardSecondaryHeader: FC<DashboardSecondaryHeaderProps> = observer((props) => {
  return (
    <div className="mb-12">
      <div className="flex items-center py-6">{props.children}</div>
      <ScreenSeparator />
    </div>
  );
});
