import { cx } from 'class-variance-authority';
import { FC, PropsWithChildren } from 'react';

export type ProjectNavIconProps = PropsWithChildren<{
  active?: boolean;
}>;

export const ProjectNavIcon: FC<ProjectNavIconProps> = (props) => {
  return (
    <div
      className={cx(
        'border-black-20 text-white-60 hover:bg-white-4 h-10 w-10 shrink-0 cursor-pointer border-b p-2 transition-colors hover:text-white',
        {
          'bg-white-4': !!props.active,
        }
      )}
    >
      {props.children}
    </div>
  );
};
