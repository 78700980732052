import { cx } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

export type TableHeaderProps = PropsWithChildren<{
  className?: string;
}>;

export const TableHeader = (props: TableHeaderProps) => {
  return <thead className={cx(props.className)}>{props.children}</thead>;
};
