import { LoaderFunction, Outlet } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { mobxStore } from '@/store';
import { PathParams, useTypedParams } from '@/hooks/useTypedParams';
import { ROUTE_PATHS } from '@/router/routes';
import { useMobx } from '@/hooks/useMobx';

import { ProjectDetails } from './ProjectDetails';
import { ProjectHeader } from './ProjectHeader';
import { ProjectNav } from './ProjectNav';

export const loader: LoaderFunction = async (args) => {
  const params = args.params as PathParams<typeof ROUTE_PATHS.PROJECT>;
  const { teams, projects, billing } = mobxStore;

  const [team, project] = await Promise.all([
    teams.fetchTeam(params.teamId),
    projects.fetchProject(params.teamId, params.projectId),
    billing.fetchCompanyProfile(params.teamId),
  ]);

  if (!team || !project) {
    throw new Response('Project or team not found', { status: 404 });
  }

  await mobxStore.marketData.fetchMarketData(project.region, project.type);
  mobxStore.marketData.setSelectedPropertiesFromOrders(project?.orders || []);

  mobxStore.ui.reset();

  return null;
};

export const ProjectTemplate = observer(() => {
  const { teamId, projectId } = useTypedParams<typeof ROUTE_PATHS.PROJECT>();
  const { projects } = useMobx();
  const project = projects.byId[projectId]!;

  return (
    <div>
      <ProjectHeader project={project} />
      <div className="fixed bottom-0 left-0 right-0 top-10 flex justify-center">
        <ProjectNav teamId={teamId} projectId={projectId} />
        <div className="min-w-0 flex-1 shrink">
          <Outlet />
        </div>
        <ProjectDetails project={project} />
      </div>
    </div>
  );
});
