import {
  AlertDialog,
  AlertDialogProps,
  ControlRow,
  TextInput,
  toast,
} from '@highmobility/console-ui-components';
import { Role } from '@highmobility/console-role-matrix';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { fakeLoader, inviteMembers } from '@/services/apiService';
import { inviteMembersValidation } from '@/services/zodService';
import { useMobx } from '@/hooks/useMobx';

type InviteMembersDialogProps = { teamId: string } & Pick<AlertDialogProps, 'isOpen' | 'setIsOpen'>;

export const InviteMembersDialog = (props: InviteMembersDialogProps) => {
  const { teams } = useMobx();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm<z.infer<typeof inviteMembersValidation>>({
    resolver: zodResolver(inviteMembersValidation),
    defaultValues: {
      role: Role.VIEWER,
    },
  });

  const inviteMembersMutation = useMutation({
    mutationFn: async (data: z.infer<typeof inviteMembersValidation>) => {
      return Promise.all([fakeLoader(), inviteMembers(props.teamId, data)]);
    },
    onError: (e) => {
      console.error('Failed to invite members', e);
      toast('Failed to invite members. Please try again.', 'error');
      props.setIsOpen(false);
      reset();
    },
    onSuccess: async () => {
      await teams.fetchTeam(props.teamId);
      toast('Invitation emails sent!', 'success');
      reset();
      props.setIsOpen(false);
    },
  });

  const onSubmit = handleSubmit((data) => inviteMembersMutation.mutate(data));

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title="Invite New Members"
      confirmButtonIntent="primary"
      cancelLabel="Cancel"
      onConfirm={onSubmit}
      onCancel={() => props.setIsOpen(false)}
      confirmLabel={inviteMembersMutation.isPending ? 'Loading' : 'Invite'}
      confirmButtonDisabled={inviteMembersMutation.isPending}
    >
      <div className="flex flex-col gap-4">
        <TextInput
          withTags
          textarea
          label="Enter emails"
          placeholder="Enter here the email addresses of those you would like on your team"
          {...register('members')}
          error={errors.members?.[0]?.message || errors.members?.message}
        />
        <div className="flex flex-col gap-2">
          <p className="text-body-regular font-bold">Member role</p>
          <div>
            <ControlRow
              className="py-2 pl-0"
              label="Admin"
              type="radio"
              layout="row"
              transparent
              name="role"
              onChange={() => setValue('role', Role.ADMIN)}
              defaultChecked={getValues('role') === Role.ADMIN}
            />
            <ControlRow
              className="py-2 pl-0"
              label="Member"
              type="radio"
              layout="row"
              transparent
              name="role"
              onChange={() => setValue('role', Role.MEMBER)}
              defaultChecked={getValues('role') === Role.MEMBER}
            />
            <ControlRow
              className="py-2 pl-0"
              label="Viewer"
              type="radio"
              layout="row"
              transparent
              name="role"
              onChange={() => setValue('role', Role.VIEWER)}
              defaultChecked={getValues('role') === Role.VIEWER}
            />
          </div>
          <p className="text-body-regular text-white-40">
            Selected role will be assigned to all invites.
          </p>
        </div>
      </div>
    </AlertDialog>
  );
};
