import { cva, cx } from 'class-variance-authority';

const toggleButton = cva('flex justify-center items-center p-3 whitespace-nowrap flex-1', {
  variants: {
    active: {
      true: 'bg-black-20 text-white-80',
      false: 'bg-white-12 text-white-20',
    },
    disabled: {
      true: 'cursor-not-allowed',
      false: 'cursor-pointer',
    },
  },
});

export type ToggleProps<TOptions extends ReadonlyArray<{ label: string; value: string }>> = {
  value: TOptions[number]['value'];
  options: TOptions;
  onChange?: (value: TOptions[0]['value'] | TOptions[1]['value']) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
};

export function Toggle<TOptions extends ReadonlyArray<{ label: string; value: string }>>(
  props: ToggleProps<TOptions>
) {
  return (
    <div
      className={cx(
        'inline-flex overflow-hidden rounded text-body-small',
        {
          'w-full': props.fullWidth,
        },
        props.className
      )}
    >
      <div
        aria-label={props.options[0].label}
        onClick={() => !props.disabled && props.onChange?.(props.options[0].value)}
        className={toggleButton({
          active: props.value === props.options[0].value,
          disabled: !!props.disabled,
        })}
      >
        {props.options[0].label}
      </div>
      <div
        aria-label={props.options[1].label}
        onClick={() => !props.disabled && props.onChange?.(props.options[1].value)}
        className={toggleButton({
          active: props.value == props.options[1].value,
          disabled: !!props.disabled,
        })}
      >
        {props.options[1].label}
      </div>
    </div>
  );
}
