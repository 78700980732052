import {
  Deprecated,
  Info,
  ProjectStatusIcon,
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
} from '@highmobility/console-ui-components';
import { FC, PropsWithChildren, useState } from 'react';
import { observer } from 'mobx-react-lite';

export type CategoryHeaderProps = {
  status: 'draft' | 'in_review' | 'published' | 'deprecated';
  propertyCount: number;
};

export const CategoryHeader: FC<PropsWithChildren<CategoryHeaderProps>> = observer((props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div className="flex items-center gap-3 border-t border-black-20 bg-black-8 px-4 py-2">
      {props.status === 'deprecated' ? (
        <Deprecated className="h-4 w-4 flex-shrink-0" />
      ) : (
        <ProjectStatusIcon status={props.status} />
      )}
      <div className="flex items-center gap-2">
        <b>{props.children}</b>
        <span className="text-white-60"> ({props.propertyCount})</span>
        {props.status === 'deprecated' && (
          <TooltipProvider open={tooltipOpen} setOpen={setTooltipOpen} useHover>
            <TooltipTrigger>
              <Info className="rotate-180 transition-colors hover:text-white" />
            </TooltipTrigger>
            <Tooltip>
              <div className="flex w-60 flex-col gap-1 text-label-regular">
                Deprecated data, properties will become unavailable.
              </div>
            </Tooltip>
          </TooltipProvider>
        )}
      </div>
    </div>
  );
});
