import {
  Accordion,
  AccordionContent,
  BrandLink,
  Car,
  Info,
} from '@highmobility/console-ui-components';
import { capitalize } from 'radash';
import { cx } from 'class-variance-authority';
import { FC, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';

import { formatPrice } from '@/services/currencyService';
import { getBrandData } from '@/services/packagesService';
import { NoneSelected } from '@/components/atoms/NoneSelected';
import { Order } from '@/types';
import { ROUTE_PATHS } from '@/router/routes';
import { usePriceCalculations } from '@/hooks/usePriceCalculations';

import { PriceBreakdownDialog } from './PriceBreakdownDialog';

export type SelectedDataAccordionProps = {
  teamId: string;
  projectId: string;
  orders: Order[];
};

export const SelectedDataAccordion: FC<SelectedDataAccordionProps> = (props) => {
  const [showPriceBreakdown, setShowPriceBreakdown] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState<Order[] | null>(null);
  const [accordionIsOpen, setAccordionIsOpen] = useState(true);
  const { draftOrdersWithPublishedPrices } = usePriceCalculations(props.orders);

  const showOrderDetails = (orders: Order[] | null) => {
    setSelectedOrders(orders);
    setShowPriceBreakdown(!!orders);
  };

  return (
    <>
      <Accordion
        title="Selected Brands & Data"
        renderSubtitle={(cn) =>
          props.orders.length > 0 ? (
            <div
              aria-label="Show price breakdown"
              onClick={(e) => {
                e.stopPropagation();
                showOrderDetails(draftOrdersWithPublishedPrices);
              }}
            >
              <Info className={cx(cn, 'rotate-180 transition-colors hover:text-white')} />
            </div>
          ) : null
        }
        isOpen={accordionIsOpen}
        setIsOpen={setAccordionIsOpen}
      >
        {props.orders.length <= 0 ? (
          <AccordionContent>
            <NoneSelected />
          </AccordionContent>
        ) : (
          draftOrdersWithPublishedPrices.map((order, i) => {
            const brandData = getBrandData(order.brand);
            const totalPrice = formatPrice(
              Object.values(order.packages).reduce((acc, pckg) => {
                return acc + (pckg!.price?.amount ?? 0);
              }, 0)
            );

            return (
              <Link
                to={generatePath(ROUTE_PATHS.PROJECT_DATA_BRAND, {
                  teamId: props.teamId,
                  projectId: props.projectId,
                  brand: order.brand,
                })}
                key={i}
              >
                <BrandLink
                  label={brandData?.name || capitalize(order.brand)}
                  renderLogo={(cn) => {
                    if (!brandData?.logo) return <Car className={cn} />;

                    return <brandData.logo className={cn} />;
                  }}
                  secondaryLabel={`(${Object.values(order.packages).flat().length})`}
                  renderPrice={(cn) => (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        showOrderDetails([order]);
                      }}
                      className={cn}
                    >
                      {totalPrice}
                    </div>
                  )}
                />
              </Link>
            );
          })
        )}
      </Accordion>
      <PriceBreakdownDialog
        isOpen={showPriceBreakdown}
        setIsOpen={() => showOrderDetails(null)}
        orders={selectedOrders || []}
      />
    </>
  );
};
