import { cx } from 'class-variance-authority';
import { useMemo } from 'react';

export type AvatarProps = {
  name: string;
  imgSrc?: string;
  className?: string;
};

export const Avatar = (props: AvatarProps) => {
  const initials = useMemo(() => {
    return props.name
      .toUpperCase()
      .split(' ')
      .slice(0, 2)
      .map((n) => n[0] || '')
      .join('');
  }, [props.name]);

  return (
    <div
      className={cx(
        props.className,
        'bg-beige-100 min-w-10 min-h-10 flex h-10 w-10 items-center justify-center rounded-full'
      )}
    >
      <div className="text-body-regular text-dark-green-900 font-bold uppercase">{initials}</div>
    </div>
  );
};
