import {
  AlertDialogProps,
  Button,
  Close,
  Dialog,
  DialogProps,
  TextInput,
} from '@highmobility/console-ui-components';
import { Fragment, useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import {
  ApplicationSinkSettings,
  KinesisSinkSettings,
  ProjectInstance,
  S3SinkSettings,
} from '@/types';
import { fakeLoader, testStreamingSettings } from '@/services/apiService';

import { StreamingTestDialogSuccess } from './StreamingTestDialogSuccess';

export type StreamingTestDialogProps = {
  isOpen: AlertDialogProps['isOpen'];
  setIsOpen: AlertDialogProps['setIsOpen'];
  onSave: (newSettings: ApplicationSinkSettings) => Promise<void>;
  teamId: string;
  projectId: string;
  instance: ProjectInstance;
  appId: string;
  type: KinesisSinkSettings['type'] | S3SinkSettings['type'];
  initialValue?: string;
};

export function StreamingTestDialog(props: StreamingTestDialogProps) {
  const [timeoutConfig, setTimeoutConfig] = useState<DialogProps['timeoutConfig']>();
  const [testSecondsRemaining, setTestSecondsRemaining] = useState<number>();
  const [testingId, setTestingId] = useState<number | null>(null);
  const [saving, setSaving] = useState(false);
  const [resourceIdentifier, setResourceIdentifier] = useState(props.initialValue ?? '');
  const [stage, setStage] = useState<'initial' | 'success' | 'error'>('initial');
  const [errorMessage, setErrorMessage] = useState<string>();

  const startTesting = (resourceIdentifierToTest: string) => {
    const testLengthInMs = 30000;
    const newTestingId = new Date().getTime();
    setTestingId(newTestingId);
    setTimeoutConfig({
      timeoutInMs: testLengthInMs,
      onTimeout: () => {
        stopTesting();
        setErrorMessage('Testing timed out. Please try again.');
        setStage('error');
      },
    });
    setTestSecondsRemaining(testLengthInMs / 1000);

    if (props.type === 's3') {
      testStreamingSettingsMutation.mutate({
        settings: { type: 's3', options: { bucket_name: resourceIdentifierToTest } },
        testingId: newTestingId,
      });
    }

    if (props.type === 'kinesis') {
      testStreamingSettingsMutation.mutate({
        settings: { type: 'kinesis', options: { stream_arn: resourceIdentifierToTest } },
        testingId: newTestingId,
      });
    }
  };

  const stopTesting = () => {
    setTestingId(null);
    setTestSecondsRemaining(undefined);
    setTimeoutConfig(undefined);
  };

  const testStreamingSettingsMutation = useMutation({
    mutationFn: async (input: {
      settings: S3SinkSettings | KinesisSinkSettings;
      testingId: number | null;
    }) => {
      const [{ data }] = await Promise.all([
        testStreamingSettings(props.teamId, props.projectId, props.instance, input.settings),
        fakeLoader(),
      ]);

      return data;
    },
    onError: (e: any, variables) => {
      if (!testingId || testingId !== variables.testingId) {
        return;
      }

      stopTesting();
      console.error('SteamingTestDialog::testing failed', e);
      setErrorMessage(
        e?.response?.data?.message ??
          e?.response?.data?.error_message ??
          'Something went wrong. Please try again.'
      );
      setStage('error');
    },
    onSuccess: async (data, variables) => {
      if (!testingId || testingId !== variables.testingId) {
        return;
      }

      stopTesting();
      if ('s3_result' in data) {
        if (data.s3_result.status === 'ok') {
          setStage('success');
          return;
        }

        setErrorMessage(data.s3_result.message);
        setStage('error');
      }

      if ('kinesis_result' in data) {
        if (data.kinesis_result.status === 'ok') {
          setStage('success');
          return;
        }

        setErrorMessage(data.kinesis_result.error_message);
        setStage('error');
      }
    },
  });

  const onClickTest = () => {
    if (testingId) {
      stopTesting();
      return;
    }

    startTesting(resourceIdentifier);
  };

  const onClickSave = async () => {
    setSaving(true);
    if (props.type === 's3') {
      await props.onSave({
        app_id: props.appId,
        sink: { type: 's3', options: { bucket_name: resourceIdentifier } },
      });
    }

    if (props.type === 'kinesis') {
      await props.onSave({
        app_id: props.appId,
        sink: { type: 'kinesis', options: { stream_arn: resourceIdentifier } },
      });
    }

    props.setIsOpen(false);
    setSaving(false);
  };

  useEffect(() => {
    if (!props.isOpen) {
      stopTesting();
      setStage('initial');
      setErrorMessage(undefined);
      setSaving(false);
      setResourceIdentifier('');
      return;
    }

    setResourceIdentifier(props.initialValue ?? '');
    if (props.initialValue) {
      startTesting(props.initialValue);
    }
  }, [props.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!testingId) {
      return;
    }

    const interval = setInterval(() => {
      setTestSecondsRemaining((prev) => prev && prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [testingId]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title={props.type === 's3' ? 'AWS S3 bucket' : 'Kinesis'}
      timeoutConfig={timeoutConfig}
    >
      <Fragment key={props.type}>
        {stage === 'initial' && (
          <div className="flex flex-col gap-3">
            <p>
              {props.type === 's3'
                ? 'Switch to AWS S3 bucket. Saving and updating will have an effect in your vehicle data updates.'
                : 'Switch to Kinesis. Saving and updating will have an effect in your vehicle data updates.'}
            </p>
            <h5 className="font-bold">Test to complete update</h5>
            <div className="flex flex-col gap-2">
              <TextInput
                disabled={!!testingId}
                onChange={(e) => setResourceIdentifier(e.currentTarget.value)}
                defaultValue={resourceIdentifier}
                name="resourceIdentifier"
                placeholder={props.type === 's3' ? 'Enter bucket name' : 'Enter stream ARN'}
              />

              {!!testingId && (
                <p className="text-label-small text-[#676C73]">
                  Time remaining {testSecondsRemaining}
                </p>
              )}
            </div>
            <div className="flex items-center justify-end gap-2">
              <Button intent="primary" disabled={!resourceIdentifier} onClick={onClickTest}>
                {!!testingId ? 'Stop' : 'Test'}
              </Button>
              <Button intent="secondary" disabled={!!testingId} onClick={onClickSave}>
                Save
              </Button>
            </div>
          </div>
        )}
        {stage === 'success' && (
          <StreamingTestDialogSuccess
            testResponse={testStreamingSettingsMutation.data!}
            saving={saving}
            onClickSave={onClickSave}
          />
        )}
        {stage === 'error' && (
          <div className="flex flex-col gap-3">
            <div className="flex w-full items-center justify-center gap-1">
              <h5 className="text-heading-5 text-error-600">Integration failed</h5>
              <Close className="text-error-600" />
            </div>
            <p className="font-bold">Error message:</p>
            <p>{errorMessage}</p>
            <div className="flex items-center justify-end gap-2">
              <Button intent="primary" onClick={() => props.setIsOpen(false)}>
                Close
              </Button>
              <Button intent="secondary" onClick={() => setStage('initial')}>
                Retry
              </Button>
            </div>
          </div>
        )}
      </Fragment>
    </Dialog>
  );
}
