import { Button, Dialog, DialogProps } from '@highmobility/console-ui-components';
import { FC, useMemo } from 'react';
import { throttle } from 'radash';
import { UseFormReturn } from 'react-hook-form';
import { UseMutationResult } from '@tanstack/react-query';

import { MFACode } from '@/components/molecules/MFACode';

export type MFAVerifyLoginDialogProps = {
  isOpen: DialogProps['isOpen'];
  setIsOpen: DialogProps['setIsOpen'];
  form: UseFormReturn<{ code: string }, any, { code: string }>;
  mutation: UseMutationResult<any, any, { code: string }, unknown>;
  onOpenRecoveryDialog: () => void;
};

export const MFAVerifyLoginDialog: FC<MFAVerifyLoginDialogProps> = (props) => {
  const onSubmit = useMemo(
    () => {
      return throttle(
        { interval: 1000 },
        props.form.handleSubmit((data) => {
          props.mutation.mutate(data);
        })
      );
    },
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onChangeCode = (value: string) => {
    if (value.length === 6) {
      props.form.setValue('code', value);
      onSubmit();
    }
  };

  return (
    <Dialog isOpen={props.isOpen} setIsOpen={props.setIsOpen} title="Two-factor authentication">
      <div className="flex flex-col gap-8">
        <div>
          <h1 className="text-heading-1 pb-3">Two-factor authentication</h1>
          <p className="font-bold">
            Open your authentication app and enter the authentication code for for High Mobility
            account.
          </p>
        </div>
        <div>
          <form onSubmit={onSubmit}>
            <MFACode onChange={onChangeCode} error={props.form.formState.errors.code?.message} />
          </form>
        </div>
        <div className="flex justify-center">
          <Button intent="primary" onClick={onSubmit} disabled={props.mutation.isPending}>
            {props.mutation.isPending ? 'Verifying...' : 'Verify'}
          </Button>
        </div>
        <p className="text-white-60 font-bold">
          Can&apos;t access your authentication app?{' '}
          <span
            onClick={() => props.onOpenRecoveryDialog()}
            className="font-bold text-white hover:cursor-pointer hover:underline"
          >
            Enter a two-factor recovery code
          </span>{' '}
          or{' '}
          <a
            href="https://www.high-mobility.com/support"
            target="_blank"
            rel="noreferrer"
            className="font-bold text-white hover:cursor-pointer hover:underline"
          >
            contact support
          </a>{' '}
          to recover your account.
        </p>
      </div>
    </Dialog>
  );
};
