import { capitalize } from 'radash';
import { cx } from 'class-variance-authority';
import { format } from 'date-fns';
import { Tag } from '@highmobility/console-ui-components';

import { Simulator, SimulatorScene } from '@/types';
import { SIMULATORS_PATH } from '@/constants';

import { SimulatorCardDropdown } from './SimulatorCardDropdown';

type SimulatorCardProps = {
  teamId: string;
  simulator: Simulator;
  scene?: SimulatorScene;
  refetch: () => void;
};

export function SimulatorCard(props: SimulatorCardProps) {
  const referrer = encodeURIComponent(window.location.href);
  const link = `${SIMULATORS_PATH}/${props.teamId}/${props.simulator.vin}?referrer=${referrer}`;
  const art = props.scene?.art?.[0];

  return (
    <div className="flex flex-col gap-2">
      <div className="overflow-hidden rounded-[4px] border border-white-12">
        <a target="_blank" href={link} rel="noreferrer">
          <div className={cx('relative border-b border-white-12')}>
            <div className="relative flex h-[230px] max-h-[230px] min-h-[230px] w-full items-center justify-center overflow-hidden transition-opacity hover:opacity-80">
              <div
                className="absolute inset-0"
                style={{
                  background:
                    'linear-gradient(-319deg, rgba(0, 0, 0, 75%) 0%, rgba(0, 0, 0, 0) 100%)',
                }}
              />
              <img
                src={`${SIMULATORS_PATH}${art}`}
                className="h-full w-full object-none object-top"
              />
            </div>
          </div>
        </a>
        <div className="flex h-14">
          <div className="flex h-full w-full items-center justify-center border-x border-white-12">
            Created on {format(new Date(props.simulator.created_at), 'dd.MM.yyyy')}
          </div>
          <div className="flex items-center justify-center">
            <SimulatorCardDropdown
              teamId={props.teamId}
              simulator={props.simulator}
              simulatorLink={link}
              refetch={props.refetch}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <h5 className="text-heading-5 text-beige-100">{props.simulator.name}</h5>
        <div className="flex items-center gap-1">
          <Tag type="light" round>
            {capitalize(props.simulator.type)}
          </Tag>
        </div>
      </div>
    </div>
  );
}
