import { AlertDialog, AlertDialogProps } from '@highmobility/console-ui-components';

type SwitchToLegacyDialogProps = {
  isOpen: AlertDialogProps['isOpen'];
  setIsOpen: AlertDialogProps['setIsOpen'];
};

export function SwitchToLegacyDialog(props: SwitchToLegacyDialogProps) {
  const onConfirm = () => {
    if (window.LEGACY_CONSOLE_ENDPOINT) {
      window.open(window.LEGACY_CONSOLE_ENDPOINT, '_blank');
    }
  };

  return (
    <AlertDialog
      title="Switch to legacy"
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      onCancel={() => {
        props.setIsOpen(false);
      }}
      onConfirm={onConfirm}
      confirmLabel={'Open'}
      cancelButtonVisible={false}
    >
      <p className="text-label-regular text-white-60">
        Legacy will open a new window and require log-in
      </p>
    </AlertDialog>
  );
}
