import { PropsWithChildren } from 'react';

type ProjectSecondaryMenuProps = PropsWithChildren;

export const ProjectSecondaryMenu = (props: ProjectSecondaryMenuProps) => {
  return (
    <div className="h-full flex">
      <div className="bg-white-4 w-2 flex-shrink-0" />
      <div className="flex h-full w-[300px] flex-col border-r border-black-20">
        {props.children}
      </div>
    </div>
  );
};
