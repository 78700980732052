import { ActionButton, Arrow, ScreenSeparator } from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { LoaderFunction, redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Roles } from '@highmobility/console-role-matrix';

import { ChangeTeamNameForm } from '@/components/views/team-settings/ChangeTeamNameForm';
import { DashboardHeader } from '@/components/organisms/DashboardHeader';
import { DashboardSecondaryHeader } from '@/components/organisms/DashboardSecondaryHeader';
import { DeleteTeamSection } from '@/components/views/team-settings/DeleteTeamSection';
import { GeneralTemplate } from '@/components/templates/general/GeneralTemplate';
import { MembersSection } from '@/components/views/team-settings/MembersSection';
import { mobxStore } from '@/store';
import { NoPermissionNotification } from '@/components/molecules/NoPermissionNotification';
import { OnlyOwnerCanConfigureNotification } from '@/components/molecules/OnlyOwnerCanConfigureNotification';
import { PathParams, useTypedParams } from '@/hooks/useTypedParams';
import { ROUTE_PATHS } from '@/router/routes';
import { useMobx } from '@/hooks/useMobx';
import { useNavigateBack } from '@/hooks/useNavigateBack';

import { CompanyProfileSection } from './CompanyProfileSection';
import { CreditCardBox } from './CreditCardBox';

export const loader: LoaderFunction = async (args) => {
  const params = args.params as PathParams<typeof ROUTE_PATHS.TEAM_SETTINGS>;
  const { teams, billing } = mobxStore;
  const [team] = await Promise.all([
    teams.fetchTeam(params.teamId),
    billing.fetchCompanyProfile(params.teamId),
  ]);
  if (!team) {
    return redirect(ROUTE_PATHS.DASHBOARD_ALL);
  }

  return null;
};

export const TeamSettingsView = observer(() => {
  const { teams, session, billing } = useMobx();
  const { teamId } = useTypedParams<typeof ROUTE_PATHS.TEAM_SETTINGS>();
  const activeTeam = teams.byId[teamId];
  const activeTeamRole = activeTeam?.members.find((member) => member.id === session.user?.id)?.role;
  const canEditTeam = activeTeamRole && Roles.has(activeTeamRole, 'teams.edit');
  const canManageUsers = activeTeamRole && Roles.has(activeTeamRole, 'teams.manage.users');
  const canEditBilling = activeTeamRole && Roles.has(activeTeamRole, 'teams.edit.billing');
  const navigateBack = useNavigateBack(ROUTE_PATHS.DASHBOARD_ALL);

  return (
    <GeneralTemplate renderHeader={() => <DashboardHeader />}>
      <DashboardSecondaryHeader>
        <div className="flex items-center gap-6">
          <div onClick={navigateBack}>
            <ActionButton>{(cn) => <Arrow className={cx('rotate-180', cn)} />}</ActionButton>
          </div>
          <div className="flex items-center gap-1">
            <h3 className="text-heading-3">{activeTeam?.name || ''}</h3>
            <h4 className="text-heading-4">Settings</h4>
          </div>
        </div>
      </DashboardSecondaryHeader>
      <main className="pb-header h-full w-full max-w-[800px]">
        <section>
          {!canEditTeam && <OnlyOwnerCanConfigureNotification className="mb-12" />}
          <h2 className="text-heading-2 pb-8">Team</h2>
          <ChangeTeamNameForm
            disabled={!canEditTeam}
            teamId={teamId}
            defaultValues={{
              name: activeTeam?.name || '',
            }}
          />
        </section>
        <ScreenSeparator className="my-12" />
        <section>
          {!canManageUsers && <NoPermissionNotification className="mb-12" />}
          <MembersSection disabled={!canManageUsers} teamId={teamId} />
        </section>
        <ScreenSeparator className="my-12" />

        <section>
          {!canEditBilling && <NoPermissionNotification className="mb-12" />}
          <CompanyProfileSection
            companyProfile={billing.companyProfiles[teamId]}
            disabled={!canEditBilling}
            teamId={teamId}
          />
        </section>
        <ScreenSeparator className="my-12" />

        <section>
          {!canEditBilling && <NoPermissionNotification className="mb-12" />}
          <h2 className="text-heading-2 pb-1">Payment Details</h2>
          <p className="text-white-60 pb-8">Credit card details</p>
          <CreditCardBox
            disabled={!canEditBilling || !billing.companyProfiles[teamId]}
            teamId={teamId}
            paymentCard={billing.companyProfiles[teamId]?.card}
          />
        </section>
        <ScreenSeparator className="my-12" />

        <section>
          {!canEditTeam && <OnlyOwnerCanConfigureNotification className="mb-12" />}
          <DeleteTeamSection disabled={!canEditTeam} teamId={teamId} teamName={activeTeam?.name} />
        </section>
      </main>
    </GeneralTemplate>
  );
});
