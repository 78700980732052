import { Button } from '@highmobility/console-ui-components';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { CreatePasskeyDialog } from '@/components/views/account-settings/CreatePasskeyDialog';
import { PasskeyData } from '@/components/views/account-settings/PasskeyData';
import { useMobx } from '@/hooks/useMobx';

export const PasskeySection = observer(() => {
  const [showCreatePasskeyDialog, setShowCreatePasskeyDialog] = useState(false);

  const { session } = useMobx();

  return (
    <section className="max-w-[800px]">
      <div className="flex w-full items-center justify-between pb-8">
        <h2 className="text-heading-2">Passkeys</h2>
        <Button intent="primary" onClick={() => setShowCreatePasskeyDialog(true)}>
          Create a Passkey
        </Button>
      </div>
      <div className="flex flex-col gap-4">
        {session.passkeys.map((passkey) => (
          <PasskeyData key={passkey.id} data={passkey} />
        ))}
      </div>
      <CreatePasskeyDialog
        isOpen={showCreatePasskeyDialog}
        setIsOpen={setShowCreatePasskeyDialog}
      />
    </section>
  );
});
