import {
  AlfaRomeo,
  Audi,
  BMW,
  Citroen,
  Cupra,
  Dacia,
  Ds,
  Fiat,
  Ford,
  Hyundai,
  Jeep,
  Kia,
  Lexus,
  Maserati,
  Mercedes,
  Mini,
  Opel,
  Peugeot,
  Porsche,
  Renault,
  Seat,
  Skoda,
  Tesla,
  Toyota,
  Vauxhall,
  Volkswagen,
  Volvo,
} from '@highmobility/console-ui-components';

import americanExpress from '@/assets/images/stripe/americanExpress.png';
import cartesBancaires from '@/assets/images/stripe/cartesBancaires.png';
import dinersClub from '@/assets/images/stripe/dinersClub.png';
import discover from '@/assets/images/stripe/discover.png';
import jcb from '@/assets/images/stripe/jcb.png';
import masterCard from '@/assets/images/stripe/mastercard.png';
import unionPay from '@/assets/images/stripe/unionpay.png';
import visa from '@/assets/images/stripe/visa.png';

import { Brand, BrandData, Oem, ProjectStatus, ProjectType, Region, WebhookEvent } from '@/types';

export const PUBLIC_PATH = process.env.CONSOLE_UI_PUBLIC_PATH || '';

export const SIMULATORS_PATH =
  process.env.CONSOLE_UI_SIMULATORS_PATH ||
  'https://console.develop.high-mobility.net/simulators';

export const API_PATH = `${PUBLIC_PATH.replace(/\/$/, '')}/api/v1`;

export const FAKE_LOADER_TIMEOUT = 500;

export const AUTH_REDIRECT_PARAM = 'redirect';

export const PROJECT_REGION: Record<Region, string> = {
  europe: 'Europe',
  north_america: 'North America',
};

export const PROJECT_STATUS: Record<ProjectStatus, string> = {
  draft: 'Draft',
  in_review: 'Review',
  published: 'Published',
  published_with_changes: 'Published with changes',
};

export const PROJECT_TYPE: Record<ProjectType, string> = {
  fleet: 'Fleet',
  non_fleet: 'Non-fleet',
};

export const OEMS: Record<Oem, { name: string; brands: Brand[] }> = {
  bmw: {
    name: 'BMW Group',
    brands: ['bmw', 'mini'],
  },
  mercedes_benz: {
    name: 'Mercedes-Benz',
    brands: ['mercedes_benz'],
  },
  kia: {
    name: 'Kia',
    brands: ['kia'],
  },
  toyota: {
    name: 'Toyota Motor Corporation',
    brands: ['toyota', 'lexus'],
  },
  renault: {
    name: 'Renault Group',
    brands: ['renault', 'dacia'],
  },
  ford: {
    name: 'Ford',
    brands: ['ford'],
  },
  porsche: {
    name: 'Porsche',
    brands: ['porsche'],
  },
  stellantis: {
    name: 'Stellantis',
    brands: [
      'peugeot',
      'citroen',
      'opel',
      'vauxhall',
      'ds',
      'fiat',
      'alfaromeo',
      'jeep',
      'maserati',
    ],
  },
  tesla: {
    name: 'Tesla',
    brands: ['tesla'],
  },
  volkswagen: {
    name: 'Volkswagen Group',
    brands: ['cupra', 'volkswagen', 'seat', 'skoda', 'audi'],
  },
  volvo_cars: {
    name: 'Volvo Cars',
    brands: ['volvo_cars'],
  },
};

export const BRANDS: Record<Brand, BrandData> = {
  ford: {
    id: 'ford',
    name: 'Ford',
    logo: Ford,
  },
  mercedes_benz: {
    id: 'mercedes_benz',
    name: 'Mercedes-Benz',
    logo: Mercedes,
  },
  bmw: {
    id: 'bmw',
    name: 'BMW',
    logo: BMW,
  },
  mini: {
    id: 'mini',
    name: 'MINI',
    logo: Mini,
  },
  peugeot: {
    id: 'peugeot',
    name: 'Peugeot',
    logo: Peugeot,
  },
  citroen: {
    id: 'citroen',
    name: 'Citroën',
    logo: Citroen,
  },
  ds: {
    id: 'ds',
    name: 'DS Automobiles',
    logo: Ds,
  },
  fiat: {
    id: 'fiat',
    name: 'Fiat',
    logo: Fiat,
  },
  alfaromeo: {
    id: 'alfaromeo',
    name: 'Alfa Romeo',
    logo: AlfaRomeo,
  },
  jeep: {
    id: 'jeep',
    name: 'Jeep',
    logo: Jeep,
  },
  maserati: {
    id: 'maserati',
    name: 'Maserati',
    logo: Maserati,
  },
  porsche: {
    id: 'porsche',
    name: 'Porsche',
    logo: Porsche,
  },
  renault: {
    id: 'renault',
    name: 'Renault',
    logo: Renault,
  },
  dacia: {
    id: 'dacia',
    name: 'Dacia',
    logo: Dacia,
  },
  kia: {
    id: 'kia',
    name: 'Kia',
    logo: Kia,
  },
  lexus: {
    id: 'lexus',
    name: 'Lexus',
    logo: Lexus,
  },
  toyota: {
    id: 'toyota',
    name: 'Toyota',
    logo: Toyota,
  },
  tesla: {
    id: 'tesla',
    name: 'Tesla',
    logo: Tesla,
  },
  opel: {
    id: 'opel',
    name: 'Opel',
    logo: Opel,
  },
  vauxhall: {
    id: 'vauxhall',
    name: 'Vauxhall',
    logo: Vauxhall,
  },
  audi: {
    id: 'audi',
    name: 'Audi',
    logo: Audi,
  },
  hyundai: {
    id: 'hyundai',
    name: 'Hyundai',
    logo: Hyundai,
  },
  volvo_cars: {
    id: 'volvo_cars',
    name: 'Volvo',
    logo: Volvo,
  },
  cupra: {
    id: 'cupra',
    name: 'Cupra',
    logo: Cupra,
  },
  seat: {
    id: 'seat',
    name: 'SEAT',
    logo: Seat,
  },
  skoda: {
    id: 'skoda',
    name: 'Skoda',
    logo: Skoda,
  },
  volkswagen: {
    id: 'volkswagen',
    name: 'Volkswagen',
    logo: Volkswagen,
  },
};

export const ISO_ALPHA2_EUROPE_COUNTRIES = {
  AL: 'Albania',
  AD: 'Andorra',
  AT: 'Austria',
  BY: 'Belarus',
  BE: 'Belgium',
  BA: 'Bosnia And Herzegovina',
  BG: 'Bulgaria',
  HR: 'Croatia',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DK: 'Denmark',
  EE: 'Estonia',
  FI: 'Finland',
  FR: 'France',
  DE: 'Germany',
  GR: 'Greece',
  HU: 'Hungary',
  IS: 'Iceland',
  IE: 'Ireland',
  IT: 'Italy',
  LV: 'Latvia',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  MT: 'Malta',
  MD: 'Moldova',
  MC: 'Monaco',
  ME: 'Montenegro',
  NL: 'Netherlands',
  NO: 'Norway',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  RU: 'Russian Federation',
  SM: 'San Marino',
  RS: 'Serbia',
  SK: 'Slovakia',
  SI: 'Slovenia',
  ES: 'Spain',
  SE: 'Sweden',
  CH: 'Switzerland',
  TR: 'Turkey',
  UA: 'Ukraine',
  GB: 'United Kingdom',
};

export const STRIPE_CARD_BRANDS: Record<
  string,
  { id: string; name: string; logo: string | null } | undefined
> = {
  amex: {
    id: 'amex',
    name: 'American Express',
    logo: americanExpress,
  },
  cartes_bancaires: {
    id: 'cartes_bancaires',
    name: 'Cartes Bancaires',
    logo: cartesBancaires,
  },
  diners: {
    id: 'diners',
    name: 'Diners Club',
    logo: dinersClub,
  },
  discover: {
    id: 'discover',
    name: 'Discover',
    logo: discover,
  },
  jcb: {
    id: 'jcb',
    name: 'JCB',
    logo: jcb,
  },
  mastercard: {
    id: 'mastercard',
    name: 'Mastercard',
    logo: masterCard,
  },
  visa: {
    id: 'visa',
    name: 'Visa',
    logo: visa,
  },
  unionpay: {
    id: 'unionpay',
    name: 'UnionPay',
    logo: unionPay,
  },
};

export const MFAMethods = {
  TOTP: 'totp',
  RECOVERY_CODE: 'recovery_code',
} as const;

export const WEBHOOK_EVENTS: Record<WebhookEvent, WebhookEvent> = {
  fleet_clearance_changed: 'fleet_clearance_changed',
  fleet_geofence_notification: 'fleet_geofence_notification',
};
