import { DialogProps } from '@highmobility/console-ui-components';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { fakeLoader, updateTOTP } from '@/services/apiService';
import { loginWithMFAValidation, MFARecoveryValidation } from '@/services/zodService';
import { MFAMethods } from '@/constants';
import { MFAVerifyDialog } from '@/components/organisms/MFAVerifyDialog';

export type UpdateMFAVerifyDialogProps = {
  isOpen: DialogProps['isOpen'];
  setIsOpen: DialogProps['setIsOpen'];
  onSuccess: (
    MFASettings: { id: string; url: string; secret: string; expires_at: number },
    codeUsed: string
  ) => void;
};

export const UpdateMFAVerifyDialog: FC<UpdateMFAVerifyDialogProps> = (props) => {
  const updateMFAMutation = useMutation({
    mutationFn: async (data: z.infer<typeof loginWithMFAValidation>) => {
      await fakeLoader();
      return await updateTOTP({ 'X-Console-MFA-Token': `${MFAMethods.TOTP}:${data.code}` });
    },
    onError: (e: any) => {
      console.error('UpdateMFAVerifyDialog::login failed', e);
      updateMFAForm.setError('code', { message: 'Invalid code' });
    },
    onSuccess: ({ data }, variables) => {
      props.onSuccess(
        { id: data.id, url: data.url, secret: data.secret, expires_at: data.expires_at },
        variables.code
      );
    },
  });

  const updateMFAForm = useForm<z.infer<typeof loginWithMFAValidation>>({
    resolver: zodResolver(loginWithMFAValidation),
  });

  const recoveryMutation = useMutation({
    mutationFn: async (data: z.infer<typeof MFARecoveryValidation>) => {
      await fakeLoader();
      return await updateTOTP({
        'X-Console-MFA-Token': `${MFAMethods.RECOVERY_CODE}:${data.code}`,
      });
    },
    onError: (e: any) => {
      console.error('UpdateMFAVerifyDialog::recovery failed', e);
      recoveryForm.setError('code', { message: 'Invalid code' });
    },
    onSuccess: ({ data }, variables) => {
      props.onSuccess(
        { id: data.id, url: data.url, secret: data.secret, expires_at: data.expires_at },
        variables.code
      );
    },
  });

  const recoveryForm = useForm<z.infer<typeof MFARecoveryValidation>>({
    resolver: zodResolver(MFARecoveryValidation),
  });

  return (
    <MFAVerifyDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      loginForm={updateMFAForm}
      loginMutation={updateMFAMutation}
      recoveryForm={recoveryForm}
      recoveryMutation={recoveryMutation}
    />
  );
};
