import { cx } from 'class-variance-authority';
import { FC } from 'react';
import { HMLogo } from '@highmobility/console-ui-components';
import { Link } from 'react-router-dom';

export type AuthHeaderProps = {
  className?: string;
  type: 'login' | 'signup';
};

export const AuthHeader: FC<AuthHeaderProps> = ({ className, type }) => {
  const renderRightText = () => {
    if (type === 'login') {
      return (
        <div className="text-label-regular text-dark-green-900 pl-1">
          <span>Don&apos;t have an account?</span>{' '}
          <Link to="/sign-up" className="font-bold hover:underline">
            Sign up now!
          </Link>
        </div>
      );
    } else {
      return (
        <div className="text-label-regular text-dark-green-900 pl-1">
          <span>Already have an account?</span>{' '}
          <Link to="/login" className="font-bold hover:underline">
            Log in
          </Link>
        </div>
      );
    }
  };

  return (
    <nav className={cx('h-header flex w-full items-center justify-between py-8', className)}>
      <Link to={`/`}>
        <HMLogo className="text-dark-green-900 pr-1" />
      </Link>
      <div>{renderRightText()}</div>
    </nav>
  );
};
