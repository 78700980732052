import { ChangeEvent, useEffect, useRef, useState } from 'react';

export function useTags(settings: {
  enabled: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  name: string;
}) {
  const [tags, setTags] = useState<string[]>([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const inputNode = inputRef.current;
    if (!settings.enabled || !inputNode) {
      return;
    }

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        e.stopPropagation();

        if (!inputValue.trim()) {
          return;
        }

        setTags((old) => [...old, inputValue.trim()]);
        setInputValue('');
      }

      if (e.key === 'Backspace' && !inputNode?.value) {
        setTags((old) => old.slice(0, -1));
      }
    };

    inputNode.addEventListener('keydown', onKeyDown);

    return () => {
      inputNode.removeEventListener('keydown', onKeyDown);
    };
  }, [settings]); // eslint-disable-line

  useEffect(() => {
    if (!settings.enabled) {
      return;
    }

    const event = new Event('change', { bubbles: true });
    settings.onChange({
      ...event,
      target: {
        name: settings.name || '',
        value: tags,
      },
    } as any);

    setIsEmpty(tags.length <= 0 && !inputValue);
  }, [tags, inputValue, settings]);

  const inputProps = {
    ref: inputRef,
    value: inputValue,
    name: settings.enabled ? settings.name : '',
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      setIsEmpty(tags.length <= 0 && e.target.value.length <= 0);
    },
    onBlur: () => {
      setIsFocused(false);
      if (!inputValue.trim()) {
        return;
      }

      setTags((old) => [...old, inputValue.trim()]);
      setInputValue('');
    },
    onFocus: () => {
      setIsFocused(true);
    },
  };

  return { tags, setTags, inputRef, inputProps, isEmpty, isFocused } as const;
}
