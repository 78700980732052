import { Button, ControlRow, TextInput } from '@highmobility/console-ui-components';
import { DefaultValues, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { PasswordInputWithHints } from '@/components/molecules/PasswordInputWithHints';
import { signUp } from '@/services/apiService';
import { signUpValidation } from '@/services/zodService';

interface InitialStepProps {
  onError: () => void;
  onSuccess: () => void;
  setEmail: (email: string) => void;
  defaultValues?: DefaultValues<z.infer<typeof signUpValidation>>;
  invitationToken?: string;
}

export const InitialStep = (props: InitialStepProps) => {
  const signUpMutation = useMutation({
    mutationFn: (data: z.infer<typeof signUpValidation>) => {
      return signUp(
        {
          name: `${data.firstName} ${data.lastName}`,
          email: data.email,
          company: data.company,
          newsletter: false,
          password: data.password,
          terms_agreed: data.terms,
        },
        {
          invitation_token: props.invitationToken,
        }
      );
    },
    onError: (e) => {
      console.error('Error submitting the form', e);
      props.onError();
    },
    onSuccess: () => {
      props.onSuccess();
    },
  });
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    watch,
  } = useForm<z.infer<typeof signUpValidation>>({
    resolver: zodResolver(signUpValidation),
    defaultValues: props.defaultValues,
  });
  const onSubmit = handleSubmit((data) => signUpMutation.mutate(data));

  return (
    <form
      className="w-login-modal-lg bg-dark-green-900 flex flex-col gap-6 rounded px-6 py-8 sm:gap-8 sm:p-12"
      onSubmit={onSubmit}
    >
      <h1 className="text-heading-1">Create your account</h1>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4 sm:flex-row">
          <TextInput
            label="First Name"
            placeholder="Jon"
            {...register('firstName')}
            error={errors.firstName?.message}
            className="flex-1"
          />
          <TextInput
            label="Last Name"
            placeholder="Snow"
            {...register('lastName')}
            error={errors.lastName?.message}
            className="flex-1"
          />
        </div>
        <TextInput
          label="Organisation Name"
          placeholder="High Mobility"
          {...register('company')}
          error={errors.company?.message}
        />
        <TextInput
          label="Work Email"
          placeholder="jon@highmobility.com"
          {...register('email')}
          onChange={(e) => {
            register('email').onChange(e);
            props.setEmail(e.currentTarget.value);
          }}
          error={errors.email?.message}
          disabled={!!props.invitationToken && !!props.defaultValues?.email}
        />
        <PasswordInputWithHints
          inputProps={register('password')}
          watchedPassword={watch('password')}
          errorMessage={errors.password?.message}
          isDirty={!!dirtyFields.password}
        />
        <ControlRow
          defaultChecked={false}
          label={
            <div className="sm:max-w-[60%]">
              <span>By registering, you confirm you have read and agree to </span>
              <Link to="/terms" target="_blank" className="font-bold hover:underline">
                Terms
              </Link>{' '}
              <span>and</span>{' '}
              <Link to="/privacy" target="_blank" className="font-bold hover:underline">
                Privacy Policy
              </Link>
            </div>
          }
          {...register('terms')}
          error={errors.terms?.message}
          type="checkbox"
          layout="row"
          className="pl-0 pt-2"
          transparent
        />
      </div>

      <div className="flex justify-center">
        <Button type="submit">{signUpMutation.isPending ? 'Loading' : 'Sign up'}</Button>
      </div>
    </form>
  );
};
