import { LoaderFunction } from 'react-router-dom';
import { ScreenSeparator } from '@highmobility/console-ui-components';

import { mobxStore } from '@/store';
import { useMobx } from '@/hooks/useMobx';

import { ChangePasswordForm } from '@/components/views/account-settings/ChangePasswordForm';
import { DashboardHeader } from '@/components/organisms/DashboardHeader';
import { DeleteAccountSection } from '@/components/views/account-settings/DeleteAccountSection';
import { EmailPreferencesForm } from '@/components/views/account-settings/EmailPreferencesForm';
import { GeneralTemplate } from '@/components/templates/general/GeneralTemplate';
import { MFASection } from '@/components/views/account-settings/MFASection';
import { PasskeySection } from '@/components/views/account-settings/PasskeySection';
import { UserDetailsForm } from '@/components/views/account-settings/UserDetailsForm';

export const loader: LoaderFunction = async () => {
  await mobxStore.session.fetchPasskeys();
  return null;
};

export const AccountSettingsView = () => {
  const { session } = useMobx();

  return (
    <GeneralTemplate renderHeader={() => <DashboardHeader />}>
      <main className="pb-header h-full w-full pt-12">
        <h2 className="text-heading-2 pb-8">My Account Settings</h2>
        <UserDetailsForm defaultValues={{ email: session.user?.email, name: session.user?.name }} />
        <ScreenSeparator className="my-12" />

        <h2 className="text-heading-2 pb-8">Change Password</h2>
        <ChangePasswordForm />
        <ScreenSeparator className="my-12" />

        <MFASection />
        <ScreenSeparator className="my-12" />

        <PasskeySection />
        <ScreenSeparator className="my-12" />

        <h2 className="text-heading-2 pb-8">Email Preferences</h2>
        <EmailPreferencesForm
          defaultValues={{ newsletter: session.user?.subscriptions.includes('newsletter') }}
        />
        <ScreenSeparator className="my-12" />

        <h2 className="text-heading-2 pb-8">Delete Account</h2>
        <DeleteAccountSection />
      </main>
    </GeneralTemplate>
  );
};
