import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthBackgroundIllustrations } from '@/components/atoms/AuthBackgroundIllustrations';
import { AuthHeader } from '@/components/atoms/AuthHeader';
import { GeneralTemplate } from '@/components/templates/general/GeneralTemplate';
import { ROUTE_PATHS } from '@/router/routes';

export const EmailConfirmedView = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(ROUTE_PATHS.DASHBOARD_ALL);
    }, 3000);
  }, [navigate]);

  return (
    <GeneralTemplate
      className="bg-beige-100"
      renderHeader={() => <AuthHeader type="signup" />}
      renderIllustrations={() => <AuthBackgroundIllustrations />}
    >
      <main className="pb-header flex h-full w-full items-center justify-around">
        <div className="bg-dark-green-900 w-login-modal-sm flex flex-col gap-6 rounded p-12">
          <h1 className="text-heading-1">Welcome!</h1>
          <p className="text-body-regular font-bold">
            Your email is now confirmed. Welcome to High Mobility! Redirecting in a few seconds
          </p>
        </div>
      </main>
    </GeneralTemplate>
  );
};
