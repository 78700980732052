import { makeAutoObservable } from 'mobx';

export class UI {
  projectTemplate: { projectSubmitted: boolean } = { projectSubmitted: false };

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.projectTemplate = { projectSubmitted: false };
  }

  setProjectTemplate(data: { projectSubmitted: boolean }) {
    this.projectTemplate = data;
  }
}
