import { useMemo } from 'react';

import { Order } from '@/types';
import { useMobx } from '@/hooks/useMobx';

export function usePriceCalculations(orders: Order[]) {
  const { marketData } = useMobx();

  // If a package has been published, use the approved price.
  const draftOrdersWithPublishedPrices = useMemo(() => {
    const result = orders
      .filter((order) => order.status === 'draft')
      .map((order) => {
        return {
          ...order,
          packages: Object.entries(order.packages).reduce(
            (acc, [pckgId, pckg]) => {
              const marketDataPrice = marketData.byBrand?.[order.brand]?.find(
                (pack) => pack.id === pckgId
              )?.price;

              acc[pckgId] = {
                ...pckg!,
                price: marketDataPrice
                  ? {
                      amount: marketDataPrice.cents,
                      currency: marketDataPrice.currency,
                    }
                  : undefined,
              };

              return acc;
            },
            {} as { [packageId: string]: Order['packages'][string] }
          ),
        };
      })
      .sort((a, b) => a.brand.localeCompare(b.brand));

    const publishedOrders = orders.filter((order) => order.status === 'published');

    for (const publishedOrder of publishedOrders) {
      for (const [packageId, publishedPackage] of Object.entries(publishedOrder.packages)) {
        if (!publishedPackage?.price) {
          continue;
        }

        for (const draftOrder of result) {
          const draftPckg = draftOrder.packages[packageId];
          if (!draftPckg?.price) continue;
          draftPckg.price.amount = publishedPackage.price.amount;
        }
      }
    }

    return result;
  }, [orders, marketData.byBrand]);

  return { draftOrdersWithPublishedPrices };
}
