import { Fragment } from 'react';

import { WebhookDelivery } from '@/types';

type WebhookDeliveryResponseDetailsProps = {
  response: WebhookDelivery['response'];
};

export function WebhookDeliveryResponseDetails(props: WebhookDeliveryResponseDetailsProps) {
  return (
    <div>
      <p className="pb-2 pt-3 font-bold">Headers</p>
      <div
        className="grid gap-x-5 break-all rounded border border-white-20 bg-white-4 px-4 py-3"
        style={{
          gridTemplateColumns: 'auto minmax(0,1fr)',
        }}
      >
        {Object.entries(props.response.headers).map(([key, value]) => (
          <Fragment key={key}>
            <div className="font-bold">{key}:</div>
            <div>{value}</div>
          </Fragment>
        ))}
      </div>
      <p className="pb-2 pt-3 font-bold">Body</p>
      <div className="break-all rounded border border-white-20 bg-white-4 px-4 py-3">
        {props.response.body ? <pre className="text-wrap">{props.response.body}</pre> : '-'}
      </div>
    </div>
  );
}
