import { Lock, Notification } from '@highmobility/console-ui-components';

type OnlyOwnerCanConfigureNotificationProps = {
  className?: string;
};

export const OnlyOwnerCanConfigureNotification = (
  props: OnlyOwnerCanConfigureNotificationProps
) => {
  return (
    <Notification className={props.className} renderLeftIcon={(c) => <Lock className={c} />}>
      Only your organisation owner can configure this
    </Notification>
  );
};
