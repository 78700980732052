import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useClientPoint,
  useDismiss,
  useFloating,
  UseFloatingReturn,
  useHover,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';
import { Context, createContext, FC, PropsWithChildren, Ref, useContext, useRef } from 'react';

export type TooltipContextType = {
  open: boolean;
  getReferenceProps: (userProps?: React.HTMLProps<Element>) => Record<string, unknown>;
  getFloatingProps: (userProps?: React.HTMLProps<HTMLElement>) => Record<string, unknown>;
  refs: UseFloatingReturn['refs'];
  floatingStyles: UseFloatingReturn['floatingStyles'];
  context: UseFloatingReturn['context'];
  middlewareData: UseFloatingReturn['middlewareData'];
  arrowRef: Ref<SVGSVGElement>;
};

export const TooltipContext = createContext<TooltipContextType>({
  open: false,
} as TooltipContextType);

type TooltipProviderProps = PropsWithChildren<{
  open: boolean;
  setOpen?: (open: boolean) => void;
  useHover?: boolean;
  followMouse?: boolean;
  offset?: number;
}>;

export const TooltipProvider: FC<TooltipProviderProps> = (props) => {
  const arrowRef = useRef(null);
  const ARROW_HEIGHT = 12;

  const getOffset = () => {
    let offset = ARROW_HEIGHT;
    if (props.offset !== undefined) {
      offset += props.offset;
    } else if (props.useHover) {
      offset += 10;
    } else {
      offset += 2;
    }

    return offset;
  };

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    open: props.open,
    onOpenChange: props.setOpen,
    middleware: [
      offset({
        mainAxis: getOffset(),
      }),
      flip(),
      shift({
        padding: 10,
        crossAxis: true,
      }),
      arrow({
        element: arrowRef,
      }),
    ],
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
  });
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
    escapeKey: true,
  });
  const hover = useHover(context, { enabled: !!props.useHover, restMs: 200 });
  const clientPoint = useClientPoint(context, { enabled: !!props.followMouse });
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, hover, clientPoint]);

  const { styles } = useTransitionStyles(context, {
    initial: { opacity: 0 },
    open: { opacity: 1 },
    duration: 200,
  });

  const Context = TooltipContext as Context<TooltipContextType>;
  return (
    <Context.Provider
      value={
        {
          getReferenceProps,
          getFloatingProps,
          refs,
          floatingStyles: { ...floatingStyles, ...styles },
          context,
          middlewareData,
          open: props.open,
          arrowRef: arrowRef,
        } as Required<TooltipContextType>
      }
    >
      {props.children}
    </Context.Provider>
  );
};

export const TooltipTrigger: FC<PropsWithChildren> = ({ children }) => {
  const { getReferenceProps, refs } = useContext(TooltipContext);

  return (
    <div ref={refs!.setReference} {...getReferenceProps!()}>
      {children}
    </div>
  );
};
