import { matchRoutes, useLocation } from 'react-router-dom';

import { routes } from '@/router/routes';

export const useCurrentPath = () => {
  const location = useLocation();
  const matches = matchRoutes(routes, location);

  if (!matches) {
    return '';
  }

  return matches[matches.length - 1]?.route?.path;
};
