import { Accordion, AccordionContent } from '@highmobility/console-ui-components';
import { FC, useState } from 'react';

import { CompanyProfile, Project, WithPaymentCard } from '@/types';
import { LockedTextField } from '@/components/atoms/LockedTextField';
import { NoneSelected } from '@/components/atoms/NoneSelected';

export type BillingAccordionProps = {
  billingInfo: WithPaymentCard<CompanyProfile> | undefined;
  billingMethod: Project['billing_method'];
  showError: boolean;
};

export const BillingAccordion: FC<BillingAccordionProps> = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  const renderBillingMethod = () => {
    if (props.billingMethod === 'credit_card') {
      if (props.billingInfo?.card) {
        return (
          <LockedTextField label="Card" value={`**** **** **** ${props.billingInfo.card.last4}`} />
        );
      }

      return <NoneSelected label="Card" error={props.showError ? 'Mandatory field' : undefined} />;
    }

    if (props.billingMethod === 'invoice') {
      return <LockedTextField label="Payment method" value="Invoice" />;
    }
  };

  return (
    <Accordion title="Billing" isOpen={isOpen} setIsOpen={setIsOpen}>
      <AccordionContent>
        {props.billingInfo ? (
          <>
            {renderBillingMethod()}
            <LockedTextField label="Organisation" value={props.billingInfo.entity_name} />
            <LockedTextField
              label="Address"
              value={`${props.billingInfo.address.street}, ${props.billingInfo.address.city}, ${props.billingInfo.address.zip_code}`}
            />
            <LockedTextField label="VAT" value={props.billingInfo.vat_number} />
          </>
        ) : (
          <NoneSelected error={props.showError ? 'Mandatory field' : undefined} />
        )}
      </AccordionContent>
    </Accordion>
  );
};
