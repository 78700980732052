import { cx } from 'class-variance-authority';
import { FC, PropsWithChildren, useMemo, useState } from 'react';

import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset as offsetMiddleware,
  OffsetOptions,
  shift,
  useClick,
  useDismiss,
  useFloating,
  UseFloatingOptions,
  useInteractions,
  useRole,
} from '@floating-ui/react';

export type DropdownProps = PropsWithChildren<{
  className?: string;
  renderButton: (open: boolean) => JSX.Element;
  offset?: OffsetOptions;
  options?: Partial<UseFloatingOptions>;
  disabled?: boolean;
}>;

export const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({
  className,
  children,
  renderButton,
  offset,
  options,
  disabled,
}) => {
  const [open, setOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [
      offsetMiddleware(
        offset ?? {
          crossAxis: 8,
          mainAxis: 8,
        }
      ),
      flip({ fallbackAxisSideDirection: 'end' }),
      shift({
        padding: 10,
        crossAxis: true,
      }),
    ],
    placement: 'bottom-start',
    whileElementsMounted: autoUpdate,
    ...options,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
    escapeKey: true,
  });
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role]);

  const buttonWidth = useMemo(() => {
    return refs.reference.current?.getBoundingClientRect().width;
  }, [refs.reference.current]); // eslint-disable-line

  return (
    <div className={cx(className)}>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        onKeyDown={undefined}
        onKeyUp={undefined}
        onClick={() => !disabled && context.onOpenChange(!context.open)}
        aria-label="Open dropdown"
      >
        {renderButton(context.open)}
      </div>
      {context.open && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              style={{
                ...floatingStyles,
                minWidth: buttonWidth || 0,
              }}
              {...getFloatingProps()}
              onClick={() => setOpen(false)}
            >
              {children}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </div>
  );
};
