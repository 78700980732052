import {
  DotMenu,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Tag,
  toast,
} from '@highmobility/console-ui-components';
import { generatePath, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { ROUTE_PATHS } from '@/router/routes';
import { useMobx } from '@/hooks/useMobx';

export const TeamsTable = observer(() => {
  const { session, teams } = useMobx();
  const navigate = useNavigate();

  const tableData = useMemo(() => {
    return teams.list.map((team) => {
      const role = team.members.find((member) => member.id === session.user?.id)?.role;

      return {
        id: team.id,
        name: team.name,
        isDefault: team.is_default,
        memberCount: team.members.length,
        projectCount: team.projects_count,
        role: `${role?.charAt(0).toUpperCase()}${role?.slice(1)}`,
      };
    });
  }, [teams.list, session.user?.id]);

  const setAsDefault = async (teamId: string) => {
    const success = await teams.setDefaultTeam(teamId);
    if (success) {
      toast('Default team changed.', 'success');
    } else {
      toast('Failed to change default team.', 'error');
    }
  };

  return (
    <div>
      <Table className="mb-6 w-full">
        <TableHeader>
          <TableRow>
            <TableHead className="w-1/2">Name</TableHead>
            <TableHead className="whitespace-nowrap">Your role</TableHead>
            <TableHead className="whitespace-nowrap">Members</TableHead>
            <TableHead className="whitespace-nowrap" colSpan={2}>
              Projects
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {tableData.map((team, index) => (
            <TableRow key={index}>
              <TableCell>
                <div className="flex items-center gap-2">
                  <span>{team.name}</span>
                  {team.isDefault && (
                    <Tag className="-my-[2px]" type="light">
                      Default
                    </Tag>
                  )}
                </div>
              </TableCell>
              <TableCell>{team.role}</TableCell>
              <TableCell>{team.memberCount}</TableCell>
              <TableCell>{team.projectCount}</TableCell>
              <TableCell className="w-10" noPadding>
                <Dropdown
                  renderButton={() => (
                    <div className="group flex h-10 w-full cursor-pointer items-center justify-center">
                      <DotMenu className="hover:text-white-80 group-hover:text-white-60 h-4 min-h-4 w-4 min-w-4 text-white transition-colors" />
                    </div>
                  )}
                >
                  <DropdownMenu>
                    {!team.isDefault && (
                      <DropdownMenuItem
                        label="Set as default"
                        onClick={() => setAsDefault(team.id)}
                      />
                    )}
                    <>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem
                        label="Team settings"
                        onClick={() =>
                          navigate(generatePath(ROUTE_PATHS.TEAM_SETTINGS, { teamId: team.id }))
                        }
                      />
                    </>
                  </DropdownMenu>
                </Dropdown>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
});
