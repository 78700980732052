import { FC, PropsWithChildren, useContext } from 'react';
import { FloatingArrow, FloatingPortal } from '@floating-ui/react';

import { TooltipContext } from '../../utils/tooltip';

export const Tooltip: FC<
  PropsWithChildren & {
    arrowCentered?: boolean;
  }
> = (props) => {
  const { context, refs, getFloatingProps, floatingStyles, middlewareData, open, arrowRef } =
    useContext(TooltipContext);
  const ARROW_HEIGHT = 12;

  if (!open) {
    return null;
  }

  return (
    <FloatingPortal>
      <div
        ref={refs.setFloating}
        style={floatingStyles}
        {...getFloatingProps()}
        className="bg-dark-green-950 rounded-[4px] p-3 text-white"
      >
        <FloatingArrow
          staticOffset={props.arrowCentered ? null : 1 - (middlewareData?.shift?.x || 0)}
          width={40}
          height={ARROW_HEIGHT}
          className="fill-dark-green-950"
          ref={arrowRef}
          context={context}
          d="m39.73684,40.15599c-1.4574,0 -2.9145,0.0078 -4.3719,-0.0092c-1.3949,-0.0166 -2.9561,-0.0402 -4.3136,-0.5955c-1.4664,-0.6001 -2.4371,-1.64926 -3.4139,-2.88402c-0.7032,-0.88811 -2.0845,-2.78295 -2.757,-3.69783c-0.5505,-0.75009 -1.6281,-2.23273 -2.2164,-2.95004c-0.7406,-0.90242 -1.6561,-1.86161 -2.9276,-1.86161c-1.2716,0 -2.1866,0.95919 -2.9268,1.86068c-0.5883,0.71686 -1.6659,2.20042 -2.2168,2.95005c-0.6717,0.91488 -2.053,2.80972 -2.7566,3.69782c-0.9785,1.23476 -1.9475,2.28395 -3.41348,2.88405c-1.35789,0.5539 -2.91955,0.5788 -4.31361,0.5954c-1.45786,0.0171 -2.91487,0.0093 -4.37231,0.0093"
        />
        {props.children}
      </div>
    </FloatingPortal>
  );
};
