import { Button, ButtonProps } from '../atoms/Button';

import { Dialog, DialogProps } from './Dialog';

export type AlertDialogProps = {
  onConfirm: () => void;
  onCancel: () => void;
  cancelLabel?: string;
  confirmLabel?: string;
  confirmButtonIntent?: ButtonProps['intent'];
  confirmButtonDisabled?: boolean;
  cancelButtonVisible?: boolean;
} & DialogProps;

export const AlertDialog = ({
  cancelLabel = 'Close',
  confirmLabel = 'Save',
  confirmButtonIntent = 'primary',
  cancelButtonVisible = true,
  ...props
}: AlertDialogProps) => {
  const _setIsOpen: DialogProps['setIsOpen'] = (open) => {
    /* istanbul ignore else */
    if (!open) {
      props.onCancel();
    }

    props.setIsOpen(open);
  };

  return (
    <Dialog
      isOpen={props.isOpen}
      setIsOpen={_setIsOpen}
      title={props.title}
      timeoutConfig={props.timeoutConfig}
    >
      <>
        {props.children}
        <div className="flex items-center justify-end gap-2">
          {cancelButtonVisible && (
            <Button intent="secondary" onClick={() => _setIsOpen(false)}>
              {cancelLabel}
            </Button>
          )}
          <Button
            intent={confirmButtonIntent}
            onClick={props.onConfirm}
            disabled={props.confirmButtonDisabled}
          >
            {confirmLabel}
          </Button>
        </div>
      </>
    </Dialog>
  );
};
