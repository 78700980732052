import {
  Airtable,
  Car,
  DoubleCircle,
  Menu,
  Navigation,
  Slack,
  ToggleIcon,
} from '@highmobility/console-ui-components';
import { FC } from 'react';
import { generatePath, Link } from 'react-router-dom';

import { ProjectInstance } from '@/types';
import { ProjectNavIcon } from '@/components/atoms/ProjectNavIcon';
import { ROUTE_PATHS } from '@/router/routes';
import { useCurrentPath } from '@/hooks/useCurrentPath';

export type ProjectNavProps = {
  teamId: string;
  projectId: string;
};

export const ProjectNav: FC<ProjectNavProps> = (props) => {
  const currentPath = useCurrentPath();
  const defaultAuthInstance: ProjectInstance = 'sandbox';

  return (
    <div className="flex w-10 shrink-0 flex-col border-r border-black-20">
      <div>
        <Link
          to={generatePath(ROUTE_PATHS.PROJECT_DATA, {
            teamId: props.teamId,
            projectId: props.projectId,
          })}
        >
          <ProjectNavIcon active={currentPath.includes(ROUTE_PATHS.PROJECT_DATA)}>
            <Car />
          </ProjectNavIcon>
        </Link>
        <Link
          to={generatePath(ROUTE_PATHS.PROJECT_AUTH, {
            teamId: props.teamId,
            projectId: props.projectId,
            instance: defaultAuthInstance,
          })}
        >
          <ProjectNavIcon active={currentPath.includes(ROUTE_PATHS.PROJECT_AUTH)}>
            <ToggleIcon />
          </ProjectNavIcon>
        </Link>
        <Link
          to={generatePath(ROUTE_PATHS.PROJECT_SIMULATION_STUDIO, {
            teamId: props.teamId,
            projectId: props.projectId,
          })}
        >
          <ProjectNavIcon active={currentPath.includes(ROUTE_PATHS.PROJECT_SIMULATION_STUDIO)}>
            <Navigation />
          </ProjectNavIcon>
        </Link>
        <Link
          to={generatePath(ROUTE_PATHS.PRODUCT_INFO, {
            teamId: props.teamId,
            projectId: props.projectId,
          })}
        >
          <ProjectNavIcon active={currentPath.includes(ROUTE_PATHS.PRODUCT_INFO)}>
            <Menu />
          </ProjectNavIcon>
        </Link>
      </div>
      <div className="flex-1" />
      <div>
        <a href="https://docs.high-mobility.com/guides/" target="blank" rel="noreferrer">
          <ProjectNavIcon>
            <DoubleCircle />
          </ProjectNavIcon>
        </a>
        <a href="https://slack.high-mobility.com/" target="blank" rel="noreferrer">
          <ProjectNavIcon>
            <Slack />
          </ProjectNavIcon>
        </a>
        <a
          href="https://airtable.com/appnqv8fdlWYRB0D4/shry3EDO6lLiBunTm/tblCBBV23F1zBOnhI"
          target="blank"
          rel="noreferrer"
        >
          <ProjectNavIcon>
            <Airtable />
          </ProjectNavIcon>
        </a>
      </div>
    </div>
  );
};
