import { cva, cx } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

export type NotificationProps = PropsWithChildren<{
  renderLeftIcon?: (className: string) => React.ReactNode;
  renderAdditionalContent?: (className: string) => React.ReactNode;
  className?: string;
  type?: 'default' | 'success' | 'error';
}>;

const notification = cva('inline-flex w-[800px] max-w-full items-center gap-2 rounded p-3', {
  variants: {
    type: {
      default: 'bg-white-4',
      success: 'bg-success-500-message',
      error: 'bg-error-600-message',
    },
  },
  defaultVariants: {
    type: 'success',
  },
});

export const Notification = ({ type = 'default', ...props }: NotificationProps) => {
  return (
    <div className={cx(notification({ type }), props.className)}>
      {props.renderLeftIcon?.('w-6 min-w-6 min-h-6 h-6 text-white-60')}
      <div className="flex flex-col gap-1">
        <p className="text-body-regular text-white">{props.children}</p>
        {props.renderAdditionalContent?.('text-body-regular text-white-60')}
      </div>
    </div>
  );
};
