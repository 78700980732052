import { Elements } from '@stripe/react-stripe-js';
import { FC, PropsWithChildren, useMemo } from 'react';
import { loadStripe } from '@stripe/stripe-js';

import { useAppConfig } from '@/hooks/useAppConfig';

export const StripeElementsProvider: FC<PropsWithChildren> = (props) => {
  const appConfig = useAppConfig();
  const stripePromise = useMemo(async () => loadStripe(appConfig.STRIPE_PUBLIC_KEY), [appConfig]);

  return <Elements stripe={stripePromise}>{props.children}</Elements>;
};
