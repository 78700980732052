import {
  AuthenticationResponseJSON,
  PublicKeyCredentialCreationOptionsJSON,
  PublicKeyCredentialRequestOptionsJSON,
  RegistrationResponseJSON,
} from '@simplewebauthn/types';
import axios from 'axios';
import { Role } from '@highmobility/console-role-matrix';

import { API_PATH, FAKE_LOADER_TIMEOUT, SIMULATORS_PATH } from '@/constants';
import {
  ApplicationSinkSettings,
  Authenticator,
  CompanyProfile,
  KinesisSinkSettings,
  MFAParameters,
  MQTTCertificate,
  OAuthClient,
  Project,
  ProjectInstance,
  ProjectType,
  Region,
  S3SinkSettings,
  SelectedProperties,
  ServiceAccount,
  Simulator,
  SimulatorScene,
  Team,
  User,
  WebhookDelivery,
  WebhooksConfiguration,
  WithPaymentCard,
} from '@/types';

export const fakeLoader = async () =>
  new Promise((resolve) => setTimeout(resolve, FAKE_LOADER_TIMEOUT));

export function signUp(
  data: {
    name: string;
    email: string;
    company: string;
    newsletter: boolean;
    password: string;
    terms_agreed: boolean;
  },
  queryParams?: { invitation_token?: string }
) {
  const params = queryParams?.invitation_token
    ? { invitation_token: queryParams.invitation_token }
    : {};

  return axios.post(`${API_PATH}/users`, data, { params });
}

export function updateUserDetails(data: { name: string }) {
  return axios.put(`${API_PATH}/users`, data);
}

export function changeEmail(data: { email: string; password: string }) {
  return axios.post(`${API_PATH}/users/change-email`, data);
}

export function changePassword(data: {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}) {
  return axios.post(`${API_PATH}/users/change-password`, {
    current_password: data.currentPassword,
    password: data.password,
    password_confirmation: data.passwordConfirmation,
  });
}

export function login(
  data: {
    email: string;
    password: string;
  },
  headers?: { 'X-Console-MFA-Token': `${MFAParameters['method']}:${string}` },
  queryParams?: { invitation_token?: string }
) {
  const params = queryParams?.invitation_token
    ? { invitation_token: queryParams.invitation_token }
    : {};

  return axios.post(`${API_PATH}/users/session`, data, { params, headers });
}

export function logout() {
  return axios.delete(`${API_PATH}/users/session`);
}

export function fetchCurrentUser() {
  return axios.get<User>(`${API_PATH}/users/current`);
}

export function resendActivationToken(data: { email: string }) {
  return axios.post(`${API_PATH}/users/resend-activation-token`, data);
}

export function initResetPassword(data: { email: string }) {
  return axios.post(`${API_PATH}/users/forgot-password`, data);
}

export function resetPassword(data: {
  password: string;
  password_confirmation: string;
  token: string;
}) {
  return axios.post(`${API_PATH}/users/reset-password`, data);
}

export function updateSubscriptions(data: { subscriptions: 'newsletter'[] }) {
  return axios.put(`${API_PATH}/users/subscriptions`, data);
}

export function deleteAccount() {
  return axios.delete(`${API_PATH}/users`);
}

export function fetchTeams() {
  return axios.get<Team[]>(`${API_PATH}/teams`);
}

export function fetchTeam(teamId: string) {
  return axios.get<Team>(`${API_PATH}/teams/${teamId}`);
}

export function createTeam(data: { name: string; members: string[] }) {
  return axios.post(`${API_PATH}/teams`, data);
}

export function changeTeamName(data: { name: string }, teamId: string) {
  return axios.patch(`${API_PATH}/teams/${teamId}`, data);
}

export function setDefaultTeam(teamId: string) {
  return axios.put(`${API_PATH}/teams/${teamId}/default`);
}

export function leaveTeam(teamId: string) {
  return axios.delete(`${API_PATH}/teams/${teamId}/members`);
}

export function inviteMembers(teamId: string, data: { members: string[]; role: Role }) {
  return axios.patch(`${API_PATH}/teams/${teamId}/members`, data);
}

export function updateTeamMemberRole(teamId: string, userId: string, data: { role: Role }) {
  return axios.put(`${API_PATH}/teams/${teamId}/members/${userId}/role`, data);
}

export function removeTeamMember(teamId: string, userId: string) {
  return axios.delete(`${API_PATH}/teams/${teamId}/members/${userId}`);
}

export function updateInvitationRole(teamId: string, invitationId: string, data: { role: Role }) {
  return axios.put(`${API_PATH}/teams/${teamId}/invitations/${invitationId}/role`, data);
}

export function deleteInvitation(teamId: string, invitationId: string) {
  return axios.delete(`${API_PATH}/teams/${teamId}/invitations/${invitationId}`);
}

export function deleteTeam(teamId: string) {
  return axios.delete(`${API_PATH}/teams/${teamId}`);
}

export function createProject(
  teamId: string,
  data: { region: Region; name: string; type: ProjectType; image: string }
) {
  return axios.post<Project>(`${API_PATH}/teams/${teamId}/projects`, data);
}

export function fetchProjects(teamId: string) {
  return axios.get<Project[]>(`${API_PATH}/teams/${teamId}/projects`);
}

export function fetchProject(teamId: string, projectId: string) {
  return axios.get<Project>(`${API_PATH}/teams/${teamId}/projects/${projectId}`);
}

export function updateProject(teamId: string, projectId: string, data: { name: string }) {
  return axios.patch<Project>(`${API_PATH}/teams/${teamId}/projects/${projectId}`, data);
}

export function submitProjectForReview(teamId: string, projectId: string) {
  return axios.post(`${API_PATH}/teams/${teamId}/projects/${projectId}/review`);
}

export function saveProjectDraft(teamId: string, projectId: string, data: SelectedProperties) {
  return axios.post<Project>(`${API_PATH}/teams/${teamId}/projects/${projectId}/drafts`, data);
}

export function fetchMarketData(region: Region, type: ProjectType) {
  return axios.get(`${API_PATH}/market-data/${region}/${type}`);
}

export function updateCompanyProfile(
  teamId: string,
  data: {
    entity_name: string;
    address: {
      street: string;
      city: string;
      zip_code: string;
      country: string;
    };
    contact: {
      billing: {
        name: string;
        email: string;
      }[];
      technical?: {
        name: string;
        email: string;
      };
    };
    vat_number: string;
  }
) {
  return axios.put<WithPaymentCard<CompanyProfile>>(
    `${API_PATH}/teams/${teamId}/company-profile`,
    data
  );
}

export function fetchCompanyProfile(teamId: string) {
  return axios.get<WithPaymentCard<CompanyProfile>>(`${API_PATH}/teams/${teamId}/company-profile`);
}

export function setupCardInit(teamId: string) {
  return axios.get<{ secret: string }>(`${API_PATH}/teams/${teamId}/company-profile/setup-card`);
}

export function setupCard(teamId: string, data: { id: string }) {
  return axios.post(`${API_PATH}/teams/${teamId}/company-profile/setup-card`, data);
}

export function updateTOTP(headers?: {
  'X-Console-MFA-Token': `${MFAParameters['method']}:${string}`;
}) {
  return axios.post<{
    id: string;
    config: {
      algo: 'sha1' | 'sha256' | 'sha512';
      digits: number;
      period: number;
      secretSize: number;
    };
    expires_at: number;
    secret: string;
    url: string;
  }>(`${API_PATH}/users/mfa/totp`, {}, { headers });
}

export function verifyTOTP(data: { id: string; code: string }) {
  return axios.post(`${API_PATH}/users/mfa/totp/${data.id}/verify`, data);
}

export function deleteMFA() {
  return axios.delete(`${API_PATH}/users/mfa`);
}

export function generateMFARecoveryCodes(headers?: {
  'X-Console-MFA-Token': `${MFAParameters['method']}:${string}`;
}) {
  return axios.post<string[]>(`${API_PATH}/users/mfa/recovery-codes`, {}, { headers });
}

export function fetchPasskeyOptions() {
  return axios.get<PublicKeyCredentialCreationOptionsJSON>(`${API_PATH}/users/passkeys/register`);
}

export function registerPasskey(data: RegistrationResponseJSON) {
  return axios.post<Authenticator>(`${API_PATH}/users/passkeys/register`, data);
}

export function fetchPasskeys() {
  return axios.get<Authenticator[]>(`${API_PATH}/users/passkeys`);
}

export function updatePasskey(id: string, name: string) {
  return axios.put<Authenticator>(`${API_PATH}/users/passkeys/${id}`, { name });
}

export function deletePasskey(id: string) {
  return axios.delete(`${API_PATH}/users/passkeys/${id}`);
}

export function fetchPasskeyLoginOptions() {
  return axios.get<PublicKeyCredentialRequestOptionsJSON>(`${API_PATH}/users/session/challenge`);
}

export function loginWithPasskey(
  data: AuthenticationResponseJSON,
  queryParams?: { invitation_token?: string }
) {
  const params = queryParams?.invitation_token
    ? { invitation_token: queryParams.invitation_token }
    : {};

  return axios.post(`${API_PATH}/users/session/challenge`, data, { params });
}

export function fetchOAuthClient(teamId: string, projectId: string, instance: ProjectInstance) {
  return axios.get<OAuthClient>(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/oauth-clients/${instance}`
  );
}

export function resetOAuthClientSecret(
  teamId: string,
  projectId: string,
  instance: ProjectInstance,
  clientId: string
) {
  return axios.post<OAuthClient>(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/oauth-clients/${instance}/${clientId}/reset-secret`
  );
}

export function createServiceAccount(
  teamId: string,
  projectId: string,
  instance: ProjectInstance,
  data: { name: string; expires_at?: string; public_key: string }
) {
  return axios.post<ServiceAccount>(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/service-accounts/${instance}`,
    data
  );
}

export function fetchServiceAccounts(teamId: string, projectId: string, instance: ProjectInstance) {
  return axios.get<ServiceAccount[]>(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/service-accounts/${instance}`
  );
}

export function fetchStreamingSettings(
  teamId: string,
  projectId: string,
  instance: ProjectInstance
) {
  return axios.get<ApplicationSinkSettings>(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/streaming-settings/${instance}`
  );
}

export function createMQTTCertificate(
  teamId: string,
  projectId: string,
  instance: ProjectInstance
) {
  return axios.post<
    MQTTCertificate & {
      ca_certificates: string;
      client_certificate: string;
      client_private_key: string;
    }
  >(`${API_PATH}/teams/${teamId}/projects/${projectId}/mqtt-certificates/${instance}`);
}

export function fetchMQTTCertificates(
  teamId: string,
  projectId: string,
  instance: ProjectInstance
) {
  return axios.get<MQTTCertificate[]>(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/mqtt-certificates/${instance}`
  );
}

export function revokeMQTTCertificate(
  teamId: string,
  projectId: string,
  instance: ProjectInstance,
  certificateId: string
) {
  return axios.delete<MQTTCertificate>(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/mqtt-certificates/${instance}/${certificateId}`
  );
}

export function testStreamingSettings(
  teamId: string,
  projectId: string,
  instance: ProjectInstance,
  data: S3SinkSettings | KinesisSinkSettings
) {
  return axios.post<
    | {
        s3_result:
          | {
              fileName: string;
              status: 'ok';
              statusCode: number;
            }
          | {
              message: string;
              status: 'error';
              statusCode: number;
            };
      }
    | {
        kinesis_result:
          | {
              status: 'ok';
              pushed_message: {
                sequence_number: string;
                shard_id: string;
              };
            }
          | {
              status: 'error';
              error_message: string;
            };
      }
  >(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/streaming-settings/${instance}/verify`,
    data
  );
}

export function updateStreamingSettings(
  teamId: string,
  projectId: string,
  instance: ProjectInstance,
  data: ApplicationSinkSettings['sink']
) {
  return axios.post<ApplicationSinkSettings['sink']>(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/streaming-settings/${instance}`,
    data
  );
}

export function deleteServiceAccount(
  teamId: string,
  projectId: string,
  instance: ProjectInstance,
  accountId: string
) {
  return axios.delete(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/service-accounts/${instance}/${accountId}`
  );
}

export function setupWebhooks(
  teamId: string,
  projectId: string,
  instance: ProjectInstance,
  data: {
    callback_url: string;
    events: ('fleet_clearance_changed' | 'fleet_geofence_notification')[];
    secret?: string;
  }
) {
  return axios.post<WebhooksConfiguration>(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/webhooks/${instance}`,
    data
  );
}

export function updateWebhooks(
  teamId: string,
  projectId: string,
  instance: ProjectInstance,
  webhookId: string,
  data: {
    callback_url: string;
    events: ('fleet_clearance_changed' | 'fleet_geofence_notification')[];
    secret?: string;
  }
) {
  return axios.put<WebhooksConfiguration>(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/webhooks/${instance}/${webhookId}`,
    data
  );
}

export function fetchWebhooksConfiguration(
  teamId: string,
  projectId: string,
  instance: ProjectInstance
) {
  return axios.get<WebhooksConfiguration>(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/webhooks/${instance}`
  );
}

export function fetchWebhookDeliveries(
  teamId: string,
  projectId: string,
  instance: ProjectInstance,
  webhookId: string
) {
  return axios.get<WebhookDelivery[]>(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/webhooks/${instance}/${webhookId}/deliveries`
  );
}

export function redeliverWebhook(
  teamId: string,
  projectId: string,
  instance: ProjectInstance,
  webhookId: string,
  webhookDeliveryId: string
) {
  return axios.post(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/webhooks/${instance}/${webhookId}/deliveries/${webhookDeliveryId}`
  );
}

export function triggerWebhook(teamId: string, projectId: string, instance: ProjectInstance) {
  return axios.post(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/webhooks/${instance}/trigger`
  );
}

export function createSimulator(teamId: string, type: Simulator['type']) {
  return axios.post<Simulator>(`${API_PATH}/teams/${teamId}/simulators`, { type });
}

export function fetchSimulators(teamId: string) {
  return axios.get<Simulator[]>(`${API_PATH}/teams/${teamId}/simulators`);
}

export function fetchScenes() {
  return axios.get<SimulatorScene[]>(`${SIMULATORS_PATH}/api/scenes`);
}

export function deleteSimulator(teamId: string, simulatorVin: string) {
  return axios.delete(`${API_PATH}/teams/${teamId}/simulators/${simulatorVin}`);
}

export function updateSimulatorName(teamId: string, vin: string, data: { name: string }) {
  return axios.post<Simulator>(`${API_PATH}/teams/${teamId}/simulators/${vin}`, data);
}

export function updateProductInfo(
  teamId: string,
  projectId: string,
  data:
    | {
        website: string;
      }
    | {
        privacy_policy?: string;
        terms_of_service?: string;
      }
) {
  return axios.patch<Project>(
    `${API_PATH}/teams/${teamId}/projects/${projectId}/product-info`,
    data
  );
}
