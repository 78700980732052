import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { fetchWebhookDeliveries, fetchWebhooksConfiguration } from '@/services/apiService';
import { NoPermissionNotification } from '@/components/molecules/NoPermissionNotification';
import { PathParams, useTypedParams } from '@/hooks/useTypedParams';
import { ProjectInstance } from '@/types';
import { ROUTE_PATHS } from '@/router/routes';
import { usePermissions } from '@/hooks/usePermissions';

import { WebhookDelivery } from './WebhookDelivery';

export const loader = async (args: LoaderFunctionArgs) => {
  const params = args.params as PathParams<typeof ROUTE_PATHS.PROJECT_AUTH_WEBHOOKS_DELIVERIES>;
  const instance = params.instance as ProjectInstance;

  const webhooksConfig = await fetchWebhooksConfiguration(
    params.teamId,
    params.projectId,
    instance
  ).catch(() => null);

  const webhookDeliveriesResponse = webhooksConfig
    ? await fetchWebhookDeliveries(
        params.teamId,
        params.projectId,
        instance,
        webhooksConfig.data.id
      )
    : null;

  return {
    webhookDeliveries: webhookDeliveriesResponse?.data ?? [],
    webhooksConfig: webhooksConfig?.data ?? null,
  };
};

export const ProjectAuthWebhooksDeliveriesView = observer(() => {
  const loaderData = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const { teamId, projectId, ...params } =
    useTypedParams<typeof ROUTE_PATHS.PROJECT_AUTH_WEBHOOKS_DELIVERIES>();
  const instance = params.instance as ProjectInstance;
  const { userHasPermission } = usePermissions(teamId);
  const hasViewPermission = userHasPermission('projects.view.credentials');

  if (!hasViewPermission) {
    return <NoPermissionNotification />;
  }

  return (
    <div>
      {loaderData.webhookDeliveries.length === 0 ? (
        <div className="flex items-center justify-center gap-2 rounded border border-white-4 p-6">
          You have no deliveries yet.
        </div>
      ) : (
        <div className="flex flex-col gap-1 xl:max-w-[800px]">
          {loaderData.webhookDeliveries.map(
            (delivery) =>
              loaderData.webhooksConfig && (
                <WebhookDelivery
                  key={delivery.id}
                  delivery={delivery}
                  teamId={teamId}
                  projectId={projectId}
                  instance={instance}
                  webhookId={loaderData.webhooksConfig.id}
                />
              )
          )}
        </div>
      )}
    </div>
  );
});
