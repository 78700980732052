import { Button, Check } from '@highmobility/console-ui-components';

import { testStreamingSettings } from '@/services/apiService';

type StreamingTestDialogSuccessProps = {
  testResponse: Awaited<ReturnType<typeof testStreamingSettings>>['data'];
  saving: boolean;
  onClickSave: () => Promise<void>;
};

export function StreamingTestDialogSuccess(props: StreamingTestDialogSuccessProps) {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex w-full items-center justify-center gap-1">
        <h5 className="text-heading-5 text-success-500">Integration successful</h5>
        <Check className="text-success-500" />
      </div>
      {'s3_result' in props.testResponse && props.testResponse.s3_result.status === 'ok' && (
        <div>
          <p className="font-bold">File was pushed to your S3 bucket under name:</p>
          <p>{props.testResponse.s3_result.fileName}</p>
        </div>
      )}
      {'kinesis_result' in props.testResponse &&
        props.testResponse.kinesis_result.status === 'ok' && (
          <div>
            A dummy message was successfully pushed to shard
            <strong>{props.testResponse.kinesis_result.pushed_message.shard_id}</strong>
            with sequence number
            <strong>{props.testResponse.kinesis_result.pushed_message.sequence_number}</strong>
          </div>
        )}
      <div className="flex items-center justify-end gap-2">
        <Button intent="primary" disabled={props.saving} onClick={props.onClickSave}>
          Save
        </Button>
      </div>
    </div>
  );
}
