import { FC, PropsWithChildren } from 'react';
import { LinearIndeterminateLoader } from '@highmobility/console-ui-components';

export type AuthModalProps = PropsWithChildren<{
  title: string;
  showLoader?: boolean;
}>;

export const AuthModal: FC<AuthModalProps> = (props) => (
  <div className="bg-dark-green-900 w-login-modal-sm flex flex-col gap-6 rounded px-6 py-8 sm:gap-8 sm:p-12 relative">
    {props.showLoader && (
      <div className="absolute -top-2 left-0 w-full">
        <LinearIndeterminateLoader />
      </div>
    )}
    <h1 className="text-heading-1">{props.title}</h1>
    {props.children}
  </div>
);
