import { Button } from '@highmobility/console-ui-components';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { GenerateNewMFACodesDialog } from '@/components/views/account-settings/GenerateNewMFACodesDialog';
import { ToggleMFAButton } from '@/components/views/account-settings/ToggleMFAButton';
import { UpdateMFADialog } from '@/components/views/account-settings/UpdateMFADialog';
import { useMobx } from '@/hooks/useMobx';

export const MFASection = observer(() => {
  const { session } = useMobx();
  const [showGenerateNewMFACodesDialog, setShowGenerateNewMFACodesDialog] = useState(false);
  const [showMFAUpdateDialog, setShowMFAUpdateDialog] = useState(false);

  if (!session.user) {
    return null;
  }

  const MFAEnabled = session.user.mfa_methods.length > 0;

  return (
    <section className="max-w-[800px]">
      <div className="flex w-full items-center justify-between pb-8">
        <h2 className="text-heading-2">Two-factor authentication</h2>
        <ToggleMFAButton MFAEnabled={MFAEnabled} />
      </div>
      <div className="flex flex-col gap-14">
        <div>
          <h3 className="text-heading-3 pb-8">Authentication app</h3>
          <div className="flex items-center justify-between">
            <p>Use an authentication app to secure your account.</p>
            <Button
              disabled={!MFAEnabled}
              intent="secondary"
              onClick={() => setShowMFAUpdateDialog(true)}
            >
              Edit
            </Button>
          </div>
        </div>
        <div>
          <h3 className="text-heading-3 pb-8">Recovery options</h3>
          <div className="flex items-center justify-between">
            <p>Authenticate yourself with private recovery codes.</p>
            <Button
              disabled={!MFAEnabled}
              intent="primary"
              onClick={() => setShowGenerateNewMFACodesDialog(true)}
            >
              Generate new codes
            </Button>
          </div>
        </div>
      </div>

      <GenerateNewMFACodesDialog
        isOpen={showGenerateNewMFACodesDialog}
        setIsOpen={setShowGenerateNewMFACodesDialog}
      />
      <UpdateMFADialog isOpen={showMFAUpdateDialog} setIsOpen={setShowMFAUpdateDialog} />
    </section>
  );
});
