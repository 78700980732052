import { AlertDialog, AlertDialogProps, toast } from '@highmobility/console-ui-components';
import { FC, useMemo } from 'react';
import QRCode from 'react-qr-code';
import { throttle } from 'radash';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { fakeLoader, verifyTOTP } from '@/services/apiService';
import { MFACode } from '@/components/molecules/MFACode';
import { useMobx } from '@/hooks/useMobx';
import { verifyTOTPValidation } from '@/services/zodService';

export type MFAConnectDialogProps = {
  isOpen: AlertDialogProps['isOpen'];
  setIsOpen: AlertDialogProps['setIsOpen'];
  onConfirm: (code: string) => void;
  MFASettings: {
    id: string;
    url: string;
    secret: string;
    expires_at: number;
  };
};

export const MFAConnectDialog: FC<MFAConnectDialogProps> = (props) => {
  const { session } = useMobx();
  const verifyTOTPMutation = useMutation({
    mutationFn: async (data: { code: string }) => {
      await Promise.all([
        fakeLoader(),
        verifyTOTP({
          id: props.MFASettings.id,
          code: data.code,
        }),
      ]);

      await session.fetchCurrentUser();
    },
    onError: (e: any) => {
      console.error('Error verifying TOTP', e);
      setError('code', { message: 'Invalid code' });
    },
    onSuccess: async (_, { code }) => {
      toast('Two-factor authentication enabled', 'success');
      props.onConfirm(code);
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
  } = useForm<z.infer<typeof verifyTOTPValidation>>({
    resolver: zodResolver(verifyTOTPValidation),
  });

  const onSubmit = useMemo(
    () =>
      throttle(
        { interval: 1000 },
        handleSubmit((data) => verifyTOTPMutation.mutate(data))
      ),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onChangeCode = (value: string) => {
    setValue('code', value);
    if (value.length === 6) {
      onSubmit();
    }
  };

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title="Connect your app"
      confirmButtonIntent="primary"
      confirmLabel={verifyTOTPMutation.isPending ? 'Verifying' : 'Verify'}
      confirmButtonDisabled={verifyTOTPMutation.isPending}
      cancelLabel="Cancel"
      onConfirm={() => onSubmit()}
      onCancel={() => props.setIsOpen(false)}
      timeoutConfig={{
        timeoutInMs: props.MFASettings.expires_at - Date.now(),
        onTimeout: () => {
          toast('Time expired. Please try again.', 'error');
          props.setIsOpen(false);
        },
      }}
    >
      <div className="flex flex-col gap-2">
        <p>
          Use an authentication app like Google Authenticator, Microsoft Authenticator, Duo or Authy
          to scan the QR code below and enter the 6 digit code that the app returns.
        </p>
        <h5 className="text-heading-5">Scan the QR code</h5>
        <p>
          Use an authenticator app or browser extension to scan.{' '}
          <a className="font-bold hover:underline" href="" target="_blank">
            Learn more about enabling 2FA
          </a>
        </p>
      </div>
      <div className="flex justify-center">
        <div className="rounded border-2 border-white">
          <QRCode value={props.MFASettings.url} />
        </div>
      </div>
      <p>
        Or enter <b>{props.MFASettings.secret}</b> in your app.
      </p>
      <form onSubmit={onSubmit}>
        <MFACode onChange={onChangeCode} error={errors.code?.message} />
      </form>
    </AlertDialog>
  );
};
