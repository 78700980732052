import { Button, Plus } from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { generatePath, Link, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { ROUTE_PATHS } from '@/router/routes';

type ProjectListHeaderProps = {
  activeTab: 'all' | 'live';
  allProjectsCount: number;
  liveProjectsCount: number;
  teamId: string;
};

export const ProjectListHeader = observer((props: ProjectListHeaderProps) => {
  const navigate = useNavigate();

  return (
    <div className="flex w-full items-center justify-between gap-4 pb-14">
      <div className="flex items-center gap-4">
        <h3 className="text-heading-3 text-white">Projects</h3>
        <div className="bg-white-20 h-6 w-[1px]" />
        <h4
          className={cx('text-heading-4 cursor-pointer transition-colors hover:text-white', {
            'text-white': props.activeTab === 'all',
            'text-white-20': props.activeTab !== 'all',
          })}
          onClick={() => {
            navigate(generatePath(ROUTE_PATHS.DASHBOARD_ALL, { teamId: props.teamId }));
          }}
        >
          All ({props.allProjectsCount})
        </h4>
        <h4
          className={cx('text-heading-4 cursor-pointer transition-colors hover:text-white', {
            'text-white': props.activeTab === 'live',
            'text-white-20': props.activeTab !== 'live',
          })}
          onClick={() => {
            navigate(generatePath(ROUTE_PATHS.DASHBOARD_LIVE, { teamId: props.teamId }));
          }}
        >
          Live ({props.liveProjectsCount})
        </h4>
      </div>
      <Link to={generatePath(ROUTE_PATHS.CREATE_PROJECT, { teamId: props.teamId })}>
        <Button intent="primary" renderLeftIcon={() => <Plus />} className="ml-auto">
          New data project
        </Button>
      </Link>
    </div>
  );
});
