import { FC, useState } from 'react';

import { MFARecoveryDialog, MFARecoveryDialogProps } from './MFARecoveryDialog';
import { MFAVerifyLoginDialog, MFAVerifyLoginDialogProps } from './MFAVerifyLoginDialog';

export type MFAVerifyDialogProps = {
  isOpen: MFAVerifyLoginDialogProps['isOpen'];
  setIsOpen: MFAVerifyLoginDialogProps['setIsOpen'];
  loginForm: MFAVerifyLoginDialogProps['form'];
  loginMutation: MFAVerifyLoginDialogProps['mutation'];
  recoveryForm: MFARecoveryDialogProps['form'];
  recoveryMutation: MFARecoveryDialogProps['mutation'];
};

export const MFAVerifyDialog: FC<MFAVerifyDialogProps> = (props) => {
  const [showRecoveryDialog, setShowRecoveryDialog] = useState(false);

  return (
    <>
      <MFAVerifyLoginDialog
        isOpen={props.isOpen}
        setIsOpen={props.setIsOpen}
        form={props.loginForm}
        mutation={props.loginMutation}
        onOpenRecoveryDialog={() => setShowRecoveryDialog(true)}
      />
      <MFARecoveryDialog
        isOpen={showRecoveryDialog}
        setIsOpen={setShowRecoveryDialog}
        form={props.recoveryForm}
        mutation={props.recoveryMutation}
      />
    </>
  );
};
