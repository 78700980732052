import { generatePath, Outlet, useNavigate } from 'react-router-dom';
import { SegmentedButtons } from '@highmobility/console-ui-components';

import { ProjectAuthHeader } from '@/components/molecules/ProjectAuthHeader';
import { ProjectInstance } from '@/types';
import { ROUTE_PATHS } from '@/router/routes';
import { useCurrentPath } from '@/hooks/useCurrentPath';
import { useTypedParams } from '@/hooks/useTypedParams';

export function ProjectAuthWebhooksTemplate() {
  const { teamId, projectId, ...params } =
    useTypedParams<typeof ROUTE_PATHS.PROJECT_AUTH_STREAMING>();
  const instance = params.instance as ProjectInstance;
  const navigate = useNavigate();
  const currentPath = useCurrentPath();
  const tabItems = [
    {
      label: 'Configuration',
      value: ROUTE_PATHS.PROJECT_AUTH_WEBHOOKS,
      onClick: () =>
        navigate(generatePath(ROUTE_PATHS.PROJECT_AUTH_WEBHOOKS, { teamId, projectId, instance })),
    },
    {
      label: 'Recent deliveries',
      value: ROUTE_PATHS.PROJECT_AUTH_WEBHOOKS_DELIVERIES,
      onClick: () =>
        navigate(
          generatePath(ROUTE_PATHS.PROJECT_AUTH_WEBHOOKS_DELIVERIES, {
            teamId,
            projectId,
            instance,
          })
        ),
    },
  ] as const;
  /* istanbul ignore next */
  const activeItem = tabItems.find((i) => i.value === currentPath)?.value ?? tabItems[0].value;

  return (
    <main className="flex h-full w-full flex-col overflow-y-auto">
      <ProjectAuthHeader
        title="Webhook"
        instance={instance}
        teamId={teamId}
        projectId={projectId}
      />
      <div className="flex flex-col gap-6 p-8">
        <p className="text-white-60">
          Select an event and we will deliver a POST request to the URL that you provide.
        </p>
        <SegmentedButtons activeItem={activeItem} items={tabItems} />
        <Outlet />
      </div>
    </main>
  );
}
