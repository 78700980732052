import { Button, TextInput, toast } from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { changeTeamName, fakeLoader } from '@/services/apiService';
import { changeTeamNameValidation } from '@/services/zodService';
import { useMobx } from '@/hooks/useMobx';

type ChangeTeamNameFormProps = {
  className?: string;
  defaultValues: z.infer<typeof changeTeamNameValidation>;
  teamId: string;
  disabled?: boolean;
};

export const ChangeTeamNameForm: FC<ChangeTeamNameFormProps> = (props) => {
  const { teams } = useMobx();

  const teamNameMutation = useMutation({
    mutationFn: async (data: z.infer<typeof changeTeamNameValidation>) => {
      return Promise.all([changeTeamName(data, props.teamId), fakeLoader()]);
    },
    onError: (e) => {
      console.error('Error submitting the form', e);
      toast('Oops! Changes were not saved. Please try again!', 'error');
    },
    onSuccess: () => {
      teams.fetchTeams();
      toast('Team name updated', 'success');
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.infer<typeof changeTeamNameValidation>>({
    resolver: zodResolver(changeTeamNameValidation),
    defaultValues: props.defaultValues,
  });
  const onSubmit = handleSubmit((data) => teamNameMutation.mutate(data));

  return (
    <form className={cx(props.className, 'max-w-[800px]')} onSubmit={onSubmit}>
      <div className="mb-8 flex flex-col gap-6">
        <TextInput
          label="Name"
          placeholder={props.defaultValues?.name}
          {...register('name')}
          defaultValue={props.defaultValues?.name}
          error={errors.name?.message}
          disabled={teamNameMutation.isPending || props.disabled}
        />
      </div>
      <div className="flex justify-end">
        <Button disabled={teamNameMutation.isPending || props.disabled} type="submit">
          {teamNameMutation.isPending ? 'Loading' : 'Save'}
        </Button>
      </div>
    </form>
  );
};
