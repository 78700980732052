import {
  DotMenu,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
} from '@highmobility/console-ui-components';
import { generatePath, Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { RenameProjectDialog } from '@/components/organisms/RenameProjectDialog';
import { ROUTE_PATHS } from '@/router/routes';
import { usePermissions } from '@/hooks/usePermissions';

type ProjectCardOptionsDropdownProps = {
  projectId: string;
  projectName: string;
  teamId: string;
};

export const ProjectCardOptionsDropdown = observer((props: ProjectCardOptionsDropdownProps) => {
  const [showRenameProjectDialog, setShowRenameProjectDialog] = useState(false);
  const { userHasPermission } = usePermissions(props.teamId);

  return (
    <>
      <Dropdown
        renderButton={() => (
          <div className="text-white-20 group flex h-14 w-14 cursor-pointer items-center justify-center">
            <DotMenu className="min-w-4 min-h-4 hover:text-white-80 group-hover:text-white-60 h-4 w-4 text-white transition-colors" />
          </div>
        )}
      >
        <DropdownMenu>
          {userHasPermission('projects.edit') && (
            <DropdownMenuItem label="Rename" onClick={() => setShowRenameProjectDialog(true)} />
          )}
          <DropdownMenuSeparator />
          <Link
            to={generatePath(ROUTE_PATHS.PROJECT, {
              teamId: props.teamId,
              projectId: props.projectId,
            })}
          >
            <DropdownMenuItem label="Open" />
          </Link>
        </DropdownMenu>
      </Dropdown>
      <RenameProjectDialog
        teamId={props.teamId}
        projectId={props.projectId}
        projectName={props.projectName}
        isOpen={showRenameProjectDialog}
        setIsOpen={setShowRenameProjectDialog}
      />
    </>
  );
});
