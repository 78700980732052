import { LoaderFunction, redirect } from 'react-router-dom';

import { AUTH_REDIRECT_PARAM } from '@/constants';
import { mobxStore } from '@/store';
import { ROUTE_PATHS } from '@/router/routes';
import { RouteObject } from '@/types';

export const authMiddleware = (routes: RouteObject[]) => {
  return routes.map((route) => {
    const loader: LoaderFunction = async (loaderData) => {
      if (route.type === 'public') {
        return route.loader ? route.loader(loaderData) : null;
      }

      const { session } = mobxStore;
      const user = await session.fetchCurrentUser();

      if (route.type === 'guest') {
        const url = new URL(loaderData.request.url);
        const redirectTo = url.searchParams.get(AUTH_REDIRECT_PARAM);

        if (user?.status !== 'active') {
          return route.loader ? route.loader(loaderData) : null;
        }

        if (redirectTo) {
          return redirect(redirectTo);
        }

        return redirect(ROUTE_PATHS.DASHBOARD);
      }

      if (user?.status === 'active') {
        return route.loader ? route.loader(loaderData) : null;
      }

      const url = new URL(loaderData.request.url);
      const path = url.pathname + url.search;
      return redirect(`${ROUTE_PATHS.LOGIN}?${AUTH_REDIRECT_PARAM}=${path}`);
    };

    return {
      ...route,
      loader,
    };
  });
};
