type LockedTextFieldProps = {
  label?: string;
  value: string;
};

export const LockedTextField = (props: LockedTextFieldProps) => {
  return (
    <div className="flex flex-col gap-2">
      {props.label && <span className="text-body-small text-white-80">{props.label}</span>}
      <div className="bg-white-4 text-white-60 border-white-20 text-body-small flex w-full cursor-default items-center rounded border px-5 py-[10px] font-semibold">
        {props.value}
      </div>
    </div>
  );
};
