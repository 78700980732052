import { Info, Notification } from '@highmobility/console-ui-components';
import { Link } from 'react-router-dom';

export const SharedDataOfferingNotification = () => {
  return (
    <Notification
      className="w-full"
      renderLeftIcon={(c) => <Info className={c} />}
      renderAdditionalContent={() => (
        <p className="text-white-60">
          Selection applied across various brands.{' '}
          <Link
            to="https://www.high-mobility.com/car-data"
            target="_blank"
            className="font-bold hover:underline"
          >
            Learn more about data offering.
          </Link>
        </p>
      )}
    >
      Shared Data offering
    </Notification>
  );
};
