import { cva, cx } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

type TagType = 'light' | 'dark' | 'error' | 'alert' | 'success';
export type TagProps<TType extends TagType> = PropsWithChildren<{
  disabled?: boolean;
  type: TType;
  round?: boolean;
  withBorder?: TType extends 'error' | 'alert' | 'success' ? boolean : false;
  className?: string;
}>;

const tag = cva('inline-flex items-center gap-1 text-label-small py-1 px-2', {
  variants: {
    disabled: {
      true: '',
      false: '',
    },
    type: {
      light: 'bg-white-4',
      dark: 'bg-black-20',
      error: '',
      alert: '',
      success: '',
    },
    round: {
      true: 'rounded-[20px]',
      false: 'rounded-[4px]',
    },
    withBorder: {
      true: 'border',
    },
  },
  compoundVariants: [
    {
      disabled: true,
      type: 'light',
      className: 'text-white-40',
    },
    {
      disabled: false,
      type: 'light',
      className: 'text-white-80',
    },
    {
      disabled: true,
      type: 'dark',
      className: 'text-white-40',
    },
    {
      disabled: false,
      type: 'dark',
      className: 'text-white-80',
    },
    {
      withBorder: true,
      type: 'error',

      className: 'bg-error-600-shade text-error-600 border-error-600-shade',
    },
    {
      withBorder: false,
      type: 'error',
      className: 'bg-error-600 text-white',
    },
    {
      withBorder: true,
      type: 'alert',
      className: 'bg-alert-500-shade text-alert-500 border-alert-500-shade',
    },
    {
      withBorder: false,
      type: 'alert',
      className: 'bg-alert-500 text-dark-green-900',
    },
    {
      withBorder: true,
      type: 'success',
      className: 'bg-success-500-shade text-success-400 border-success-500',
    },
    {
      withBorder: false,
      type: 'success',
      className: 'bg-success-500 text-dark-green-900',
    },
  ],
});

export function Tag<TType extends TagType>(props: TagProps<TType>) {
  return (
    <div
      className={cx(
        tag({
          disabled: !!props.disabled,
          type: props.type,
          round: !!props.round,
          withBorder: !!props.withBorder,
        }),
        props.className
      )}
    >
      {props.children}
    </div>
  );
}
