import {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { SelectInputProps } from '../components/molecules/SelectInput';

export function useNativeSelectSearch<TItemValue>(
  dropdownItems: SelectInputProps<TItemValue>['items'],
  dropdownItemsRef: MutableRefObject<HTMLDivElement[]>,
  setOpen: Dispatch<SetStateAction<boolean>>,
  focused: boolean,
  onChange: SelectInputProps<TItemValue>['onChange'],
  value: SelectInputProps<TItemValue>['value']
) {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const selectItem = useCallback(
    (item: SelectInputProps<TItemValue>['items'][number]) => {
      item.onClick?.();
      const newValue = item.value;
      if (newValue && onChange) {
        onChange(newValue);
      }
    },
    [onChange]
  );

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (!focused || event.ctrlKey || event.metaKey || event.altKey || event.shiftKey) {
        return;
      }

      if (event.key.length === 1) {
        event.preventDefault();
        setSearchTerm((prev) => prev + event.key);
        const dropdownItemIndex = dropdownItems.findIndex((item) =>
          item.label.toLowerCase().startsWith(searchTerm)
        );
        const dropdownItem = dropdownItems[dropdownItemIndex];
        if (dropdownItem) {
          selectItem(dropdownItem);
        }

        if (dropdownItemIndex !== -1) {
          dropdownItemsRef.current?.[dropdownItemIndex].scrollIntoView?.({
            block: 'nearest',
            inline: 'center',
          });
          const newValue = dropdownItems[dropdownItemIndex].value;
          onChange?.(newValue);
        }

        return;
      }

      const currentIndex = Math.max(
        dropdownItems.findIndex((item) => item.value === value),
        -1
      );
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        const dropdownItem = dropdownItems[currentIndex + 1];
        if (dropdownItem) {
          selectItem(dropdownItem);
        }

        return;
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault();
        const dropdownItem = dropdownItems[currentIndex - 1];
        if (dropdownItem) {
          selectItem(dropdownItem);
        }

        return;
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [dropdownItems, searchTerm, setOpen, dropdownItemsRef, onChange, selectItem, value, focused]);

  useEffect(() => {
    const myTimeout = setTimeout(() => {
      setSearchTerm('');
    }, 1000);

    return () => {
      clearTimeout(myTimeout);
    };
  }, [searchTerm]);
}
