import { AlertDialog, DialogProps, toast } from '@highmobility/console-ui-components';
import { FC } from 'react';
import { useMutation } from '@tanstack/react-query';

import { deleteMFA, fakeLoader } from '@/services/apiService';
import { useMobx } from '@/hooks/useMobx';

export type DisableMFADialogProps = {
  isOpen: DialogProps['isOpen'];
  setIsOpen: DialogProps['setIsOpen'];
};

export const DisableMFADialog: FC<DisableMFADialogProps> = (props) => {
  const { session } = useMobx();

  const disableMFAMutation = useMutation({
    mutationFn: async () => {
      await Promise.all([fakeLoader(), deleteMFA()]);
      await session.fetchCurrentUser();
    },
    onError: (e: any) => {
      toast(e?.response?.data?.message || 'Something went wrong. Please try again.', 'error');
    },
    onSuccess: async () => {
      toast('Two-factor authentication disabled', 'success');
      props.setIsOpen(false);
    },
  });

  return (
    <AlertDialog
      isOpen={props.isOpen}
      setIsOpen={props.setIsOpen}
      title="Disable Two-factor authentication"
      confirmButtonIntent="primary"
      confirmLabel={disableMFAMutation.isPending ? 'Disabling' : 'Disable'}
      confirmButtonDisabled={disableMFAMutation.isPending}
      cancelLabel="Cancel"
      onConfirm={() => disableMFAMutation.mutate()}
      onCancel={() => props.setIsOpen(false)}
    >
      Are you sure you want to disable this method?
    </AlertDialog>
  );
};
