import { browserSupportsWebAuthn, startAuthentication } from '@simplewebauthn/browser';
import { Button } from '@highmobility/console-ui-components';
import { cva } from 'class-variance-authority';
import { FC } from 'react';
import { FloatingOverlay } from '@floating-ui/react';
import { useMutation } from '@tanstack/react-query';

import { AuthModal } from '@/components/atoms/AuthModal';
import { fetchPasskeyLoginOptions, loginWithPasskey } from '@/services/apiService';

export type LoginWithPasskeyProps = {
  setIsOpen: (open: boolean) => void;
  onError: (error: string) => void;
  onSuccess: () => void;
  invitationToken: string | undefined;
};

const floatingOverlay = cva('flex items-center justify-center', {
  variants: {
    isOpen: {
      true: 'z-50 backdrop-blur-sm bg-black-40 transition-colors',
    },
  },
});

export const LoginWithPasskey: FC<LoginWithPasskeyProps> = (props) => {
  const loginWithPasskeyMutation = useMutation({
    mutationFn: async () => {
      if (!browserSupportsWebAuthn()) {
        props.onError('Your browser does not support Passkey login.');
        props.setIsOpen(false);
        return;
      }

      const { data } = await fetchPasskeyLoginOptions();
      const authResponse = await startAuthentication(data);
      await loginWithPasskey(authResponse, {
        invitation_token: props.invitationToken,
      });
    },
    onError: (e: any) => {
      console.error('Failed to login with passkey', e);
      props.onError('Authentication failed');
      props.setIsOpen(false);
    },
    onSuccess: async () => {
      props.onSuccess();
    },
  });

  return (
    <FloatingOverlay className={floatingOverlay({ isOpen: loginWithPasskeyMutation.isPending })}>
      <AuthModal title="Log in with PassKey" showLoader={loginWithPasskeyMutation.isPending}>
        <>
          <p className="text-body-regular font-bold">
            Your device will ask for your fingerprint, face or screen lock
          </p>
          <div className="flex items-center gap-2 justify-end">
            <Button
              intent="secondary"
              onClick={() => props.setIsOpen(false)}
              disabled={loginWithPasskeyMutation.isPending}
            >
              Cancel
            </Button>
            <Button
              intent="primary"
              onClick={() => loginWithPasskeyMutation.mutate()}
              disabled={loginWithPasskeyMutation.isPending}
            >
              {loginWithPasskeyMutation.isPending ? 'Verifying' : 'Continue'}
            </Button>
          </div>
        </>
      </AuthModal>
    </FloatingOverlay>
  );
};
