function arrayBufferToString(arrayBuffer: ArrayBuffer) {
  const byteArray = new Uint8Array(arrayBuffer);
  let byteString = '';
  for (let i = 0; i < byteArray.byteLength; i++) {
    byteString += String.fromCharCode(byteArray[i]);
  }
  return byteString;
}

function arrayBufferToBase64String(arrayBuffer: ArrayBuffer) {
  return btoa(arrayBufferToString(arrayBuffer));
}

function privateKeyToPem(key: string) {
  return `-----BEGIN PRIVATE KEY-----
${key}-----END PRIVATE KEY-----`;
}

function addLineBreaks(argStr: string) {
  let str = argStr;
  let finalString = '';
  while (str.length > 0) {
    finalString += `${str.substring(0, 64)}\n`;
    str = str.substring(64);
  }
  return finalString;
}

function formatAsPem(str: string, type: 'PUBLIC') {
  return `-----BEGIN ${type} KEY-----
${str}-----END ${type} KEY-----`;
}

function spkiToPEM(keydata: ArrayBuffer) {
  const keydataS = arrayBufferToString(keydata);
  const keydataB64 = window.btoa(keydataS);
  const keydataB64Pem = formatAsPem(addLineBreaks(keydataB64), 'PUBLIC');
  return keydataB64Pem;
}

export const generateKeyPair = async () => {
  try {
    const pair = await window.crypto.subtle.generateKey(
      {
        name: 'ECDH',
        namedCurve: 'P-256',
      },
      true,
      ['deriveKey', 'deriveBits']
    );

    const keydata = await window.crypto.subtle.exportKey(
      'spki', // can be "jwk" (public or private), "raw" (public only), "spki" (public only), or "pkcs8" (private only)
      pair.publicKey // can be a publicKey or privateKey, as long as extractable was true
    );

    const publicKey = spkiToPEM(keydata);

    const privatekeydata = await window.crypto.subtle.exportKey(
      'pkcs8', // can be "jwk" (public or private), "raw" (public only), "spki" (public only), or "pkcs8" (private only)
      pair.privateKey // can be a publicKey or privateKey, as long as extractable was true
    );

    const privateKey = privateKeyToPem(addLineBreaks(arrayBufferToBase64String(privatekeydata)));

    return { privateKey, publicKey };
  } catch (e) {
    throw new Error('This feature is not supported in your browser.');
  }
};
