import { z } from 'zod';

const appConfigSchema = z.object({
  CONSOLE_UI_ENDPOINT: z
    .string()
    .regex(/^[^:]+:[0-9]+$/)
    .optional()
    .default('localhost:8080'),
  STRIPE_PUBLIC_KEY: z.string().regex(/^pk_.*$/),
});

export const AppConfig: z.infer<typeof appConfigSchema> = {
  CONSOLE_UI_ENDPOINT:
    (typeof process !== 'undefined' && process.env.CONSOLE_UI_ENDPOINT) || 'localhost:8080',
  STRIPE_PUBLIC_KEY:
    (typeof process !== 'undefined' ? process.env.STRIPE_PUBLIC_KEY : window.STRIPE_PUBLIC_KEY) ||
    '',
};

validateAppConfig();

function validateAppConfig() {
  if (typeof process !== 'undefined' && process.env.NODE_ENV === 'test') {
    return;
  }

  try {
    appConfigSchema.parse(AppConfig);
  } catch (e) {
    console.error('App config validation failed:', e instanceof Error ? e.message : e);
  }
}
