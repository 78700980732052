import {
  Chevron,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuSeparator,
  HMLogo,
  NavLink,
  ScreenSeparator,
} from '@highmobility/console-ui-components';
import { cx } from 'class-variance-authority';
import { FC, useState } from 'react';
import { generatePath, Link, useNavigate, useParams } from 'react-router-dom';

import { ROUTE_PATHS } from '@/router/routes';
import { useCurrentPath } from '@/hooks/useCurrentPath';
import { useMobx } from '@/hooks/useMobx';

import { SwitchToLegacyDialog } from './SwitchToLegacyDialog';

export type DashboardHeaderProps = {
  className?: string;
};

export const DashboardHeader: FC<DashboardHeaderProps> = ({ className }) => {
  const dropdownOffset = { crossAxis: 8 };
  const { session } = useMobx();
  const navigate = useNavigate();
  const currentPath = useCurrentPath();
  const params = useParams();
  const [showSwitchToLegacyDialog, setShowSwitchToLegacyDialog] = useState(false);

  const onLogout = async () => {
    await session.logout();
    navigate(ROUTE_PATHS.LOGIN);
  };

  return (
    <div>
      <nav className={cx('flex w-full items-center py-4', className)}>
        <Link className="pr-[10px]" to={`/`}>
          <HMLogo className="pr-1 text-white" />
        </Link>
        <div className="flex items-center gap-2">
          <Link to={generatePath(ROUTE_PATHS.DASHBOARD_ALL, { teamId: params.teamId || null })}>
            <NavLink
              active={
                currentPath === ROUTE_PATHS.DASHBOARD_ALL ||
                currentPath === ROUTE_PATHS.DASHBOARD_LIVE
              }
            >
              Dashboard
            </NavLink>
          </Link>
          <Dropdown
            offset={dropdownOffset}
            renderButton={(open) => (
              <NavLink
                renderRightIcon={() => (
                  <Chevron
                    className={cx('transition-transform', {
                      'rotate-180': open,
                    })}
                  />
                )}
              >
                Docs
              </NavLink>
            )}
          >
            <DropdownMenu>
              <a href="https://docs.high-mobility.com/" target="_blank" rel="noreferrer">
                <DropdownMenuItem label="API Guides" />
              </a>
              <DropdownMenuSeparator />
              <a
                href="https://docs.high-mobility.com/api-references/"
                target="_blank"
                rel="noreferrer"
              >
                <DropdownMenuItem label="API References" />
              </a>
              <DropdownMenuSeparator />
              <a href="https://airtable.com/shry3EDO6lLiBunTm" target="_blank" rel="noreferrer">
                <DropdownMenuItem label="Data Catalog" label2="in Airtable" />
              </a>
            </DropdownMenu>
          </Dropdown>
          <Dropdown
            offset={dropdownOffset}
            renderButton={(open) => (
              <NavLink
                renderRightIcon={() => (
                  <Chevron
                    className={cx('transition-transform', {
                      'rotate-180': open,
                    })}
                  />
                )}
              >
                Resources
              </NavLink>
            )}
          >
            <DropdownMenu>
              <a href="https://www.high-mobility.com/support" target="_blank" rel="noreferrer">
                <DropdownMenuItem label="Support" />
              </a>
              <DropdownMenuSeparator />
              <a href="https://hm-developers.slack.com/" target="_blank" rel="noreferrer">
                <DropdownMenuItem label="DevChat" />
              </a>
              <DropdownMenuSeparator />
              <a href="https://www.high-mobility.com/devtalks" target="_blank" rel="noreferrer">
                <DropdownMenuItem label="DevTalks" />
              </a>
              <DropdownMenuSeparator />
              <a href="https://www.high-mobility.com/downloads" target="_blank" rel="noreferrer">
                <DropdownMenuItem label="Downloads" />
              </a>
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="ml-auto flex items-center">
          <Dropdown
            offset={dropdownOffset}
            renderButton={(open) => (
              <NavLink
                renderRightIcon={() => (
                  <Chevron
                    className={cx('transition-transform', {
                      'rotate-180': open,
                    })}
                  />
                )}
              >
                <div className="flex items-center">
                  <span>Account</span>
                </div>
              </NavLink>
            )}
          >
            <DropdownMenu>
              <Link to={ROUTE_PATHS.ACCOUNT_SETTINGS}>
                <DropdownMenuItem label="Account" />
              </Link>
              <DropdownMenuSeparator />
              <Link to="/teams">
                <DropdownMenuItem label="Teams" />
              </Link>
              <DropdownMenuSeparator />
              <DropdownMenuItem label="Sign out" onClick={() => onLogout()} />
              <DropdownMenuItem
                label="Switch to Legacy"
                onClick={() => setShowSwitchToLegacyDialog(true)}
              />
            </DropdownMenu>
          </Dropdown>
        </div>
      </nav>
      <ScreenSeparator />
      <SwitchToLegacyDialog
        isOpen={showSwitchToLegacyDialog}
        setIsOpen={setShowSwitchToLegacyDialog}
      />
    </div>
  );
};
