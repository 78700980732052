import { cva, cx, VariantProps } from 'class-variance-authority';
import { forwardRef, ForwardRefRenderFunction, HTMLAttributes } from 'react';

const radio = cva(
  [
    'inline-flex',
    'items-center',
    'justify-center',
    'rounded-full',
    'transition-colors',
    'outline-none',
    'w-[14px]',
    'h-[14px]',
    'rounded',
    'bg-white-12',
    'appearance-none',
    'border',
    'border-white-40',
    'shadow-[0_4px_4px_0px_rgba(16,16,16,0.10)_inset]',
    'cursor-pointer',
    'disabled:shadow-none',
    'disabled:bg-white-4',
    'disabled:border-white-12',
    'disabled:cursor-not-allowed',
    'flex-shrink-0',
  ],
  {
    variants: {
      color: {
        green: 'checked:bg-success-500',
        orange: 'checked:bg-alert-500',
        white: 'checked:bg-white',
        red: 'checked:bg-error-600',
      },
    },
  }
);

export type RadioProps = HTMLAttributes<HTMLInputElement> &
  VariantProps<typeof radio> & {
    name: string;
    defaultChecked?: boolean;
    disabled?: boolean;
    color?: 'green' | 'orange' | 'white' | 'red';
  };

const RadioComponent: ForwardRefRenderFunction<HTMLInputElement, RadioProps> = (
  { className, name, defaultChecked = false, disabled, color = 'green', ...props },
  ref
) => (
  <input
    ref={ref}
    className={cx(radio({ color }), className)}
    type="radio"
    name={name}
    defaultChecked={defaultChecked}
    disabled={!!disabled}
    {...props}
  />
);

export const Radio = forwardRef(RadioComponent);
